import { useTranslation } from 'react-i18next';
import {
  ActionStatus,
  actionStatusToLabel,
} from '../../../../models/actionStatus';
import {
  MultipleSelect,
  MultiSelectActions,
} from '../../../atoms/MultipleSelect/MultipleSelect';

export interface StatusSelectProps {
  selectedStatuses: ActionStatus[];
  onSelectedStatusesChanged: (statuses: ActionStatus[]) => void;
}

export function StatusSelect(props: StatusSelectProps) {
  const { t } = useTranslation();

  return (
    <MultipleSelect
      label={t('status')}
      name="status-filter"
      options={Object.values(ActionStatus).map((actionStatus) => ({
        label: actionStatusToLabel(actionStatus),
        value: actionStatus,
      }))}
      values={props.selectedStatuses}
      actions={[MultiSelectActions.Clear, MultiSelectActions.Close]}
      onChange={props.onSelectedStatusesChanged}
    />
  );
}

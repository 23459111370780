import { useEffect, useRef, useState } from 'react';
import { ActionHistoryApi } from '../api/actionHistoryApi';
import { ActionStatus } from '../models/actionStatus';
import { ActionTypeName } from '../models/actionTypeName';
import { Environment } from '../models/environment';
import { useActionTypesStore } from '../stores/actionTypesStore';
import { unwrap } from '../utilities/assertions';
import { repeatPromiseUntil } from '../utilities/repeatedPromise';

/**
 * Props for {@link useCheckExistingRunningAction}. Defines criterion to find the action.
 */
export interface UseCheckExistingActionProps {
  /**
   * The environment id of the action to search.
   */
  environmentId?: Environment['id'];
  /**
   * The {@link ActionTypeName} of the action to search.
   */
  actionTypeName: ActionTypeName;
}

/**
 * Checks if a running action matching {@link props} properties exists. And check it repeatedly until the action is no longer running.
 * @param props See {@link UseCheckExistingActionProps}.
 * @returns
 *   * `undefined` if the check is in progress.
 *   * `true` if there is an existing running action matching {@link props}.
 *   * `false` otherwise.
 */
export function useCheckExistingRunningAction(
  props: UseCheckExistingActionProps
): boolean | undefined {
  const [actionExists, setActionExists] = useState<boolean | undefined>(
    undefined
  );
  const [actionTypesSnapshot, fetchActionTypes] = useActionTypesStore(
    (state) => [state.snapshot, state.fetch]
  );
  const abortController = useRef(new AbortController());

  useEffect(() => {
    fetchActionTypes();
  }, [fetchActionTypes]);

  useEffect(() => {
    if (props.environmentId === undefined) return;
    if (!actionTypesSnapshot.isOver()) return;

    (async () => {
      const actionTypes = actionTypesSnapshot.getSucceededData();

      const actionType = unwrap(
        actionTypes.find((at) => at.name === props.actionTypeName)
      );

      const actionHistoryApi = new ActionHistoryApi();
      const runningActions = await actionHistoryApi.getAll(
        {
          'environment[]': [unwrap(props.environmentId)],
          'status[]': [ActionStatus.Running],
          'action_type[]': [actionType.id],
        },
        { abortController: abortController.current }
      );

      const anotherExistingActionInProgress = runningActions.length > 0;
      setActionExists(anotherExistingActionInProgress);

      if (anotherExistingActionInProgress) {
        const runningAction = runningActions[0];
        await repeatPromiseUntil(
          () =>
            actionHistoryApi.get(runningAction.id, {
              abortController: abortController.current,
            }),
          (action) => action.status !== ActionStatus.Running,
          10000,
          { initialDelay: true }
        );
        setActionExists(false);
      }
    })();

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [actionTypesSnapshot, props.actionTypeName, props.environmentId]);

  return actionExists;
}

import { FormatException } from '../exceptions/formatException';

/**
 * Represents a formatted sentence skeleton divided into parts.
 * Each part can be :
 * * a simple `string`,
 * * or a formatted part containing
 *   * a `string` for the formatting key.
 *   * another {@link FormattedSentenceSkeleton}.
 */
export type FormattedSentenceSkeleton = (
  | string
  | [string, FormattedSentenceSkeleton]
)[];

const openingTagSearch = /(.*?)<format-([^>]+)>(.*)/;

/**
 * From a {@link sentence}, extracts the formatting skeleton.
 * Its main purpose is to break down the structure of a string (meant to be formatted), to allow adding styles to each formatted part later.
 * @details The corresponding test file may help to understand how a sentence is broke down depending on its format attribute.
 * @example
 * const result = extractSentenceSkeleton('This is a <format-1>formatted text</format-1>.');
 * console.log(result); // ['This is a ', ['1', ['formatted text']], '.']
 */
export function extractSentenceSkeleton(
  sentence: string
): FormattedSentenceSkeleton {
  if (sentence.length === 0) return [];

  const openingRes = openingTagSearch.exec(sentence);
  if (openingRes === null) {
    return [sentence];
  }
  const leading = openingRes[1];
  const formatKey = openingRes[2];
  const trailingWithTag = openingRes[3];

  const closingTagSearch = new RegExp(`(.*?)</format-${formatKey}>(.*)`);
  const closingRes = closingTagSearch.exec(trailingWithTag);
  if (closingRes === null) {
    throw new FormatException(
      `Could not find </format-${formatKey}> in ${sentence} to match opening tag.`
    );
  }

  const formattedPart = closingRes[1];
  const trailing = closingRes[2];

  const res: FormattedSentenceSkeleton = [];
  if (leading.length > 0) {
    res.push(leading);
  }
  res.push([formatKey, extractSentenceSkeleton(formattedPart)]);
  if (trailing.length > 0) {
    res.push(...extractSentenceSkeleton(trailing));
  }

  return res;
}

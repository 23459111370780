import {
  styled,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';
import tailwindRawConfig from '../../../tailwind.config.js';

/**
 * @see {@link MUITextField}
 */
export function TextField(props: TextFieldProps) {
  return <StyledMUITextField {...props} variant="outlined" size="small" />;
}

export type TextFieldProps = Omit<MUITextFieldProps, 'variant' | 'size'>;

const StyledMUITextField = styled(MUITextField)<MUITextFieldProps>(() => ({
  '& .MuiInput-root': {
    '&::before': {
      borderBottom: `1px solid ${tailwindRawConfig.theme.extend.colors.grey[300]}`,
    },
    '&:hover:not(.Mui-disabled)': {
      '&::before': {
        borderBottom: `2px solid ${tailwindRawConfig.theme.extend.colors.grey[300]} !important`,
        color: 'transparent',
      },
    },
  },
}));

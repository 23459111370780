import { NotUsableException } from '../exceptions/notUsableException';
import { ParameterException } from '../exceptions/parameterException';
import { UnhandledSwitchCaseException } from '../exceptions/unhandledSwitchCaseException';
import { unwrap } from './assertions';

export enum PromiseState {
  /**
   * The execution has not started yet.
   */
  NotStarted,
  /** The execution is running. */
  Running,
  /** The execution has ended successfully. If a result was expected, it is available. */
  Succeeded,
  /** The execution has ended with errors. If a result was expected, it is not available. */
  Failed,
}

interface TrackPromiseOptions<T> {
  /**
   * Data to pass when the request is still running.
   */
  defaultData?: T;
  /**
   * If `true`, `defaultData` will be used to fill {@link PromiseSnapshot.data} when the processing results in error. Otherwise data will be `undefined`.
   *
   * Defaults to `false`.
   */
  keepsDefaultDataOnError?: boolean;
  /**
   * An abort controller, to cancel the promise.
   */
  abortController?: AbortController | null;
}

export interface PromiseSnapshotInterface<T> {
  /** The current promise state, see {@link PromiseState} for more. */
  state: PromiseState;
  /** The data associated with the promise task execution. Represents the result of its processing. */
  data?: T;
  /** The error associated with the promise task execution. Represents the error of its processing if it failed to execute successfully. */
  error?: unknown;
}

export type PromiseSnapshotData =
  | object
  | number
  | string
  | symbol
  | boolean
  | null;

/**
 * Mapper for each {@link PromiseState}.
 * You can directly map to a function. Alternatively you can map to another mapper function by giving its name key (note that this is a one-level redirection, more level of redirection is forbidden).
 * @typeParam T The type of the {@link PromiseSnapshot} expected data.
 * @param <U> The type of the result of the mapping.
 */
export interface PromiseSnapshotMap<T, U> {
  notStarted: (() => U) | 'running';
  running: (() => U) | 'notStarted';
  succeeded: ((data: T) => U) | 'notStarted' | 'running';
  failed: ((error: unknown) => U) | 'notStarted' | 'running';
}

/**
 * Represents a snapshot of a promise state.
 */
export class PromiseSnapshot<T extends PromiseSnapshotData>
  implements PromiseSnapshotInterface<T>
{
  public state: PromiseState = PromiseState.NotStarted;
  public data?: T | undefined = undefined;
  public error?: unknown = undefined;

  constructor(data?: Partial<PromiseSnapshotInterface<T>>) {
    if (data !== undefined) {
      Object.assign(this, data);
    }
  }

  /**
   * Returns the {@link data} contained in this snapshot.
   * @throws {@link NotUsableException} If the promise {@link state} is not {@link PromiseState.Succeeded}, then throw an error.
   */
  public getSucceededData(): T {
    if (this.state !== PromiseState.Succeeded) {
      throw new NotUsableException(
        `Attempted to get data of a succeeded ${
          this.constructor.name
        } but its current state is ${this.getReadableState()}`
      );
    }
    return unwrap(this.data);
  }

  /**
   * Tracks the promise and for each step of it, yields a snapshot reprensenting the current state.
   * @example
   * async function fetchMagicNumber(): Promise<number> {
   *   await new Promise((resolve) => setTimeout(resolve, 2000));
   *   return 42;
   * }
   *
   * async function fetchMagicNumberWithTracking() {
   *   for await (const snapshot of PromiseSnapshot.trackPromise(
   *     fetchMagicNumber
   *   )) {
   *     console.log(snapshot);
   *   }
   * }
   * @param promise The promise to execute.
   * @param options The options of tracking. See {@link TrackPromiseOptions} for more details.
   */
  public static async *trackPromise<T extends PromiseSnapshotData>(
    promise: () => Promise<T>,
    options?: TrackPromiseOptions<T>
  ): AsyncGenerator<PromiseSnapshot<T>, void, unknown> {
    let canceled = false;

    if (
      options?.abortController !== undefined &&
      options?.abortController !== null
    ) {
      options.abortController.signal.addEventListener(
        'abort',
        () => (canceled = true)
      );
    }

    if (canceled) return;

    const runningPromise = PromiseSnapshot.buildRunning<T>();
    runningPromise.data = options?.defaultData;
    yield runningPromise;
    try {
      const data = await promise();

      if (canceled) return;

      yield PromiseSnapshot.buildSucceeded(data);
    } catch (e) {
      if (canceled) return;

      const failedPromise = PromiseSnapshot.buildFailed<T>(e);
      failedPromise.data = options?.keepsDefaultDataOnError
        ? options.defaultData
        : undefined;
      yield failedPromise;
    }
  }

  /**
   * Alternative version of {@link trackPromise} using a setter.
   * @param promise The promise to execute.
   * @param setter The callback function to retrieve each snapshot.
   * @param options The options of tracking. See {@link TrackPromiseOptions} for more details.
   */
  public static async trackPromiseSetter<T extends PromiseSnapshotData>(
    promise: () => Promise<T>,
    setter: (snapshot: PromiseSnapshot<T>) => void,
    options?: TrackPromiseOptions<T>
  ): Promise<void> {
    for await (const snapshot of this.trackPromise(promise, options)) {
      setter(snapshot);
    }
  }

  /**
   * @param data The data after simulated success.
   * @returns A succeeded {@link PromiseSnapshot}.
   */
  public static buildSucceeded<T extends PromiseSnapshotData>(
    data: T
  ): PromiseSnapshot<T> {
    const snapshot = new PromiseSnapshot<T>();
    snapshot.state = PromiseState.Succeeded;
    snapshot.data = data;
    return snapshot;
  }

  /**
   * @returns A {@link PromiseSnapshot} with state set to {@link PromiseState.Running}.
   */
  public static buildRunning<
    T extends PromiseSnapshotData
  >(): PromiseSnapshot<T> {
    const snapshot = new PromiseSnapshot<T>();
    snapshot.state = PromiseState.Running;
    return snapshot;
  }

  /**
   * @returns A {@link PromiseSnapshot} with state set to {@link PromiseState.Failed} and optionally an error set.
   */
  public static buildFailed<T extends PromiseSnapshotData>(
    error?: unknown
  ): PromiseSnapshot<T> {
    const snapshot = new PromiseSnapshot<T>();
    snapshot.state = PromiseState.Failed;
    snapshot.error = error;
    return snapshot;
  }

  /**
   * @returns `true` if the promise has finished (whether is it is a success or a failure). Otherwise, returns `false`.
   */
  public isOver(): boolean {
    return (
      this.state === PromiseState.Succeeded ||
      this.state === PromiseState.Failed
    );
  }

  /**
   * @returns A clone of this instance. (This is not a deep copy.)
   */
  public clone(): PromiseSnapshot<T> {
    const theClone = new PromiseSnapshot<T>();
    Object.assign(theClone, this);
    return theClone;
  }

  /**
   * Maps a snapshot with type {@link T} to a snapshot with the type {@link U}.
   * @param dataMapping The function to map data type {@link T} to data type {@link U}.
   * @returns The snapshot with the same internal state except for its {@link data} attribute which is map to the new type.
   */
  public mapType<U extends PromiseSnapshotData>(
    dataMapping: (data: T) => U
  ): PromiseSnapshot<U> {
    const snapshot = new PromiseSnapshot<U>();
    snapshot.state = this.state;
    snapshot.error = this.error;
    snapshot.data =
      this.data !== undefined ? dataMapping(this.data) : undefined;
    return snapshot;
  }

  /**
   * Maps the different state of the promise to functions to be executed.
   * @param mapper See {@link PromiseSnapshotMap}.
   * @returns The result of one of the {@link mapper} function depending on the state of the {@link PromiseSnapshot}.
   * @throws {@link ParameterException} if a redirection leads to another thing that a function.
   */
  public map<ReturnType>(
    mapper: PromiseSnapshotMap<T, ReturnType>
  ): ReturnType {
    switch (this.state) {
      case PromiseState.NotStarted:
        if (typeof mapper.notStarted === 'function') {
          return mapper.notStarted();
        }
        return this._mapRedirect(mapper, 'notStarted', mapper.notStarted);
      case PromiseState.Running:
        if (typeof mapper.running === 'function') {
          return mapper.running();
        }
        return this._mapRedirect(mapper, 'running', mapper.running);
      case PromiseState.Succeeded:
        if (typeof mapper.succeeded === 'function') {
          return mapper.succeeded(this.getSucceededData());
        }
        return this._mapRedirect(mapper, 'succeeded', mapper.succeeded);
      case PromiseState.Failed:
        if (typeof mapper.failed === 'function') {
          return mapper.failed(this.error);
        }
        return this._mapRedirect(mapper, 'failed', mapper.failed);
      default:
        throw new UnhandledSwitchCaseException();
    }
  }

  /**
   * @returns The state as a string (this is mainly useful for debugging purpose).
   */
  public getReadableState(): string {
    return Object.values(PromiseState)[this.state].toString();
  }

  /**
   * @returns `true` if the promise has not started.
   */
  public isNotStarted(): boolean {
    return this.state === PromiseState.NotStarted;
  }

  /**
   * @returns `true` if the promise is running.
   */
  public isRunning(): boolean {
    return this.state === PromiseState.Running;
  }

  /**
   * @returns `true` if the promise has failed.
   */
  public isFailed(): boolean {
    return this.state === PromiseState.Failed;
  }

  /**
   * @returns `true` if the promise has succeeded.
   */
  public isSucceeded(): boolean {
    return this.state === PromiseState.Succeeded;
  }

  /**
   * @throws {@link ParameterException} if the redirection leads to another thing that a function.
   * @returns The result of the member indicated by {@link redirect}.
   */
  private _mapRedirect<ReturnType>(
    mapper: PromiseSnapshotMap<T, ReturnType>,
    source: keyof PromiseSnapshotMap<T, ReturnType>,
    redirect: keyof Pick<
      PromiseSnapshotMap<T, ReturnType>,
      'notStarted' | 'running'
    >
  ): ReturnType {
    const redirectFunc = mapper[redirect];
    if (typeof redirectFunc === 'function') {
      return redirectFunc();
    } else {
      throw new ParameterException(
        `Mapper for "${source}" is redirected to "${redirect}" function, but "${redirect}" value is not a function.`
      );
    }
  }
}

import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ActionHistoryApi } from '../../../../api/actionHistoryApi';
import { useActionTypes } from '../../../../hooks/useActionTypes';
import { useUsers } from '../../../../hooks/useUsers';
import { ActionHistory } from '../../../../models/actionHistory';
import { Environment } from '../../../../models/environment';
import { Project } from '../../../../models/project';
import * as array from '../../../../utilities/array';
import { unwrap } from '../../../../utilities/assertions';
import { ClassUtilities } from '../../../../utilities/classUtility';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import { RouteUtilities } from '../../../../utilities/routeUtilities';
import { Block } from '../../../atoms/Block/Block';
import { RoundedFilledGreenButton } from '../../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../../atoms/Title/Title';
import { ActionHistoryTile } from '../../../molecules/ActionHistoryTile/ActionHistoryTile';

export interface ActionHistoryBlockProps {
  className?: string;
  environmentId: Environment['id'];
  projectId: Project['id'];
}

export function ActionHistoryBlock(props: ActionHistoryBlockProps) {
  const { t } = useTranslation();

  const usersSnapshot = useUsers();
  const actionTypesSnapshot = useActionTypes();
  const [actionHistoriesSnapshot, setActionHistoriesSnapshot] = useState(
    new PromiseSnapshot<ActionHistory[]>()
  );
  const abortControllerRef = useRef(new AbortController());

  const seeAllLink = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.append('environment[]', props.environmentId);
    return (
      RouteUtilities.construct('/project/{id}/history/action', {
        id: props.projectId,
      }) +
      '?' +
      searchParams.toString()
    );
  }, [props.environmentId, props.projectId]);

  // Fetch action histories
  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      async () => {
        const actionHistories = await new ActionHistoryApi()
          .getAllPaginated(
            {
              'environment[]': [props.environmentId],
            },
            { abortController }
          )
          .then((paginated) => paginated['hydra:member']);
        return actionHistories.sort(
          (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
        );
      },
      setActionHistoriesSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [props.environmentId]);
  //#endregion

  // Callback for ActionHistory update.
  const actionHistoryUpdated = (updatedActionHistory: ActionHistory) => {
    setActionHistoriesSnapshot(
      PromiseSnapshot.buildSucceeded(
        array.replace(
          unwrap(actionHistoriesSnapshot.data),
          (actionHistory) => actionHistory.id === updatedActionHistory.id,
          updatedActionHistory
        )
      )
    );
  };

  // Keep snapshot errors
  const error = useMemo(() => {
    return [usersSnapshot, actionTypesSnapshot, actionHistoriesSnapshot].find(
      (snapshot) => snapshot.error !== undefined
    )?.error;
  }, [actionHistoriesSnapshot, actionTypesSnapshot, usersSnapshot]);

  // #region Map action types and users to their id
  const actionTypes = useMemo(() => {
    if (!actionTypesSnapshot.isSucceeded()) return undefined;
    return array.asMap(unwrap(actionTypesSnapshot.data), 'id');
  }, [actionTypesSnapshot]);

  const users = useMemo(() => {
    if (!usersSnapshot.isSucceeded()) return undefined;
    return array.asMap(unwrap(usersSnapshot.data), 'id');
  }, [usersSnapshot]);
  // #endregion

  return (
    <Block
      className={ClassUtilities.flatten(
        'ActionHistoryBlock flex flex-col gap-6 self-stretch',
        props.className
      )}
    >
      <div className="flex justify-between">
        <Title level={2}>{t('history')}</Title>
        <Link to={seeAllLink}>
          <RoundedFilledGreenButton>{t('see_all')}</RoundedFilledGreenButton>
        </Link>
      </div>
      {/* {devDebug(devStringify(usersSnapshot))} */}
      <div className="grow lg:basis-32 lg:overflow-hidden">
        {error ? (
          <ErrorBlock>{(error as Error)?.message}</ErrorBlock>
        ) : actionHistoriesSnapshot.data !== undefined &&
          actionTypes !== undefined &&
          users !== undefined ? (
          <div className="flex lg:flex-col gap-4 lg:h-full overflow-x-auto lg:overflow-y-auto items-stretch">
            {actionHistoriesSnapshot.data.map((actionHistory) => (
              <div
                key={actionHistory.id}
                className="lg:basis-0 lg:shrink-0 flex lg:flex-col items-stretch"
              >
                <ActionHistoryTile
                  actionHistory={actionHistory}
                  actionType={unwrap(actionTypes.get(actionHistory.actionType))}
                  author={
                    actionHistory.author !== undefined
                      ? users.get(actionHistory.author)
                      : undefined
                  }
                  onActionHistoryUpdated={actionHistoryUpdated}
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            <LoadingBlock />
          </>
        )}
      </div>
    </Block>
  );
}

import { IconButton, Popover, styled, Tooltip } from '@mui/material';
import {
  ChangeEventHandler,
  DragEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';

/**
 * * `undefined`: No change.
 * * `null`: Remove existing image.
 * * `File`: Image replacement/addition.
 */
export type ImageEdit = undefined | null | File;
/**
 * {@link ImageEdit} checker for zod.
 */
export const ZodImageEdit = z.undefined().or(z.null()).or(z.instanceof(File));

export interface ImagePickerProps {
  /** The current value of the {@link ImagePicker} widget.
   * See {@link ImageEdit} to know the subtleties between the different types possible for this value. */
  value: ImageEdit;
  /**
   * Callback triggered when the value is changed.
   * You need to update the value in the {@link ImageEdit} props to reflect the changes.
   */
  onChange: (value: ImageEdit) => void;
  /** The base URL for the existing image if there is any. This property is only used for displaying purpose. */
  initialURL?: string;
  className?: string;
  name?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  /** Are the component interactions disabled? Defaults to `false`. */
  disabled?: boolean;
}

enum DragOverStatus {
  None,
  Valid,
  Invalid,
}

type DragAndDropHandlers =
  | {
      onDragOver: DragEventHandler<HTMLElement>;
      onDragLeave: DragEventHandler<HTMLElement>;
      onDragEnd: DragEventHandler<HTMLElement>;
      onDrop: DragEventHandler<HTMLElement>;
    }
  | Record<string, never>;

//#region Style constants
const imageSize = 64;
const iconSize = 34;
const iconContainerPadding = 16;
//#endregion

const acceptedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

export function ImagePicker(props: ImagePickerProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const open = Boolean(anchorEl);
  const htmlInput = useRef<HTMLInputElement>(null);
  const [needsURLRegenerate, setNeedsURLRegenerate] = useState(true);
  const [objectURL, setObjectURL] = useState<string | undefined>(undefined);
  const [dragOverStatus, setDragOverStatus] = useState(DragOverStatus.None);
  const mouseMovedSinceUpload = useRef(true);
  const disabled = props.disabled ?? false;
  const onChange = props.onChange;

  const openEditMenu = (event: React.MouseEvent<HTMLImageElement>) => {
    // Prevent Firefox for being dumb (after picking a file, and without moving mouse it may triggers in loop mouseEnter and mouseLeave at incoherent positions)
    if (!mouseMovedSinceUpload.current) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const closeEditMenu = () => {
    // Prevent Firefox for being dumb (after picking a file, and without moving mouse it may triggers in loop mouseEnter and mouseLeave at incoherent positions)
    if (!mouseMovedSinceUpload.current) {
      return;
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    const mouseMoved = () => {
      if (!mouseMovedSinceUpload.current) {
        mouseMovedSinceUpload.current = true;
      }
    };

    document.addEventListener('mousemove', mouseMoved);

    return () => {
      document.removeEventListener('mousemove', mouseMoved);
    };
  }, []);

  useEffect(() => {
    setNeedsURLRegenerate(true);
  }, [props.value]);

  useEffect(() => {
    if (!needsURLRegenerate) return;

    if (props.value !== undefined && props.value !== null) {
      setObjectURL(URL.createObjectURL(props.value));
    } else {
      setObjectURL(undefined);
    }
    setNeedsURLRegenerate(false);

    return () => {
      if (objectURL !== undefined) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [needsURLRegenerate, objectURL, props.value]);

  const imagePicked: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0];
    if (file === undefined) return;

    mouseMovedSinceUpload.current = false;
    setAnchorEl(null);
    props.onChange(file);
  };

  const deleteImage = () => {
    setAnchorEl(null);
    props.onChange(null);
  };

  const undoModifications = () => {
    setAnchorEl(null);
    props.onChange(undefined);
  };

  const dndHandlers: DragAndDropHandlers = useMemo(() => {
    if (disabled) {
      return {} as DragAndDropHandlers;
    } else {
      return {
        onDragOver: (ev) => {
          if (
            ev.dataTransfer.items[0]?.kind === 'file' &&
            acceptedTypes.includes(ev.dataTransfer.items[0]?.type)
          ) {
            ev.dataTransfer.dropEffect = 'copy';
            setDragOverStatus(DragOverStatus.Valid);
          } else {
            ev.dataTransfer.dropEffect = 'none';
            setDragOverStatus(DragOverStatus.Invalid);
          }
          ev.preventDefault();
        },
        onDragLeave: (ev) => {
          setDragOverStatus(DragOverStatus.None);
        },
        onDragEnd: (ev) => {
          ev.preventDefault();
        },
        onDrop: (ev) => {
          onChange(ev.dataTransfer.files[0]);
          setDragOverStatus(DragOverStatus.None);
          ev.preventDefault();
        },
      };
    }
  }, [disabled, onChange]);

  return (
    <div className={ClassUtilities.flatten('ImagePicker', props.className)}>
      <input
        ref={htmlInput}
        className="hidden"
        name={props.name}
        type="file"
        id="image-input"
        accept={acceptedTypes.join(', ')}
        onChange={imagePicked}
      ></input>
      <div className="stack justify-center">
        <img
          className={ClassUtilities.flatten(
            'm-auto rounded-full self-center object-cover transition-all filter',
            ClassUtilities.conditional({
              'grayscale opacity-50': disabled,
            })
          )}
          style={{
            width: imageSize,
            height: imageSize,
            visibility:
              props.value === null ||
              (props.value === undefined && props.initialURL === undefined)
                ? 'hidden'
                : undefined,
          }}
          src={props.value === null ? undefined : objectURL ?? props.initialURL}
          alt=""
        ></img>
        <div
          className={ClassUtilities.flatten(
            'w-full h-full rounded-full border self-center cursor-pointer object-cover transition-[border] duration-100',
            ClassUtilities.conditional({
              'border-red': props.error === true,
              'border-green border-4': dragOverStatus === DragOverStatus.Valid,
              'border-pink-500 border-4':
                dragOverStatus === DragOverStatus.Invalid,
            })
          )}
          onMouseEnter={disabled ? undefined : openEditMenu}
          {...dndHandlers}
        />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeEditMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 0,
          className: '',
          sx: {
            background: 'none',
          },
        }}
      >
        <div
          className="flex items-start gap-4 p-4"
          style={{
            height: imageSize + iconContainerPadding * 2 + iconSize / 2,
            padding: iconContainerPadding,
            minWidth: imageSize,
          }}
          onMouseLeave={closeEditMenu}
          {...dndHandlers}
        >
          {(props.value === null ||
            (props.value === undefined && props.initialURL === undefined)) && (
            <Tooltip title={t('add')}>
              <LightButton
                size="small"
                onClick={() => htmlInput.current?.click()}
              >
                <i className="icon-plus" />
              </LightButton>
            </Tooltip>
          )}
          {props.value !== null &&
            (props.value !== undefined || props.initialURL !== undefined) && (
              <Tooltip title={t('change_image')}>
                <LightButton
                  size="small"
                  onClick={() => htmlInput.current?.click()}
                >
                  <i className="icon-repeat" />
                </LightButton>
              </Tooltip>
            )}
          {props.value !== null &&
            (props.value !== undefined || props.initialURL !== undefined) && (
              <Tooltip title={t('delete')} onClick={deleteImage}>
                <LightButton size="small">
                  <i className="icon-trash" />
                </LightButton>
              </Tooltip>
            )}
          {props.value !== undefined &&
            !(props.value === null && props.initialURL === undefined) && (
              <Tooltip title={t('cancel_changes')} onClick={undoModifications}>
                <LightButton size="small">
                  <i className="icon-undo" />
                </LightButton>
              </Tooltip>
            )}
        </div>
      </Popover>
      {props.error && <p>{props.helperText}</p>}
    </div>
  );
}

const LightButton = styled(IconButton)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  height: iconSize,
  width: iconSize,
  background: 'white',

  '&:hover': {
    background: Colors.fromTailwind('grey-200'),
  },
}));

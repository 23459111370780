import { EmptyValueException } from '../../../exceptions/emptyValueException';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { unwrapWithException } from '../../../utilities/assertions';
import { Colors } from '../../../utilities/colors';

export type Context = 'neutral' | 'warning' | 'success' | 'info' | 'error';

export function contextToBackgroundColor(context: Context): string {
  let color: string | undefined = undefined;
  switch (context) {
    case 'info':
      color = Colors.fromTailwind('blue-200');
      break;
    case 'neutral':
      color = '#fff';
      break;
    case 'success':
      color = Colors.fromTailwind('green-100');
      break;
    case 'warning':
      color = Colors.fromTailwind('orange-200');
      break;
    case 'error':
      color = Colors.fromTailwind('pink-200');
      break;
    default:
      throw new UnhandledSwitchCaseException();
  }

  return unwrapWithException(
    color,
    new EmptyValueException(
      `Associated color with context "${context}" cannot be retrieved.`
    )
  );
}

import { useTranslation } from 'react-i18next';
import { InitEnvEstimate } from '../../../../models/project';
import { ClassUtilities } from '../../../../utilities/classUtility';
import { DateFormatter } from '../../../../utilities/dateFormatter';
import { NumberFormatter } from '../../../../utilities/numberFormatter';
import { ActionShortInfoBlocks } from '../../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';

export function EstimatesBlocks({
  data,
  isMonoVM,
}: {
  data?: InitEnvEstimate | undefined;
  isMonoVM: boolean;
}) {
  const { t } = useTranslation();
  const styleProps = [
    {
      icon: { color: 'yellow-200', icon: 'icon-clock' },
      label: t('operation_estimated_duration'),
    },
    {
      icon: { color: 'blue-200', icon: 'icon-server' },
      label: t('operation_estimated_cost'),
      details: t('for_mono_env'),
      className: ClassUtilities.flatten(
        'transition-opacity',
        ClassUtilities.conditional({
          'opacity-50 hover:opacity-100': !isMonoVM,
        })
      ),
    },
    {
      icon: {
        color: 'orange-300',
        icon: 'icon-multi-server',
      },
      label: t('operation_estimated_cost'),
      details: t('for_multi_env'),
      className: ClassUtilities.flatten(
        'transition-opacity',
        ClassUtilities.conditional({
          'opacity-50 hover:opacity-100': isMonoVM,
        })
      ),
    },
  ];

  return (
    <>
      {data ? (
        <ActionShortInfoBlocks
          stylesProps={styleProps}
          valueDescriptions={[
            DateFormatter.toDuration(data.estimatedDuration, t, {
              maximumParts: 1,
            }),
            t('price_per_month_template', {
              price: NumberFormatter.toEuro(data.estimatedTotalInclTax.monoVm),
            }),
            t('price_per_month_template', {
              price: NumberFormatter.toEuro(data.estimatedTotalInclTax.multiVm),
            }),
          ]}
        />
      ) : (
        <ActionShortInfoBlocks stylesProps={styleProps} />
      )}
    </>
  );
}

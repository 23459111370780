import { z } from 'zod';
import { ZodImageEdit } from '../../atoms/ImagePicker/ImagePicker';

export const schema = z.object({
  name: z.string().min(1),
  gitRepositoryUrl: z.string(),
  image: ZodImageEdit,
  billingCompany: z
    .object({
      id: z.string().uuid(),
    })
    .transform((company) => company.id),
});

export type NewProjectSchema = z.infer<typeof schema>;

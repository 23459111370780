import { Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ClassUtilities } from '../../../utilities/classUtility';
import { MenuButtonProps } from '../../molecules/MenuButton/MenuButton';
import { menuButtonIsSelected } from '../../molecules/MenuButton/utilities';
import './SubMenuButton.css';

export function SubMenuButton(props: SubMenuButtonProps) {
  const { pathname } = useLocation();

  const selected = menuButtonIsSelected(
    props.selectMode,
    props.route,
    pathname
  );

  return (
    <Link
      className={ClassUtilities.flatten(
        'SubMenuButton',
        ClassUtilities.conditional({
          'SubMenuButton--selected': selected,
        })
      )}
      to={props.route}
    >
      <div className="SubMenuButton__desktop">
        <div className="SubMenuButton__icon-wrapper">
          <i className={ClassUtilities.flatten(props.icon)} />
        </div>
        {props.label}
      </div>

      <div className="SubMenuButton__mobile">
        <Tooltip title={props.label}>
          <div className="SubMenuButton__icon-wrapper">
            <i className={ClassUtilities.flatten(props.icon)} />
          </div>
        </Tooltip>
      </div>
    </Link>
  );
}

export type SubMenuButtonProps = MenuButtonProps;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { useEstimates } from '../../../hooks/useEstimates';
import { useTitle } from '../../../hooks/useTitle';
import { useProjectsStore } from '../../../stores/projectsStore';
import { DateFormatter, TimeUnit } from '../../../utilities/dateFormatter';
import { NumberFormatter } from '../../../utilities/numberFormatter';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { IconTooltip } from '../../atoms/IconTooltip/IconTooltip';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { InformationBlock } from './subcomponents/InformationBlock';
import { TotalBlock } from './subcomponents/TotalBlock';

export function BillingCurrentMonth() {
  const { t } = useTranslation();

  useTitle(t('current_month_estimation'));
  const currentProject = useProjectsStore((state) => state.getProject());

  const estimatesSnapshot = useEstimates(currentProject?.id);

  return (
    <div className="BillingCurrentMonth flex flex-col gap-12 py-8 overflow-y-auto">
      {estimatesSnapshot.map({
        notStarted: 'running',
        running: () => <LoadingBlock />,
        failed: (error) => (
          <ErrorBlock noShadow>
            {error instanceof NetworkRequestException
              ? `${error.message}`
              : `${error}`}
          </ErrorBlock>
        ),
        succeeded(mapEstimates) {
          const estimates = Array.from(mapEstimates.values());

          return (
            <>
              <TableContainer component="div" className="hidden lg:block">
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('environment')}</TableCell>
                      <TableCell>{t('creation_date')}</TableCell>
                      <TableCell>{t('deletion_date')}</TableCell>
                      <TableCell>{t('lifetime')}</TableCell>
                      <TableCell>
                        {t('billing_estimation_dday')}{' '}
                        <IconTooltip title={t('high_range_cost_tooltip')} />
                      </TableCell>
                      <TableCell>
                        {t('billing_month_end')}{' '}
                        <IconTooltip title={t('high_range_cost_tooltip')} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="whitespace-nowrap">
                    {estimates.map((estimate) => (
                      <TableRow
                        key={estimate.environment.id}
                        className="table-row"
                      >
                        <TableCell>{estimate.environment.name}</TableCell>
                        <TableCell>
                          {DateFormatter.toDateTime(estimate.createdAt)}
                        </TableCell>
                        <TableCell>
                          {estimate.deletedAt
                            ? DateFormatter.toDateTime(estimate.deletedAt)
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {DateFormatter.toDuration(estimate.lifeDuration, t, {
                            parts: TimeUnit.All & ~TimeUnit.Second,
                          })}
                        </TableCell>
                        <TableCell>
                          {estimate.estimations
                            ? NumberFormatter.toEuro(
                                estimate.estimations.toDate.highestTotalInclTax
                              )
                            : t('pending')}
                        </TableCell>
                        <TableCell>
                          {estimate.estimations
                            ? NumberFormatter.toEuro(
                                estimate.estimations.toEndOfMonth
                                  .highestTotalInclTax
                              )
                            : t('pending')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="flex flex-col gap-4 px-1 lg:hidden">
                {estimates.map((estimate) => (
                  <DataPaper
                    key={estimate.environment.id}
                    header={estimate.environment.name}
                    content={[
                      <DataPaperElement
                        key="creation-date"
                        label={t('creation_date')}
                        value={DateFormatter.toDateTime(estimate.createdAt)}
                      />,
                      <DataPaperElement
                        key="deletion-date"
                        label={t('deletion_date')}
                        value={
                          estimate.deletedAt
                            ? DateFormatter.toDateTime(estimate.deletedAt)
                            : '-'
                        }
                      />,
                      <DataPaperElement
                        key="activity-time"
                        fullSpan
                        label={t('lifetime')}
                        value={DateFormatter.toDuration(
                          estimate.lifeDuration,
                          t,
                          { parts: TimeUnit.All & ~TimeUnit.Second }
                        )}
                      />,
                      <DataPaperElement
                        key="estimation-d-day"
                        label={t('billing_estimation_dday')}
                        value={
                          <>
                            {estimate.estimations
                              ? NumberFormatter.toEuro(
                                  estimate.estimations.toDate.lowestTotalInclTax
                                )
                              : t('pending')}
                            {' - '}
                            {estimate.estimations
                              ? NumberFormatter.toEuro(
                                  estimate.estimations.toDate
                                    .highestTotalInclTax
                                )
                              : t('pending')}
                          </>
                        }
                      />,
                      <DataPaperElement
                        key="estimation-month-end"
                        label={t('billing_month_end')}
                        value={
                          <>
                            {estimate.estimations
                              ? NumberFormatter.toEuro(
                                  estimate.estimations.toEndOfMonth
                                    .lowestTotalInclTax
                                )
                              : t('pending')}
                            {' - '}
                            {estimate.estimations
                              ? NumberFormatter.toEuro(
                                  estimate.estimations.toEndOfMonth
                                    .highestTotalInclTax
                                )
                              : t('pending')}
                          </>
                        }
                      />,
                    ]}
                  ></DataPaper>
                ))}
              </div>
            </>
          );
        },
      })}

      <div className="flex flex-col lg:flex-row gap-8 justify-between">
        <InformationBlock />
        <TotalBlock estimatesSnapshot={estimatesSnapshot} />
      </div>
    </div>
  );
}

import { EnvironmentApi } from '../../../api/environmentApi';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { Environment } from '../../../models/environment';
import { EnvironmentStatus } from '../../../models/environmentStatus';

export async function waitForEnvironmentToBeReady(
  environmentId: Environment['id']
): Promise<Environment> {
  const api = new EnvironmentApi();
  const delay = 10000; // 10s

  await new Promise((resolve) => setTimeout(resolve, delay));

  let environment = await api.get(environmentId);
  while (environment.status === EnvironmentStatus.Creating) {
    await new Promise((resolve) => setTimeout(resolve, delay));
    environment = await api.get(environmentId);
  }

  return environment;
}

export function errorToErrorTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 422:
        switch (exception.backendDescription) {
          case 'name: This value is already used.':
            return 'env_name_already_exist';
        }
        break;
      case 429:
        return 'env_creation_already_occurring';
    }
  }

  return 'env_unknown_error_could_not_create';
}

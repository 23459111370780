import { useEffect, useRef } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import { BlockTabProps } from '../BlockTab/BlockTab';

export interface BlockTabsProps {
  children:
    | React.ReactElement<BlockTabProps>[]
    | React.ReactElement<BlockTabProps>;
  className?: string;
  onTabsTakesFullWidthChanged?: (fullWidth: boolean) => void;
}

export function BlockTabsHeader(props: BlockTabsProps) {
  //#region Handle width observation
  const container = useRef<HTMLDivElement>(null);
  const fullWidth = useRef<boolean | undefined>(undefined);
  const resizeObserver = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      let totalChildrenWidth = 0;
      for (const child of entries[0].target.children) {
        totalChildrenWidth += child.clientWidth;
      }

      const isNowFullWidth = totalChildrenWidth >= entries[0].contentRect.width;

      if (isNowFullWidth !== fullWidth.current) {
        fullWidth.current = isNowFullWidth;
        props.onTabsTakesFullWidthChanged?.(isNowFullWidth);
      }
    })
  );

  useEffect(() => {
    if (!container.current) return;

    const containerInstance = container.current;
    const resizeObserverInstance = resizeObserver.current;
    resizeObserverInstance.observe(containerInstance);

    return () => {
      resizeObserverInstance.unobserve(containerInstance);
    };
  }, [container]);
  //#endregion

  return (
    <div
      className={ClassUtilities.flatten(
        'BlockTabs flex select-none cursor-pointer',
        props.className
      )}
      ref={container}
    >
      {props.children}
    </div>
  );
}

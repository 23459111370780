import './DebugTable.css';

/**
 * Component that allow to dump a variable in order to know what it contains.
 * Should only be used on dev mode.
 */
export function DebugTable(props: DebugTableProps) {
  return (
    <table className="DebugTable">
      <thead>
        {props.name && (
          <tr>
            <th colSpan={2} className="text-center">
              {props.name}
            </th>
          </tr>
        )}
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(props.object).map(([k, v]) => (
          <tr key={k}>
            <td>{k}</td>
            <td>{JSON.stringify(v)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export type DebugTableProps = {
  object: Record<string, unknown>;
  name?: string;
};

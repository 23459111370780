import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '../../../atoms/Button/Button';
import { FilledRoundedButton } from '../../../atoms/Button/variations/FilledRoundedButton';
import { lightGrayFilledStyle } from '../../../atoms/Button/variations/sharedBase';

const StyledButton = styled(FilledRoundedButton)(lightGrayFilledStyle);

export function DetailsButton(
  props: Omit<ButtonProps, 'startIcon' | 'children'>
) {
  const { t } = useTranslation();

  return (
    <StyledButton startIcon={<i className="icon-search"></i>} {...props}>
      {t('details')}
    </StyledButton>
  );
}

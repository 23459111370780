import dayjs from 'dayjs';

/** A line of payment as represented in the UI. */
export type PaymentLineInfo = {
  /** The amount paid. */
  amount: number;
  /** The date of the payment. */
  paidAt: Date;
};

/**
 * @returns `true` if {@link v1} is equals to {@link v2}.
 */
export function equals(v1: PaymentLineInfo, v2: PaymentLineInfo): boolean {
  return v1.amount === v2.amount && dayjs(v1.paidAt).isSame(v2.paidAt, 'day');
}

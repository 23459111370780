import { styled } from '@mui/material';
import { Colors } from '../../../../utilities/colors';
import { OutlineRoundedButton } from './OutlinedRoundedButton';

const pinkStyle = {
  '&': {
    color: Colors.fromTailwind('pink-500'),
    borderColor: Colors.fromTailwind('pink-500'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('pink-300'),
    borderColor: Colors.fromTailwind('pink-500'),
  },
};

export const OutlinedRoundedPinkButton =
  styled(OutlineRoundedButton)(pinkStyle);

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProjectApi } from '../../../../api/projectApi';
import { Project } from '../../../../models/project';
import { Block } from '../../../atoms/Block/Block';
import { Title } from '../../../atoms/Title/Title';
import { ActionButton } from '../../../molecules/ActionButton/ActionButton';
import './ActionBlock.css';

export interface ActionBlockProps {
  className?: string;
  project: Project;
}

export function ActionBlock(props: ActionBlockProps) {
  const { t } = useTranslation();
  //#region Fetch the action types
  const navigate = useNavigate();
  //#endregion

  const [isDiscoveringJobs, setIsDiscoveringJobs] = useState(false);

  return (
    <Block className="ActionBlock flex flex-col gap-6">
      <Title level={2}>{t('actions')}</Title>
      <div className="flex flex-col lg:flex-row gap-2 justify-between items-center">
        <div className="action-buttons">
          <ActionButton
            color={'purple-200'}
            icon={'icon-edit'}
            justifyContent="start"
            onClick={() =>
              navigate('edit', { state: { project: props.project } })
            }
          >
            {t('edit_project_settings')}
          </ActionButton>
          <ActionButton
            color={'orange-300'}
            icon={'icon-plus'}
            justifyContent="start"
            onClick={() => navigate('users/new')}
          >
            {t('add_a_user_to_project')}
          </ActionButton>
          <ActionButton
            color={'green-200'}
            icon={'icon-compass'}
            justifyContent="start"
            disabled={isDiscoveringJobs}
            onClick={() => {
              setIsDiscoveringJobs(true);
              document.body.style.cursor = 'wait';
              new ProjectApi().autodiscoverJobs(props.project.id).then(() => {
                setIsDiscoveringJobs(false);
                document.body.style.cursor = 'default';
              });
            }}
          >
            {t('autodiscover_jobs')}
          </ActionButton>
        </div>
      </div>
    </Block>
  );
}

import {
  refineZipCode,
  ZipCodeInfoFr,
  ZipCodeInfoFrRaw,
} from '../models/zipCodeFr';
import { unwrap } from '../utilities/assertions';
import {
  AbortOptions,
  AbortOptionsDefault,
  getJsonExternal,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';

const { REACT_APP_ZIP_CODE_FR } = process.env;

export class ZipCodeInfoApi {
  private static _zipCodeRegexFr = /^\d?\d{4}$/;

  public static isValidZipCodeFr(zipCode: string): boolean {
    return this._zipCodeRegexFr.test(zipCode);
  }

  /**
   * @returns The french zip code.
   */
  public static async getInfoFr(
    zipCode: string,
    options?: Partial<AbortOptions>
  ): Promise<ZipCodeInfoFr[]> {
    const route = unwrap(
      REACT_APP_ZIP_CODE_FR,
      'The route for french zip code is not defined.'
    );

    const filledOptions = fillOptions(options, AbortOptionsDefault);

    const codes: ZipCodeInfoFrRaw[] = await getJsonExternal(route, {
      args: { zipCode },
      ...filledOptions,
    });

    return codes.map(refineZipCode);
  }
}

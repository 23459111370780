import { z } from 'zod';

export const schema = z.object({
  user: z.object({
    id: z.string(),
  }),
  projectId: z.string(),
  role: z.string(),
});

export type ProjectAddUserSchema = z.infer<typeof schema>;

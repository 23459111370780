import { useEffect, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import { ProjectApi } from '../../../../../api/projectApi';
import { GitReference } from '../../../../../models/gitReference';
import { useProjectsStore } from '../../../../../stores/projectsStore';
import { devStringify } from '../../../../../utilities/devMessages';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../../utilities/promiseSnapshot';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { FormSingleSelect } from '../../../../atoms/SingleSelect/FormSingleSelect';

export interface BranchSchema {
  gitReference: {
    branch: string;
  };
}

export interface BranchSelectProps<T extends BranchSchema> {
  control: Control<T, unknown>;
}

export function BranchSelect<T extends BranchSchema>(
  props: BranchSelectProps<T>
) {
  const control = props.control as unknown as Control<BranchSchema, unknown>;

  const [branches, setBranches] = useState<PromiseSnapshot<GitReference[]>>(
    new PromiseSnapshot()
  );
  const abortController = useRef(new AbortController());

  const projectId = useProjectsStore((state) => state.selectedProjectId);

  useEffect(() => {
    if (projectId === undefined) return;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ProjectApi().getBranches(projectId, {
          abortController: abortController.current,
        }),
      setBranches
    );

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [projectId]);

  return (
    <div className="flex flex-col items-stretch">
      <FormSingleSelect
        control={control}
        options={
          branches.data
            ? branches.data.map((branch) => ({
                label: branch.name,
                value: branch.name,
              }))
            : []
        }
        name="gitReference.branch"
        label="Id de la référence (branche)"
        disabled={branches.state !== PromiseState.Succeeded}
        loading={branches.state === PromiseState.Running}
      />
      {branches.state === PromiseState.Failed && (
        <ErrorBlock title="Erreur lors de la récupération des branches">
          {devStringify(branches.error)}
        </ErrorBlock>
      )}
    </div>
  );
}

import { assert } from '../../../utilities/assertions';
import { PlaceholderText } from '../../atoms/PlaceholderText/PlaceholderText';
import {
  IconShortInfoBlock,
  IconShortInfoBlockProps,
} from '../IconShortInfoBlock/IconShortInfoBlock';

export interface ActionShortInfoBlocksProps {
  stylesProps: Omit<IconShortInfoBlockProps, 'valueDescription'>[];
  valueDescriptions?: IconShortInfoBlockProps['valueDescription'][];
}

export function ActionShortInfoBlocks(props: ActionShortInfoBlocksProps) {
  assert(
    props.valueDescriptions === undefined ||
      props.stylesProps.length === props.valueDescriptions.length,
    'styles arrays must be equals to valueDescriptions arrays.'
  );

  return (
    <div className="ActionShortInfoBlocks flex flex-col gap-4">
      {props.stylesProps.map((styleProps, index) => {
        const valueDescription = props.valueDescriptions?.[index];

        return (
          <IconShortInfoBlock
            key={index}
            {...styleProps}
            valueDescription={valueDescription ?? <PlaceholderText />}
          />
        );
      })}
    </div>
  );
}

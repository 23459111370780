import { create } from 'zustand';
import { UserApi } from '../api/userApi';
import { UserProjectRoleApi } from '../api/userProjectRoleApi';
import { BadLogicException } from '../exceptions/badLogicException';
import { User } from '../models/user';
import { UserProjectRole } from '../models/userProjectRole';
import { unwrap } from '../utilities/assertions';
import { PromiseSnapshot, PromiseState } from '../utilities/promiseSnapshot';
import { hasToken } from '../utilities/tokenUtilities';

export interface UserWithRoles extends User {
  projectRoles: UserProjectRole[];
}

export interface UserStoreState {
  /**
   * The current user state.
   */
  user: PromiseSnapshot<UserWithRoles>;
  /**
   * Invalidates the user as if it could not be retrieved.
   * @param error The error to pass to the user snapshot.
   */
  invalidateUser: (error?: unknown) => void;
  /**
   * Replaces user by another user, useful after a patch to the user for example.
   * Will only be effective if the snapshot is in {@link PromiseState.Succeeded} state.
   */
  replaceUser: (user: Partial<UserWithRoles>) => void;
}

export const useCurrentUserStore = create<UserStoreState>((set, get) => {
  // Attempts to retrieve user informations
  const fetchUser = async () => {
    PromiseSnapshot.trackPromiseSetter(
      async () => {
        const userWithRoles: UserWithRoles = {
          ...(await UserApi.getMe()),
          ...{ projectRoles: [] },
        };
        userWithRoles.projectRoles = await new UserProjectRoleApi().getAll({
          'targeted_user[]': [userWithRoles.id],
        });
        return userWithRoles;
      },
      (user) => set({ user })
    );
  };

  const token = hasToken();
  if (token) {
    fetchUser();
  }

  return {
    // if no token, fake an error to force log in
    user: token
      ? new PromiseSnapshot()
      : PromiseSnapshot.buildFailed({ status: 401 }),
    invalidateUser(error?: unknown) {
      set({
        user: PromiseSnapshot.buildFailed(error),
      });
    },
    replaceUser(user) {
      const snapshot = get().user;
      if (snapshot.state !== PromiseState.Succeeded) {
        throw new BadLogicException(
          'Snapshot is not in succeeded state, the user cannot be replaced.'
        );
      }
      set({
        user: PromiseSnapshot.buildSucceeded({
          ...unwrap(snapshot.data),
          ...user,
        }),
      });
    },
  };
});

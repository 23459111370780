/**
 * HTML classes helper
 */
export class ClassUtilities {
  /**
   * @param classes An object that has as key the classes to display conditionnally depending on the value.
   * @returns The classes associated with a `true` value in `classes` parameter.
   */
  static conditional(classes: { [classes: string]: boolean }): string {
    const enabledClasses = [];
    for (const [k, v] of Object.entries(classes)) {
      if (v) {
        enabledClasses.push(k);
      }
    }
    return enabledClasses.join(' ');
  }

  static flatten(...classes: (string | undefined)[]): string {
    return classes.filter((str) => str !== undefined).join(' ');
  }
}

const { REACT_APP_MISSING_TRANSLATION_FORMAT_KEY_WARNINGS } = process.env;

export class Development {
  static environmentIsDev(): boolean {
    return devMode;
  }
  static missingTranslationFormatKeyWarnings(): boolean {
    return REACT_APP_MISSING_TRANSLATION_FORMAT_KEY_WARNINGS === 'true';
  }
}

const devMode: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

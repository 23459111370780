import { EmailPreference } from '../models/emailPreference';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import { GetJsonOptions } from '../utilities/fetchUtilities';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const { REACT_APP_ROUTE_EMAIL_PREFERENCES } = process.env;
export type AbortOptions = Pick<GetJsonOptions, 'abortController'>;

export const getAbortOptionsDefault: AbortOptions = {
  abortController: null,
};

export interface GetOptions {
  page?: number;
}

class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
    };
  }
}

export type PatchEmailPreferenceArgs = {
  enabled: boolean;
  succeededAction: boolean;
  failedAction: boolean;
  costWarning: boolean;
};

export class EmailPreferenceApi extends BaseApi<
  EmailPreference,
  GetOptions,
  EmailPreference,
  null,
  PatchEmailPreferenceArgs
> {
  constructor() {
    super(
      unwrap(
        REACT_APP_ROUTE_EMAIL_PREFERENCES,
        'Email preferences route not defined'
      ),
      GetOptionsHandler,
      { allowDelete: false }
    );
  }
}

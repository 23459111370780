import { ActionType } from '../models/actionType';
import { Environment } from '../models/environment';
import {
  EnvironmentActionType,
  EnvironmentActionTypeEstimate,
  RawEnvironmentActionTypeEstimate,
  refineEnvironmentActionTypeEstimate,
} from '../models/environmentActionType';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import {
  AbortOptions,
  AbortOptionsDefault,
  getJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const {
  REACT_APP_ROUTE_ENVIRONMENTS_ACTION_TYPES,
  REACT_APP_ROUTE_ENVIRONMENTS_ACTION_TYPES_ESTIMATED_BILL,
} = process.env;

/**
 * Internal data for {@link GetOptionsHandler}.
 */
export interface GetOptions {
  page?: number;
  'environment[]'?: Environment['id'][];
  'actionType[]'?: ActionType['id'][];
  'actionType.name[]'?: ActionType['name'][];
}

/**
 * Get options for the {@link ActionTypeApi}.
 */
class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      'environment[]': ParamMappingHelper.identity,
      'actionType[]': ParamMappingHelper.identity,
      'actionType.name[]': ParamMappingHelper.identity,
    };
  }
}

export type PatchArgs = Pick<EnvironmentActionType, 'position'>;

export type PostArgs = {
  actionTypeId: ActionType['id'];
  environmentId: Environment['id'];
  position: EnvironmentActionType['position'];
};

export class EnvironmentActionTypeApi extends BaseApi<
  EnvironmentActionType,
  GetOptions,
  EnvironmentActionType,
  PostArgs,
  PatchArgs
> {
  constructor() {
    super(
      unwrap(
        REACT_APP_ROUTE_ENVIRONMENTS_ACTION_TYPES,
        'EnvironmentActionType route not defined'
      ),
      GetOptionsHandler,
      {
        allowDelete: true,
      }
    );
  }

  async getEstimatedBill(
    id: EnvironmentActionType['id'],
    options?: Partial<AbortOptions>
  ): Promise<EnvironmentActionTypeEstimate> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_ACTION_TYPES_ESTIMATED_BILL,
      'GET environment action types estimated bill route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    const rawEstimates = (await getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    })) as RawEnvironmentActionTypeEstimate;

    return refineEnvironmentActionTypeEstimate(rawEstimates);
  }
}

import {
  Autocomplete as MUIAutocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps as MUIAutocompleteProps,
  AutocompleteValue,
  ChipTypeMap,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as array from '../../../utilities/array';
import { unwrap } from '../../../utilities/assertions';
import { ClassUtilities } from '../../../utilities/classUtility';
import { assertIsOption, isOption } from './utilities';

export interface Option<T> {
  /**
   * Visible label for the option
   */
  label: React.ReactNode;
  /**
   * Value of the option. Typically has the type of the fetched API items
   */
  value: T;
}

/**
 * Objects containing necessary dependencies for {@link Autocomplete} freeSolo mode (if enabled).
 */
type AutocompleteFreeSoloValueMapperProps<FreeSolo, T> = FreeSolo extends true
  ? {
      /** Maps a freeSolo value (typically a `string`) to a value of type T. */
      freeSoloMapping: (
        freeSoloValue: AutocompleteFreeSoloValueMapping<FreeSolo>
      ) => T;
    }
  : never;

export type ValueType<T, Multiple, DisableClearable> = Multiple extends true
  ? T[]
  : DisableClearable extends true
  ? T
  : T | null;

/**
 * @warning Do not use array type as T, otherwise onChange will behave in an unexpected way.
 */
export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MUIAutocompleteProps<
      Option<T>,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipTypeMap['defaultComponent']
    >,
    | 'onChange'
    | 'value'
    | 'options'
    | 'size'
    | 'freeSolo'
    | 'isOptionEqualToValue'
  > {
  /**
   * Options to enable FreeSolo mode. If enabled this allows the user to input arbitrary values.
   */
  freeSoloOptions?: {
    /** Is the user allowed to use arbitrary values. */
    enabled: FreeSolo;
  } & AutocompleteFreeSoloValueMapperProps<FreeSolo, T>;
  /**
   * Boolean param which tells if the form element has error or not
   */
  error?: boolean;
  /**
   * Set of customizable options to provide to the autocomplete element
   */
  options: readonly Option<T>[];
  /**
   * Customizable callback on value change
   */
  onChange?: (value: ValueType<T, Multiple, DisableClearable>) => void;
  /**
   * The value for the AutoComplete
   */
  value?: ValueType<T, Multiple, DisableClearable>;
  /**
   * Content in case of error
   */
  helperText?: React.ReactNode;
  /**
   * Does the {@link option} corresponds with {@link value}?
   *
   * @default option === value
   */
  valueEqualityOperator?: (value1: T, value2: T) => boolean;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const oldAutoCompleteValue = useRef<
    | AutocompleteValue<Option<T>, Multiple, DisableClearable, FreeSolo>
    | undefined
  >(undefined);

  const {
    error,
    onChange,
    helperText,
    noOptionsText,
    value,
    options,
    sx,
    freeSoloOptions,
    valueEqualityOperator,
    onInputChange,
    ...otherProps
  } = props;

  const autocompleteValue = (() => {
    const eqOperator: (value1: T, value2: T) => boolean =
      valueEqualityOperator ?? ((v1, v2) => v1 === v2);
    const newValue = (() => {
      if (value === undefined) return undefined;
      if (value === null) return null;
      if (props.multiple) {
        return array.filterUndefined(
          (value as T[]).map((e) =>
            props.options.find((option) => eqOperator(option.value, e))
          )
        );
      } else {
        return (
          props.options.find((option) =>
            eqOperator(option.value, value as T)
          ) ?? (props.freeSoloOptions?.enabled ? value : null)
        );
      }
    })() as
      | AutocompleteValue<Option<T>, Multiple, DisableClearable, FreeSolo>
      | undefined;

    //#region Lazily change value (avoid issues with MUI thinking its a different value)
    const oldValue = oldAutoCompleteValue.current;
    let identical = false;
    if (
      newValue === null ||
      newValue === undefined ||
      typeof newValue === 'string'
    ) {
      identical = newValue === oldValue;
    } else if (Array.isArray(newValue)) {
      identical =
        Array.isArray(oldValue) &&
        array.equivalent<
          Option<T> | AutocompleteFreeSoloValueMapping<FreeSolo>
        >(newValue, oldValue, (a, b) => {
          if (typeof a === 'string' || typeof b === 'string') return a === b;
          return eqOperator(a.value, b.value);
        });
    }
    // newValue is Option
    else {
      identical =
        isOption(oldValue) && eqOperator(newValue.value, oldValue.value);
    }

    if (!identical) {
      oldAutoCompleteValue.current = newValue;
      return newValue;
    } else {
      return oldAutoCompleteValue.current;
    }
    //#endregion
  })();

  return (
    <FormControl
      className={ClassUtilities.flatten(
        'Autocomplete min-w-80',
        props.className
      )}
      disabled={props.disabled}
    >
      <MUIAutocomplete
        onChange={(
          _evt,
          option: AutocompleteValue<
            Option<T>,
            Multiple,
            DisableClearable,
            FreeSolo
          >
        ) => {
          if (props.multiple === true) {
            const multipleOption = option as AutocompleteValue<
              Option<T>,
              true,
              DisableClearable,
              FreeSolo
            >;
            if (props.freeSoloOptions?.enabled) {
              const mapping = unwrap(props.freeSoloOptions?.freeSoloMapping);
              onChange?.(
                multipleOption.map((e) =>
                  isOption(e) ? e.value : mapping(e)
                ) as ValueType<T, Multiple, DisableClearable>
              );
              return;
            } else {
              onChange?.(
                multipleOption.map((e) => (e as Option<T>).value) as ValueType<
                  T,
                  Multiple,
                  DisableClearable
                >
              );
            }
          }
          // If added by user
          else if (typeof option === 'string') {
            onChange?.(
              (freeSoloOptions?.freeSoloMapping as (val: string) => T)(
                option
              ) as ValueType<T, Multiple, DisableClearable>
            );
          } else if (option === null) {
            onChange?.(null as ValueType<T, Multiple, DisableClearable>);
          } else {
            assertIsOption(option);
            onChange?.(
              option.value as ValueType<T, Multiple, DisableClearable>
            );
          }
        }}
        value={autocompleteValue}
        noOptionsText={noOptionsText ?? t('no_option')}
        size="small"
        options={options}
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) => {
          setInputValue(newInputValue);
          onInputChange?.(event, newInputValue, reason);
        }}
        isOptionEqualToValue={(option1, option2) =>
          valueEqualityOperator?.(option1.value, option2.value) ??
          option1.value === option2.value
        }
        sx={{
          ...{
            minHeight: '40px',
            '& > .MuiAutocomplete': {
              padding: '8px 16px 8px 14px',
            },
          },
          ...{ sx },
        }}
        freeSolo={freeSoloOptions?.enabled}
        {...otherProps}
      />

      {props.error && props.helperText && (
        <FormHelperText
          error={props.error}
          sx={{ marginLeft: 0, marginRight: 0 }}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

import { useEffect } from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';
import {
  PromiseSnapshot,
  PromiseSnapshotData,
  PromiseState,
} from './promiseSnapshot';

/** The state model of immutable data fetch stores. */
export interface ImmutableFetchedDataState<E extends PromiseSnapshotData, P> {
  /** The data. May be `undefined` if {@link fetch} has not been called or has not finished its process. */
  snapshot: PromiseSnapshot<E>;
  /** Lazily load data if it is not loaded yet. */
  fetch: (p: P) => Promise<void>;
}

/**
 * Creates a store for data fetch that should only happens once (for immutable data that are not changed by the current app for example).
 */
export function createImmutableDataFetchStore<T extends PromiseSnapshotData, P>(
  fetchFunction: (parameters: P) => Promise<T>
) {
  return create<ImmutableFetchedDataState<T, P>>((set, get) => ({
    snapshot: new PromiseSnapshot(),
    async fetch(p) {
      const initialEntityState = get().snapshot.state;
      if (
        initialEntityState === PromiseState.Failed ||
        initialEntityState === PromiseState.NotStarted
      ) {
        await PromiseSnapshot.trackPromiseSetter(
          () => fetchFunction(p),
          (snapshot) => {
            set({
              snapshot,
            });
          }
        );
      }
    },
  }));
}

/**
 * Fetches the store and returns the snapshot.
 * @param useStoreFunc The function to use the store.
 * @param parameters The parameters to pass to the fetch function if any.
 * @returns The snapshot contained in the store.
 */
export function useImmutableDataFetchHook<T extends PromiseSnapshotData, P>(
  useStoreFunc: UseBoundStore<StoreApi<ImmutableFetchedDataState<T, P>>>,
  parameters: P
): PromiseSnapshot<T> {
  const { fetch, snapshot } = useStoreFunc();

  useEffect(() => {
    fetch(parameters);
  }, [fetch, parameters]);

  return snapshot;
}

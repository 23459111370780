import { useEffect, useState } from 'react';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../utilities/promiseSnapshot';
import { User } from '../../../../models/user';
import { UserApi } from '../../../../api/userApi';
import { useTranslation } from 'react-i18next';

type Props = {
  userId?: User['id'];
};

export function AdminUserLink({ userId }: Props) {
  const { t } = useTranslation();
  const [userSnapshot, setUserSnapshot] = useState<PromiseSnapshot<User>>(
    new PromiseSnapshot()
  );

  useEffect(() => {
    if (userId !== undefined) {
      // Fetch project
      PromiseSnapshot.trackPromiseSetter(
        () => new UserApi().get(userId),
        setUserSnapshot
      );
    }
  }, [userId]);

  return (
    <>
      {(() => {
        if (userId === undefined) {
          return <>-</>;
        } else if (userSnapshot.state === PromiseState.Succeeded) {
          return (
            // Todo :: Faire un lien fiche user admin
            <span>
              {userSnapshot.data?.firstname + ' ' + userSnapshot.data?.lastname}
            </span>
          );
        } else {
          return <>{t('loading')}</>;
        }
      })()}
    </>
  );
}

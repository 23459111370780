import background from '../../../assets/images/logo-tiled.png';
import { ClassUtilities } from '../../../utilities/classUtility';

export interface ErrorPageTemplateProps {
  /** The title of the error, should be short. */
  title: React.ReactNode;
  /** A text explaining why the user landed on this page. */
  details: React.ReactNode;
  /** The possible actions, possible for this page.
   * Will be displayed in an horizontal flex container. */
  actions: React.ReactNode;
  /**
   * Additional class to add the component root.
   */
  className?: string;
}

/**
 * A template to display an error page.
 * @param props See {@link ErrorPageTemplateProps}.
 */
export function ErrorPageTemplate(props: ErrorPageTemplateProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        props.className,
        'ErrorPageTemplate w-[100vw] h-[100vh] flex flex-col items-center justify-center gap-16'
      )}
      style={{ background: `url('${background}` }}
    >
      <div className="flex flex-col gap-6 items-center">
        <div className="text-6xl font-raleway text-orange-700">
          {props.title}
        </div>
        <div className="font-light text-sm text-grey-600">{props.details}</div>
      </div>
      <div className="flex gap-2 flex-col md:flex-row md:flex-wrap">
        {props.actions}
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, Navigate } from 'react-router-dom';
import { UserApi } from '../../../api/userApi';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { AuthenticationMiddleware } from '../../../otherBusinessLogic/authenticationMiddleware';
import { useCurrentUserStore } from '../../../stores/currentUserStore';
import { useLoadingScreenStore } from '../../../stores/loadingScreenStore';
import { responseMiddlewares } from '../../../utilities/fetchUtilities';
import { PromiseState } from '../../../utilities/promiseSnapshot';
import { ExternalNavigate } from '../../atoms/ExternalNavigate/ExternalNavigate';

export interface RequireAuthProps {
  children: JSX.Element;
}

/**
 * Wrapper for component that should be unavailable to anonymous user.
 * Will redirect any anonymous user to the login page.
 * @param props Contains the component to protect against anonymous user.
 */
export function RequireAuth(props: RequireAuthProps) {
  const { t } = useTranslation();
  const authState = useCurrentUserStore((state) => state.user.state);
  const error = useCurrentUserStore(
    (state) => state.user.error
  ) as NetworkRequestException;

  useEffect(() => {
    responseMiddlewares.add(new AuthenticationMiddleware(), { unique: true });
  }, []);

  useEffect(() => {
    const loadingLabel = t('retrieving_authentication_info');
    useLoadingScreenStore.setState({
      opened: [PromiseState.NotStarted, PromiseState.Running].includes(
        authState
      ),
      title: loadingLabel,
    });
  }, [authState, t]);

  return (
    <>
      {authState === PromiseState.Succeeded && props.children}
      {authState === PromiseState.Failed && (
        <>{errorToComponent(error.status)}</>
      )}
    </>
  );
}

const errorToComponent = (errorStatus: number) => {
  switch (errorStatus) {
    case 403:
      return <Navigate to={'/project-list-no-rights'} />;
    case 401:
      return <ExternalNavigate to={UserApi.getSSORoute()} />;
    default:
      return (
        <Navigate
          to={`/connexion-problem?${createSearchParams({
            redirect_url: encodeURIComponent(window.location.href),
          })}`}
          replace
        />
      );
  }
};

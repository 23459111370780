import { AnchorHTMLAttributes, useMemo } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';

export type ExternalLinkProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'target'
>;

/**
 * A styled external link (opening a new tab on click).
 * @param props Same props as a classic {@link HTMLAnchorElement}, with `target` omitted.
 */
export function ExternalLink(props: ExternalLinkProps) {
  const { children, className, rel: userRel, ...otherProps } = props;

  const rel = useMemo(() => {
    const rels = new Set<string>();
    rels.add('noreferrer');
    rels.add('noopener');
    if (userRel !== undefined) {
      for (const rel of userRel.split(' ')) {
        rels.add(rel);
      }
    }
    return Array.from(rels.values()).join(' ');
  }, [userRel]);

  return (
    <a
      className={ClassUtilities.flatten(
        'ExternalLink hover:text-orange inline-flex gap-2 items-baseline',
        className
      )}
      target="_blank"
      rel={rel}
      {...otherProps}
    >
      <i className="icon-external-link transform translate-y-[0.125em]" />
      {children}
    </a>
  );
}

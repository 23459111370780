import tailwindConfig from '../tailwind.config';
import { unwrapNull } from './assertions';
import { fillOptions } from './options';

export type TailwindColors = {
  [color: string]: { [variation: string]: string };
};

export interface GetAllColorsOptions {
  keepDefault?: boolean;
}

const getAllColorsOptionsDefault: GetAllColorsOptions = {
  keepDefault: true,
};

/**
 * Represents a conceptual brightness.
 */
export enum Brightness {
  Dark,
  Bright,
}

/**
 * Represents a color by its component.
 * Each component is supposed to be in range [0, 255].
 */
export interface RgbColor {
  r: number;
  g: number;
  b: number;
}

export class Colors {
  /**
   * Attempts to retrieve the color code from a color name.
   * @param color The color code like `blue-200` or `orange.400` or  `deepgreen`.
   * @returns The color code (as `#D4E6FF`) from the given color or `null` if this does not match any color in tailwind configuration.
   */
  static fromTailwind(color: string): string | undefined {
    const parts = color.split(/[.-]/);
    if (!(parts[0] in tailwindConfig.theme.extend.colors)) {
      return undefined;
    }
    const colorModifier = parts.length > 1 ? parts[1] : 'DEFAULT';
    // eslint-disable-next-line
    return (tailwindConfig.theme.extend.colors as any)[parts[0]][colorModifier];
  }

  static getAllColors(options?: Partial<GetAllColorsOptions>): TailwindColors {
    const filledOptions = fillOptions(options, getAllColorsOptionsDefault);
    if (filledOptions.keepDefault) return tailwindConfig.theme.extend.colors;
    else {
      const result: TailwindColors = {};
      for (const [color, variations] of Object.entries(
        tailwindConfig.theme.extend.colors
      )) {
        result[color] = {};
        for (const [variationKey, variationValue] of Object.entries(
          variations
        )) {
          if (variationKey.toLowerCase() === 'default') continue;
          result[color][variationKey] = variationValue;
        }
      }

      return result;
    }
  }

  /**
   * Changes an hexadecimal color to have given {@link alphaFactor}.
   * @param colorCode An hexadecimal color code.
   * @param alphaFactor The desired opacity. In range [0, 1].
   */
  static withAlpha(colorCode: string, alphaFactor: number): string {
    const plainColor = colorCode.substring(0, 7);
    return `${plainColor}${Math.floor(alphaFactor * 255)
      .toString(16)
      .padEnd(2, '0')}`.toUpperCase();
  }

  /**
   * @param hexColor An hexadecimal color code. Accepts short form color code like #FFF.
   * @returns The RGB components of the color. See {@link RgbColor}.
   */
  static hexToRGB(hexColor: string): RgbColor {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hexColor = hexColor.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = unwrapNull(
      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor),
      `Cannot find component in color "${hexColor}".`
    );
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }

  /**
   * Returns the luminance of {@link color} in range [0, 1].
   * * 0 represents black.
   * * 1 represents white.
   */
  static getLuminance(color: RgbColor): number {
    const { r, g, b } = this.mapComponent({ ...color }, (c) => {
      c = c / 255.0;
      if (c <= 0.04045) {
        return c / 12.92;
      } else {
        return Math.pow((c + 0.055) / 1.055, 2.4);
      }
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  /**
   * @returns The preferred foreground brightness to go with the {@link backgroundColor}.
   */
  static getPreferredForegroundBrightness(
    backgroundColor: RgbColor
  ): Brightness {
    return this.getLuminance(backgroundColor) > 0.179
      ? Brightness.Dark
      : Brightness.Bright;
  }

  /**
   * Map each component of {@link color} by applying {@link func} on it.
   * {@link color} is modified by the call of this function.
   */
  static mapComponent(
    color: RgbColor,
    func: (component: number) => number
  ): RgbColor {
    color.r = func(color.r);
    color.g = func(color.g);
    color.b = func(color.b);
    return color;
  }

  /**
   * Builds a hsla color ready to be used in style/CSS.
   * @param hue The hue in degrees. [0, 360]
   * @param saturation The saturation in percentage (integer). [0, 100]
   * @param lightness The lightness in percentage (integer). [0, 100]
   * @param alpha The alpha channel in percentage (float). [0, 1]
   */
  static buildHSLA(
    hue: number,
    saturation: number,
    lightness: number,
    alpha = 1
  ) {
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  }

  /**
   * @returns The default color code for the color when something wrong happened. This color should only happen on debug.
   */
  static debugWrong(): string {
    return '#ff0000';
  }
}

import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { PaymentLineProps } from './PaymentLine';
import { PaymentLines, PaymentLinesProps } from './PaymentLines';

export interface FormPaymentLinesProps<TFieldValues extends FieldValues>
  extends Omit<
    PaymentLineProps,
    'value' | 'onChange' | 'error' | 'helperText'
  > {
  control: Control<TFieldValues, unknown>;
  name: Path<TFieldValues>;
}

// Try to encapsulate as zod form
export function FormPaymentLines<TFieldValues extends FieldValues>(
  props: FormPaymentLinesProps<TFieldValues>
) {
  const { name, control, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <PaymentLines
          value={value}
          onChange={onChange}
          helperText={error?.message}
          {...fieldProps}
          error={Boolean(error)}
          nestedErrors={
            error !== undefined && Array.isArray(error)
              ? (error as PaymentLinesProps['nestedErrors'])
              : undefined
          }
        />
      )}
    />
  );
}

import { ButtonProps, styled } from '@mui/material';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';
import { ActionLine, ActionLineProps } from '../../atoms/ActionLine/ActionLine';
import { Button as MUIButton } from '../../atoms/Button/Button';

/**
 * Derived from {@link MUIButton}. Will display a small squared icon
 * next to the button and use the props color for the icon and the button.
 */
export function ActionButton(props: ActionButtonProps) {
  const color = Colors.fromTailwind(props.color) ?? Colors.debugWrong();

  const completedButtonProps: ButtonProps = { ...props.buttonProps };

  completedButtonProps.variant = 'text';
  completedButtonProps.sx = {
    color: Colors.fromTailwind('grey-700'),
    paddingLeft: '0.625rem',
    ':hover': {
      backgroundColor: Colors.withAlpha(color, 0.3),
    },
  };
  completedButtonProps.TouchRippleProps = {
    style: {
      color: color,
    },
  };

  return (
    <StyledMUIButton
      className={ClassUtilities.flatten('ActionButton', props.className)}
      {...completedButtonProps}
      onClick={props.onClick}
      disabled={props.disabled}
      justifyContent="start"
    >
      <ActionLine {...props} />
    </StyledMUIButton>
  );
}

type StrippedButtonProps = Omit<
  ButtonProps,
  'TouchRippleProps' | 'variant' | 'className' | 'sx' | 'startIcon' | 'children'
>;

export interface ActionButtonProps extends ActionLineProps {
  buttonProps?: StrippedButtonProps;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const StyledMUIButton = styled(MUIButton)<ButtonProps>(() => ({
  '&': {
    textTransform: 'none',
    padding: '0.375rem',
  },
}));

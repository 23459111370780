import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';

import './Account.css';
import { EmailPreferencesBlock } from './components/EmailPreferencesBlock/EmailPreferencesBlock';
import { IdBlock } from './components/IdBlock/IdBlock';

export function Account() {
  const { t } = useTranslation();

  useTitle(t('account'));

  return (
    <div className="Account">
      <MainPageContainer>
        <div className="grid grid-cols-1 lg:grid-cols-2 p-8 gap-4 lg:gap-6">
          <IdBlock />
          <EmailPreferencesBlock />
        </div>
      </MainPageContainer>
    </div>
  );
}

import { Navigate } from 'react-router-dom';
import { useCurrentUserStore } from '../../../stores/currentUserStore';
import { PromiseState } from '../../../utilities/promiseSnapshot';

/**
 * Properties for {@link RequireAdmin}.
 */
export interface RequireAdminProps {
  /**
   * The children to display if the user has sufficient rights.
   */
  children: JSX.Element;
  /**
   * The redirection path to navigate to if the user does not have sufficient rights.
   */
  redirect: string;
}

/**
 * Ensures the user has admin rights to see the page.
 * @param props See {@link RequireAdminProps}
 */
export function RequireAdmin(props: RequireAdminProps): JSX.Element {
  const snapshot = useCurrentUserStore((state) => state.user);
  const isAdmin =
    snapshot.state === PromiseState.Succeeded && snapshot.data?.admin === true;

  if (isAdmin === true) {
    return props.children;
  } else {
    return <Navigate to={props.redirect} replace />;
  }
}

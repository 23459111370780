import { SelectMode } from './MenuButton';

/**
 * @param selectMode The selection mode. See {@link SelectMode} for more details.
 * @param route The route targeted by the button.
 * @param pathname The current pathname.
 * @returns `true` if the button should appear as selected, `false` otherwise.
 */
export function menuButtonIsSelected(
  selectMode: SelectMode,
  route: string,
  pathname: string
): boolean {
  return selectMode === SelectMode.RouteExact
    ? pathname === route
    : pathname.startsWith(route);
}

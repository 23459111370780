export abstract class Exception extends Error {
  get className() {
    return this.constructor['name'];
  }

  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = this.className;
  }
}

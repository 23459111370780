import { Modal as MUIModal } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { Block } from '../../atoms/Block/Block';
import {
  RoundedFilledGrayButton,
  RoundedFilledRedButton,
} from '../../atoms/Button/variations/RoundedButtonVariations';
import { TextField } from '../../atoms/TextField/TextField';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';

/**
 * Properties for {@link DeletionModal}.
 */
export type DeletionModalProps = {
  /**
   * If `true`, the component is shown.
   */
  open: boolean;
  /**
   * Callback fired when the user clicks on the confirmation button to delete the entity.
   */
  onSubmitDelete: () => void;
  /**
   * Callback fired when the user clicks on the cancel button.
   */
  onCloseModal: () => void;
  /**
   * If `true`, the request to delete the entity is currently processing.
   */
  loading: boolean;
  /**
   * Modal title.
   */
  title: string;
  /**
   * Modal subtitle.
   */
  text: React.ReactNode;
  /**
   * If `true`, a warning is displayed on the modal.
   */
  isIrreversible: boolean;
  /**
   * If defined, the user will need to type {@link confirmUsingText} to be able to click on the delete button.
   */
  confirmUsingText?: string;
};

/**
 * Confirmation modal to delete an entity.
 * @param props See {@link DeletionModalProps}
 */
export function DeletionModal(props: DeletionModalProps) {
  const { t, tFormatted } = useTranslationFormatter();
  const [confirmationText, setConfirmationText] = useState('');

  const hasConfirmedDeletion = useMemo(
    () =>
      props.confirmUsingText === undefined ||
      props.confirmUsingText === confirmationText,
    [confirmationText, props.confirmUsingText]
  );

  return (
    <MUIModal className="Modal" open={props.open}>
      <div className="Delete flex items-center justify-center h-full p-8 lg:p-4">
        <Block className="flex flex-col gap-8 py-6">
          <Title level={2}>{props.title}</Title>
          <WarningBlock>
            {props.isIrreversible && t('warning_action_irreversible')}
          </WarningBlock>
          <Title level={4}>{props.text}</Title>
          {props.confirmUsingText !== undefined && (
            <TextField
              label={t('deletion_confirmation')}
              helperText={tFormatted(
                'enter_text_to_confirm',
                { text: props.confirmUsingText },
                {
                  text: {
                    classes: 'font-bold',
                  },
                }
              )}
              onChange={(ev) => setConfirmationText(ev.target.value)}
            />
          )}
          <div className="flex justify-end gap-2">
            <RoundedFilledRedButton
              color="info"
              className="flex gap-2"
              disabled={!hasConfirmedDeletion || props.loading}
              loading={props.loading}
              onClick={props.onSubmitDelete}
            >
              {t('yes_delete')}
            </RoundedFilledRedButton>
            <RoundedFilledGrayButton
              color="info"
              onClick={props.onCloseModal}
              disabled={props.loading}
            >
              {t('no_cancel')}
            </RoundedFilledGrayButton>
          </div>
        </Block>
      </div>
    </MUIModal>
  );
}

import { assert, unwrap } from '../utilities/assertions';

const { REACT_APP_DEFAULT_ITEMS_PER_PAGE } = process.env;

export class Env {
  public static get defaultItemsPerPage() {
    const value = parseInt(
      unwrap(
        REACT_APP_DEFAULT_ITEMS_PER_PAGE,
        '"default items per page" not defined in environment variables.'
      )
    );
    assert(
      !isNaN(value),
      '"default items per page" environment variable could not be parsed to an integer.'
    );
    return value;
  }
}

import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../../api/userApi';
import { UserProjectRoleApi } from '../../../api/userProjectRoleApi';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { useTriggerValue } from '../../../hooks/useTriggerValue';
import { Project } from '../../../models/project';
import { User } from '../../../models/user';
import { useCurrentUserStore } from '../../../stores/currentUserStore';
import { ClassUtilities } from '../../../utilities/classUtility';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { Modal } from '../../atoms/Modal/Modal';
import { Title } from '../../atoms/Title/Title';
import {
  SubmissionException,
  SubmissionStep,
} from './exceptions/SubmissionException';
import { AddUserWithMailModal } from './subcomponents/AddUserWithMailModal/AddUserWithMailModal';
import { RoleModal } from './subcomponents/RoleModal';
import { UserActions } from './subcomponents/UserActions';
import { UserDeletionModal } from './subcomponents/UserDeletionModal';

export enum FirstColumnType {
  Email,
  Id,
}

export interface ProjectUserListProps {
  /**
   * The project to list the user from.
   */
  project: Project;
  /**
   * Callback called each time this component updates the project.
   * @param updatedProject The project up-to-date with the modifications.
   */
  onProjectUpdate: (updatedProject: Project) => void;
  /**
   * The first column type to display. The other will not be displayed.
   */
  firstColumnType: FirstColumnType;
  /**
   * Is the option of "adding the user using their email" is available to the user?
   * @default false
   */
  addUserWithEmail?: boolean;
  /**
   * Called when the deleted user is the logged user and this user is not an admin.
   */
  onUserSelfDelete?: () => void;
}

export function ProjectUserList(props: ProjectUserListProps) {
  const { project, onProjectUpdate, firstColumnType } = props;
  const { t } = useTranslation();
  const abortControllerRef = useRef(new AbortController());
  const [roleModalCurrentUser, setRoleModalCurrentUser] = useState<
    User | undefined
  >(undefined);
  const { triggerValue: triggerFetchValue, trigger: triggerFetch } =
    useTriggerValue();
  const [openUserDeletionModal, setOpenUserDeletionModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | undefined>(undefined);
  const [addUserWithMailModalOpen, setAddUserWithMailModalOpen] =
    useState(false);
  const addUserWithEmail = props.addUserWithEmail ?? false;
  const userSnapshot = useCurrentUserStore((state) => state.user);

  const handleUserDeletionModal = (user: User) => {
    setOpenUserDeletionModal(true);
    setUserToDelete(user);
  };

  const closeUserDeletionModal = () => {
    setOpenUserDeletionModal(false);
    setUserToDelete(undefined);
  };

  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [errorModalText, setErrorModalText] = useState<string | undefined>(
    undefined
  );

  const onSubmitDelete = async () => {
    if (userToDelete) {
      try {
        setDeletingUser(true);

        // Get role to delete
        const userProjectRoleApi = new UserProjectRoleApi();
        const roles = await userProjectRoleApi
          .getAll({
            'project[]': [project.id],
            'targeted_user[]': [userToDelete.id],
          })
          .catch((e) => {
            throw new SubmissionException(SubmissionStep.RoleRetrieval, e);
          });
        if (roles.length > 1) {
          console.warn(
            `Found more than one role for user ${userToDelete.id} in project ${project.id}`
          );
        } else if (roles.length === 0) {
          throw new SubmissionException(
            SubmissionStep.RoleRetrieval,
            t('no_role_found_with_this_user_and_project')
          );
        }
        const role = roles[0];

        // Delete the role
        await userProjectRoleApi
          .delete(role.id)
          .catch((e) => {
            throw new SubmissionException(SubmissionStep.RoleDeletion, e);
          })
          .finally(() => setDeletingUser(false));

        // End operation when everything is successful
        closeUserDeletionModal();
        triggerFetch();
        setDeletionSuccess(true);

        if (
          userSnapshot.data &&
          userToDelete.id === userSnapshot.data.id &&
          !userToDelete.admin
        ) {
          props.onUserSelfDelete?.();
        }
      } catch (e) {
        if (!(e instanceof SubmissionException)) {
          // That's an error that has not been wrapped, it is thus really unexpected => throw it to another scope.
          throw e;
        }

        // Define the error message
        let errorMessage = '';
        switch (e.step) {
          case SubmissionStep.RoleRetrieval:
            errorMessage = t('cannot_get_user_role', { reason: e.cause });
            break;
          case SubmissionStep.RoleDeletion:
            if (e.cause instanceof NetworkRequestException) {
              if (e.cause.status === 403) {
                errorMessage = t('role_deletion_forbidden_error');
              } else if (e.cause.messageKey) {
                errorMessage = t(
                  'deletion_unknown_error_user_removal_from_project',
                  { reason: t(e.cause.messageKey) }
                );
              }
            } else {
              errorMessage = t(
                'deletion_unknown_error_user_removal_from_project',
                `${e}`
              );
            }
            break;
          default:
            throw new UnhandledSwitchCaseException();
        }
        setErrorModalText(errorMessage);
      } finally {
        setDeletingUser(false);
      }
    }
  };

  const deletionFeedbackWidget = (
    <>
      <Modal
        open={deletionSuccess}
        onClose={() => setDeletionSuccess(false)}
        doNotUseCardWrapper
      >
        <Alert severity="success">{t('user_removed_from_project')}</Alert>
      </Modal>
      <Modal
        open={errorModalText !== undefined}
        onClose={() => setErrorModalText(undefined)}
        doNotUseCardWrapper
      >
        <Alert severity="error">
          <div className="max-w-prose">{errorModalText}</div>
        </Alert>
      </Modal>
    </>
  );

  const [usersSnapshot, setUsersSnapshot] = useState<PromiseSnapshot<User[]>>(
    new PromiseSnapshot()
  );

  // Fetch users
  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new UserApi().getAll({ project_id: project.id }, { abortController }),
      setUsersSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [project.id, triggerFetchValue]);

  // Abort request if page is quitted
  useEffect(() => {
    const thisAbortController = abortControllerRef.current;

    return () => {
      thisAbortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, []);

  const userActions = (user: User) => (
    <UserActions
      onRoleModalOpenRequest={() => setRoleModalCurrentUser(user)}
      onUserDeletionRequested={() => handleUserDeletionModal(user)}
    />
  );

  const userIdentification = (user: User, style: { center: boolean }) => (
    <span
      className={ClassUtilities.flatten(
        'flex gap-2 items-baseline',
        ClassUtilities.conditional({
          'justify-center': style.center,
        })
      )}
    >
      {firstColumnType === FirstColumnType.Id ? user.id : user.email}
      {user.id === project.contact && (
        <Tooltip title={t('project_contact_user')}>
          <i className="icon-user-check" />
        </Tooltip>
      )}
    </span>
  );
  // #endregion

  return (
    <>
      <Block>
        <div className="flex justify-between flex-wrap">
          <Title level={2}>{t('users_list')}</Title>
          {addUserWithEmail && (
            <RoundedFilledBlueButton
              startIcon={<i className="icon-user-plus" />}
              onClick={() => setAddUserWithMailModalOpen(true)}
            >
              {t('add_user')}
            </RoundedFilledBlueButton>
          )}
        </div>
        <div className="h-4"></div>
        {usersSnapshot.map({
          failed: (error) => <ErrorBlock>{`${error}`}</ErrorBlock>,
          notStarted: 'running',
          running: () => <LoadingBlock />,
          succeeded: (users) => (
            <>
              <TableContainer className="hidden lg:block" component={'div'}>
                <Table sx={{ minWidth: 650 }} aria-label="project users table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t(
                          firstColumnType === FirstColumnType.Id
                            ? 'user_identifier'
                            : 'email'
                        )}
                      </TableCell>
                      <TableCell>{t('firstname')}</TableCell>
                      <TableCell>{t('lastname')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user: User) => {
                      return (
                        <TableRow key={user.id}>
                          <TableCell>
                            {userIdentification(user, { center: false })}
                          </TableCell>
                          <TableCell>{user.firstname}</TableCell>
                          <TableCell>{user.lastname}</TableCell>
                          <TableCell>{userActions(user)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="lg:hidden flex flex-col gap-4 pb-4">
                {users.map((user) => (
                  <DataPaper
                    key={user.id}
                    header={userIdentification(user, { center: true })}
                    content={[
                      <DataPaperElement
                        key="firstname"
                        label={t('firstname')}
                        value={user.firstname}
                      />,
                      <DataPaperElement
                        key="lastname"
                        label={t('lastname')}
                        value={user.lastname}
                      />,
                      <DataPaperElement
                        fullSpan
                        key="actions"
                        label={t('actions')}
                        value={userActions(user)}
                      />,
                    ]}
                  />
                ))}
              </div>
            </>
          ),
        })}
      </Block>
      <UserDeletionModal
        open={openUserDeletionModal}
        onSubmitDelete={onSubmitDelete}
        onCloseModal={closeUserDeletionModal}
        user={userToDelete}
        project={project}
        loading={deletingUser}
      ></UserDeletionModal>
      {deletionFeedbackWidget}
      {roleModalCurrentUser !== undefined && (
        <RoleModal
          user={roleModalCurrentUser}
          project={project}
          onProjectChanged={onProjectUpdate}
          onClose={() => setRoleModalCurrentUser(undefined)}
        />
      )}
      {addUserWithMailModalOpen && (
        <AddUserWithMailModal
          project={project}
          onCompleted={(userAdded) => {
            setAddUserWithMailModalOpen(false);
            if (userAdded) {
              triggerFetch();
            }
          }}
        />
      )}
    </>
  );
  //#endregion
}

import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { ActionBlock } from './subcomponents/ActionBlock';
import { CompanyList } from './subcomponents/CompanyList/CompanyList';

export function AdminCompanies() {
  const { t } = useTranslation();

  useTitle(t('administration_companies'));

  return (
    <AdminPageTemplate className="AdminCompanies">
      <div className="flex flex-col gap-6">
        <ActionBlock />
        <CompanyList />
      </div>
    </AdminPageTemplate>
  );
}

import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { unwrap } from '../../../utilities/assertions';
import { RouteUtilities } from '../../../utilities/routeUtilities';
import { BlockTabs } from '../../atoms/BlockTabs/BlockTabs';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';

const routesDetails: Record<
  string,
  { term: string; fullHeightBody?: boolean }
> = {
  action: {
    term: 'actions_history',
    fullHeightBody: true,
  },
  environment: { term: 'envs_history' },
};

export function History() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [billingRoute, tabRoute] = RouteUtilities.splitRouteIntoOriginLast(
    location.pathname
  );

  const currentRouteIndex = unwrap(
    Object.keys(routesDetails).findIndex((val) => val === tabRoute)
  );

  const onTabClicked = (index: number) => {
    navigate([billingRoute, Object.keys(routesDetails)[index]].join('/'));
  };

  return (
    <div className="History overflow-y-auto">
      <MainPageContainer>
        <BlockTabs
          tabsContent={Object.values(routesDetails).map((details, index) => (
            <span key={index} className="overflow-hidden text-ellipsis">
              {t(details.term)}
            </span>
          ))}
          body={<Outlet></Outlet>}
          shownTabIndex={currentRouteIndex}
          onTabClicked={onTabClicked}
          fullHeightBody={routesDetails[tabRoute]?.fullHeightBody}
        />
      </MainPageContainer>
    </div>
  );
}

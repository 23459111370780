import {
  TransferLists,
  TransferListsProps,
  TransferListType,
} from './TransferLists';

/**
 * Props for {@link TransferListsHandler}.
 */
export interface TransferListsHandlerProps<T>
  extends Omit<TransferListsProps<T>, 'onElementMoved'> {
  /**
   * Callback triggered when the left list data has changed.
   * @param leftList is the altered list.
   */
  onLeftListDataChanged: (leftList: T[]) => void;
  /**
   * Callback triggered when the right list data has changed.
   * @param rightList is the altered list.
   */
  onRightListDataChanged: (rightList: T[]) => void;
  /**
   * Is the component disabled? If `true`, disables interaction with the component.
   */
  disabled?: boolean;
}

/**
 * Conveniency component to handle {@link TransferLists} more easily.
 * @param props See {@link TransferListsHandlerProps}.
 */
export function TransferListsHandler<T>(props: TransferListsHandlerProps<T>) {
  const {
    onLeftListDataChanged,
    onRightListDataChanged,
    leftList,
    rightList,
    ...rest
  } = props;

  return (
    <TransferLists
      {...rest}
      leftList={leftList}
      rightList={rightList}
      disabled={props.disabled}
      onElementMoved={(element, source, destination, oldIndex, newIndex) => {
        const [srcList, setSrcList] =
          source === TransferListType.Left
            ? [props.leftList.data, props.onLeftListDataChanged]
            : [props.rightList.data, props.onRightListDataChanged];
        const [dstList, setDstList] =
          destination === TransferListType.Left
            ? [props.leftList.data, props.onLeftListDataChanged]
            : [props.rightList.data, props.onRightListDataChanged];

        srcList.splice(oldIndex, 1);
        if (source !== destination) {
          setSrcList(srcList);
        }

        dstList.splice(newIndex, 0, element);
        setDstList(Array.from(dstList));
      }}
    />
  );
}

import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/codeOps-logo.png';
import './Logo.css';

export function Logo(props: LogoProps) {
  const { t } = useTranslation();

  const logoAlt = t('logo');

  return (
    <img src={logo} alt={logoAlt} className={'Logo ' + props.className ?? ''} />
  );
}

export type LogoProps = {
  className?: string;
};

import { Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { User } from '../../../../models/user';
import { Colors } from '../../../../utilities/colors';
import { getServerUrl } from '../../../../utilities/fetchUtilities';

/**
 * Props for {@link UserGroupElement}.
 */
export interface UserGroupElementProps {
  /** The information about the user to display. */
  user: Partial<Pick<User, 'imgUri'>> & Pick<User, 'firstname' | 'lastname'>;
  /** How many times to apply the offset used to make the element overlaps. This can be negative and a floating number. */
  offsetMultiplier: number;
  /** The hue of this element if user image cannot be used. In range [0, 360]. */
  hue: number;
}

/** A single element size in pixels (width and height are identical). */
export const USER_GROUP_ELEMENT_SIZE = 32;
/** The number of pixels that an element should overlap the previous one. */
export const USER_GROUP_ELEMENT_OVERLAP = 5;

/**
 * Representation of a single user in a UserGroup. This is not meant to be use anywhere else.
 * @param props See {@link UserGroupElementProps}
 */
export function UserGroupElement(props: UserGroupElementProps) {
  const [backgroundColor, color] = useMemo(() => {
    return [
      Colors.buildHSLA(props.hue, 60, 80),
      Colors.buildHSLA(props.hue, 60, 20),
    ];
  }, [props.hue]);
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Tooltip
      title={props.user.firstname + ' ' + props.user.lastname}
      placement="top"
    >
      <div
        className="UserGroupElement group/user-group-element"
        style={{
          minWidth: USER_GROUP_ELEMENT_SIZE,
          minHeight: USER_GROUP_ELEMENT_SIZE,
          width: USER_GROUP_ELEMENT_SIZE,
          height: USER_GROUP_ELEMENT_SIZE,
          transform: `translateX(${
            -USER_GROUP_ELEMENT_OVERLAP * props.offsetMultiplier
          }px)`,
        }}
      >
        <span className="hidden">{props.offsetMultiplier}</span>
        <div
          className="UserGroup__shadowy-element rounded-full w-full h-full group-hover/user-group-element:mt-[-10px] transition-[margin] text-smd text-raleway font-medium flex items-center justify-center uppercase"
          style={{
            backgroundColor,
            color,
            display: imageLoaded ? 'none' : undefined,
          }}
        >
          {`${props.user.firstname[0]}${props.user.lastname[0]}`}
        </div>
        {props.user.imgUri && (
          <img
            src={getServerUrl() + props.user.imgUri}
            alt=""
            style={{ display: imageLoaded ? undefined : 'none' }}
            className="UserGroup__shadowy-element rounded-full w-full h-full group-hover/user-group-element:mt-[-10px] transition-[margin] object-cover"
            onLoad={() => setImageLoaded(true)}
          />
        )}
      </div>
    </Tooltip>
  );
}

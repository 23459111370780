import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerSshKeyApi } from '../../../api/serverSshKeyApi';
import { SnackbarDuration } from '../../../constants/snackbarDuration';
import { ServerSshKey } from '../../../models/serverSshKey';
import { useSnackbarStore } from '../../../stores/snackbarStore';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';

export function SSHPublicKeyDisplay() {
  const { t } = useTranslation();
  const [publicSshKeySnapshot, setPublicSshKeySnapshot] = useState<
    PromiseSnapshot<ServerSshKey> | undefined
  >();
  const abortController = useRef(new AbortController());

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      useSnackbarStore.getState().addStandaloneSnackbar('copy_ssh', {
        autoHideDuration: SnackbarDuration.Short,
        message: t('ssh_copied'),
        severity: 'success',
      });
    } catch (e) {
      console.error(`Cannot copy "${text}" into clipboard.`, e);
      useSnackbarStore.getState().addStandaloneSnackbar('copy_ssh', {
        autoHideDuration: SnackbarDuration.Short,
        message: t('impossible_to_copy_SSH'),
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ServerSshKeyApi().getSingle({
          abortController: abortController.current,
        }),
      setPublicSshKeySnapshot
    );

    return () => {
      abortController.current = new AbortController();
    };
  }, []);

  return (
    <>
      {publicSshKeySnapshot !== undefined &&
        publicSshKeySnapshot.state === PromiseState.Succeeded &&
        publicSshKeySnapshot.data && (
          <>
            <div className="font-mono shadow-inner overflow-x-auto rounded p-2 bg-grey-100">
              {publicSshKeySnapshot.data.publicSshKey}
            </div>
            <div className="mt-4 grid place-items-end">
              <RoundedFilledBlueButton
                startIcon={<i className="icon-copy"></i>}
                onClick={() =>
                  copy(publicSshKeySnapshot.data?.publicSshKey ?? '')
                }
              >
                {t('copy')}
              </RoundedFilledBlueButton>
            </div>
          </>
        )}
    </>
  );
}

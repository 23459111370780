import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmailPreferenceApi } from '../../../../../api/emailPreferenceApi';
import { EmailPreference } from '../../../../../models/emailPreference';
import { useCurrentUserStore } from '../../../../../stores/currentUserStore';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../../utilities/promiseSnapshot';
import { Block } from '../../../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../../../atoms/Button/variations/RoundedButtonVariations';
import { FormCheckbox } from '../../../../atoms/Checkbox/FormCheckbox';
import { LoadingBlock } from '../../../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../../../atoms/Title/Title';
import { Schema, schema } from './schema';

export function EmailPreferencesBlock() {
  const { t } = useTranslation();
  const abortController = useRef(new AbortController());
  const [emailPreferenceSnapshot, setEmailPreferenceSnapshot] =
    useState<PromiseSnapshot<EmailPreference>>();
  const [submitted, setSubmitted] = useState(false);

  const { handleSubmit, control, setValue, watch } = useForm<Schema>({
    defaultValues: {
      enabled: true,
      succeededAction: true,
      failedAction: true,
      costWarning: true,
    },
    resolver: zodResolver(schema),
  });

  const enabledValue = watch('enabled');

  const onSubmit = async (data: Schema) => {
    setSubmitted(true);
    if (user !== undefined) {
      await new EmailPreferenceApi().patch(user.emailPreference.id, data, {
        abortController: abortController.current,
      });
    }
    setSubmitted(false);
  };

  useEffect(() => {
    const newAbortController = new AbortController();
    abortController.current = newAbortController;

    return () => {
      newAbortController.abort();
    };
  }, []);

  const [user] = useCurrentUserStore((state) => [state.user.data]);

  useEffect(() => {
    if (user !== undefined) {
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new EmailPreferenceApi().get(user.emailPreference.id, {
            abortController: abortController.current,
          }),
        setEmailPreferenceSnapshot
      );
    }
  }, [setValue, user]);

  useEffect(() => {
    if (
      emailPreferenceSnapshot?.state === PromiseState.Succeeded &&
      emailPreferenceSnapshot.data
    ) {
      setValue('enabled', emailPreferenceSnapshot.data.enabled);
      setValue('succeededAction', emailPreferenceSnapshot.data.succeededAction);
      setValue('failedAction', emailPreferenceSnapshot.data.failedAction);
      setValue('costWarning', emailPreferenceSnapshot.data.costWarning);
    }
  }, [emailPreferenceSnapshot, setValue]);

  return (
    <Block className="EmailPreferencesBlock flex flex-col justify-between items-stretch gap-4">
      {user && emailPreferenceSnapshot?.state === PromiseState.Succeeded ? (
        <>
          <Title level={2} className="flex items-center gap-2">
            <span>{t('mail_preferences')}</span>
            <Tooltip title={t('mail_preferences_details')}>
              <i className="icon-help-circle transition-opacity opacity-25 hover:opacity-100" />
            </Tooltip>
          </Title>
          <form className="flex flex-col gap-4 pb-2 md:pb-0">
            <div className="flex justify-between">
              <div className="flex flex-col grow items-stretch px-2">
                <div className="h-4" />
                <FormCheckbox
                  control={control}
                  name="enabled"
                  label={t('mails_enabled')}
                />
                <div className="h-4" />
                <hr />
                <div className="h-2" />
                <FormCheckbox
                  control={control}
                  name="succeededAction"
                  label={t('succeeded_action_notifications')}
                  disabled={enabledValue === false}
                />
                <FormCheckbox
                  control={control}
                  name="failedAction"
                  label={t('failed_action_notifications')}
                  disabled={enabledValue === false}
                />
                <FormCheckbox
                  control={control}
                  name="costWarning"
                  label={t('cost_warning_notifications')}
                  disabled={enabledValue === false}
                />
              </div>
            </div>
            <div className="h-4"></div>
            <RoundedFilledBlueButton
              className="self-end"
              onClick={handleSubmit(onSubmit)}
              loading={submitted}
              disabled={submitted}
            >
              {t('edit')}
            </RoundedFilledBlueButton>
          </form>
        </>
      ) : (
        <LoadingBlock />
      )}
    </Block>
  );
}

import { Uuid } from './uuid';

export interface Entity {
  id: Uuid;
}

/**
 * @returns `true` if {@link e1} has the same id than {@link e2}. `false` otherwise.
 */
export function entityEquality(e1: Entity, e2: Entity): boolean {
  return e1.id === e2.id;
}

import _ from 'lodash';
import { Bill } from '../../../models/bill';
import { groupBy } from '../../../utilities/array';

/**
 * Groups the {@link bills} by date from the more recent date to the old
 * @param bills The {@link Bill}s to group.
 * @returns The elements grouped by date.
 */
export function groupByDateDesc(bills: Bill[]): [Date, Bill[]][] {
  const map = groupBy(
    bills,
    (bill) => bill.date,
    (date) => date.getTime()
  );
  const entries = Array.from(map.entries());
  return _.sortBy(entries, (entry) => entry[0].getTime()).reverse();
}

import { NetworkRequestException } from '../../../exceptions/networkRequestException';

/**
 * Translates rollback error to a term ready to be translated.
 */
export function errorToTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 408:
        return 'operation_timeout';
    }
  }

  return 'rollback_unknown_error';
}

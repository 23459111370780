import { WithTranslation } from 'react-i18next';
import { z } from 'zod';

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    amount: z.number({
      required_error: t('required_field'),
    }),
    paidAt: z.date({
      required_error: t('required_field'),
    }),
    generatedId: z.string().optional(),
    id: z.string().optional(),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ActionTypeName } from '../../../models/actionTypeName';
import { FrontColor, PickFrontVersion } from '../../../models/environment';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { FormSingleSelect } from '../../atoms/SingleSelect/FormSingleSelect';
import { Title } from '../../atoms/Title/Title';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { useCompleteEnvironment } from './hooks/useCompleteEnvironment';
import { Schema, schema } from './schema';
import { errorToTerm } from './utilities';

export function PickFront() {
  const { t, tFormatted } = useTranslationFormatter();
  useTitle(t('done_action_labels.pick-front'));

  const [partialEnvironment, localPatchEnvironment] =
    useProjectEnvironmentsStore((state) => [
      unwrap(state.getEnvironment()),
      state.localPatchEnvironment,
    ]);
  const environmentSnapshot = useCompleteEnvironment(partialEnvironment);

  const { control, handleSubmit } = useForm<Schema>({
    defaultValues: {
      version: FrontColor.Blue,
    },
    resolver: zodResolver(schema),
  });

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('done_action_labels.pick-front'),
      environmentName: partialEnvironment.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = (data: Schema) => {
    // if current color is the selected color => select live
    // if current color is NOT the selected color => select nonlive

    const environment = environmentSnapshot.getSucceededData();
    const version =
      environment.liveFront === data.version
        ? PickFrontVersion.Live
        : PickFrontVersion.NonLive;

    submitAndWatchResult(
      () =>
        new EnvironmentApi().pickFront(environment.id, {
          version,
        }),
      {
        onSuccessPostAction: async () =>
          localPatchEnvironment(await new EnvironmentApi().get(environment.id)),
      }
    );
  };

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.PickFront}>
      <div className="PickFront flex">
        <Block className="flex flex-col gap-8">
          <Title level={2}>{t('action_labels.pick-front')}</Title>

          <div className="flex flex-col items-start gap-2 max-w-prose">
            <p>
              {tFormatted('pick_front_explanation', undefined, {
                color: {
                  classes: 'font-semibold',
                },
              })}
            </p>

            {environmentSnapshot.map({
              failed: (error) => <ErrorBlock noShadow>{`${error}`}</ErrorBlock>,
              notStarted: 'running',
              running: () => (
                <p className="generic-placeholder rounded-md min-w-96 text-transparent">
                  {t('loading')}
                </p>
              ),
              succeeded: (environment) => (
                <p>
                  {tFormatted(
                    'pick_front_current_color',
                    {
                      color: environment.liveFront,
                    },
                    {
                      color: {
                        classes: `font-semibold ${
                          environment.liveFront === FrontColor.Blue
                            ? 'text-blue-700'
                            : 'text-green-500'
                        }`,
                      },
                    }
                  )}
                </p>
              ),
            })}
          </div>

          <FormSingleSelect
            control={control}
            name="version"
            options={[
              {
                label: (
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full bg-blue-700 w-2 h-2"></div>
                    <span>Blue</span>
                  </div>
                ),
                value: FrontColor.Blue,
              },
              {
                label: (
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full bg-deepgreen w-2 h-2"></div>
                    <span>Green</span>
                  </div>
                ),
                value: FrontColor.Green,
              },
            ]}
            label={t('front_to_keep')}
          />

          <div className="flex justify-end gap-2">
            <RoundedFilledBlueButton
              color="info"
              disabled={loading || !environmentSnapshot.isSucceeded()}
              loading={loading}
              className="flex gap-2"
              onClick={handleSubmit(onSubmit)}
            >
              {t('pick_front_button')}
            </RoundedFilledBlueButton>
          </div>
        </Block>
      </div>
      {feedbackWidget}
    </ActionPageTemplate>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useTitle } from '../../../hooks/useTitle';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { FormGitReferencePicker } from '../../molecules/FormGitReferencePicker/FormGitReferencePicker';
import { gitReferenceSchemaDefaultValues } from '../../molecules/FormGitReferencePicker/schema';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { Schema, getSchema, schemaToPostArgs } from './schema';
import { errorToTerm } from './utilities';

import { useEnvironmentActionTypesEstimates } from '../../../hooks/useEnvironmentActionTypesEstimates';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { EnvironmentActionTypeServerSizingEstimate } from '../../../models/environmentActionType';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { NumberFormatter } from '../../../utilities/numberFormatter';
import { newLineToBr } from '../../../utilities/textFormatter';
import { FormCheckbox } from '../../atoms/Checkbox/FormCheckbox';
import { IconTooltip } from '../../atoms/IconTooltip/IconTooltip';
import {
  ActionShortInfoBlocks,
  ActionShortInfoBlocksProps,
} from '../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';
import { EstimateErrorBlock } from '../../molecules/EstimateErrorBlock/EstimateErrorBlock';

export function Deploy() {
  const { t, tFormatted } = useTranslationFormatter();

  useTitle(t('done_action_labels.deploy'));

  const [environmentId, localPatchEnvironment] = useProjectEnvironmentsStore(
    (state) => [
      state.environments.data?.[state.selectedEnvironmentIndex]?.id,
      state.localPatchEnvironment,
    ]
  );
  // Fetch action of this env to know if another source update is in progress
  const anotherDeployInProgress = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.Deploy,
    environmentId,
  });

  const deployEstimate =
    useEnvironmentActionTypesEstimates<EnvironmentActionTypeServerSizingEstimate>(
      environmentId,
      ActionTypeName.Deploy
    );

  const formMethods = useForm<Schema>({
    defaultValues: {
      gitReference: gitReferenceSchemaDefaultValues,
      switchTraffic: true,
    },
    resolver: zodResolver(getSchema(t)),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const [environmentsSnapshot, selectedEnvironmentIndex] =
    useProjectEnvironmentsStore((state) => [
      state.environments,
      state.selectedEnvironmentIndex,
    ]);

  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('done_action_labels.deploy'),
      environmentName: selectedEnvironment?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = (data: Schema) => {
    submitAndWatchResult(
      () =>
        new EnvironmentApi().postDeploy(
          unwrap(environmentId),
          schemaToPostArgs(data)
        ),
      {
        onSuccessPostAction: async () =>
          localPatchEnvironment(
            await new EnvironmentApi().get(unwrap(environmentId))
          ),
      }
    );
  };

  const blocksStyleProps: ActionShortInfoBlocksProps['stylesProps'] = [
    {
      icon: {
        color: 'yellow-200',
        icon: 'icon-clock',
      },
      label: t('operation_estimated_duration'),
      className: 'grow',
    },
    {
      icon: {
        color: 'deepgreen-200',
        icon: 'icon-server',
      },
      label: t('server_sizing'),
      className: 'grow',
    },
    {
      icon: {
        color: 'orange-200',
        icon: 'icon-bill',
      },
      label: (
        <>
          {t('operation_estimated_cost')}{' '}
          <IconTooltip
            title={t('deploy_estimated_cost_explanation')}
          ></IconTooltip>
        </>
      ),
      className: 'grow',
    },
  ];

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.Deploy}>
      <div className="Deploy grid grid-cols-1 lg:grid-cols-aside-md gap-6 justify-items-stretch">
        <Block className="grow shrink flex flex-col gap-8">
          <Title level={2}>{t('do_deploy')}</Title>
          <div className="flex SourceUpdate__form-block gap-8">
            <div className="flex flex-col gap-6">
              <Title level={4}>
                {tFormatted(
                  'select_git_reference_env',
                  {
                    environment: selectedEnvironment?.name ?? '',
                  },
                  {
                    environment: {
                      classes: 'text-orange',
                    },
                  }
                )}
              </Title>
              <div className="flex flex-col gap-4">
                <FormGitReferencePicker formMethods={formMethods} />
                <FormCheckbox
                  control={control}
                  name="switchTraffic"
                  label={
                    <>
                      {t('switch_traffic')}{' '}
                      <IconTooltip
                        title={<>{newLineToBr(t('switch_traffic_tooltip'))}</>}
                      />
                    </>
                  }
                />
                <div className="h-2 md:h-12"></div>
                <RoundedFilledBlueButton
                  color="info"
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                  disabled={
                    Object.keys(errors).length > 0 ||
                    loading ||
                    anotherDeployInProgress
                  }
                >
                  {t('action_labels.deploy')}
                </RoundedFilledBlueButton>
              </div>
            </div>
            {anotherDeployInProgress && (
              <div className="-order-1 md:order-2 flex flex-col gap-4">
                <WarningBlock>{t('deploy_already_occurring')}</WarningBlock>
              </div>
            )}
          </div>
        </Block>
        <div className="min-w-48 flex flex-col gap-4 shrink grow">
          {deployEstimate.map({
            succeeded: (data) => (
              <>
                <ActionShortInfoBlocks
                  stylesProps={blocksStyleProps}
                  valueDescriptions={[
                    DateFormatter.toDuration(data.estimatedDuration, t, {
                      maximumParts: 1,
                    }),
                    t('server_sizing_specs', data.serverSizing),
                    t('price_per_hour_template', {
                      price: NumberFormatter.toEuro(
                        unwrap(data.estimatedTotalInclTax?.perHour)
                      ),
                    }),
                  ]}
                />
              </>
            ),
            failed: () => <EstimateErrorBlock />,
            notStarted: 'running',
            running: () => (
              <>
                <ActionShortInfoBlocks stylesProps={blocksStyleProps} />
              </>
            ),
          })}
        </div>
      </div>

      {feedbackWidget}
    </ActionPageTemplate>
  );
}

import { NetworkRequestException } from '../../../exceptions/networkRequestException';

export function errorToTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 409:
        return 'project_contains_active_environment';
    }
  }

  return 'deletion_unknown_error_project_deletion';
}

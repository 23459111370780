import { useTranslation } from 'react-i18next';
import { IconShortInfoBlock } from '../IconShortInfoBlock/IconShortInfoBlock';

export function EstimateErrorBlock() {
  const { t } = useTranslation();

  return (
    <IconShortInfoBlock
      icon={{
        icon: 'icon-alert-triangle',
        color: 'pink-300',
      }}
      label={t('estimation_retrieval_failed')}
    />
  );
}

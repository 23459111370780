import { useCallback, useId } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import { TransferList, TransferListProps } from './subcomponents/TransferList';

/** The type of a single {@link TransferList}. */
export enum TransferListType {
  Left = 'left',
  Right = 'right',
}

/**
 * Props for {@link TransferLists}.
 */
export interface TransferListsProps<T>
  extends Pick<TransferListProps<T>, 'renderElement' | 'elementToKey'> {
  /** Left list customization (see {@link TransferListProps} for more details) */
  leftList: Pick<TransferListProps<T>, 'title' | 'data'>;
  /** Right list customization (see {@link TransferListProps} for more details) */
  rightList: Pick<TransferListProps<T>, 'title' | 'data'>;
  /**
   * Callback triggered when an element moved in the lists.
   *
   * Warning: {@link source} and {@link destination} may be equals,
   * in this case, the element does not change list but is reordered in the list.
   *
   * @param dataElement The element that has moved.
   * @param source Identifies the list where the element comes from.
   * @param destination Identifies the list where the element is inserted.
   * @param oldIndex The index of the element in the {@link source} list.
   * @param newIndex The index in the element in the {@link destination} list.
   */
  onElementMoved: (
    dataElement: T,
    source: TransferListType,
    destination: TransferListType,
    oldIndex: number,
    newIndex: number
  ) => void;
  /**
   * Is the component disabled? If `true`, disable the interaction
   * with the component and alters the style.
   */
  disabled?: boolean;
}

export function TransferLists<T>(props: TransferListsProps<T>) {
  const id = useId();

  const transferToRight = useCallback(
    (oldIndex: number, newIndex?: number) => {
      props.onElementMoved(
        props.leftList.data[oldIndex],
        TransferListType.Left,
        TransferListType.Right,
        oldIndex,
        newIndex ?? props.rightList.data.length
      );
    },
    [props]
  );

  const transferToLeft = useCallback(
    (oldIndex: number, newIndex?: number) => {
      props.onElementMoved(
        props.rightList.data[oldIndex],
        TransferListType.Right,
        TransferListType.Left,
        oldIndex,
        newIndex ?? props.leftList.data.length
      );
    },
    [props]
  );

  const reorder = useCallback(
    (list: TransferListType, oldIndex: number, newIndex: number) => {
      props.onElementMoved(
        (list === TransferListType.Left ? props.leftList : props.rightList)
          .data[oldIndex],
        list,
        list,
        oldIndex,
        newIndex
      );
    },
    [props]
  );

  return (
    <div
      className={ClassUtilities.flatten(
        'TransferList grid grid-cols-2 gap-2 transition-[filter]',
        ClassUtilities.conditional({
          'grayscale pointer-events-none opacity-75': Boolean(props.disabled),
        })
      )}
    >
      <TransferList
        {...props.leftList}
        renderElement={props.renderElement}
        elementToKey={props.elementToKey}
        onElementSent={transferToRight}
        onElementReceived={transferToLeft}
        onElementReordered={(oldIndex, newIndex) =>
          reorder(TransferListType.Left, oldIndex, newIndex)
        }
        handlerId={id}
        disabled={props.disabled}
      />
      <TransferList
        {...props.rightList}
        renderElement={props.renderElement}
        elementToKey={props.elementToKey}
        onElementSent={transferToLeft}
        onElementReceived={transferToRight}
        onElementReordered={(oldIndex, newIndex) =>
          reorder(TransferListType.Right, oldIndex, newIndex)
        }
        handlerId={id}
        disabled={props.disabled}
      />
    </div>
  );
}

import { NetworkRequestException } from '../../../exceptions/networkRequestException';

export function errorToTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 429:
        return 'toggle_vm_already_occurring';
      case 403:
        if (
          exception.messageKey === 'exception.job.toggle_vm.forbidden.for_prod'
        ) {
          return 'toggle_vm_forbidden_for_prod';
        }
        break;
      case 409:
        return exception.messageKey ?? 'toggle_vm_unknown_error';
    }
  }

  return 'toggle_vm_unknown_error';
}

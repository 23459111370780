import { useEffect, useRef, useState } from 'react';
import { ProjectApi } from '../api/projectApi';
import { InitEnvEstimate, Project } from '../models/project';
import { PromiseSnapshot } from '../utilities/promiseSnapshot';

export function useInitEnvEstimates(
  id: Project['id']
): PromiseSnapshot<InitEnvEstimate> {
  const [snapshot, setSnapshot] = useState(
    new PromiseSnapshot<InitEnvEstimate>()
  );
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () => new ProjectApi().getInitEnvEstimates(id, { abortController }),
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [id]);

  return snapshot;
}

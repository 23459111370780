import { ActionType } from '../models/actionType';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const { REACT_APP_ROUTE_ACTION_TYPES } = process.env;

/**
 * Internal data for {@link GetOptionsHandler}.
 */
export interface GetOptions {
  page?: number;
}

/**
 * Get options for the {@link ActionTypeApi}.
 */
class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
    };
  }
}

export class ActionTypeApi extends BaseApi<ActionType, GetOptions> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_ACTION_TYPES, 'ActionType route not defined'),
      GetOptionsHandler
    );
  }
}

import { UserProjectRoleType } from '../models/userProjectRole';
import { containsAny, encapsulate } from '../utilities/array';
import { useProjectRoles } from './useProjectRoles';

export enum RoleCheckType {
  /**
   * Means the check is considered valid if it has all given roles.
   */
  All,
  /**
   * Means the check is considered valid if the user has at least one of the given roles.
   */
  Any,
}

/**
 * Checks for user roles.
 * @param roles The roles to check for the user.
 * @param checkType See {@link RoleCheckType}.
 * @returns
 * * `true` if the user meet the conditions.
 * * `false` otherwise.
 * * `undefined` will be returned while the user info are being retrieved or the project context is unavailable.
 */
export function useProjectRoleCheck(
  roles: UserProjectRoleType[],
  checkType: RoleCheckType
): boolean | undefined {
  const userRoles = useProjectRoles();
  if (userRoles === undefined) return undefined;
  if (checkType === RoleCheckType.All) {
    return encapsulate(userRoles, roles);
  } else {
    return containsAny(userRoles, roles);
  }
}

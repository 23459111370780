import i18next from 'i18next';

export enum ActionStatus {
  Running = 'running',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

/**
 * @param status The status.
 * @returns A human friendly readable text describing the status.
 */
export function actionStatusToLabel(status: ActionStatus): string {
  return i18next.t(`action_status.${status}`);
}

import { ClassUtilities } from '../../../utilities/classUtility';

export interface BlockTabBodyProps {
  className?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
}

export function BlockTabBody(props: BlockTabBodyProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'BlockTabBody relative z-[2] shadow-md rounded-b-md min-w-1 bg-white transition-[padding] py-1 px-3 md:py-3 md:px-6 w-full',
        ClassUtilities.conditional({
          'rounded-tr-md': !props.fullWidth,
        }),
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

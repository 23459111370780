import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { Radio, RadioProps } from './Radio';

export interface FormRadioProps<TFieldValues extends FieldValues>
  extends Omit<
    RadioProps,
    'name' | 'error' | 'helperText' | 'onChange' | 'value'
  > {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, unknown>;
  /** The value the form takes when this radio is selected. */
  onValue: PathValue<TFieldValues, Path<TFieldValues>>;
  hideErrorMessage?: boolean;
}

export function FormRadio<TFieldValues extends FieldValues>(
  props: FormRadioProps<TFieldValues>
) {
  const { name, control, onValue, hideErrorMessage, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Radio
          helperText={
            !hideErrorMessage && error?.message ? error?.message : null
          }
          error={!!error}
          onChange={(value) => {
            if (value) {
              onChange(onValue);
            }
          }}
          checked={value === onValue}
          {...fieldProps}
        />
      )}
    />
  );
}

import { Outlet } from 'react-router-dom';
import { useLoadingScreenStore } from '../../../stores/loadingScreenStore';
import { LoadingScreen } from '../../atoms/LoadingScreen/LoadingScreen';

/**
 * The widget in charge to render the loading screen matching the LoadingScreenStore.
 */
export function GlobalLoadingScreen() {
  const props = useLoadingScreenStore();

  return (
    <>
      <LoadingScreen {...props}></LoadingScreen>
      <Outlet />
    </>
  );
}

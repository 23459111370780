import { useState } from 'react';
import { UnhandledSwitchCaseException } from '../../../../exceptions/unhandledSwitchCaseException';
import { Button } from '../../../atoms/Button/Button';

export function ErrorThrower() {
  const [error, setError] = useState<Error | undefined>(undefined);

  if (error !== undefined) {
    throw error;
  }

  return (
    <div className="ErrorThrower flex items-center justify-start">
      <Button
        variant="contained"
        onClick={() => {
          setError(
            new UnhandledSwitchCaseException('fake switch case exception')
          );
        }}
      >
        Click to throw an error
      </Button>
    </div>
  );
}

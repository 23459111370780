import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Bill } from '../../models/bill';
import { DateFormatter } from '../../utilities/dateFormatter';
import { NumberFormatter } from '../../utilities/numberFormatter';
import { Modal } from '../atoms/Modal/Modal';
import { Title } from '../atoms/Title/Title';

export interface BillingLinesModalProps {
  openedBill: Bill | null;
  onCloseBill: () => void;
}

/**
 * Component to visualize billing lines details in a modal.
 * @param props See {@link BillingLinesModalProps}.
 */
export function BillingLinesModal(props: BillingLinesModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={props.openedBill !== null} onClose={props.onCloseBill}>
      {props.openedBill !== null && (
        <div className="p-4 h-full max-h-[90vh] flex flex-col gap-4 min-h-0 grow">
          <Title level={2} className="flex gap-4 justify-start items-center">
            <i className="icon-bill"></i>
            {`${t('bill_details_excl_tax')} - ${DateFormatter.toMonth(
              props.openedBill.date
            )}`}
          </Title>
          <TableContainer
            component="div"
            className="overflow-y-auto max-h-full grow min-h-0"
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('bill_wording')}</TableCell>
                  <TableCell>{t('amount_price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.openedBill.lines.map((billLine) => (
                  <TableRow key={billLine['@id']}>
                    <TableCell>{billLine.description}</TableCell>
                    <TableCell>
                      {NumberFormatter.toEuro(billLine.total)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className="font-bold">
                  <TableCell>
                    <span className="font-bold">{t('total')}</span>
                  </TableCell>
                  <TableCell>
                    <span className="font-bold">
                      {NumberFormatter.toEuro(props.openedBill.totalExclTax)}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Modal>
  );
}

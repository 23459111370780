import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { FormTextField } from '../../atoms/TextField/FormTextField';
import { Title } from '../../atoms/Title/Title';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { getSchema, Schema } from './schema';
import { errorToTerm } from './utilities';

export function RenameEnvironment() {
  const { t } = useTranslationFormatter();
  useTitle(t('done_action_labels.rename-environment'));

  const [
    environmentsSnapshot,
    selectedEnvironmentIndex,
    localPatchEnvironment,
  ] = useProjectEnvironmentsStore((state) => [
    state.environments,
    state.selectedEnvironmentIndex,
    state.localPatchEnvironment,
  ]);
  const environmentId =
    environmentsSnapshot.data?.[selectedEnvironmentIndex]?.id;
  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  const { control, handleSubmit } = useForm<Schema>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(getSchema(t)),
  });

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('done_action_labels.rename'),
      environmentName: selectedEnvironment?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = (data: Schema) => {
    submitAndWatchResult(
      () => new EnvironmentApi().rename(unwrap(environmentId), data.name),
      {
        onSuccessPostAction: async () =>
          localPatchEnvironment(
            await new EnvironmentApi().get(unwrap(environmentId))
          ),
      }
    );
  };

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.RenameEnvironment}>
      <div className="Delete flex">
        <Block className="flex flex-col gap-8">
          <Title level={2}>{t('action_labels.rename-environment')}</Title>
          <FormTextField
            control={control}
            name="name"
            label={t('environment_name')}
          />
          <div className="flex justify-end gap-2">
            <RoundedFilledBlueButton
              color="info"
              disabled={loading}
              className="flex gap-2"
              onClick={handleSubmit(onSubmit)}
              loading={loading}
            >
              {t('rename')}
            </RoundedFilledBlueButton>
          </div>
        </Block>
      </div>
      {feedbackWidget}
    </ActionPageTemplate>
  );
}

import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { UserApi } from '../../../api/userApi';
import {
  DefaultProjectIcon,
  getProjectIcon,
} from '../../../businessComponentUtilities/projectHelper';
import { PartialEnvironment } from '../../../models/environment';
import { ProjectPartial } from '../../../models/project';
import { User } from '../../../models/user';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { ChipGroup } from '../../atoms/ChipGroup/ChipGroup';
import { DiscreteTextLine } from '../../atoms/DiscreteTextLine/DiscreteTextLine';
import { Image } from '../../atoms/Image/Image';
import { PlaceholderChip } from '../../atoms/PlaceholderChip/PlaceholderChip';
import { Title } from '../../atoms/Title/Title';
import { UserGroup } from '../../atoms/UserGroup/UserGroup';
import './ProjectCard.css';
import { LastAction } from './subcomponents/LastAction';

export interface ProjectCardProps {
  project: ProjectPartial;
}

export function ProjectCard(props: ProjectCardProps) {
  const { t } = useTranslation();
  const [environmentsSnapshot, setEnvironmentsSnapshot] = useState(
    new PromiseSnapshot<PartialEnvironment[]>()
  );
  const [usersSnapshot, setUsersSnapshot] = useState(
    new PromiseSnapshot<User[]>()
  );
  const abortController = useRef(new AbortController());

  // Do all fetches
  useEffect(() => {
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new EnvironmentApi().getAll(
          {
            'project[]': [props.project.id],
            'deleted_at[strictly_after]': dayjs().add(1, 'year').toDate(),
          },
          { abortController: abortController.current }
        ),
      setEnvironmentsSnapshot,
      { abortController: abortController.current }
    );

    PromiseSnapshot.trackPromiseSetter(
      () => new UserApi().getAll({ project_id: props.project.id }),
      setUsersSnapshot,
      { abortController: abortController.current }
    );

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [props.project]);

  return (
    <Link className="ProjectCard" to={`/project/${props.project.id}`}>
      <div className="flex flex-col p-6 bg-white max-w-sm w-[24rem] rounded shadow-md hover:shadow-xl transition-[box-shadow] gap-6 h-full">
        <div className="flex gap-6 items-center">
          <Image
            src={getProjectIcon(props.project)}
            alt="Icône du projet"
            className="w-10 h-10 rounded-md"
            imgClassName="shadow-sm"
            fallbackSrc={DefaultProjectIcon}
            onError={() => {
              console.warn(
                'Project picture could not be loaded, replacing with the default one.'
              );
            }}
          />
          <Title level={2}>{props.project.name}</Title>
        </div>
        <div className="flex flex-col gap-2 grow">
          <LastAction environmentIds={props.project.environments} />
          <div className="section">
            <span className="section-title">
              {t('environment', { count: props.project.environments.length })}
            </span>
            {props.project.environments.length > 0 ? (
              <>
                {environmentsSnapshot.data ? (
                  <ChipGroup
                    chips={environmentsSnapshot.data.map((env) => ({
                      key: env.id,
                      label: env.name,
                      props: {
                        context: 'info',
                      },
                    }))}
                  />
                ) : (
                  <div className="flex gap-2">
                    <PlaceholderChip />
                    <PlaceholderChip />
                  </div>
                )}
              </>
            ) : (
              <DiscreteTextLine>
                {t('no_existing_env_for_project')}
              </DiscreteTextLine>
            )}
          </div>
          <div className="grow"></div>
          {usersSnapshot.data ? (
            <UserGroup users={usersSnapshot.data} justify="end" noShadow />
          ) : (
            <div className="self-end rounded-full generic-placeholder w-8 h-8"></div>
          )}
        </div>
      </div>
    </Link>
  );
}

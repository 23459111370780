import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import './Image.css';

type HTMLImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;
type ImagePropsBase = Omit<HTMLImageProps, 'alt' | 'className'> &
  Required<Pick<HTMLImageProps, 'alt'>>;

export interface ImageProps extends ImagePropsBase {
  /** The src to use when original src fails to load. */
  fallbackSrc?: string;
  /** Class name to apply on placeholder and image. */
  className?: HTMLImageProps['className'];
  /** Class name to apply on placeholder only. */
  placeholderClassName?: string;
  /** Class name to apply on image only. */
  imgClassName?: string;
}

/**
 * Displays a placeholder while the image is loading. If the image could not be loaded a fallback src can be used.
 * @param props See {@link ImageProps}.
 */
export function Image(props: ImageProps) {
  const [loaded, setLoaded] = useState(false);
  const {
    placeholderClassName,
    imgClassName,
    className,
    alt,
    fallbackSrc,
    onError,
    onLoad,
    src,
    ...imgProps
  } = props;

  return (
    <div
      className={ClassUtilities.flatten(
        'Image stack',
        ClassUtilities.conditional({
          'Image--loaded': loaded,
        }),
        className
      )}
    >
      <div
        className={ClassUtilities.flatten(
          'Image__placeholder',
          className,
          placeholderClassName
        )}
      />
      <img
        className={ClassUtilities.flatten(className, imgClassName)}
        alt={alt}
        src={src}
        {...imgProps}
        onLoad={(ev) => {
          onLoad?.(ev);
          setLoaded(true);
        }}
        onError={(ev) => {
          onError?.(ev);
          if (ev.currentTarget.src !== fallbackSrc) {
            if (fallbackSrc) {
              ev.currentTarget.src = fallbackSrc;
            } else {
              setLoaded(true);
            }
          } else {
            setLoaded(true);
          }
        }}
      />
    </div>
  );
}

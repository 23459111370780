import { InformationTooltip } from '../../atoms/InformationPopup/InformationPopup';
import { Option } from '../../atoms/SingleSelect/SingleSelect';

export const templatesOptions: Option<string>[] = [
  {
    label: (
      <span key="1.0.0" className="flex gap-2 items-center">
        <span>1.0.0</span>
        <InformationTooltip info="1.0.0 release version of Codein website" />
      </span>
    ),
    value: '1.0.0',
  },
];

export const tags: string[] = ['v1.0.0', 'v1.0.1'];

export const branches: string[] = ['feature/must-have', 'hotfix/fix-crash'];

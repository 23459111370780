import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GitReferenceType } from '../../../models/gitReferenceType';
import { FormSingleSelect } from '../../atoms/SingleSelect/FormSingleSelect';
import { FormTextField } from '../../atoms/TextField/FormTextField';
import { BranchSelect } from '../../pages/NewEnvironment/subcomponents/BranchSelect/BranchSelect';
import { TagSelect } from '../../pages/NewEnvironment/subcomponents/TagSelect/TagSelect';
import { GitReferenceSchema } from './schema';
// Disabled warnings because we need those imports for VS documentation to work on FormGitReferencePicker.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getGitReferenceSchema } from './schema';

export interface GitReferenceSchemaHolderInterface {
  gitReference: GitReferenceSchema;
}

export interface FormGitReferencePickerProps<
  TFieldValues extends GitReferenceSchemaHolderInterface
> {
  formMethods: Pick<
    UseFormReturn<TFieldValues, unknown>,
    'control' | 'clearErrors' | 'watch'
  >;
}

/**
 * This Git reference picker is a widget group to integrate in bigger forms.
 * It allows to select a git reference of one type among {@link GitReferenceType} values.
 *
 * The only constraint to use it is to integrate the {@link getGitReferenceSchema} into your zod schema like this for example:
 *
 * @example
 * import { WithTranslation } from 'react-i18next';
 *
 * export function getMySchema(t: WithTranslation['t']) {
 *   return z.object({
 *     gitReference: getGitReferenceSchema(t),
 *     // Your other form values...
 *   });
 * }
 */
export function FormGitReferencePicker<
  TFieldValues extends GitReferenceSchemaHolderInterface
>(props: FormGitReferencePickerProps<TFieldValues>) {
  const { t } = useTranslation();

  const { control, clearErrors, watch } =
    props.formMethods as unknown as UseFormReturn<
      GitReferenceSchemaHolderInterface,
      unknown
    >;

  const referenceType = watch('gitReference.type');

  useEffect(() => {
    clearErrors('gitReference.branch');
    clearErrors('gitReference.tag');
    clearErrors('gitReference.hash');
  }, [clearErrors, referenceType]);

  return (
    <div className="FormGitReferencePicker flex flex-col gap-4">
      <FormSingleSelect
        control={control}
        options={Object.values(GitReferenceType).map((refTypeVal) => ({
          label: t(refTypeVal.toLowerCase()),
          value: refTypeVal,
        }))}
        name="gitReference.type"
        label={t('reference_type')}
      />
      {referenceType === GitReferenceType.Hash && (
        <FormTextField
          control={control}
          name="gitReference.hash"
          label="Hash"
          placeholder="e5e6dceca0e2e93a7a864048a37cf424c61c89d0"
        />
      )}

      {referenceType === GitReferenceType.Branch && (
        <BranchSelect control={control} />
      )}

      {referenceType === GitReferenceType.Tag && (
        <TagSelect control={control} />
      )}
    </div>
  );
}

export interface GitReferenceInfo {
  referenceType: GitReferenceType;
  reference: string;
}

import { useCurrentUserStore } from '../stores/currentUserStore';
import { unwrap } from '../utilities/assertions';
import { PromiseState } from '../utilities/promiseSnapshot';

/**
 * @returns `true` if the {@link User} is admin. This has nothing to do with its specific rights in a project context.
 */
export function useCheckAdmin(): boolean | undefined {
  const userSnapshot = useCurrentUserStore((state) => state.user);
  if (userSnapshot.state !== PromiseState.Succeeded) return undefined;
  return unwrap(userSnapshot.data).admin;
}

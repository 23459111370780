import { useEffect } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { Block } from '../../atoms/Block/Block';
import {
  RoundedFilledGrayButton,
  RoundedFilledRedButton,
} from '../../atoms/Button/variations/RoundedButtonVariations';
import { Title } from '../../atoms/Title/Title';

import { useNavigate } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { RundeckException } from '../../../exceptions/rundeckException';
import { useCanCreateNewEnvironment } from '../../../hooks/useCanCreateNewEnvironment';
import { useProjectRoute } from '../../../hooks/useProjectRoute';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ActionTypeName } from '../../../models/actionTypeName';
import { EnvironmentStatus } from '../../../models/environmentStatus';
import { useSnackbarStore } from '../../../stores/snackbarStore';
import { unwrap } from '../../../utilities/assertions';
import { UniqueId } from '../../../utilities/uniqueIdGenerator';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { errorToErrorTerm, waitForEnvironmentToBeReady } from './utilities';

export function RetryInitEnv() {
  const navigate = useNavigate();
  const canCreateNewEnvironment = useCanCreateNewEnvironment();

  useEffect(() => {
    if (false === canCreateNewEnvironment) {
      navigate('/', { replace: true });
    }
  }, [canCreateNewEnvironment, navigate]);

  const { t, tFormatted } = useTranslationFormatter();
  useTitle(t('retry_init_env'));

  const projectRoute = useProjectRoute();
  const projectId = unwrap(
    useProjectsStore((state) => state.selectedProjectId)
  );
  const refreshEnvironments = () =>
    useProjectEnvironmentsStore.getState().fetch(projectId, true);
  const snackbarHandlePromise = useSnackbarStore(
    (state) => state.handlePromise
  );

  const [
    environmentsSnapshot,
    selectedEnvironmentIndex,
    setSelectedEnvironmentIndex,
  ] = useProjectEnvironmentsStore((state) => [
    state.environments,
    state.selectedEnvironmentIndex,
    state.setSelectedEnvironmentIndex,
  ]);

  const environmentId =
    environmentsSnapshot.data?.[selectedEnvironmentIndex]?.id;
  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  const onSubmit = async () => {
    await snackbarHandlePromise(
      `new-environment-${UniqueId.generate()}`,
      async () => {
        await new EnvironmentApi().postRetryInitEnv(unwrap(environmentId));
        setSelectedEnvironmentIndex(
          unwrap(environmentsSnapshot.data).findIndex(
            (e) =>
              e.status !== EnvironmentStatus.Creating &&
              e.name !== selectedEnvironment?.name
          )
        );
        // If setSelectedEnvironmentIndex and refreshEnvironments are called in the same tick, it will crash
        // because of that, we need to wait for the next tick to call refreshEnvironments
        // a better solution is probably possible, but as the problem is not that big and limited to one page,
        // we will think about it later
        setTimeout(() => {
          unwrap(refreshEnvironments)(); // To see environment in creation
        });
        const environment = await waitForEnvironmentToBeReady(
          unwrap(environmentId)
        );
        if (environment.status === EnvironmentStatus.Running) {
          unwrap(refreshEnvironments)();
        } else {
          // In fact, it could be another error, but statistically, it is the most probable
          throw new RundeckException('env_unknown_error_could_not_create');
        }

        return null;
      },
      {
        getErrorMessage: (error) => (
          <div className="flex flex-col">
            <div>
              {t('snackbar_operation_failure', {
                actionName: t('env_creation'),
              })}
            </div>
            <div>{t(errorToErrorTerm(error))}</div>
          </div>
        ),
        getLoadingMessage: () => (
          <>
            {t('snackbar_operation_in_progress', {
              actionName: t('env_creation'),
            })}
          </>
        ),
        getSuccessMessage: () => (
          <>
            {t('snackbar_operation_success', {
              actionName: t('env_creation'),
            })}
          </>
        ),
      }
    );
  };

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.RetryInitEnv}>
      <div className="flex">
        <Block className="flex flex-col gap-8">
          <Title level={2}>{t('retry_init_env')}</Title>
          <Title level={4}>
            {tFormatted(
              'confirm_retry_init_env',
              {
                environment: selectedEnvironment?.name ?? '',
              },
              {
                environment: {
                  classes: 'text-orange',
                },
              }
            )}
          </Title>
          <div className="flex justify-end gap-2">
            <RoundedFilledRedButton
              color="info"
              disabled={canCreateNewEnvironment === false}
              className="flex gap-2"
              onClick={onSubmit}
            >
              {t('yes')}
            </RoundedFilledRedButton>
            <RoundedFilledGrayButton
              color="info"
              onClick={() => navigate(projectRoute)}
            >
              {t('no_cancel')}
            </RoundedFilledGrayButton>
          </div>
        </Block>
      </div>
    </ActionPageTemplate>
  );
}

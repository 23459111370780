import { useEffect, useRef, useState } from 'react';
import { BillApi } from '../../../../../../api/billApi';
import { Bill, BillStatus } from '../../../../../../models/bill';
import { PromiseSnapshot } from '../../../../../../utilities/promiseSnapshot';
import { UniqueId } from '../../../../../../utilities/uniqueIdGenerator';
import { groupByDateDesc } from '../../../utilities';

/** The result for {@link useUnsentBills}. */
export interface UseUnsentBillsResults {
  /** The bills grouped by date. */
  billsSnapshot: PromiseSnapshot<[Date, Bill[]][]>;
  /** Refetches the bills. */
  refetch: () => void;
}

/**
 * Retrieves the unsent bills.
 * @returns See {@link UseUnsentBillsResults}
 */
export function useUnsentBills(): UseUnsentBillsResults {
  const [trigger, setTrigger] = useState(UniqueId.generate());
  const [snapshot, setSnapshot] = useState(
    new PromiseSnapshot<[Date, Bill[]][]>()
  );
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      async () => {
        const bills = await new BillApi().getAll(
          {
            'status[]': [BillStatus.New],
          },
          { abortController }
        );
        return groupByDateDesc(bills);
      },
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [trigger]);

  return {
    billsSnapshot: snapshot,
    refetch: () => setTrigger(UniqueId.generate()),
  };
}

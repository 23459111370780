import {
  Card,
  Modal as MUIModal,
  ModalProps as MUIModalProps,
} from '@mui/material';
import { ClassUtilities } from '../../../utilities/classUtility';

export interface ModalProps
  extends Pick<MUIModalProps, 'open' | 'onClose' | 'className'> {
  children: React.ReactNode;
  doNotUseCardWrapper?: boolean;
  fixedWidth?: string;
  /** Note that the modal is not the direct container of {@link children}. */
  className?: string;
}

export function Modal(props: ModalProps) {
  return (
    <MUIModal
      className={ClassUtilities.flatten('Modal', props.className)}
      open={props.open}
      onClose={props.onClose}
    >
      <div className="flex items-center justify-center h-full pointer-events-none px-4 py-8 lg:px-8 transition-[padding]">
        {props.doNotUseCardWrapper ? (
          <div className="pointer-events-auto max-h-full">{props.children}</div>
        ) : (
          <Card
            className="pointer-events-auto max-h-full"
            sx={
              props.fixedWidth !== undefined
                ? {
                    width: props.fixedWidth,
                  }
                : undefined
            }
          >
            <div className="p-2 lg:p-4 transition-[padding] overflow-y-auto flex items-stretch max-h-full">
              {props.children}
            </div>
          </Card>
        )}
      </div>
    </MUIModal>
  );
}

import { Project, ProjectPartial } from '../../models/project';

export function isProject(
  project: Project | ProjectPartial
): project is Project {
  return (
    project.billingCompany === undefined ||
    typeof project.billingCompany === 'object'
  );
}

export function isProjectPartial(
  project: Project | ProjectPartial
): project is ProjectPartial {
  return (
    project.billingCompany === undefined ||
    typeof project.billingCompany === 'string'
  );
}

export interface ZipCodeInfoFr {
  zipCode: string; // Example: "34000"
  cityCode: string; // Example: "34712"
  cityName: string; // Example: "Montpellier"
  routeLabel: string; // Example: "MONTPELLIER"
}

export interface ZipCodeInfoFrRaw {
  codePostal: string; // Example: "34000"
  codeCommune: string; // Example: "34712"
  nomCommune: string; // Example: "Montpellier"
  libelleAcheminement: string; // Example: "MONTPELLIER"
}

export function refineZipCode(raw: ZipCodeInfoFrRaw): ZipCodeInfoFr {
  return {
    zipCode: raw.codePostal,
    cityCode: raw.codeCommune,
    cityName: raw.nomCommune,
    routeLabel: raw.libelleAcheminement,
  };
}

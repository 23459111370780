import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import * as Record from '../utilities/recordUtilities';
import { Environment } from './environment';

/**
 * An estimate of the costs for the current month on a specific environment.
 */
export interface Estimate {
  /**
   * The base environment information.
   */
  environment: Pick<Environment, 'id' | 'name' | 'identifier'>;
  /**
   * The estimates costs.
   */
  estimations: {
    /**
     * Total price ranges including taxes to the end of the month in €
     */
    toEndOfMonth: {
      highestTotalInclTax: number;
      lowestTotalInclTax: number;
    };
    /**
     * Total price ranges including taxes to this day of the month in €
     */
    toDate: {
      highestTotalInclTax: number;
      lowestTotalInclTax: number;
    };
  } | null;
  /**
   * The creation date of the environment.
   */
  createdAt: Environment['createdAt'];
  /**
   * The deletion date of the environment.
   */
  deletedAt: Environment['deletedAt'];
  /**
   * The activity duration of the environment from creation date to deletion date.
   */
  lifeDuration: Duration;
}

/**
 * The raw data received from the API.
 */
export interface RawEstimate
  extends Pick<Estimate, 'environment' | 'estimations'> {
  createdAt: string;
  deletedAt: string | null;
  lifeDuration: {
    y: number;
    m: number;
    d: number;
    h: number;
    i: number; // minutes
  };
}

/**
 * @param estimate The {@link RawEstimate} coming straight from the API.
 * @returns The {@link Estimage} refined with more usable type.
 */
export function refineEstimate(estimate: RawEstimate): Estimate {
  const createdAt = dayjs(estimate.createdAt).toDate();
  const deletedAt =
    estimate.deletedAt === null
      ? undefined
      : dayjs(estimate.deletedAt).toDate();

  // #region Normalize life duration fields (negative fields clamped to zero)
  const normalizedLifeDuration = Record.mapValues<
    number,
    number,
    RawEstimate['lifeDuration']
  >(estimate.lifeDuration, (value) => (value > 0 ? value : 0));
  // #endregion

  const lifeDuration = dayjs.duration({
    years: normalizedLifeDuration.y,
    months: normalizedLifeDuration.m,
    days: normalizedLifeDuration.d,
    hours: normalizedLifeDuration.h,
    minutes: normalizedLifeDuration.i,
  });

  return {
    environment: estimate.environment,
    estimations: estimate.estimations,
    createdAt,
    deletedAt,
    lifeDuration,
  };
}

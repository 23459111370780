import { WithTranslation } from 'react-i18next';
import { z } from 'zod';

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    name: z
      .string()
      .min(1, 'required_field')
      .regex(/^[a-zA-Z0-9- ]+$/, t('env_rename_invalid_format')),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

import { z } from 'zod';

export const schema = z.object({
  role: z.string().optional(),
  userId: z.string(),
  projectId: z.string(),
  isContactProject: z.boolean(),
});

export type UserManagementSchema = z.infer<typeof schema>;

import _ from 'lodash';
import { EmptyValueException } from '../exceptions/emptyValueException';
import { MissingElementException } from '../exceptions/missingElementException';
import { unwrap } from './assertions';
import { Hashable } from './hashable';
import { HashMap } from './hashMap';
import { NonUndefined } from './typeUtilities';

/**
 * @param collection The source collection.
 * @param predicate The predicate to verify.
 * @returns `true` if all element of {@link collection} satisfies the {@link predicate}. `false` otherwise.
 */
export function all<T>(
  collection: Iterable<T>,
  predicate: (el: T) => boolean
): boolean {
  for (const el of collection) {
    if (!predicate(el)) {
      return false;
    }
  }
  return true;
}

/**
 * @param collection The source collection.
 * @param predicate The predicate to verify.
 * @returns `true` if at least one element of the {@link collection} satisfies the {@link predicate}.
 */
export function contain<T>(
  collection: Iterable<T>,
  predicate: (el: T) => boolean
): boolean {
  for (const el of collection) {
    if (predicate(el)) {
      return true;
    }
  }
  return false;
}

/**
 * Replaces an element in the array by another.
 *
 * @remarks If several elements match the {@link predicate}, only the first met will be replaced.
 *
 * @throws {@link MissingElementException} if the element to replace cannot be found.
 * @param array The array to mutate.
 * @param predicate The predicate to find the element to replace.
 * @param replacer The element that do the replacement.
 * @returns The mutated {@link array}.
 */
export function replace<T>(
  array: Array<T>,
  predicate: (el: T) => boolean,
  replacer: T
): Array<T> {
  const index = _.findIndex(array, predicate);
  if (index === -1) {
    throw new MissingElementException('The element to replace was not found.');
  }
  array[index] = replacer;
  return array;
}

/**
 * Strips out all `undefined` element from the array.
 * @param array The array to filter.
 * @returns The array without its `undefined` element.
 */
export function filterUndefined<T>(array: Array<T | undefined>): Array<T> {
  return array.filter((el) => el !== undefined) as Array<T>;
}

/**
 * @param array The array to filter.
 * @param predicate The filter predicate.
 * @returns A first array composed of the element from {@link array} matching the {@link predicate}.
 * All elements not matching the {@link predicate} will be put in the second array.
 */
export function filterSplit<T>(
  array: Array<T>,
  predicate: (el: T) => boolean
): [Array<T>, Array<T>] {
  const matching: T[] = [];
  const others: T[] = [];

  for (const element of array) {
    if (predicate(element)) {
      matching.push(element);
    } else {
      others.push(element);
    }
  }

  return [matching, others];
}

/**
 * Checks {@link encapsulator} contains all elements of {@link encapsulated}.
 * @param equalityOperator Overrides the comparison function used to compare elements.
 */
export function encapsulate<T>(
  encapsulator: T[],
  encapsulated: T[],
  equalityOperator?: (a: T, b: T) => boolean
): boolean {
  if (encapsulated.length > encapsulator.length) return false;
  const eq = getEqualityOperator(equalityOperator);

  for (const encapsulatedEl of encapsulated) {
    if (
      encapsulator.findIndex((encapsulatorEl) =>
        eq(encapsulatorEl, encapsulatedEl)
      ) === -1
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Checks {@link array1} contains the same element as {@link array2} (strictly, without any extra or missing element).
 * @param equalityOperator Overrides the comparison function used to compare elements.
 */
export function equivalent<T>(
  array1: T[],
  array2: T[],
  equalityOperator?: (a: T, b: T) => boolean
): boolean {
  if (array1.length !== array2.length) return false;
  return encapsulate(array1, array2, equalityOperator);
}

/**
 * Checks {@link array1} contains the same element as {@link array2} (strictly, without any extra or missing element) and in the exact same order.
 * @param equalityOperator Overrides the comparison function used to compare elements.
 */
export function equals<T>(
  array1: T[],
  array2: T[],
  equalityOperator?: (a: T, b: T) => boolean
): boolean {
  if (array1.length !== array2.length) return false;
  const eq = getEqualityOperator(equalityOperator);
  for (let i = 0; i < array1.length; ++i) {
    if (!eq(array1[i], array2[i])) return false;
  }
  return true;
}

/**
 * Removes {@link element} from {@link array}.
 *
 * Elements are compared using {@link equalityOperator} (if not provided `===` equality will be used).
 *
 * @warning {@link array} is not modified by this function. A new array is returned instead.
 */
export function removeFirst<T>(
  array: T[],
  element: T,
  equalityOperator?: (a: T, b: T) => boolean
): T | undefined {
  const eq = getEqualityOperator(equalityOperator);
  const indexToRemove = array.findIndex((val) => eq(val, element));
  if (indexToRemove === -1) return undefined;
  return array.splice(indexToRemove, 1)[0];
}

/**
/**
 * @param container The container to searched in.
 * @param searchedElements The element to find into {@link container}.
 * @param equalityOperator Overrides the comparison function used to compare elements.
 * @returns `true` if at least one element of {@link searchedElements} exists in {@link container}. `false` otherwise.
 */
export function containsAny<T>(
  container: T[],
  searchedElements: T[],
  equalityOperator?: (a: T, b: T) => boolean
) {
  const eq = getEqualityOperator(equalityOperator);
  for (const searchedElement of searchedElements) {
    if (container.findIndex((element) => eq(element, searchedElement)) !== -1) {
      return true;
    }
  }
  return false;
}

/**
 * Creates a new {@link array} containing elements from {@link array} and containing {@link inBetweenElement} between each element of {@link array}.
 * @example
 * const result = insertBetween([1, 2, 3], 0);
 * console.log(result); // [1, 0, 2, 0, 3]
 * @param array
 * @param inBetweenElement
 * @returns
 */
export function insertBetween<T>(array: T[], inBetweenElement: T): T[] {
  if (array.length === 0) return [];

  const result: T[] = [];
  for (let i = 0; i < array.length - 1; ++i) {
    result.push(array[i]);
    result.push(inBetweenElement);
  }
  result.push(unwrap(array.at(-1)));

  return result;
}

/**
 * @param array The elements to search through.
 * @param comparisonFunc The function used to compare the element of {@link array}.
 * @returns The maximum value of the {@link array}.
 */
export function max<T>(array: T[], comparisonFunc: (el1: T, el2: T) => number) {
  if (array.length === 0) {
    throw new EmptyValueException('array is empty.');
  }

  let maximumValue = array[0];
  for (const el of array.splice(1)) {
    if (comparisonFunc(el, maximumValue) > 0) {
      maximumValue = el;
    }
  }

  return maximumValue;
}

/**
 * @param array The elements to search through.
 * @param comparisonFunc The function used to compare the element of {@link array}.
 * @returns The minimum value of the {@link array}.
 */
export function min<T>(array: T[], comparisonFunc: (el1: T, el2: T) => number) {
  if (array.length === 0) {
    throw new EmptyValueException('array is empty.');
  }

  let minimumValue = array[0];
  for (const el of array.splice(1)) {
    if (comparisonFunc(el, minimumValue) < 0) {
      minimumValue = el;
    }
  }

  return minimumValue;
}

/**
 * @returns An element from {@link array} drawned randomly in the list.
 */
export function drawOneRandom<T>(array: T[]): T {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
}

/**
 * For each element of {@link array}, maps the element to the value of its {@link field}.
 * @warning If the field values used for the mapping contains the same value several times, the mapping will contains less values than {@link array} because values sharing the same key will be overwritten.
 * @param array The source array to map element from.
 * @param field The field used as a key for the mapping.
 */
export function asMap<T>(array: T[], field: keyof T): Map<T[keyof T], T> {
  const map = new Map<T[keyof T], T>();

  for (const element of array) {
    map.set(element[field], element);
  }

  return map;
}

/**
 * For each element of {@link array}, maps the element to an extracted key from an element.
 * @warning If the extracted keys appears several times, the mapping will contains less values than {@link array} because values sharing the same key will be overwritten.
 * @param array The source array to map element from.
 * @param keyExtractionFunc The function used to map an element to its key.
 */
export function asMapFunc<T, K>(
  array: T[],
  keyExtractionFunc: (value: T) => K
): Map<K, T> {
  const map = new Map<K, T>();

  for (const element of array) {
    map.set(keyExtractionFunc(element), element);
  }

  return map;
}

/**
 * Group {@link values} by key.
 * @param values The values to group.
 * @param keyExtractor The function allowing to extract a key from a value.
 * @param keyHash The function to transform the key into something comparable.
 * @returns The element from {@link values} grouped by key.
 */
export function groupBy<T, K>(
  values: NonUndefined<T>[],
  keyExtractor: (value: NonUndefined<T>) => K,
  keyHash: (key: K) => string | number
): HashMap<K, NonUndefined<T>[]> {
  const map = HashMap.fromNonHashable<K, NonUndefined<T>[]>(keyHash);

  for (const value of values) {
    const key = keyExtractor(value);
    const grouppedValue = map.get(key)?.concat([value]) ?? [value];
    map.set(key, grouppedValue);
  }

  return map;
}

/**
 * Group {@link values} by key.
 * @param values The values to group.
 * @param keyExtractor The function allowing to extract a key from a value. The key must implements the {@link Hashable} interface.
 * @returns The element from {@link values} grouped by key.
 */
export function groupByHashable<T, K extends Hashable>(
  values: NonUndefined<T>[],
  keyExtractor: (value: NonUndefined<T>) => K
): HashMap<K, T[]> {
  return groupBy(values, keyExtractor, (key) => key.toHash());
}

//#region Private functions

/**
 * @param equalityOperator A user passed equality operator option.
 * @returns The option if defined or the default equality operator.
 */
function getEqualityOperator<T>(
  equalityOperator?: (a: T, b: T) => boolean
): (a: T, b: T) => boolean {
  return equalityOperator ?? ((a, b) => a === b);
}

//#endregion

const numberRegex = /^\d+$/;

/**
 * @param urlSearchParams The search parameters.
 * @param key The key of the integer value to retrieve.
 * @returns
 * * An integer number if the value can be parsed to an integer number.
 * * `undefined` if the value cannot be parsed to an integer number (note that float number will not be parsed to integer number).
 * * `null` if the value does not exist in the
 */
export function getInt(
  urlSearchParams: URLSearchParams,
  key: string
): number | undefined | null {
  const numberStr = urlSearchParams.get(key);
  if (numberStr === null) return null;

  if (numberRegex.test(numberStr)) {
    return parseInt(numberStr);
  } else return undefined;
}

/**
 * Utility class to generate unique identifiers.
 */
export class UniqueId {
  private static _lastTime: number = Date.now();
  private static _increment = 0;

  /** Generates a unique identifier. This identifier is not cryptographically secure or random as a UUID would be.
   * But its uniqueness is guaranteed when used in a single thread.
   */
  static generate(): string {
    const now = Date.now();
    if (now !== this._lastTime) {
      this._lastTime = now;
      this._increment = 0;
    } else {
      ++this._increment;
    }
    return `${this._lastTime}${this._increment}`;
  }
}

import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface UserActionsProps {
  onRoleModalOpenRequest?: () => void;
  onUserDeletionRequested?: () => void;
}

export function UserActions(props: UserActionsProps) {
  const { t } = useTranslation();
  const roleActionDisabled = props.onRoleModalOpenRequest === undefined;
  const userDeletionActionDisabled =
    props.onUserDeletionRequested === undefined;

  return (
    <ButtonGroup variant="text" aria-label="text button group">
      <Tooltip title={t('show_edit_role')}>
        <span>
          <IconButton
            size="small"
            onClick={() => props.onRoleModalOpenRequest?.()}
            disabled={roleActionDisabled}
          >
            <i className="icon-id-card text-orange" />
          </IconButton>
        </span>
      </Tooltip>
      <div className="w-2"></div>
      <Tooltip title={t('remove_user_from_project')}>
        <span>
          <IconButton
            size="small"
            onClick={() => props.onUserDeletionRequested?.()}
            disabled={userDeletionActionDisabled}
          >
            <i
              className={
                'icon-user-minus ' +
                (userDeletionActionDisabled ? 'text-grey-300' : 'text-orange')
              }
            />
          </IconButton>
        </span>
      </Tooltip>
    </ButtonGroup>
  );
}

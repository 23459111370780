import { useEffect } from 'react';

const { REACT_APP_TITLE_PREFIX } = process.env;

/**
 * Uses the given {@link title} for this page.
 * @param title The title to use for the tab.
 */
export function useTitle(title: string): void {
  useEffect(() => {
    const separatorNeeded =
      REACT_APP_TITLE_PREFIX !== undefined && title !== undefined;
    document.title = `${REACT_APP_TITLE_PREFIX ?? ''}${
      separatorNeeded ? ' - ' : ''
    }${title ?? ''}`;
  }, [title]);
}

import { Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';

export interface IconTooltipProps {
  /** The icon classname. Defaults to `icon-info`. */
  iconClassName?: string;
  /** The title (the text that should appear in the tooltip). */
  title: ReactNode;
}

/**
 * An icon triggering the appearance of a tooltip when the mouse is hovering it.
 * @param props See {@link IconTooltipProps}.
 */
export function IconTooltip(props: IconTooltipProps) {
  return (
    <Tooltip className="icon-tooltip" title={props.title}>
      <i
        className={ClassUtilities.flatten(
          'inline-block flex-col items-baseline transform translate-y-[0.125em] transition-opacity opacity-50 hover:opacity-100',
          props.iconClassName ?? 'icon-info'
        )}
      />
    </Tooltip>
  );
}

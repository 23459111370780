import { Location } from 'react-router-dom';
import { ActionType } from '../../../models/actionType';
import { Environment } from '../../../models/environment';
import { Project } from '../../../models/project';

export function extractProjectEnvironmentFromLocation(location: Location) {
  const state = location.state as
    | { project?: Project; environment?: Environment }
    | undefined;
  return {
    project: state?.project,
    environment: state?.environment,
  };
}

export type ActionTypeLists = {
  disabled: ActionType[];
  enabled: ActionType[];
};

import { EmptyValueException } from '../exceptions/emptyValueException';
import { unwrap } from '../utilities/assertions';
import { Entity } from './entity';
import { Project } from './project';
import { User } from './user';

export enum UserProjectRoleType {
  Admin = 'ROLE_ADMIN',
  Manager = 'ROLE_MANAGER',
  Developer = 'ROLE_DEVELOPER',
  CustomerViewer = 'ROLE_CUSTOMER_VIEWER',
  Viewer = 'ROLE_VIEWER',
  Access = 'ROLE_ACCESS',
}

export interface UserProjectRole extends Entity {
  /** The {@link Project} id. */
  project: Project['id'];
  /** The role, see {@link UserProjectRoleType} for available options. */
  role: UserProjectRoleType;
  /** The {@link User} id. */
  user: User['id'];
}

export class UserProjectRoleHandler {
  public data: UserProjectRole;

  constructor(userProjectRole: UserProjectRole) {
    this.data = userProjectRole;
  }

  /**
   * Get the term of the role (for translation purpose).
   */
  getRoleTerm(): string {
    return UserProjectRoleHandler.getTermForRole(this.data.role);
  }

  /**
   * Get the term of the {@link role} (for translation purpose).
   */
  static getTermForRole(role: UserProjectRoleType): string {
    return role.toLowerCase();
  }

  /**
   * @returns The most important roles among {@link roles}.
   */
  static getMostImportantRoleType(
    roles: UserProjectRoleType[]
  ): UserProjectRoleType {
    return roles[this.getMostImportantRoleTypeIndex(roles)];
  }

  private static getMostImportantRoleTypeIndex(
    roles: UserProjectRoleType[]
  ): number {
    if (roles.length === 0) {
      throw new EmptyValueException('roles must contain at least one element');
    }

    const roleOrdering = new Map<UserProjectRoleType, number>();
    roleOrdering.set(UserProjectRoleType.Admin, 5);
    roleOrdering.set(UserProjectRoleType.Manager, 4);
    roleOrdering.set(UserProjectRoleType.Developer, 3);
    roleOrdering.set(UserProjectRoleType.CustomerViewer, 2);
    roleOrdering.set(UserProjectRoleType.Viewer, 1);
    roleOrdering.set(UserProjectRoleType.Access, 0);

    let mostImportantRoleIndex = 0;
    let mostImportantRoleOrder: number | undefined = undefined;
    let index = 0;

    for (const role of roles) {
      const order = unwrap(
        roleOrdering.get(role),
        `order for role "${role}" is unknown.`
      );

      if (
        mostImportantRoleOrder === undefined ||
        mostImportantRoleOrder < order
      ) {
        mostImportantRoleOrder = order;
        mostImportantRoleIndex = index;
      }
      ++index;
    }

    return mostImportantRoleIndex;
  }
}

import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { unwrap } from '../../../utilities/assertions';
import { RouteUtilities } from '../../../utilities/routeUtilities';
import { BlockTabs } from '../../atoms/BlockTabs/BlockTabs';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';
import './Billing.css';

const routesLabel = {
  'current-month': 'current_month_estimation',
  details: 'billing_detail',
  history: 'history',
};

export function Billing() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [billingRoute, tabRoute] = RouteUtilities.splitRouteIntoOriginLast(
    location.pathname
  );
  const currentRouteIndex = unwrap(
    Object.keys(routesLabel).findIndex((val) => val === tabRoute)
  );

  const onTabClicked = (index: number) => {
    navigate([billingRoute, Object.keys(routesLabel)[index]].join('/'));
  };

  return (
    <div className="Billing overflow-y-auto">
      <MainPageContainer>
        <BlockTabs
          tabsContent={Object.values(routesLabel).map((label, index) => (
            <span key={index} className="overflow-hidden text-ellipsis">
              {t(label)}
            </span>
          ))}
          body={<Outlet></Outlet>}
          shownTabIndex={currentRouteIndex}
          onTabClicked={onTabClicked}
        />
      </MainPageContainer>
    </div>
  );
}

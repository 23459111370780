import { styled, Tooltip } from '@mui/material';
import { duration } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionHistoryApi } from '../../../api/actionHistoryApi';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { ActionHistory } from '../../../models/actionHistory';
import { ActionStatus } from '../../../models/actionStatus';
import { ActionType, ActionTypeHandler } from '../../../models/actionType';
import { User } from '../../../models/user';
import { ClassUtilities } from '../../../utilities/classUtility';
import {
  DateFormatter,
  TimeUnit,
  ToDurationOptions,
} from '../../../utilities/dateFormatter';
import { repeatPromiseUntil } from '../../../utilities/repeatedPromise';
import { FilledRoundedButton } from '../../atoms/Button/variations/FilledRoundedButton';
import {
  lightOrangeFilledStyle,
  tightStyle,
} from '../../atoms/Button/variations/sharedBase';
import {
  SquaredIcon,
  SquaredIconProps,
} from '../../atoms/SquaredIcon/SquaredIcon';
import './ActionHistoryTile.css';
import { ActionHistoryModal } from './subcomponents/ActionHistoryTileModal';

/** Props for {@link ActionHistoryTile}. */
export interface ActionHistoryTileProps {
  /** The {@link ActionHistory} */
  actionHistory: ActionHistory;
  /** The hydrated {@link ActionType} linked to the {@link actionHistory}. */
  actionType: ActionType;
  /** The hydrated {@link User} linked to the {@link actionHistory}. */
  author: User | undefined;

  /** Callback function triggered whenever the {@link actionHistory} is updated by the back. */
  onActionHistoryUpdated: (updatedActionHistory: ActionHistory) => void;
}

/**
 * @param actionStatus An action status
 * @returns An icon and a color to represent the action status.
 */
function actionStatusToIconProps(
  actionStatus: ActionStatus
): Pick<SquaredIconProps, 'icon' | 'color'> {
  switch (actionStatus) {
    case ActionStatus.Failed:
      return { icon: 'icon-x', color: 'pink-300' };
    case ActionStatus.Succeeded:
      return { icon: 'icon-check', color: 'green-200' };
    case ActionStatus.Running:
      return { icon: 'icon-refresh-cw', color: 'orange-300' };
    default:
      throw new UnhandledSwitchCaseException();
  }
}

function actionStatusToTooltipTerm(actionStatus: ActionStatus): string {
  switch (actionStatus) {
    case ActionStatus.Failed:
      return 'tooltip_task_failed';
    case ActionStatus.Succeeded:
      return 'tooltip_task_succeeded';
    case ActionStatus.Running:
      return 'tooltip_task_running';
    default:
      throw new UnhandledSwitchCaseException(`received: ${actionStatus}`);
  }
}

const toDurationOptions: Partial<ToDurationOptions> = {
  maximumParts: 2,
  parts: TimeUnit.Hour | TimeUnit.Minute | TimeUnit.Second,
  countZeroParts: false,
};

/**
 * Displays a compact blowk with the information about an {@link ActionHistory} element.
 * @param props See {@link ActionHistoryTileProps}.
 */
export function ActionHistoryTile(props: ActionHistoryTileProps) {
  const { t } = useTranslation();
  const { id, status, startedAt, endedAt, estimatedDuration } =
    props.actionHistory;
  const [logsModalOpen, setLogsModalOpen] = useState(false);

  const abortController = useRef(new AbortController());
  const previouslyRunning = useRef(status === ActionStatus.Running);

  const durationIsEstimated = status === ActionStatus.Running;
  const formattedDuration = durationIsEstimated
    ? DateFormatter.toDuration(
        duration({ seconds: estimatedDuration }),
        t,
        toDurationOptions
      )
    : DateFormatter.toDurationFromDates(
        startedAt,
        endedAt ?? startedAt,
        t,
        toDurationOptions
      );

  // Fetch the action while it is running.
  useEffect(() => {
    if (status === ActionStatus.Running) {
      const api = new ActionHistoryApi();
      repeatPromiseUntil(
        () => api.get(id, { abortController: abortController.current }),
        (actionHistory) => actionHistory.status !== ActionStatus.Running,
        10000,
        { initialDelay: true }
      ).then((actionHistory) => {
        props.onActionHistoryUpdated(actionHistory);
      });
    }

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [id, props, status]);

  return (
    <div
      className={ClassUtilities.flatten(
        'ActionHistoryTile p-4 bg-grey-100 rounded-md flex text-smd gap-4 font-heebo transition-all',
        ClassUtilities.conditional({
          'bg-grey-200 shadow-inner': logsModalOpen,
        })
      )}
    >
      <div>
        <Tooltip title={t(actionStatusToTooltipTerm(status))}>
          <div>
            <SquaredIcon
              {...actionStatusToIconProps(status)}
              small
              iconClassName={
                status === ActionStatus.Running
                  ? 'animate-spin-sometimes'
                  : previouslyRunning.current
                  ? 'animate-wiggle-once'
                  : undefined
              }
            />
          </div>
        </Tooltip>
      </div>
      <div className="grow flex flex-col justify-between gap-2">
        <div>
          <div className="inline-spaced">
            <span className="font-bold">
              {new ActionTypeHandler(props.actionType).getDoneFriendlyName()}
            </span>
            {status === ActionStatus.Running && (
              <>
                <span>-</span>
                <span>{t('action_status.running')}</span>
              </>
            )}
          </div>
          <div className="inline-spaced">
            <span>{DateFormatter.toDateTime(startedAt)}</span>
            <span>-</span>
            <span className="inline-flex gap-1 items-baseline">
              {durationIsEstimated && (
                <Tooltip title={t('tooltip_estimated_duration_description')}>
                  <i className="icon-crystal-ball self-center" />
                </Tooltip>
              )}
              {formattedDuration}
            </span>
          </div>
          <div className="text-sm text-grey-500">
            {props.author ? (
              <span>{`${props.author.firstname[0].toUpperCase()}. ${
                props.author.lastname
              }`}</span>
            ) : (
              <span className="italic">{t('unknown')}</span>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <LogButton onClick={() => setLogsModalOpen(true)}>
            {t('log')}
          </LogButton>
        </div>
      </div>

      <ActionHistoryModal
        actionHistoryId={id}
        open={logsModalOpen}
        onClose={() => setLogsModalOpen(false)}
      />
    </div>
  );
}

const LogButton = styled(styled(FilledRoundedButton)(lightOrangeFilledStyle))(
  tightStyle
);

import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';
import { Block, BlockProps } from '../../atoms/Block/Block';
import { Title } from '../../atoms/Title/Title';
import { SSHPublicKeyDisplay } from '../SshPublicKeyDisplay/SshPublicKeyDisplay';

export type SSHPublicKeyBlockProps = BlockProps;

export function SSHPublicKeyBlock(props: SSHPublicKeyBlockProps) {
  const { className, ...blockProps } = props;
  const { t } = useTranslation();

  return (
    <Block
      className={ClassUtilities.flatten('SSHPublicKeyBlock', className)}
      {...blockProps}
    >
      <Title level={2}>{t('ssh_deploy_key')}</Title>
      <div className="h-4"></div>
      <Alert
        icon={<i className="icon-info text-grey-700" />}
        sx={{ background: Colors.fromTailwind('blue-100') }}
      >
        <p className="text-md font-heebo">
          {t('please_allow_ssh_key_in_repository')}
        </p>
      </Alert>
      <div className="h-4"></div>
      <SSHPublicKeyDisplay />
    </Block>
  );
}

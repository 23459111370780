import { useEffect } from 'react';
import { useEstimates } from '../../../../../hooks/useEstimates';
import { useTranslationFormatter } from '../../../../../hooks/useTranslationFormatter';
import { Environment } from '../../../../../models/environment';
import { Project } from '../../../../../models/project';
import { useEstimatesStore } from '../../../../../stores/estimatesStore';
import { Block } from '../../../../atoms/Block/Block';
import { InformationTooltip } from '../../../../atoms/InformationPopup/InformationPopup';
import { Title } from '../../../../atoms/Title/Title';
import {
  getOperatingIconParagraph,
  getStandbyIconParagraph,
} from './utilities';

export interface CostBlockProps {
  className?: string;
  projectId: Project['id'];
  environmentId: Environment['id'];
}

export function CostBlock(props: CostBlockProps) {
  const { t, tFormatted } = useTranslationFormatter();

  const estimatesSnapshot = useEstimates(props.projectId);
  const { fetch } = useEstimatesStore();

  useEffect(() => {
    if (!estimatesSnapshot.isSucceeded()) return;
    if (!estimatesSnapshot.data) return;
    if (estimatesSnapshot.data.has(props.environmentId)) return;
    fetch(props.projectId, { force: true });
  }, [estimatesSnapshot, fetch, props.environmentId, props.projectId]);

  return (
    <Block className="CostBlock">
      <Title level={2}>
        <div className="flex gap-2 items-center">
          {t('estimated_costs')}
          <InformationTooltip
            info={tFormatted('estimates_env_cost_tooltip', undefined, {
              bold: {
                classes: 'font-bold',
              },
            })}
          />
        </div>
      </Title>
      <div className="pt-4 gap-x-4 justify-start flex flex-wrap sm:flex-col xl:flex-nowrap xl:flex-row">
        {getOperatingIconParagraph(
          estimatesSnapshot.mapType(
            (estimates) =>
              estimates.get(props.environmentId)?.estimations?.toEndOfMonth
                ?.highestTotalInclTax ?? null
          ),
          t
        )}
        {getStandbyIconParagraph(
          estimatesSnapshot.mapType(
            (estimates) =>
              estimates.get(props.environmentId)?.estimations?.toEndOfMonth
                ?.lowestTotalInclTax ?? null
          ),
          t
        )}
      </div>
    </Block>
  );
}

import { useRef } from 'react';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { BillHistoryBlock } from './subcomponents/BillHistoryBlock/BillHistoryBlock';
import { BillToEmitBlock } from './subcomponents/BillToEmitBlock/BillToEmitBlock';
import { BillPatchEventNotifier } from './utilities/billPatchEventNotifier';

export function AdminBills() {
  const billPatchEventNotifierRef = useRef(new BillPatchEventNotifier());

  return (
    <AdminPageTemplate>
      <div className="flex flex-col gap-6">
        <BillToEmitBlock
          billPatchEventNotifier={billPatchEventNotifierRef.current}
        />
        <BillHistoryBlock
          billPatchEventNotifier={billPatchEventNotifierRef.current}
        />
      </div>
    </AdminPageTemplate>
  );
}

import { NetworkRequestException } from '../../../exceptions/networkRequestException';

export function errorToTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 429:
        return 'deploy_already_occurring';
    }
  }

  return 'deploy_unknown_error';
}

/**
 * @param milliseconds The time to wait in milliseconds.
 * @returns A promise resolved after {@link milliseconds}.
 */
export function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Wait for {@link milliseconds} unless it is aborted (with the {@link abortController}).
 * @returns `true` if the delay did go to its end. `false` otherwise.
 */
export function delayAbortable(
  milliseconds: number,
  abortController: AbortController
): Promise<boolean> {
  return new Promise((resolve) => {
    if (abortController.signal.aborted) resolve(false);
    const timeoutId = setTimeout(() => {
      abortController.signal.removeEventListener('abort', abortFunc);
      resolve(true);
    }, milliseconds);
    const abortFunc = () => {
      clearTimeout(timeoutId);
      resolve(false);
    };
    abortController.signal.addEventListener('abort', abortFunc);
  });
}

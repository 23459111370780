import { Paper } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Estimates } from '../../../../stores/estimatesStore';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import { newLineToBr } from '../../../../utilities/textFormatter';
import { TotalLine, TotalLineType } from './TotalLine';

export interface TotalBlockProps {
  estimatesSnapshot: PromiseSnapshot<Estimates>;
}

function computeEstimates(snapshot: PromiseSnapshot<Estimates>): {
  hardwareToDate: number | undefined;
  hardwareEndOfMonth: number | undefined;
  totalEndOfMonth: number | undefined;
} {
  if (snapshot.isSucceeded()) {
    const estimates = Array.from(snapshot.getSucceededData().values());
    const hardwareToDate = _.sum(
      estimates.map(
        (estimate) => estimate.estimations?.toDate?.highestTotalInclTax ?? 0
      )
    );
    const hardwareEndOfMonth = _.sum(
      estimates.map(
        (estimate) =>
          estimate.estimations?.toEndOfMonth?.highestTotalInclTax ?? 0
      )
    );
    // TODO Maybe change this later cause that's a fix value of 1000€.
    const totalEndOfMonth = hardwareEndOfMonth + 1000;
    return {
      hardwareToDate,
      hardwareEndOfMonth,
      totalEndOfMonth,
    };
  } else {
    return {
      hardwareToDate: undefined,
      hardwareEndOfMonth: undefined,
      totalEndOfMonth: undefined,
    };
  }
}

export function TotalBlock(props: TotalBlockProps) {
  const { t } = useTranslation();

  const estimates = useMemo(
    () => computeEstimates(props.estimatesSnapshot),
    [props.estimatesSnapshot]
  );

  return (
    <Paper variant="outlined" className="px-6 py-3 md:min-w-80">
      <TotalLine
        type={TotalLineType.HardwareToDate}
        value={estimates.hardwareToDate}
      />
      <TotalLine
        type={TotalLineType.HardwareEndOfMonth}
        value={estimates.hardwareEndOfMonth}
      />
      <TotalLine
        type={TotalLineType.TotalEndOfMonth}
        value={estimates.totalEndOfMonth}
        valueTooltip={newLineToBr(
          t('total_month_end_estimation_tooltip') ?? ''
        )}
      />
    </Paper>
  );
}

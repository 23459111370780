import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

export interface CheckboxProps extends MUICheckboxProps {
  label?: React.ReactNode;
  error?: boolean;
  helperText?: string | null;
}

export function Checkbox(props: CheckboxProps) {
  const { label, helperText, error, ...checkboxProps } = props;

  return (
    <FormControl size="small">
      <FormControlLabel
        control={<MUICheckbox {...checkboxProps} />}
        label={props.label}
      />
      <FormHelperText error={error} sx={{ marginLeft: 0, marginRight: 0 }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

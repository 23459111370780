/** Props for {@link PlaceholderChip}. */
interface PlaceholderChipProps {
  style?: React.StyleHTMLAttributes<'div'>;
}

/**
 * A placeholder chip to display during loading time.
 * @param props See {@link PlaceholderChipProps}.
 */
export function PlaceholderChip(props: PlaceholderChipProps) {
  return (
    <div
      className="ChipLoading generic-placeholder w-16 h-8 rounded-full"
      style={props.style}
    ></div>
  );
}

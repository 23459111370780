import { Alert } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionHistoryApi } from '../api/actionHistoryApi';
import { Modal } from '../components/atoms/Modal/Modal';
import { BadLogicException } from '../exceptions/badLogicException';
import { RundeckException } from '../exceptions/rundeckException';
import { ActionHistory } from '../models/actionHistory';
import { ActionStatus } from '../models/actionStatus';
import { Environment } from '../models/environment';
import { useSnackbarStore } from '../stores/snackbarStore';
import { fillOptions } from '../utilities/options';
import { repeatPromiseUntil } from '../utilities/repeatedPromise';
import { useProjectAndEnvironmentRoute } from './useProjectAndEnvironmentRoute';
import { useUnmountAbortController } from './useUnmountAbortController';

export interface UseActionSubmitHandlerProps {
  /**
   * Maps an error to a user-friendly text.
   */
  errorToText: (error: unknown) => string;
  /**
   * A user-friendly name representing the action.
   */
  actionName: string;
  /**
   * The environment name.
   */
  environmentName: Environment['name'] | undefined;
}

export interface SubmitWatcherActionOptions {
  /**
   * Should the submit handler navigate to the workplace once the action is done successfully?
   * @warning This will not navigate to workplace if the page has changed since the submission.
   * @default true
   */
  navigateToWorkplace: boolean;
  /**
   * An action called when the action history becomes successful.
   * This action takes place before the {@link navigateToWorkplace} but is always triggered if the action is a success.
   * @default null
   */
  onSuccessPostAction: (() => Promise<void>) | (() => void) | null;
  /**
   * An action called when the submission post is done successfully.
   *
   * If you want to catch the errors from submission, just catch errors emitted by the {@link SubmitWatcherAction} call.
   */
  onSubmissionSuccess:
    | ((actionHistory: ActionHistory) => Promise<void>)
    | ((actionHistory: ActionHistory) => void)
    | null;
}

/**
 * @throws {ActionSubmitHandlerException}
 */
export type SubmitWatcherAction = (
  submission: () => Promise<ActionHistory>,
  options?: Partial<SubmitWatcherActionOptions>
) => Promise<boolean>;

export interface UseActionSubmitHandlerReturn {
  /**
   * Is the submission in progress?
   */
  loading: boolean;
  /**
   * Submit the action through {@link submission} and watch for the returned {@link ActionHistory} to be ready.
   * @param submission the function that submit the action and returns an {@link ActionHistory} that will be reused by this function wrapper.
   * @returns `true` if operation succeeds, `false` otherwise.
   * @throws any exception thrown by the {@link SubmitWatcherAction.submission} function.
   */
  submitAndWatchResult: SubmitWatcherAction;
  /**
   * A set of widgets to include into the page using this hook to have a feedback on what is going on with the action submission process.
   * This widget set is wrapped into a React fragment.
   */
  feedbackWidget: React.ReactElement;
}

export function useActionSubmitHandler(
  props: UseActionSubmitHandlerProps
): UseActionSubmitHandlerReturn {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorModalText, setErrorModalText] = useState<string | undefined>(
    undefined
  );
  const [submitActionNameMemo, setSubmitActionNameMemo] = useState(
    props.actionName
  );
  const unmountAbortController = useUnmountAbortController();
  const navigate = useNavigate();
  const workplaceRoute = useProjectAndEnvironmentRoute();

  const { t } = useTranslation();
  const snackbarHandlePromise = useSnackbarStore(
    (state) => state.handlePromise
  );

  const submitAction: SubmitWatcherAction = async (submission, options) => {
    setSubmitActionNameMemo(props.actionName);
    setLoading(true);

    const filledOptions = fillOptions(options, {
      onSuccessPostAction: null,
      onSubmissionSuccess: null,
      navigateToWorkplace: true,
    });

    try {
      let actionHistory = await submission();
      if (filledOptions.onSubmissionSuccess) {
        await filledOptions.onSubmissionSuccess(actionHistory);
      }

      const api = new ActionHistoryApi();

      // Function to fetch the action repeatedly while the action is in status RUNNING.
      const getSucceededAction = () =>
        new Promise<ActionHistory>((resolve, reject) => {
          // If already finished do not do not wait for it to be finished.
          if (actionHistory.status !== ActionStatus.Running) {
            if (actionHistory.status === ActionStatus.Succeeded) {
              resolve(actionHistory);
            } else {
              reject(
                new BadLogicException(`ActionHistory failed immediately.`)
              );
            }
          }

          // Continuously fetch the action while it's running.
          repeatPromiseUntil(
            () => api.get(actionHistory.id),
            (actionHistory) => actionHistory.status !== ActionStatus.Running,
            10000,
            { initialDelay: true }
          ).then((actionHistory) => {
            if (actionHistory.status !== ActionStatus.Succeeded) {
              reject(new RundeckException('Could not create action.'));
            } else {
              resolve(actionHistory);
            }
          });
        });

      actionHistory = await snackbarHandlePromise(
        actionHistory.id,
        getSucceededAction,
        {
          getErrorMessage: (error) => (
            <div className="flex flex-col">
              <div>
                {props.environmentName &&
                  t('snackbar_operation_env_prefix', {
                    environment: props.environmentName,
                  })}
                {t('snackbar_operation_failure', {
                  actionName: props.actionName,
                })}
              </div>
              <div>{props.errorToText(error)}</div>
            </div>
          ),
          getLoadingMessage: () => (
            <>
              {props.environmentName &&
                t('snackbar_operation_env_prefix', {
                  environment: props.environmentName,
                })}
              {t('snackbar_operation_in_progress', {
                actionName: props.actionName,
              })}
            </>
          ),
          getSuccessMessage: () => (
            <>
              {props.environmentName &&
                t('snackbar_operation_env_prefix', {
                  environment: props.environmentName,
                })}
              {t('snackbar_operation_success', {
                actionName: props.actionName,
              })}
            </>
          ),
        }
      );

      setLoading(false);
      setSuccess(true);

      await filledOptions.onSuccessPostAction?.();

      if (
        filledOptions.navigateToWorkplace &&
        !unmountAbortController.signal.aborted
      ) {
        navigate(workplaceRoute);
      }

      return true;
    } catch (e) {
      setLoading(false);
      console.warn(e);
      setErrorModalText(props.errorToText(e));

      return false;
    }
  };

  const feedbackWidget = (
    <>
      <Modal
        open={success}
        onClose={() => setSuccess(false)}
        doNotUseCardWrapper
      >
        <Alert severity="success">
          {t('modal_operation_success', { actionName: submitActionNameMemo })}
        </Alert>
      </Modal>
      <Modal
        open={errorModalText !== undefined}
        onClose={() => setErrorModalText(undefined)}
        doNotUseCardWrapper
      >
        <Alert severity="error">{errorModalText && errorModalText}</Alert>
      </Modal>
    </>
  );

  return {
    loading,
    submitAndWatchResult: submitAction,
    feedbackWidget,
  };
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PartialEnvironment } from '../../../../models/environment';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import {
  MultipleSelect,
  MultiSelectActions,
} from '../../../atoms/MultipleSelect/MultipleSelect';

export interface EnvironmentSelectProps {
  environmentsSnapshot: PromiseSnapshot<PartialEnvironment[]>;
  selectedEnvironmentIds: PartialEnvironment['id'][];
  onSelectedEnvironmentIdsChanged: (ids: PartialEnvironment['id'][]) => void;
}

export const environmentQueryParam = 'environments[]';

export function EnvironmentSelect(props: EnvironmentSelectProps) {
  const { t } = useTranslation();

  const environmentsSnapshot = props.environmentsSnapshot;

  const availableEnvironments = environmentsSnapshot.data ?? [];
  const availableEnvironmentsIds = availableEnvironments.map((env) => env.id);

  const selectedEnvironmentIds = useMemo(
    () =>
      props.selectedEnvironmentIds.filter((el) =>
        availableEnvironmentsIds.includes(el)
      ),
    [availableEnvironmentsIds, props.selectedEnvironmentIds]
  );

  return (
    <MultipleSelect
      label={t('environment')}
      name="environment-filter"
      options={(environmentsSnapshot.data ?? []).map((env) => ({
        label: env.name,
        value: env.id,
      }))}
      disabled={environmentsSnapshot.data === undefined}
      values={selectedEnvironmentIds}
      loading={environmentsSnapshot.data === undefined}
      actions={[MultiSelectActions.Clear, MultiSelectActions.Close]}
      onChange={(values) => {
        props.onSelectedEnvironmentIdsChanged(values);
      }}
    />
  );
}

import { useEffect, useRef, useState } from 'react';
import { EnvironmentApi } from '../../../../api/environmentApi';
import { Environment } from '../../../../models/environment';
import { VirtualMachineHandler } from '../../../../models/virtualMachine';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';

/**
 *
 * @param environmentId The {@link Environment} id.
 * @returns `true` if the vms are considered online, `false` otherwise.
 */
export function useEnvironmentVmsState(
  environmentId: Environment['id'] | undefined
): PromiseSnapshot<boolean> {
  const [vmsOnline, setVmsOnline] = useState(new PromiseSnapshot<boolean>());
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    if (environmentId === undefined) return;

    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new EnvironmentApi()
          .getVirtualMachines(environmentId, {
            abortController,
          })
          .then((vms) => VirtualMachineHandler.consideredOnline(vms)),
      setVmsOnline,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [environmentId]);

  return vmsOnline;
}

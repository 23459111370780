import { ClassUtilities } from '../../../utilities/classUtility';
import './PlaceholderText.css';

export interface LoadingTextProps {
  animate?: boolean;
  className?: string;
}

export function PlaceholderText(props: LoadingTextProps) {
  return (
    <span
      className={ClassUtilities.flatten(
        'PlaceholderText generic-placeholder block',
        props.className
      )}
    ></span>
  );
}

import { Bill } from '../models/bill';
import { Payment } from '../models/payment';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';
import { BillApi } from './billApi';

const { REACT_APP_ROUTE_PAYMENTS } = process.env;

export interface GetOptions {
  page?: number;
  'bill[]'?: Bill['id'][];
}

class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      'bill[]': ParamMappingHelper.identity,
    };
  }
}

export type PostArgs = Pick<Payment, 'amount' | 'paidAt' | 'bill'>;

export class PaymentApi extends BaseApi<
  Payment,
  GetOptions,
  Payment,
  PostArgs,
  null
> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_PAYMENTS, 'Payment route not defined'),
      GetOptionsHandler,
      {
        allowDelete: true,
      }
    );
  }

  transformGottenPartialEntity(entity: Payment): Payment {
    return this.transformGottenEntity(entity);
  }

  transformGottenEntity(entity: Payment): Payment {
    entity.bill = BaseApi.getIdFromIri(entity.bill);
    return entity;
  }

  transformPostArgs(postArgs: PostArgs): FormData | Record<string, unknown> {
    return {
      ...postArgs,
      bill: new BillApi().getIriFromId(postArgs.bill),
      paidAt: ParamMappingHelper.mapDay(postArgs.paidAt),
    };
  }
}

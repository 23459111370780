import { styled, TableCell } from '@mui/material';
import { Button } from '../../atoms/Button/Button';

export const LinkButton = styled(Button)({
  textDecoration: 'underline',
  paddingTop: 0,
  paddingBottom: 0,
  minWidth: 0,
});

export const BoldTableCell = styled(TableCell)({
  fontWeight: 500,
});

import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';

type BreadcrumbElement = {
  link: string;
  title: string;
  subtitle: string;
};

type Props = {
  homeLink: string;
  elements: BreadcrumbElement[];
};

export function Breadcrumb({ homeLink, elements }: Props) {
  return (
    <div className="mx-4 mt-4">
      <div className="flex items-center gap-4">
        <div className="grid py-1 cursor-pointer justify-items-center">
          <Link to={homeLink}>
            <IconButton size="small">
              <i className="icon-home" />
            </IconButton>
          </Link>
        </div>
        {elements.map((element, index) => (
          <div key={element.link} className="flex gap-4 items-center">
            {index !== elements.length - 1 ? (
              <>
                <div className="grid justify-items-center">
                  <i className="icon-chevron-right" />
                </div>
                <Link to={element.link}>
                  <Button className="block" variant="text" sx={{ padding: 0 }}>
                    <div className="flex flex-row-reverse gap-2 items-center px-4 py-1 bg-orange-200 cursor-pointer rounded-md justify-items-center">
                      <div className="font-semibold">{element.title}</div>
                      <div className="text-sm font-semibold text-gray-500">
                        {element.subtitle}
                      </div>
                    </div>
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <div className="grid justify-items-center">
                  <i className="icon-chevron-right" />
                </div>
                <Button
                  className="block"
                  variant="text"
                  sx={{ padding: 0 }}
                  disabled={true}
                >
                  <div className="flex flex-row-reverse gap-2 items-center px-4 py-1 bg-gray-200 rounded-md justify-items-center">
                    <div className="font-semibold text-gray-500">
                      {element.title}
                    </div>
                    <div className="text-sm font-semibold">
                      {element.subtitle}
                    </div>
                  </div>
                </Button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

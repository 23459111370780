import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';

export interface BackButtonProps {
  className?: string;
  route: string;
}

export function BackButton(props: BackButtonProps) {
  return (
    <Link
      to={props.route}
      className={ClassUtilities.flatten('BackButton', props.className)}
    >
      <ContainedIconButton
        className="back-button h-full w-full"
        color="inherit"
        title="Retour"
      >
        <i className="icon-arrow-left"></i>
      </ContainedIconButton>
    </Link>
  );
}

const ContainedIconButton = styled(IconButton)({
  '&': {
    backgroundColor: Colors.fromTailwind('orange-350'),
    color: 'white',
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('orange'),
  },
});

import dayjs from 'dayjs';

import duration, { Duration } from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { fillOptions } from './options';
import { getKeys } from './recordUtilities';
import { uppercaseFirstLetter } from './stringUtilities';

dayjs.extend(duration);
dayjs.extend(relativeTime);

/**
 * The units of a duration/date.
 */
export enum TimeUnit {
  None = 0,
  Year = 1,
  Month = 2,
  Day = 4,
  Hour = 8,
  Minute = 16,
  Second = 32,
  All = 63,
}

type ConcreteTimeUnit = Exclude<Exclude<TimeUnit, TimeUnit.None>, TimeUnit.All>;

/**
 * The options of {@link DateFormatter.toDurationOld} method.
 */
export interface ToDurationOptions {
  /** Indicates the maximum of parts to keep. `undefined` means all. Default to `undefined`. */
  maximumParts?: number;
  /** If parts of the date are 0 (after the first non-zero part) and this option is `true`, they will be taken into account for the maximum parts count. Default to `false`. */
  countZeroParts: boolean;
  /** Indicates which parts will be used to format the duration. Default to `Parts.All`. */
  parts: TimeUnit;
  /** Indicates whether or not to use abbreviation for the date formatting. Default to `true`. */
  useAbbrev: boolean;
  partsOptions: Partial<
    Record<TimeUnit, Partial<ToDurationOptionsPartsDetails>>
  >;
}

interface ToDurationOptionsPartsDetails {
  /**
   * Uses abbreviation for this duration part (default: `null` => no override)
   */
  useAbbrev: boolean | null;
}

/** The default values for {@link ToDurationOptions}. */
const toDurationOptionsDefault: ToDurationOptions = {
  maximumParts: undefined,
  countZeroParts: false,
  parts: TimeUnit.All,
  useAbbrev: true,
  partsOptions: {},
};

const toDurationOptionsPartsDetailsDefault: ToDurationOptionsPartsDetails = {
  useAbbrev: null,
};

/**
 * Utility functions to format a date.
 */
export class DateFormatter {
  /**
   * From a date, returns a formatted string.
   * @example
   * const date = new Date('10/24/2003 11:02:03');
   * console.log(date); // 24/10/03 11:02
   * @param date The date.
   * @returns A formatted date with time.
   */
  static toDateTime(date: Date): string {
    if (dayjs.locale() === 'en-us') {
      return dayjs(date).format('MM/DD/YY HH:mm');
    } else {
      return dayjs(date).format('DD/MM/YY HH:mm');
    }
  }

  /**
   * From a date, returns a formatted string.
   * @example
   * const date = new Date('10/24/2003 11:02:03');
   * console.log(date); // 24/10/03
   * @param date The date.
   * @returns A formatted date.
   */
  static toDate(date: Date): string {
    if (dayjs.locale() === 'en-us') {
      return dayjs(date).format('MM/DD/YY');
    } else {
      return dayjs(date).format('DD/MM/YY');
    }
  }

  /**
   * From a date, returns a formatted string.
   * @example
   * const date = new Date('10/24/2003 11:02:03');
   * console.log(date); // 24 octobre 2003
   * @param date The date.
   * @returns A formatted date.
   */
  static toLongDate(date: Date): string {
    return dayjs(date).format('LL');
  }

  /**
   * From a date, returns a formatted string.
   * @example
   * const date = new Date('2021/10/01');
   * console.log(date); // Octobre 2021
   * @param date The date.
   * @returns A formatted date.
   */
  static toMonth(date: Date): string {
    const month = uppercaseFirstLetter(dayjs(date).format('MMMM'));
    const year = dayjs(date).format('YYYY');
    return `${month} ${year}`;
  }

  private static _durationFormatDesc: Record<
    Exclude<Exclude<TimeUnit, TimeUnit.None>, TimeUnit.All>,
    {
      formatString: string;
      translationTerm: string;
      getUnitPart: (duration: Duration) => number;
      getAsUnit: (duration: Duration) => number;
    }
  > = {
    [TimeUnit.Year]: {
      formatString: 'Y',
      translationTerm: 'year',
      getUnitPart: (duration) => duration.years() ?? 0,
      getAsUnit: (duration) => Math.floor(duration.asYears()),
    },
    [TimeUnit.Month]: {
      formatString: 'M',
      translationTerm: 'month',
      getUnitPart: (duration) => duration.months() ?? 0,
      getAsUnit: (duration) => Math.floor(duration.asMonths()),
    },
    [TimeUnit.Day]: {
      formatString: 'D',
      translationTerm: 'day',
      getUnitPart: (duration) => duration.days() ?? 0,
      getAsUnit: (duration) => Math.floor(duration.asDays()),
    },
    [TimeUnit.Hour]: {
      formatString: 'H',
      translationTerm: 'hour',
      getUnitPart: (duration) => duration.hours() ?? 0,
      getAsUnit: (duration) => Math.floor(duration.asHours()),
    },
    [TimeUnit.Minute]: {
      formatString: 'mm',
      translationTerm: 'minute',
      getUnitPart: (duration) => duration.minutes() ?? 0,
      getAsUnit: (duration) => Math.floor(duration.asMinutes()),
    },
    [TimeUnit.Second]: {
      formatString: 's',
      translationTerm: 'second',
      getUnitPart: (duration) => duration.seconds() ?? 0,
      getAsUnit: (duration) => Math.floor(duration.asSeconds()),
    },
  };

  /**
   * Formats a duration.
   * @warning The part with a value of 0 will be omitted from the result.
   * @param durationNormalized The duration.
   * @param t The translation function.
   * @param options The options to change formatting (see {@link ToDurationOptions}).
   * @returns The formatted duration.
   */
  static toDuration(
    duration: Duration,
    t: TFunction<'translation', unknown>,
    options?: Partial<ToDurationOptions>
  ): string {
    const filledOptions = fillOptions(options, toDurationOptionsDefault);

    const durationNormalized = dayjs.duration(duration.asMilliseconds());

    const maxAllowedParts = filledOptions.maximumParts ?? Infinity;

    const formattedParts: string[] = [];
    let partsCount = 0;

    for (const unitKey of getKeys(DateFormatter._durationFormatDesc)) {
      const unit: ConcreteTimeUnit = Number(unitKey);

      if ((unit & filledOptions.parts) !== unit) continue;
      // If we reached maximum number of parts allowed by the user
      if (partsCount >= maxAllowedParts) break;

      const unitDesc = DateFormatter._durationFormatDesc[unit];

      const firstPart = formattedParts.length === 0;

      // Get full duration floored as unit if this is the first part (in case we skipped previous part for example), otherwise just keep part value.
      const unitValue = firstPart
        ? unitDesc.getAsUnit(durationNormalized)
        : unitDesc.getUnitPart(durationNormalized);

      if (unitValue === 0) {
        if (filledOptions.countZeroParts) {
          ++partsCount;
        }
        continue;
      }

      const partOption = fillOptions(
        filledOptions.partsOptions[unit],
        toDurationOptionsPartsDetailsDefault
      );

      const useAbbrev = partOption.useAbbrev ?? filledOptions.useAbbrev;

      let formattedPart = firstPart
        ? unitValue.toString()
        : unitDesc.formatString;
      if (useAbbrev) {
        formattedPart += `[${t(`${unitDesc.translationTerm}_abbrev`, {
          count: unitValue,
        })}]`;
      } else {
        formattedPart += `\u00a0[${t(unitDesc.translationTerm, {
          count: unitValue,
        })}]`;
      }

      formattedParts.push(formattedPart);

      ++partsCount;
    }

    // Handle zero duration
    if (formattedParts.length === 0 && partsCount < maxAllowedParts) {
      const lastUnit = _.last(
        getKeys(DateFormatter._durationFormatDesc).filter(
          (unit) => (Number(unit) & filledOptions.parts) === Number(unit)
        )
      );

      if (lastUnit === undefined) return '';

      const lastUnitDesc = DateFormatter._durationFormatDesc[lastUnit];

      const useAbbrev =
        filledOptions.partsOptions[lastUnit]?.useAbbrev ??
        filledOptions.useAbbrev;

      let formattedPart = lastUnitDesc.formatString;
      if (useAbbrev) {
        formattedPart += `[${t(`${lastUnitDesc.translationTerm}_abbrev`, {
          count: lastUnitDesc.getAsUnit(durationNormalized),
        })}]`;
      } else {
        formattedPart += `\u00a0[${t(lastUnitDesc.translationTerm, {
          count: lastUnitDesc.getAsUnit(durationNormalized),
        })}]`;
      }

      formattedParts.push(formattedPart);
    }

    if (formattedParts.length === 0) return '';
    return durationNormalized.format(formattedParts.join('[ ]'));
  }

  /**
   * Formats a duration between two dates.
   * @warning The part with a value of 0 will be omitted from the result.
   * @param startDate The starting date of the time range.
   * @param endDate The ending date of the time range.
   * @param t The translation function.
   * @param options The options to change formatting (see {@link ToDurationOptions}).
   * @returns The formatted duration between the two dates.
   */
  static toDurationFromDates(
    startDate: Date,
    endDate: Date,
    t: TFunction<'translation', unknown>,
    options?: Partial<ToDurationOptions>
  ) {
    const duration = dayjs.duration(dayjs(endDate).diff(startDate));

    return DateFormatter.toDuration(duration, t, options);
  }
}

import {
  createTheme,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  styled,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { Key, useState } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';

export enum MultiSelectActions {
  Clear = 'clear',
  Close = 'close',
}

export interface Option<T> {
  label: React.ReactNode;
  value: T;
}

export interface SingleSelectProps<T>
  extends Pick<SelectProps<T>, 'name' | 'value' | 'style' | 'error'> {
  className?: string;
  options: Option<T>[];
  label: string;
  onChange?: (value: T) => void;
  disabled?: boolean;
  loading?: boolean;
  helperText?: React.ReactNode;
}

export function SingleSelect<T extends Key>(props: SingleSelectProps<T>) {
  const [open, setOpen] = useState(false);

  return (
    <FormControl
      className={ClassUtilities.flatten(
        'MultipleSelect min-w-80',
        props.className
      )}
      disabled={props.disabled}
    >
      <ThemeProvider theme={overrideInputLabelTheme}>
        <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
      </ThemeProvider>
      <StyledSelect
        IconComponent={
          props.loading
            ? () => (
                <div className="w-6 h-6 mr-2 shrink-0 flex items-center justify-center">
                  <i className="icon-loader text-grey-500 animate-spin-slow" />
                </div>
              )
            : undefined
        }
        labelId={`${props.name}-label`}
        onChange={(ev) => {
          props.onChange?.(ev.target.value as T);
        }}
        open={open}
        onClick={() => {
          if (open === false) {
            if (props.disabled !== true) {
              setOpen(true);
            }
          } else {
            setOpen(false);
            setTimeout(() => {
              (document.activeElement as HTMLDivElement).blur();
            }, 0);
          }
        }}
        size="small"
        value={props.value}
        name={props.name}
        disabled={props.disabled}
        input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
        style={props.style}
        error={props.error}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {props.error && props.helperText && (
        <FormHelperText
          error={props.error}
          sx={{ marginLeft: 0, marginRight: 0 }}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const StyledSelect = (function () {
  const res = styled(Select)({
    minHeight: '40px',
    '& > .MuiSelect-select': {
      padding: '8px 16px 8px 14px',
    },
  }) as unknown as <T>(props: SelectProps<T>) => JSX.Element;
  return res;
})();

const overrideInputLabelTheme = function (baseTheme: Theme) {
  return createTheme({
    ...baseTheme,
    components: {
      ...baseTheme.components,
      MuiInputLabel: {
        ...baseTheme.components?.MuiInputLabel,
        styleOverrides: {
          root: {
            transform: 'translate(10px, 9px) scale(1)',
          },
          shrink: {
            transform: 'translate(14px, -8px) scale(0.75)',
          },
        },
      },
    },
  });
};

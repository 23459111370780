import { useEffect, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import { ProjectApi } from '../../../../../api/projectApi';
import { GitReference } from '../../../../../models/gitReference';
import { useProjectsStore } from '../../../../../stores/projectsStore';
import { devStringify } from '../../../../../utilities/devMessages';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../../utilities/promiseSnapshot';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { FormSingleSelect } from '../../../../atoms/SingleSelect/FormSingleSelect';

export interface TagSchema {
  gitReference: {
    tag: string;
  };
}

export interface TagSelectProps<T extends TagSchema> {
  control: Control<T, unknown>;
}

export function TagSelect<T extends TagSchema>(props: TagSelectProps<T>) {
  const control = props.control as unknown as Control<TagSchema, unknown>;

  const [tags, setTags] = useState<PromiseSnapshot<GitReference[]>>(
    new PromiseSnapshot()
  );
  const abortController = useRef(new AbortController());

  const projectId = useProjectsStore((state) => state.selectedProjectId);

  useEffect(() => {
    if (projectId === undefined) return;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ProjectApi().getTags(projectId, {
          abortController: abortController.current,
        }),
      setTags
    );

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [projectId]);

  return (
    <div className="flex flex-col items-stretch">
      <FormSingleSelect
        control={control}
        options={
          tags.data
            ? tags.data.map((tag) => ({
                label: tag.name,
                value: tag.name,
              }))
            : []
        }
        name="gitReference.tag"
        label="Id de la référence (tag)"
        disabled={tags.state !== PromiseState.Succeeded}
        loading={tags.state === PromiseState.Running}
      />
      {tags.state === PromiseState.Failed && (
        <ErrorBlock title="Erreur lors de la récupération des tags">
          {devStringify(tags.error)}
        </ErrorBlock>
      )}
    </div>
  );
}

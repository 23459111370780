import { Box, Grid, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyApi } from '../../../../api/companyApi';
import DefaultCompanyPicture from '../../../../assets/images/default-company-picture.png';
import { useLazyAutocompleteSearch } from '../../../../hooks/useLazyAutocompleteSearch';
import { Company } from '../../../../models/company';
import { Env } from '../../../../otherBusinessLogic/env';
import { getServerUrl } from '../../../../utilities/fetchUtilities';
import { HashSet } from '../../../../utilities/hashSet';
import { Option } from '../../../atoms/Autocomplete/Autocomplete';
import { FormAutocomplete } from '../../../atoms/Autocomplete/FormAutocomplete';
import { Image } from '../../../atoms/Image/Image';
import { Schema } from '../schema';

export interface FormBillingCompanyProps {
  control: Control<Schema>;
  watch: UseFormWatch<Schema>;
}

export function FormBillingCompany(props: FormBillingCompanyProps) {
  const { t } = useTranslation();
  const watchCompany = props.watch('billingCompany');
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');

  const matchingCompanies = useLazyAutocompleteSearch({
    fetchFunction: (input, _itemsPerPage, abortController) =>
      new CompanyApi().getAllPaginated(
        {
          name: input,
        },
        {
          abortController,
        }
      ),
    input: autocompleteInputValue,
    itemsPerPage: Env.defaultItemsPerPage,
    extractAutocompleteValueFunction: (company) => company.name.toLowerCase(),
  });

  const companyOptions = useMemo<readonly Option<Company>[]>(() => {
    const set = HashSet.fromNonHashable((company) => company.id, {
      initialValues: matchingCompanies,
    });
    if (watchCompany && typeof watchCompany === 'object') {
      set.add(watchCompany as Company);
    }
    return Array.from(set.values()).map((company) => ({
      label: `${company.name} / ${company.billingContactFullname}`,
      value: company,
    }));
  }, [matchingCompanies, watchCompany]);

  return (
    <FormAutocomplete
      name="billingCompany"
      control={props.control}
      options={companyOptions}
      filterOptions={(x) => x}
      onInputChange={(evt, value) => {
        setAutocompleteInputValue(value.toLowerCase());
      }}
      valueEqualityOperator={(value1, value2) => {
        return value1.id === value2.id;
      }}
      renderInput={(params) => (
        <TextField {...params} label={t('billing_company')} fullWidth />
      )}
      renderOption={(props, option) => {
        const useDefaultImage =
          option?.value.imgUri === '' || option?.value.imgUri === undefined;
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={() => (
                    <Image
                      src={
                        useDefaultImage
                          ? DefaultCompanyPicture
                          : getServerUrl() + option?.value.imgUri
                      }
                      fallbackSrc={DefaultCompanyPicture}
                      alt="Company"
                      className="rounded-full w-10 h-10"
                    />
                  )}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs className="pl-3">
                <span>{option?.label}</span>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

import {
  Button as MUIButton,
  ButtonClasses as MUIButtonClasses,
  ButtonProps as MUIButtonProps,
  buttonClasses as MUIbuttonClasses,
  styled,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';

export interface ButtonProps
  extends Omit<MUIButtonProps, 'children' | 'disableElevation'> {
  loading?: boolean;
  children?: ReactNode;
  justifyContent?: 'start' | 'center' | 'end';
  /** Is elevation wanted for this button? Defaults to `false`. */
  enableElevation?: boolean;
}

interface ButtonClasses extends MUIButtonClasses {
  content: string;
}

export const buttonClasses: ButtonClasses = {
  ...MUIbuttonClasses,
  content: 'Button_content',
};

/**
 * @see {@link MUIButton}
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const {
      loading,
      children,
      justifyContent: optionalJustifyContent,
      enableElevation,
      ...muiButtonProps
    } = props;
    const justifyContent = optionalJustifyContent ?? 'center';

    return (
      <StyledMUIButton
        disableElevation={!enableElevation}
        {...muiButtonProps}
        ref={ref}
      >
        <div
          className={`${buttonClasses.content} flex items-baseline w-full justify-${justifyContent}`}
        >
          <div
            className={ClassUtilities.flatten(
              'tranform transition-[height,width,padding] flex items-baseline',
              ClassUtilities.conditional({
                'w-0 scale-0 opacity-0 pr-0': !loading,
                'w-[1.5em] pr-2': Boolean(loading),
              })
            )}
          >
            <i className="icon-loader animate-spin-slow grow" />
          </div>
          <span className="pt-1 max-w-full">{props.children}</span>
        </div>
      </StyledMUIButton>
    );
  }
);
Button.displayName = 'Button';

const StyledMUIButton = styled(MUIButton)<ButtonProps>(() => ({
  '&': {
    textTransform: 'none',
  },
}));

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { ProjectApi } from '../../../api/projectApi';
import { isProject } from '../../../businessUtilities/models/projects';
import { usePromiseSnapshot } from '../../../hooks/usePromiseSnapshot';
import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { Project } from '../../../models/project';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../atoms/Title/Title';
import { SSHPublicKeyBlock } from '../../molecules/SSHPublicKeyBlock/SSHPublicKeyBlock';
import {
  FirstColumnType,
  ProjectUserList,
} from '../../organisms/ProjectUserList/ProjectUserList';
import { EnvironmentList } from './subcomponents/environmentList';

/**
 * Page for project settings (can be used by the manager of a project)
 */
export function ProjectSettings() {
  const { t, tFormatted } = useTranslationFormatter();
  const [projectFromStore, updateProject] = useProjectsStore((state) => [
    unwrap(
      state.getProject(),
      'project should be defined in ProjectSettings as RequireProject must wrap its routing.'
    ),
    state.localUpdateProjects,
  ]);
  useTitle(t('projectSettings', { name: projectFromStore.name }));
  const navigate = useNavigate();
  const [projectSnapshot, setProjectSnapshot] = useState<
    PromiseSnapshot<Project>
  >(new PromiseSnapshot());
  const abortControllerRef = useRef(new AbortController());

  // Fetch project
  useEffect(() => {
    if (isProject(projectFromStore)) {
      setProjectSnapshot(PromiseSnapshot.buildSucceeded(projectFromStore));
    } else {
      const abortController = abortControllerRef.current;
      PromiseSnapshot.trackPromiseSetter(
        () => new ProjectApi().get(projectFromStore.id, { abortController }),
        setProjectSnapshot,
        { abortController }
      );

      return () => {
        abortController.abort();
        abortControllerRef.current = new AbortController();
      };
    }
  }, [projectFromStore]);

  //#region Fetch environments
  const fetchEnvironments = useCallback(
    (abortController: AbortController) =>
      new EnvironmentApi().getAll(
        { 'project[]': [projectFromStore.id], 'deleted_at[after]': null },
        { abortController }
      ),
    [projectFromStore.id]
  );
  const environmentsSnapshot = usePromiseSnapshot(fetchEnvironments);
  //#endregion

  return (
    <div className="ProjectSettings p-8 max-h-full overflow-y-auto">
      <Title level={1}>
        <span className="flex gap-[0.5em] items-center">
          <i className="icon-settings" />
          <span>
            {tFormatted(
              'project_settings',
              { name: projectFromStore.name },
              {
                name: {
                  classes: 'text-grey-600',
                },
              }
            )}
          </span>
        </span>
      </Title>
      <div className="h-6"></div>
      {projectSnapshot.map({
        failed: (error) => <ErrorBlock>{`${error}`}</ErrorBlock>,
        notStarted: 'running',
        running: () => <LoadingBlock />,
        succeeded: (project) => (
          <div className="flex flex-col gap-4">
            <ProjectUserList
              project={project}
              onProjectUpdate={updateProject}
              firstColumnType={FirstColumnType.Email}
              addUserWithEmail
              onUserSelfDelete={() => navigate('/project-list')}
            />
            <SSHPublicKeyBlock />
            <EnvironmentList snapshot={environmentsSnapshot} />
          </div>
        ),
      })}
    </div>
  );
}

import {
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButtonGroupProps as MUIToggleButtonProps,
  ToggleButton,
} from '@mui/material';
import { Key, ReactNode } from 'react';

export interface Option<T extends Key> {
  label: ReactNode;
  value: T;
}

export interface ExclusiveToggleButtonGroupProps<T extends Key>
  extends Omit<
    MUIToggleButtonProps,
    'size' | 'onChange' | 'children' | 'exclusive'
  > {
  options?: Option<T>[];
  /**
   * Whether to expand the width of all button to take full width.
   * The rendering is different from the `fullWidth` property.
   * @default false
   */
  expandWidth?: boolean;
  onChange: (value: T) => void;
}

export function ExclusiveToggleButtonGroup<T extends Key>(
  props: ExclusiveToggleButtonGroupProps<T>
) {
  const {
    options,
    expandWidth: optExpandWidth,
    onChange,
    ...toggleButtonGroupProps
  } = props;
  const expandWidth = optExpandWidth ?? false;

  return (
    <MUIToggleButtonGroup
      size="small"
      {...toggleButtonGroupProps}
      onChange={(_ev, value) => onChange(value)}
      exclusive
    >
      {(options ?? []).map((option) => {
        return (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{ flex: expandWidth ? '1 auto' : undefined }}
          >
            {option.label}
          </ToggleButton>
        );
      })}
    </MUIToggleButtonGroup>
  );
}

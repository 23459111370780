import { ClassUtilities } from '../../../utilities/classUtility';
import { SquaredIconProps } from '../../atoms/SquaredIcon/SquaredIcon';
import { IconParagraph } from '../IconParagraph/IconParagraph';

export interface IconShortInfoProps {
  className?: string;
  icon: SquaredIconProps;
  label: React.ReactNode;
  valueDescription?: React.ReactNode;
  details?: React.ReactNode;
}

export function IconShortInfo(props: IconShortInfoProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'flex flex-col justify-center py-2',
        props.className
      )}
    >
      <IconParagraph squaredIcon={props.icon}>
        <p className="text-smd text-grey-700">{props.label}</p>
        {props.valueDescription && (
          <p className="text-lg font-bold">{props.valueDescription}</p>
        )}
      </IconParagraph>
      <p className="text-sm font-light px-2 text-grey-500">{props.details}</p>
    </div>
  );
}

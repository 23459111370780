import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompanyApi } from '../../../api/companyApi';
import { ZipCodeInfoApi } from '../../../api/zipCodeInfoApi';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { useTitle } from '../../../hooks/useTitle';
import { useSnackbarStore } from '../../../stores/snackbarStore';
import { getServerUrl } from '../../../utilities/fetchUtilities';
import { Option } from '../../atoms/Autocomplete/Autocomplete';
import { FormAutocomplete } from '../../atoms/Autocomplete/FormAutocomplete';
import { Block } from '../../atoms/Block/Block';
import { Breadcrumb } from '../../atoms/Breadcrumb/Breadcrumb';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { FormImagePicker } from '../../atoms/FormImagePicker/FormImagePicker';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { FormTextField } from '../../atoms/TextField/FormTextField';
import { TextField } from '../../atoms/TextField/TextField';
import { Title } from '../../atoms/Title/Title';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { knownCompanyLegalStatus } from './companyLegalStatus';
import { useCompany } from './hooks/useCompany';
import {
  Schema,
  getSchema,
  schemaToPatchArgs,
  schemaToPostArgs,
} from './schema';
import { errorToTerm } from './utilities';

export function AdminCompanyEdition() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const companySnapshot = useCompany();
  const [cityOptions, setCityOptions] = useState<Option<string>[]>([]);
  const [loading, setLoading] = useState(false);
  useTitle(
    t(
      companySnapshot === null
        ? 'administration_new_company'
        : 'administration_edit_company'
    )
  );
  const snackbarHandlePromise = useSnackbarStore(
    (state) => state.handlePromise
  );

  const { handleSubmit, control, watch, setValue, getValues } = useForm<Schema>(
    {
      defaultValues: {
        name: '',
        legalStatus: '',
        billingContactFullName: '',
        address: '',
        zipCode: '',
        city: null,
        country: '',
      },
      resolver: zodResolver(getSchema(t)),
    }
  );

  const zipCode = watch('zipCode');

  // Fill values when editing a company
  useEffect(() => {
    if (!companySnapshot?.isSucceeded()) return;

    const company = companySnapshot.getSucceededData();

    setValue('name', company.name);
    setValue('legalStatus', company.legalStatus ?? null);
    setValue('billingContactFullName', company.billingContactFullname ?? null);
    setValue('address', company.address ?? null);
    setValue('zipCode', company.zipCode ?? null);
    setValue('city', company.city ?? null);
    setValue('country', company.country ?? null);
  }, [companySnapshot, setValue, getValues]);

  // Determine city options with zipCode
  useEffect(() => {
    (async () => {
      if (zipCode === undefined || zipCode === null) return;
      const trimmedZipCode = zipCode.trim();

      const isFilledWithSnapshotZipCodeAndCity =
        companySnapshot?.data?.zipCode === getValues().zipCode &&
        companySnapshot?.data?.city === getValues().city;

      if (!ZipCodeInfoApi.isValidZipCodeFr(trimmedZipCode)) {
        if (!isFilledWithSnapshotZipCodeAndCity) {
          setValue('city', null);
        }
        setCityOptions([]);
        return;
      }
      try {
        const cityOptions = (
          await ZipCodeInfoApi.getInfoFr(trimmedZipCode)
        ).map((info) => ({
          label: info.cityName,
          value: info.cityName,
        }));
        if (!isFilledWithSnapshotZipCodeAndCity) {
          setValue('city', null);
          if (cityOptions.length === 1) {
            setValue('city', cityOptions[0].value);
          }
        }
        setCityOptions(cityOptions);
      } catch (error) {
        const exception = error as NetworkRequestException;
        // 400 is for invalid zipcode
        if (exception.status !== 400) {
          console.warn(
            `Error while retrieving zipCode "${trimmedZipCode}"`,
            error
          );
        }
      }
    })();
  }, [companySnapshot, getValues, setValue, zipCode]);

  const onSubmit = async (data: Schema) => {
    const creation = companySnapshot === null;
    try {
      setLoading(true);
      await snackbarHandlePromise(
        'administration_new_company',
        async () => {
          if (creation) {
            // Creation
            return new CompanyApi().post(schemaToPostArgs(data));
          } else {
            // Edition
            const companyId = companySnapshot.getSucceededData().id;
            if (data.img !== undefined) {
              await CompanyApi.changePicture(companyId, data.img);
            }
            return new CompanyApi().patch(companyId, schemaToPatchArgs(data));
          }
        },
        {
          getSuccessMessage: (_res) =>
            t(`company_${creation ? 'post' : 'patch'}_succeeded`, {
              name: data.name,
            }),
          getLoadingMessage: () =>
            t(`company_${creation ? 'post' : 'patch'}_in_progress`, {
              name: data.name,
            }),
          getErrorMessage: (error) =>
            t(`company_${creation ? 'post' : 'patch'}_failed`, {
              name: data.name,
              error: t(errorToTerm(error)),
            }),
        },
        {
          autoHideSuccessDuration: 3000,
        }
      );
      navigate('/admin/companies');
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  const disabledInteraction =
    companySnapshot !== null && !companySnapshot.isSucceeded();

  return (
    <AdminPageTemplate className="NewAdminCompany">
      <div className="flex flex-col gap-4">
        <Breadcrumb
          homeLink={'/admin/companies'}
          elements={[
            {
              title: t('create_a_new_company'),
              subtitle: t('company'),
              link: '',
            },
          ]}
        ></Breadcrumb>
        <Block>
          <Title level={2}>{t('create_a_new_company')}</Title>
          <div className="pt-6 pb-4 flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              {companySnapshot !== null &&
                companySnapshot.map({
                  notStarted: 'running',
                  running: () => <LoadingBlock />,
                  failed: (error) => (
                    <ErrorBlock noShadow>{`${error}`}</ErrorBlock>
                  ),
                  succeeded: () => <></>,
                })}
              <Title level={3}>{t('general')}</Title>
              <div className="flex gap-6 items-center">
                <FormImagePicker
                  control={control}
                  name="img"
                  initialURL={
                    companySnapshot?.data?.imgUri
                      ? getServerUrl() + companySnapshot?.data?.imgUri
                      : undefined
                  }
                  disabled={disabledInteraction}
                />
                <div className="flex flex-col gap-4 grow">
                  <FormTextField
                    control={control}
                    name="name"
                    label={t('name') + ' *'}
                    disabled={disabledInteraction}
                  />
                  <FormAutocomplete
                    control={control}
                    options={knownCompanyLegalStatus.map((e) => ({
                      label: e,
                      value: e,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onBlur={(ev) => {
                          setValue('legalStatus', ev.target.value);
                        }}
                        label={t('legal_status')}
                      />
                    )}
                    noOptionsText={t('no_city_matching_zip_code')}
                    name="legalStatus"
                    freeSoloOptions={{
                      enabled: true,
                      freeSoloMapping: (str) => str,
                    }}
                    selectOnFocus
                    className="grow"
                    disabled={disabledInteraction}
                  />
                </div>
              </div>
              <FormTextField
                control={control}
                name="billingContactFullName"
                label={t('billing_contact')}
                placeholder="Jean Dupont"
                disabled={disabledInteraction}
              />
            </div>
            <div className="flex flex-col gap-4">
              <Title level={3}>{t('address')}</Title>
              <FormTextField
                control={control}
                name="address"
                label={t('address')}
                placeholder="5 rue de Maguelone"
                disabled={disabledInteraction}
              />
              <div className="flex gap-4">
                <FormTextField
                  control={control}
                  name="zipCode"
                  label={t('zip_code')}
                  placeholder="34000"
                  className="max-w-xs"
                  disabled={disabledInteraction}
                />
                <FormAutocomplete
                  control={control}
                  options={cityOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={(ev) => {
                        setValue('city', ev.target.value);
                      }}
                      label={t('city')}
                      placeholder="Montpellier"
                      disabled={disabledInteraction}
                    />
                  )}
                  noOptionsText={t('no_city_matching_zip_code')}
                  name="city"
                  freeSoloOptions={{
                    enabled: true,
                    freeSoloMapping: (str) => str,
                  }}
                  autoComplete
                  clearOnBlur
                  selectOnFocus
                  className="grow"
                  disabled={disabledInteraction}
                />
              </div>
              <FormTextField
                control={control}
                name="country"
                label={t('country')}
                placeholder="France"
                disabled={disabledInteraction}
              />
            </div>
            <RoundedFilledBlueButton
              onClick={handleSubmit(onSubmit)}
              className="self-end"
              loading={loading}
              disabled={disabledInteraction}
            >
              {t(companySnapshot === null ? 'create' : 'edit')}
            </RoundedFilledBlueButton>
          </div>
        </Block>
      </div>
    </AdminPageTemplate>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useTitle } from '../../../hooks/useTitle';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { FormGitReferencePicker } from '../../molecules/FormGitReferencePicker/FormGitReferencePicker';
import { gitReferenceSchemaDefaultValues } from '../../molecules/FormGitReferencePicker/schema';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { Schema, getSchema, schemaToPostArgs } from './schema';
import { errorToTerm } from './utilities';

import { useEnvironmentActionTypesEstimates } from '../../../hooks/useEnvironmentActionTypesEstimates';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { DateFormatter } from '../../../utilities/dateFormatter';
import {
  ActionShortInfoBlocks,
  ActionShortInfoBlocksProps,
} from '../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';
import { EstimateErrorBlock } from '../../molecules/EstimateErrorBlock/EstimateErrorBlock';
import './UpdateSources.css';

export function UpdateSources() {
  const { t, tFormatted } = useTranslationFormatter();

  useTitle(t('done_action_labels.update-sources'));

  const [environmentId, localPatchEnvironment] = useProjectEnvironmentsStore(
    (state) => [
      state.environments.data?.[state.selectedEnvironmentIndex]?.id,
      state.localPatchEnvironment,
    ]
  );
  // Fetch action of this env to know if another source update is in progress
  const anotherSourceUpdateInProgress = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.UpdateSources,
    environmentId,
  });

  const updateSourcesEstimate = useEnvironmentActionTypesEstimates(
    environmentId,
    ActionTypeName.UpdateSources
  );

  const formMethods = useForm<Schema>({
    defaultValues: {
      gitReference: gitReferenceSchemaDefaultValues,
    },
    resolver: zodResolver(getSchema(t)),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const [environmentsSnapshot, selectedEnvironmentIndex] =
    useProjectEnvironmentsStore((state) => [
      state.environments,
      state.selectedEnvironmentIndex,
    ]);

  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('done_action_labels.update-sources'),
      environmentName: selectedEnvironment?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = (data: Schema) => {
    submitAndWatchResult(
      () =>
        new EnvironmentApi().postSourceUpdate(
          unwrap(environmentId),
          schemaToPostArgs(data)
        ),
      {
        onSuccessPostAction: async () =>
          localPatchEnvironment(
            await new EnvironmentApi().get(unwrap(environmentId))
          ),
      }
    );
  };

  const blocksStyleProps: ActionShortInfoBlocksProps['stylesProps'] = [
    {
      icon: {
        color: 'yellow-200',
        icon: 'icon-clock',
      },
      label: t('operation_estimated_duration'),
      className: 'grow',
    },
  ];

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.UpdateSources}>
      <div className="SourceUpdate grid grid-cols-1 lg:grid-cols-aside-md gap-6 justify-items-stretch">
        <Block className="grow shrink flex flex-col gap-8">
          <Title level={2}>{t('do_update_sources')}</Title>
          <div className="flex SourceUpdate__form-block gap-8">
            <div className="flex flex-col gap-6">
              <Title level={4}>
                {tFormatted(
                  'select_git_reference_env',
                  { environment: selectedEnvironment?.name ?? '' },
                  {
                    environment: {
                      classes: 'text-orange',
                    },
                  }
                )}
              </Title>
              <div className="flex flex-col gap-4">
                <FormGitReferencePicker formMethods={formMethods} />

                <div className="h-2 md:h-12"></div>
                <RoundedFilledBlueButton
                  color="info"
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    Object.keys(errors).length > 0 ||
                    loading ||
                    anotherSourceUpdateInProgress
                  }
                >
                  {t('action_labels.update-sources')}
                </RoundedFilledBlueButton>
              </div>
            </div>
            {anotherSourceUpdateInProgress && (
              <div className="-order-1 md:order-2 flex flex-col gap-4">
                <WarningBlock>
                  {t('update_sources_already_occurring')}
                </WarningBlock>
              </div>
            )}
          </div>
        </Block>
        <div className="min-w-48 flex flex-col gap-4 shrink grow">
          {updateSourcesEstimate.map({
            succeeded: (data) => (
              <ActionShortInfoBlocks
                stylesProps={blocksStyleProps}
                valueDescriptions={[
                  DateFormatter.toDuration(data.estimatedDuration, t, {
                    maximumParts: 1,
                  }),
                ]}
              />
            ),
            failed: () => <EstimateErrorBlock />,
            notStarted: 'running',
            running: () => (
              <ActionShortInfoBlocks stylesProps={blocksStyleProps} />
            ),
          })}
        </div>
      </div>

      {feedbackWidget}
    </ActionPageTemplate>
  );
}

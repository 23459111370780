import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PartialEnvironment } from '../../../../models/environment';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import { Block } from '../../../atoms/Block/Block';
import { DataPaper } from '../../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../../atoms/Title/Title';

interface EnvironmentListProps {
  snapshot: PromiseSnapshot<PartialEnvironment[]>;
}

export function EnvironmentList(props: EnvironmentListProps) {
  const { t } = useTranslation();

  return (
    <Block className="EnvironmentList">
      <Title level={2}>{t('environments_list')}</Title>
      <div className="h-4"></div>
      {props.snapshot.map({
        notStarted: 'running',
        running: () => <LoadingBlock />,
        failed: (error) => <ErrorBlock>{`${error}`}</ErrorBlock>,
        succeeded: (environments) => (
          <>
            <TableContainer className="hidden lg:block" component={'div'}>
              <Table sx={{ minWidth: 650 }} aria-label="users table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('environment_identifier')}</TableCell>
                    <TableCell>{t('environment_name')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {environments.map((env) => {
                    return (
                      <TableRow key={env.id}>
                        <TableCell>{env.identifier}</TableCell>
                        <TableCell>{env.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="lg:hidden flex flex-col gap-4 pb-4">
              {environments.map((env) => (
                <DataPaper
                  key={env.id}
                  header={env.id}
                  content={[
                    <DataPaperElement
                      key="identifier"
                      label={t('environment_identifier')}
                      value={env.identifier}
                    />,
                    <DataPaperElement
                      key="name"
                      label={t('environment_name')}
                      value={env.name}
                    />,
                  ]}
                />
              ))}
            </div>
          </>
        ),
      })}
    </Block>
  );
}

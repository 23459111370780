/**
 * @warning The keys will be string values (numbers for example, are not preserved).
 * @returns The extracted keys from {@link o}.
 */
export const getKeys = <T extends Record<keyof T, unknown>>(
  o: T
): Array<keyof T> => Object.keys(o) as Array<keyof T>;

/**
 * @typeParam T The original value type.
 * @typeParam U The mapped value type.
 * @typeParam V The object which values will be converted.
 * @param record The object to map values from.
 * @param mapper The function to use to map a value to another.
 * @returns The mapped object with the same set of keys but with its values mapped to another value (thanks to {@link mapper}).
 * @warning The keys will be string values (numbers for example, are not preserved).
 */
export function mapValues<T, U, V extends Record<keyof V, T>>(
  record: V,
  mapper: (value: T) => U
): Record<keyof V, U> {
  const mapped: Partial<Record<keyof V, U>> = {};

  for (const key of getKeys(record)) {
    mapped[key] = mapper(record[key]);
  }

  return mapped as Record<keyof V, U>;
}

/**
 * Removes all entries from {@link object} where the value of the entry is `undefined`.
 * @warning The modifications are applied to {@link object}.
 * @details If most functions ignored undefined values. Some like {@link Object.keys}
 * behaves differently with keys that has value of `undefined`.
 * This function removes the entries to harmonize the behavior.
 * @returns The same object.
 */
export function stripUndefined<T extends Record<string | number, unknown>>(
  object: T
): T {
  for (const [key, val] of Object.entries(object)) {
    if (val === undefined) {
      delete object[key];
    }
  }
  return object;
}

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectApi } from '../../../api/projectApi';
import { useTitle } from '../../../hooks/useTitle';
import { ProjectPricing } from '../../../models/project';
import { useProjectsStore } from '../../../stores/projectsStore';
import { NumberFormatter } from '../../../utilities/numberFormatter';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { errorToTerm } from './utilities';

export function BillingDetails() {
  const { t } = useTranslation();
  const currentProject = useProjectsStore((state) => state.getProject());
  const abortController = useRef(new AbortController());
  const [snapshot, setSnapshot] = useState<PromiseSnapshot<ProjectPricing[]>>();

  useTitle(t('billing_detail'));

  useEffect(() => {
    if (currentProject?.id !== undefined) {
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new ProjectApi().getPricings(currentProject.id, {
            abortController: abortController.current,
          }),
        setSnapshot
      );
    }
  }, [currentProject]);

  return (
    <div className="BillingDetails py-8 flex flex-col items-stretch">
      <div className="hidden xl:block">
        {snapshot?.map({
          notStarted: 'running',
          running: () => <LoadingBlock />,
          failed: (error) => (
            <div className="col-span-4">
              <ErrorBlock className="col-span-4" noShadow>
                {t('bills_retrieval_error', {
                  reason: t(errorToTerm(error)),
                })}
              </ErrorBlock>
            </div>
          ),
          succeeded: (pricings) => (
            <>
              <TableContainer component="div">
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('element')}</TableCell>
                      <TableCell>{t('provisioning')}</TableCell>
                      <TableCell>{t('description')}</TableCell>
                      <TableCell>{t('billing_estimation')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="whitespace-nowrap">
                    {pricings.map((data, index) => (
                      <TableRow key={index} className="table-row">
                        <TableCell>{t(data.nameTransKey)}</TableCell>
                        <TableCell>{t(data.provisioningTransKey)}</TableCell>
                        <TableCell>{t(data.descriptionTransKey)}</TableCell>
                        <TableCell>
                          {NumberFormatter.toEuro(data.price, {
                            decimalDigits: data.price < 1 ? 2 : 0,
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ),
        })}
      </div>

      <div className="flex xl:hidden flex-col gap-4">
        {snapshot?.map({
          notStarted: 'running',
          running: () => <LoadingBlock />,
          failed: (error) => (
            <div className="col-span-4">
              <ErrorBlock className="col-span-4" noShadow>
                {t('bills_retrieval_error', {
                  reason: t(errorToTerm(error)),
                })}
              </ErrorBlock>
            </div>
          ),
          succeeded: (pricings) => (
            <>
              {pricings.map((data, index) => (
                <DataPaper
                  key={index}
                  header={t(data.nameTransKey)}
                  content={[
                    <DataPaperElement
                      key="description"
                      fullSpan
                      label={t('description')}
                      value={t(data.descriptionTransKey)}
                    />,
                    <DataPaperElement
                      key="provisioning"
                      label={t('provisioning')}
                      value={t(data.provisioningTransKey)}
                    />,
                    <DataPaperElement
                      key="estimation"
                      label={t('billing_estimation_monthly_short')}
                      value={NumberFormatter.toEuro(data.price, {
                        decimalDigits: data.price < 1 ? 2 : 0,
                      })}
                    />,
                  ]}
                />
              ))}
            </>
          ),
        })}
      </div>
    </div>
  );
}

import { ServerSshKey } from '../models/serverSshKey';
import { unwrap } from '../utilities/assertions';
import {
  AbortOptions,
  AbortOptionsDefault,
  getJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';

const { REACT_APP_ROUTE_SERVER_SSH_KEY } = process.env;

export class ServerSshKeyApi {
  /**
   * Gets a specific entity without specific id.
   * @param options The options of the request.
   * @returns The entity.
   */
  getSingle(options?: Partial<AbortOptions>): Promise<ServerSshKey> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    return getJsonFromServerRoute(unwrap(REACT_APP_ROUTE_SERVER_SSH_KEY), {
      abortController: filledOptions.abortController,
    });
  }
}

import { Hashable } from './hashable';
import { fillOptions } from './options';

type ConstructorOptions<K, V> = {
  // The initial values to put in the map. Default to `[]`.
  initialValues: [K, V][];
};

const ConstructorOptionsDefault: ConstructorOptions<unknown, unknown> = {
  initialValues: [],
};

type InternalMap<K, V> = Map<string | number, [K, V]>;

class HashMapValueIterator<K, V> implements IterableIterator<V> {
  private _internalIterator: IterableIterator<[K, V]>;

  constructor(_internalMap: InternalMap<K, V>) {
    this._internalIterator = _internalMap.values();
  }

  [Symbol.iterator](): IterableIterator<V> {
    return this;
  }

  next(): IteratorResult<V, V | undefined> {
    const iteratorResult = this._internalIterator.next();
    if (iteratorResult.done) {
      return {
        done: true,
        value: undefined,
      };
    }

    return {
      done: false,
      value: iteratorResult.value[1],
    };
  }
}

class HashMapKeyIterator<K, V> implements IterableIterator<K> {
  private _internalIterator: IterableIterator<[K, V]>;

  constructor(_internalMap: InternalMap<K, V>) {
    this._internalIterator = _internalMap.values();
  }

  [Symbol.iterator](): IterableIterator<K> {
    return this;
  }

  next(): IteratorResult<K, K | undefined> {
    const iteratorResult = this._internalIterator.next();
    if (iteratorResult.done) {
      return {
        done: true,
        value: undefined,
      };
    }

    return {
      done: false,
      value: iteratorResult.value[0],
    };
  }
}

class HashMapEntryIterator<K, V> implements IterableIterator<[K, V]> {
  private _internalIterator: IterableIterator<[K, V]>;

  constructor(_internalMap: InternalMap<K, V>) {
    this._internalIterator = _internalMap.values();
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this;
  }

  next(): IteratorResult<[K, V], [K, V] | undefined> {
    const iteratorResult = this._internalIterator.next();
    if (iteratorResult.done) {
      return {
        done: true,
        value: undefined,
      };
    }

    return {
      done: false,
      value: iteratorResult.value,
    };
  }
}

/**
 * Map that acts as a proxy for storing element with {@link Hashable} keys.
 * The map allows you to get your key and value as you would expect but compares identity with an hash function instead.
 * @details Tests available in `src/tests/utilities/array.test.ts`.
 */
export class HashMap<K, V> {
  private _internalMap: InternalMap<K, V> = new Map();
  private _hashFunc: (key: K) => string | number;

  //#region Constructors and factories
  /**
   * @param hashFunc The function to transform the key to an hashable element which can be used for equality operations internally.
   * @param options The options. See {@link ConstructorOptions}.
   */
  protected constructor(
    hashFunc: (key: K) => string | number,
    options?: Partial<ConstructorOptions<K, V>>
  ) {
    this._hashFunc = hashFunc;

    const filledOptions = fillOptions(
      options,
      ConstructorOptionsDefault
    ) as ConstructorOptions<K, V>;

    for (const [key, value] of filledOptions.initialValues) {
      this.set(key, value);
    }
  }

  /**
   * Create a map with {@link Hashable} elements as keys.
   * @param options The options. See {@link ConstructorOptions}.
   * @returns a {@link HashMap} instance.
   */
  public static fromHashable<K extends Hashable, V>(
    options?: Partial<ConstructorOptions<K, V>>
  ): HashMap<K, V> {
    return new this((key) => key.toHash(), options);
  }

  /**
   * Create a map with non-{@link Hashable} elements as keys.
   * @param hashFunc The function to convert the key to a unique value that can be used to check equality between keys. Note that this function should be quite fast for performance issues.
   * @param options The options. See {@link ConstructorOptions}.
   * @returns a {@link HashMap} instance.
   */
  public static fromNonHashable<K, V>(
    hashFunc: (key: K) => string | number,
    options?: Partial<ConstructorOptions<K, V>>
  ): HashMap<K, V> {
    return new this(hashFunc, options);
  }
  //#endregion

  /**
   * @returns the number of elements in the Map.
   */
  public get size(): number {
    return this._internalMap.size;
  }

  /**
   * Returns a specified element from the Map object. If the value that is associated to the provided {@link key} is an object, then you will get a reference to that object and any change made to that object will effectively modify it inside the Map.
   * @returns — Returns the element associated with the specified key. If no element is associated with the specified {@link key}, `undefined` is returned.
   */
  public get(key: K): V | undefined {
    return this._internalMap.get(this._hashFunc(key))?.[1];
  }

  /**
   * Adds a new element with a specified {@link key} and {@link value} to the Map. If an element with the same key already exists, the element will be updated.
   * @returns `this` instance.
   */
  public set(key: K, value: V): this {
    this._internalMap.set(this._hashFunc(key), [key, value]);
    return this;
  }

  /**
   * Clears all data in map.
   */
  public clear(): void {
    this._internalMap.clear();
  }

  /**
   * @returns boolean indicating whether an element with the specified {@link key} exists or not.
   */
  public has(key: K): boolean {
    return this._internalMap.has(this._hashFunc(key));
  }

  /**
   * @returns an iterable of values in the map.
   */
  public values(): HashMapValueIterator<K, V> {
    return new HashMapValueIterator(this._internalMap);
  }

  /**
   * @returns an iterable of keys in the map.
   */
  public keys(): HashMapKeyIterator<K, V> {
    return new HashMapKeyIterator(this._internalMap);
  }

  /**
   * @returns an iterable of key, value pairs for every entry in the map.
   */
  public entries(): HashMapEntryIterator<K, V> {
    return new HashMapEntryIterator(this._internalMap);
  }
}

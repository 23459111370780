import { Tooltip } from '@mui/material';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import { StatusChip, StatusChipProps } from '../Chip/StatusChip';

/**
 * Props for {@link ChipGroup}.
 */
export interface ChipGroupProps {
  /**
   * The chips to display. See {@link ChipGroupElementProps}.
   */
  chips: ChipGroupElementProps[];
  /**
   * The extra class to add to this widget.
   */
  className?: string;
}

/**
 * A single element of {@link ChipGroup} properties.
 */
export type ChipGroupElementProps = {
  /**
   * Basic {@link StatusChipProps}.
   */
  props: Omit<StatusChipProps, 'label'>;
  /**
   * A key unique to this list of chip. For rendering reason only.
   */
  key: Key;
  /**
   * The label to display in the chip. If the chip has no place
   * in the widget, this will be used in the condensed chip
   * tooltip.
   */
  label: string;
};

/** Spacing between each chip (in pixels). */
const CHIP_GROUP_SPACING = 6;
/** Size (horizontal and vertical) of the remaining indicator (in pixels). */
const CHIP_GROUP_REMAINING_SIZE = 32;

/**
 * Displays a list of {@link StatusChip}, if the horizontal space
 * is not big enough to display them all, will instead displays
 * a trailing small component to says everything is not shown.
 * @param props See {@link ChipGroupProps}.
 */
export function ChipGroup(props: ChipGroupProps) {
  const root = useRef<HTMLDivElement>(null);
  const [maxChipCount, setMaxChipCount] = useState<number | undefined>(
    undefined
  );

  const displayedChips = useMemo(() => {
    if (maxChipCount === undefined || maxChipCount === props.chips.length)
      return props.chips;
    return props.chips.slice(0, maxChipCount);
  }, [maxChipCount, props.chips]);

  const hiddenChips = useMemo(() => {
    if (maxChipCount === undefined || maxChipCount === props.chips.length)
      return [];
    return props.chips.slice(maxChipCount);
  }, [maxChipCount, props.chips]);

  const [chipsSize, setChipsSize] = useState<undefined | number[]>(undefined);

  // Reset maxChipCount as
  useEffect(() => {
    setMaxChipCount(undefined);
  }, [props.chips]);

  // Retrieve each chip size and store them.
  useEffect(() => {
    if (root.current === null) return;
    if (maxChipCount !== undefined) return;

    const newChipsSize: number[] = [];
    for (const child of root.current.querySelectorAll('.ChipGroup__element')) {
      newChipsSize.push(child.clientWidth);
    }
    setChipsSize(newChipsSize);
    setMaxChipCount(undefined);
  }, [maxChipCount]);

  // Check how many chip fit into the group
  useEffect(() => {
    if (root.current === null) return;
    if (chipsSize === undefined) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const rootElement = entries[0];
      const horizontalSize = rootElement.contentBoxSize[0].inlineSize;
      let remainingHorizontalSize = horizontalSize;
      let fittingElementCount = 0;

      for (const chipSize of chipsSize) {
        if (remainingHorizontalSize - chipSize < 0) {
          if (remainingHorizontalSize - CHIP_GROUP_REMAINING_SIZE < 0) {
            --fittingElementCount;
          }
          break;
        }
        ++fittingElementCount;
        remainingHorizontalSize -= chipSize + CHIP_GROUP_SPACING;
      }

      setMaxChipCount(fittingElementCount);
    });
    resizeObserver.observe(root.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [chipsSize]);

  return (
    <div
      className={ClassUtilities.flatten(
        'ChipGroup flex overflow-x-clip',
        props.className
      )}
      ref={root}
      style={{ gap: CHIP_GROUP_SPACING }}
    >
      {displayedChips.map(({ props, label, key }) => (
        <StatusChip
          key={key}
          className="ChipGroup__element"
          {...props}
          label={label}
        />
      ))}
      {hiddenChips.length > 0 && (
        <Tooltip
          title={
            <div className="flex flex-col items-start">
              {hiddenChips.map(({ label, key }) => (
                <span key={key}>{label}</span>
              ))}
            </div>
          }
          placement="top"
        >
          <div
            className="ChipGroup__remaining rounded-full bg-grey-500 text-white text-smd text-raleway font-medium flex items-center justify-center"
            style={{
              minWidth: CHIP_GROUP_REMAINING_SIZE,
              width: CHIP_GROUP_REMAINING_SIZE,
              minHeight: CHIP_GROUP_REMAINING_SIZE,
              height: CHIP_GROUP_REMAINING_SIZE,
            }}
          >
            {`+${hiddenChips.length}`}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import { PatchArgs } from '../../../models/project';
import { ZodImageEdit } from '../../atoms/ImagePicker/ImagePicker';

export function getPositiveIntegerFromStringSchema(t: WithTranslation['t']) {
  return z
    .string()
    .or(z.number())
    .transform((arg, ctx) => {
      const value = typeof arg === 'number' ? arg : parseInt(arg);
      if (isNaN(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('not_a_number_issue'),
        });
        return z.NEVER;
      }

      if (value < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('field_value_must_be_positive'),
        });
        return z.NEVER;
      }

      return value;
    });
}

export function getSchema(t: WithTranslation['t']) {
  const positiveIntegerFromStringSchema = getPositiveIntegerFromStringSchema(t);

  return z.object({
    // Base
    img: ZodImageEdit,
    gitRepositoryURL: z.string().max(
      255,
      t('field_max_length_issue', {
        count: 255,
      })
    ),
    name: z
      .string()
      .min(1, 'required_field')
      .max(
        255,
        t('field_max_length_issue', {
          count: 255,
        })
      ),
    contact: z
      .object({
        id: z.string().uuid(),
      })
      .nullable(), // The user to refer to for this project.

    // Billing
    billingCompany: z
      .object({
        id: z.string().uuid(),
      })
      .nullable(),

    // Technical details
    monoVMDataUpdateCountPerWeek: positiveIntegerFromStringSchema.nullable(),
    monoVMEnvironmentLimit: positiveIntegerFromStringSchema.nullable(),
    multiVMEnvironmentLimit: positiveIntegerFromStringSchema.nullable(),
    margin: positiveIntegerFromStringSchema.nullable(),
    canAnImageBeUsedInAnyEnvironment: z.boolean().nullable(),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

export function schemaToPatchArgs(data: Schema): PatchArgs {
  const {
    img,
    billingCompany,
    contact,
    monoVMDataUpdateCountPerWeek,
    monoVMEnvironmentLimit,
    multiVMEnvironmentLimit,
    margin,
    canAnImageBeUsedInAnyEnvironment,
    ...identicalData
  } = data;

  return {
    ...identicalData,
    billingCompany: billingCompany?.id ?? null,
    monoVMDataUpdateCountPerWeek: monoVMDataUpdateCountPerWeek ?? undefined,
    monoVMEnvironmentLimit: monoVMEnvironmentLimit ?? undefined,
    multiVMEnvironmentLimit: multiVMEnvironmentLimit ?? undefined,
    margin: margin ?? undefined,
    canAnImageBeUsedInAnyEnvironment:
      canAnImageBeUsedInAnyEnvironment ?? undefined,
  };
}

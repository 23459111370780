import {
  USER_GROUP_ELEMENT_OVERLAP,
  USER_GROUP_ELEMENT_SIZE,
} from './subcomponents/UserGroupElement';

/**
 * @param inlineSize The horizontal size of the container holding the element.
 * @returns The maximum element that can fit into the given {@link inlineSize}
 */
export function computeMaxElementCount(inlineSize: number): number {
  return Math.floor(
    (inlineSize - USER_GROUP_ELEMENT_OVERLAP) /
      (USER_GROUP_ELEMENT_SIZE - USER_GROUP_ELEMENT_OVERLAP)
  );
}

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../atoms/Button/Button';
import { ErrorPageTemplate } from '../../templates/ErrorPageTemplate/ErrorPageTemplate';

export function PageNotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPageTemplate
      title="404"
      details={t('page_does_not_exist')}
      actions={
        <>
          <Button
            variant="contained"
            startIcon={<i className="icon-arrow-left" />}
            onClick={() => navigate(-1)}
          >
            {t('go_back')}
          </Button>
          <Button
            variant="contained"
            startIcon={<i className="icon-home" />}
            onClick={() => navigate('/')}
          >
            {t('go_home')}
          </Button>
        </>
      }
    />
  );
}

import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillApi } from '../../../../../api/billApi';
import { Bill, BillStatus } from '../../../../../models/bill';
import { contain } from '../../../../../utilities/array';
import { unwrap } from '../../../../../utilities/assertions';
import { DateFormatter } from '../../../../../utilities/dateFormatter';
import { getServerUrl } from '../../../../../utilities/fetchUtilities';
import {
  NumberFormatter,
  RoundMethods,
} from '../../../../../utilities/numberFormatter';
import { Block } from '../../../../atoms/Block/Block';
import {
  RoundedFilledBlueButton,
  RoundedFilledGrayButton,
} from '../../../../atoms/Button/variations/RoundedButtonVariations';
import { Checkbox } from '../../../../atoms/Checkbox/Checkbox';
import { StatusChip } from '../../../../atoms/Chip/StatusChip';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../../../atoms/Title/Title';
import { BillingLinesModal } from '../../../../molecules/BillingLinesModal';
import { BillPatchEventNotifier } from '../../utilities/billPatchEventNotifier';
import { DateCell, GrowTableCell, ShrinkTableCell } from '../TableCells';
import { useUnsentBills } from './hooks/useUnsentBills';

enum BillSendStatus {
  Initial,
  Sending,
  SendingFailed,
  Sent,
}

interface BillState {
  bill: Bill;
  sendStatus: BillSendStatus;
  selected: boolean;
}

interface BillToEmitBlockProps {
  billPatchEventNotifier: BillPatchEventNotifier;
}

export function BillToEmitBlock(props: BillToEmitBlockProps) {
  const { t } = useTranslation();
  const [filteredBills, setFilteredBills] = useState<
    [Date, Bill[]][] | undefined
  >(undefined);
  const { billsSnapshot } = useUnsentBills();
  const [openedBillForDetails, setOpenedBillForDetails] = useState<Bill | null>(
    null
  );
  const [billStates, setBillStates] = useState<Map<Bill['id'], BillState>>(
    new Map()
  );

  // Create billStates once snapshot has been retrieved.
  useEffect(() => {
    if (!billsSnapshot.isSucceeded()) return;

    const bills = billsSnapshot.getSucceededData().flatMap((group) => group[1]);

    const newBillStates = new Map<Bill['id'], BillState>();
    for (const bill of bills) {
      newBillStates.set(bill.id, {
        bill,
        sendStatus: BillSendStatus.Initial,
        selected: false,
      });
    }

    setBillStates(newBillStates);
    setFilteredBills(billsSnapshot.getSucceededData());
  }, [billsSnapshot]);
  //#endregion

  //#region Bill states manipulation function
  const setSendStatus = (
    billId: Bill['id'],
    sendStatus: BillState['sendStatus']
  ) => {
    const billState = unwrap(billStates.get(billId));
    const oldSendStatus = billState.sendStatus;
    if (sendStatus !== oldSendStatus) {
      billState.sendStatus = sendStatus;
      setBillStates(new Map(billStates));
      if (sendStatus === BillSendStatus.Sent) {
        props.billPatchEventNotifier.notifyPatch({
          id: billState.bill.id,
          status: BillStatus.Sent,
        });
      }
    }
  };

  const toggleSelection = (billId: Bill['id'], selected?: boolean) => {
    const billState = unwrap(billStates.get(billId));
    const newSelected = selected ?? !billState.selected;
    if (newSelected === billState.selected) return;
    billState.selected = newSelected;
    setBillStates(new Map(billStates));
  };
  //#endregion

  //#region Computed values
  const hasASentBill = useMemo(
    () =>
      contain(
        billStates.values(),
        (value) => value.sendStatus === BillSendStatus.Sent
      ),
    [billStates]
  );

  const hasASelectedElement = useMemo(
    () => contain(billStates.values(), (value) => value.selected),
    [billStates]
  );
  //#endregion

  //#region Action functions
  const sendBill = async (billId: Bill['id']): Promise<void> => {
    try {
      setSendStatus(billId, BillSendStatus.Sending);
      toggleSelection(billId, false);

      await new BillApi().send(billId);

      setSendStatus(billId, BillSendStatus.Sent);
    } catch (e) {
      console.warn(`Error encountered while sending bill (${billId}):`, e);
      setSendStatus(billId, BillSendStatus.SendingFailed);
    }
  };

  const sendSelectedBills = async (): Promise<void[]> => {
    const selectedBillIds = Array.from(billStates.values())
      .filter((billState) => billState.selected)
      .map((billState) => billState.bill.id);

    return Promise.all(selectedBillIds.map((billId) => sendBill(billId)));
  };

  const tagBillAsSent = async (billId: Bill['id']): Promise<void> => {
    try {
      setSendStatus(billId, BillSendStatus.Sending);
      toggleSelection(billId, false);

      await new BillApi().patch(billId, { status: BillStatus.Sent });

      setSendStatus(billId, BillSendStatus.Sent);
    } catch (e) {
      console.warn(
        `Error encountered while marking bill as sent (${billId}):`,
        e
      );
      setSendStatus(billId, BillSendStatus.SendingFailed);
    }
  };

  const tagSelectedBillsAsSent = async (): Promise<void[]> => {
    const selectedBillIds = Array.from(billStates.values())
      .filter((billState) => billState.selected)
      .map((billState) => billState.bill.id);

    return Promise.all(selectedBillIds.map((billId) => tagBillAsSent(billId)));
  };

  const cleanSentBills = () => {
    const newBillStates = new Map<string, BillState>();

    for (const billState of billStates.values()) {
      if (billState.sendStatus === BillSendStatus.Sent) continue;
      newBillStates.set(billState.bill.id, billState);
    }

    if (newBillStates.size === billStates.size) return;

    setFilteredBills(
      unwrap(filteredBills)
        .map<[Date, Bill[]]>(([date, bills]) => [
          date,
          bills.filter((bill) => newBillStates.has(bill.id)),
        ])
        .filter(([_date, bills]) => bills.length > 0)
    );

    setBillStates(newBillStates);
  };
  //#endregion

  const tExclTax = t('excl_tax');
  const tInclTax = t('incl_tax');
  const tDetails = t('details');
  const tDownload = t('download');
  const serverUrl = getServerUrl();
  const tTag = t('tag_as_sent');
  const tSend = t('send');

  return (
    <Block className="BillToEmitBlock">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row lg:justify-between">
          <Title level={2}>{t('bills_to_emit')}</Title>
          <div className="flex flex-col lg:flex-row lg:flex-wrap lg:items-center gap-2">
            <Tooltip title={t('bill_clean_tooltip')}>
              <span>
                <RoundedFilledGrayButton
                  disabled={!hasASentBill}
                  startIcon={<i className="icon-clean" />}
                  onClick={cleanSentBills}
                >
                  {t('clean')}
                </RoundedFilledGrayButton>
              </span>
            </Tooltip>
            <RoundedFilledBlueButton
              disabled={!hasASelectedElement}
              startIcon={<i className="icon-tag" />}
              onClick={tagSelectedBillsAsSent}
            >
              {t('tag_as_sent_selection')}
            </RoundedFilledBlueButton>
            <RoundedFilledBlueButton
              disabled={!hasASelectedElement}
              startIcon={<i className="icon-send" />}
              onClick={sendSelectedBills}
            >
              {t('send_selection')}
            </RoundedFilledBlueButton>
          </div>
        </div>

        {billsSnapshot.map({
          notStarted: 'running',
          running: () => <LoadingBlock />,
          failed: (error) => <ErrorBlock noShadow>{`${error}`}</ErrorBlock>,
          succeeded: (billsGrouppedByDate) => (
            <TableContainer component="div">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <GrowTableCell>
                      {t('project')} ({t('company')})
                    </GrowTableCell>
                    <ShrinkTableCell>{t('amount_price')}</ShrinkTableCell>
                    <ShrinkTableCell>{t('actions')}</ShrinkTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billStates.size > 0 &&
                    filteredBills &&
                    filteredBills.map(([date, bills]) => (
                      <React.Fragment key={date.getTime()}>
                        <TableRow>
                          <DateCell colSpan={3}>
                            {DateFormatter.toMonth(date)}
                          </DateCell>
                        </TableRow>
                        {bills.map((bill) => {
                          const billStatus = unwrap(billStates.get(bill.id));
                          const sendable = [
                            BillSendStatus.Initial,
                            BillSendStatus.SendingFailed,
                          ].includes(billStatus.sendStatus);
                          return (
                            <TableRow key={bill.id}>
                              <GrowTableCell>
                                <div className="flex gap-2 items-center">
                                  <Checkbox
                                    label={
                                      <>
                                        {bill.project.name}{' '}
                                        {bill.project.billingCompany &&
                                          `(${bill.project.billingCompany.name})`}
                                      </>
                                    }
                                    checked={billStatus.selected}
                                    disabled={!sendable}
                                    onChange={() => toggleSelection(bill.id)}
                                  />
                                  {billStatus.sendStatus ===
                                    BillSendStatus.Sending && (
                                    <StatusChip
                                      context="info"
                                      label={
                                        <div className="flex gap-2 items-center">
                                          <i className="icon-loader animate-spin-slow" />
                                          <span>
                                            {t('bill_sending_in_progress')}
                                          </span>
                                        </div>
                                      }
                                    />
                                  )}
                                  {billStatus.sendStatus ===
                                    BillSendStatus.Sent && (
                                    <StatusChip
                                      context="success"
                                      label={t('bill_sending_succeeded')}
                                    />
                                  )}
                                  {billStatus.sendStatus ===
                                    BillSendStatus.SendingFailed && (
                                    <StatusChip
                                      context="error"
                                      label={t('bill_sending_failed')}
                                    />
                                  )}
                                </div>
                              </GrowTableCell>
                              <ShrinkTableCell>
                                {NumberFormatter.toEuro(bill.totalExclTax, {
                                  roundMethod: RoundMethods.Ceil,
                                })}
                                &nbsp;{tExclTax} (
                                {NumberFormatter.toEuro(bill.totalInclTax, {
                                  roundMethod: RoundMethods.Ceil,
                                })}
                                &nbsp;{tInclTax})
                              </ShrinkTableCell>
                              <ShrinkTableCell>
                                <div className="flex flex-col md:flex-row">
                                  <Tooltip title={tDetails}>
                                    <IconButton
                                      onClick={() =>
                                        setOpenedBillForDetails(bill)
                                      }
                                    >
                                      {<i className="icon-search" />}
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={tDownload}>
                                    <a
                                      href={serverUrl + bill.pdfUri}
                                      target="_blank"
                                      rel="noreferrer noopener"
                                    >
                                      <IconButton>
                                        <i className="icon-download" />
                                      </IconButton>
                                    </a>
                                  </Tooltip>
                                  <Tooltip title={tTag}>
                                    <IconButton
                                      disabled={!sendable}
                                      onClick={() => tagBillAsSent(bill.id)}
                                    >
                                      <i className="icon-tag" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={tSend}>
                                    <IconButton
                                      disabled={!sendable}
                                      onClick={() => sendBill(bill.id)}
                                    >
                                      <i className="icon-send" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </ShrinkTableCell>
                            </TableRow>
                          );
                        })}
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ),
        })}
      </div>

      <BillingLinesModal
        openedBill={openedBillForDetails}
        onCloseBill={() => setOpenedBillForDetails(null)}
      />
    </Block>
  );
}

import { UserProjectRoleType } from '../models/userProjectRole';
import { useCheckAdmin } from './useCheckAdmin';
import { RoleCheckType, useProjectRoleCheck } from './useProjectRoleCheck';

/**
 * Checks for user rights to create a new Environment.
 * The user should either be admin or manager for the current Project.
 *
 * @returns
 * * `true` if the user has sufficient rights to create a new Environment.
 * * `false` otherwise.
 * * `undefined` will be returned while the user info are being retrieved.
 */
export function useCanCreateNewEnvironment(): boolean | undefined {
  const isSuperAdmin = useCheckAdmin();
  const isAdminOrManager = useProjectRoleCheck(
    [UserProjectRoleType.Admin, UserProjectRoleType.Manager],
    RoleCheckType.Any
  );

  if (isSuperAdmin === undefined || isAdminOrManager === undefined)
    return undefined;
  return isSuperAdmin || isAdminOrManager;
}

import { User } from '../../../models/user';
import { useCurrentUserStore } from '../../../stores/currentUserStore';
import { unwrap } from '../../../utilities/assertions';
import { uppercaseFirstLetter } from '../../../utilities/stringUtilities';

import {
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  styled,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DefaultProfilePicture from '../../../assets/images/default_profile_picture.png';
import { useMostRelevantProjectRole } from '../../../hooks/useMostRelevantProjectRole';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';
import { getServerUrl } from '../../../utilities/fetchUtilities';
import { unsetToken } from '../../../utilities/tokenUtilities';
import { getRoleTerm } from './utilities';

const { REACT_APP_ROUTE_LOGOUT } = process.env;

export function UserTile() {
  const [menuOpen, setMenuOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);
  const userSnapshot = useCurrentUserStore((state) => state.user);
  const { t } = useTranslation();

  const user: User | undefined = userSnapshot.data;
  const projectRole = useMostRelevantProjectRole();

  const role = user ? t(getRoleTerm(user, projectRole)) : '';

  const useDefaultImage = user?.imgUri === '' || user?.imgUri === undefined;

  return (
    <div
      className={
        'UserTile transform translate-x-2 ' +
        ClassUtilities.conditional({ 'animate-pulse': user === undefined })
      }
    >
      <UserTileButton
        onClick={() => setMenuOpen(true)}
        ref={button}
        className="transition"
        TouchRippleProps={{
          style: {
            color: unwrap(Colors.fromTailwind('grey-500') ?? undefined),
          },
        }}
      >
        <div className="w-full h-full px-2 py-2 flex gap-2 items-center">
          <div className="flex flex-col items-end justify-center">
            {user ? (
              <span className="whitespace-nowrap">
                {uppercaseFirstLetter(user.firstname)}{' '}
                {uppercaseFirstLetter(user.lastname)}
              </span>
            ) : (
              <div className="flex gap-2 items-baseline">
                <span className="w-16 h-2 bg-black/10 rounded-full"></span>
                <span className="w-24 h-2 bg-black/10 rounded-full"></span>
              </div>
            )}
            {user ? (
              <span className="text-sm">{role}</span>
            ) : (
              <span className="w-20 h-2 mt-2 bg-black/10 rounded-full"></span>
            )}
          </div>
          <img
            src={
              useDefaultImage
                ? DefaultProfilePicture
                : getServerUrl() + user.imgUri
            }
            alt="Utilisateur"
            className="rounded-full w-10 h-10 object-cover"
            onError={(ev) => {
              console.warn(
                'Profile picture could not be loaded, replacing with the default one.'
              );
              ev.currentTarget.src = DefaultProfilePicture;
            }}
          />
        </div>
      </UserTileButton>
      <Popover
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={button.current}
        elevation={2}
      >
        <MenuList>
          <Link to="/account" onClick={() => setMenuOpen(false)}>
            <MenuItem>
              <ListItemIcon>
                <i className="icon-user" />
              </ListItemIcon>
              <ListItemText>{t('access_my_account')}</ListItemText>
            </MenuItem>
          </Link>
          <Divider />
          <Link
            to={getServerUrl() + unwrap(REACT_APP_ROUTE_LOGOUT)}
            onClick={() => unsetToken()}
          >
            <MenuItem>
              <ListItemIcon>
                <i className="icon-log-out" />
              </ListItemIcon>
              <ListItemText>{t('logout')}</ListItemText>
            </MenuItem>
          </Link>
        </MenuList>
      </Popover>
    </div>
  );
}

const UserTileButton = styled(ButtonBase)({
  borderRadius: '0.375rem',
  color: 'red',
  '& > *': {
    color: Colors.fromTailwind('grey-800'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('grey-100'),
  },
});

import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  getGitReferenceInfo,
  getGitReferenceSchema,
} from '../../molecules/FormGitReferencePicker/schema';

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    gitReference: getGitReferenceSchema(t),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

export function schemaToPostArgs(schema: Schema) {
  const { referenceType: gitReferenceType, reference: gitReference } =
    getGitReferenceInfo(schema);

  return {
    gitReferenceType,
    gitReference,
  };
}

import { Paper } from '@mui/material';
import { InvalidComponentException } from '../../../exceptions/invalidComponentException';
import {
  DataPaperElement,
  DataPaperElementProps,
} from '../DataPaperElement/DataPaperElement';

export interface DataPaperProps {
  content: React.ReactElement<DataPaperElementProps>[];
  header: React.ReactNode;
}

const columnNumber = 2;

export function DataPaper(props: DataPaperProps) {
  assertContentIsValid(props.content);
  const children = insertSeparators(props.content);

  return (
    <Paper
      className="DataPaper grid grid-cols-2 py-2 px-4 gap-x-2 gap-y-2"
      elevation={1}
    >
      <span className="text-md font-semibold text-center col-span-2">
        {props.header}
      </span>
      <Separator />
      {children}
    </Paper>
  );
}

function Separator() {
  return <div className="col-span-2 border-b border-grey-200"></div>;
}

function insertSeparators(
  content: DataPaperProps['content']
): React.ReactElement[] {
  const elements: React.ReactElement[] = [];

  let separatorCount = 0;
  let count = 0;
  let index = 0;
  for (const el of content) {
    elements.push(el);
    if (el.props.fullSpan) {
      count = columnNumber;
    } else {
      ++count;
    }

    if (count >= columnNumber && index !== content.length - 1) {
      elements.push(<Separator key={`separator-${separatorCount++}`} />);
      count = 0;
    }

    ++index;
  }

  return elements;
}

function assertContentIsValid(content: DataPaperProps['content']) {
  const typename = DataPaperElement.name;
  for (const el of content) {
    if (typeof el.type === 'string' && el.type !== typename) {
      throw new InvalidComponentException(
        `Expected ${DataPaperElement.name}, got: ${el.type}`
      );
    }
    const elType = el.type as unknown as Record<string, unknown>;
    if (!(elType?.name === typename)) {
      throw new InvalidComponentException(
        `Expected ${DataPaperElement.name}, got: ${elType.name}`
      );
    }
  }
}

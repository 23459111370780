import { styled } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { unwrap } from '../../../utilities/assertions';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';

export interface LoadingScreenProps {
  /**
   * Controls the visibility of the loading screen.
   */
  opened: boolean;
  /**
   * The title displayed on the loading screen. If this is not defined, a default text will be displayed. To display nothing, give '' as a value.
   */
  title?: string;
  /**
   * Additional details to give to the user.
   */
  details?: string;
  /**
   * Progress bar properties.
   *
   * A common use case is to use this to show a determinated progress. The default is an indeterminate progress bar.
   * @example // Show a determinated progress
   * {
   *   variant: 'determinate',
   *   value: 75,
   * }
   */
  progressBar?: Omit<LinearProgressProps, 'classname'>;
  /**
   * Callback triggered by a click on any part of the loading screen.
   */
  onClick?: () => unknown;
}

/**
 * Display a full page loading screen hiding everything.
 */
export function LoadingScreen(props: LoadingScreenProps) {
  const { t } = useTranslation();

  return (
    <div
      className={ClassUtilities.flatten(
        'LoadingHider absolute w-screen h-screen left-0 top-0 bg-orange flex flex-col justify-center p-8 items-center gap-4 transition-opacity duration-300 z-50',
        ClassUtilities.conditional({
          'opacity-100 pointer-events-auto': props.opened,
          'opacity-0 pointer-events-none': !props.opened,
        })
      )}
      onClick={props.onClick}
    >
      <div className="flex flex-col gap-0 items-center">
        {props.title !== '' && (
          <p className="text-md text-orange-200 font-raleway font-medium">
            {props.title ?? t('loading')}
          </p>
        )}
        {props.details && (
          <p className="text-sm text-orange-200 font-light">{props.details}</p>
        )}
      </div>
      <BigLinearProgress
        className="max-w-lg min-w-80"
        {...(props.progressBar ?? {})}
      ></BigLinearProgress>
    </div>
  );
}

const BigLinearProgress = styled(LinearProgress)({
  height: '0.5rem',
  borderRadius: 5,
  backgroundColor: unwrap(Colors.fromTailwind('orange-350')),
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: unwrap(Colors.fromTailwind('orange-200')),
  },
});

import React, { useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { setToken } from '../../../utilities/tokenUtilities';

export default function TokenWatcher() {
  const [searchParams, setSearchParams] = useSearchParams();
  const uriToken = searchParams.get('token');

  // if token is present, save it and strip it from the url
  useEffect(() => {
    if (uriToken) {
      setToken(uriToken);
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [uriToken, searchParams, setSearchParams]);

  return <MemoizedOutlet />;
}

const MemoizedOutlet = React.memo(Outlet);

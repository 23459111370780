import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { NumberFormatter } from '../../../../utilities/numberFormatter';
import {
  InformationTooltip,
  InformationTooltipProps,
} from '../../../atoms/InformationPopup/InformationPopup';
import {
  IconShortInfo,
  IconShortInfoProps,
} from '../../../molecules/IconShortInfo/IconShortInfo';

export enum TotalLineType {
  HardwareToDate,
  HardwareEndOfMonth,
  TotalEndOfMonth,
}

export interface TotalLineProps<ValueType extends number | undefined> {
  type: TotalLineType;
  /**
   * The cost value. If `undefined`, it is considered to be loading.
   */
  value: number | undefined;
  valueTooltip?: ValueType extends number
    ? InformationTooltipProps['info']
    : never;
}

function typeToIconLabel(
  type: TotalLineType,
  t: TFunction<'translation', unknown>
): Pick<IconShortInfoProps, 'icon' | 'label'> {
  switch (type) {
    case TotalLineType.HardwareToDate:
      return {
        icon: {
          icon: 'icon-clock',
          color: 'yellow-200',
        },
        label: t('total_current_hardware_estimation'),
      };
    case TotalLineType.HardwareEndOfMonth:
      return {
        icon: {
          icon: 'icon-server',
          color: 'blue-200',
        },
        label: t('total_month_end_hardware_estimation'),
      };
    case TotalLineType.TotalEndOfMonth:
      return {
        icon: {
          icon: 'icon-bill',
          color: 'orange-200',
        },
        label: t('total_month_end_estimation'),
      };
  }
}

export function TotalLine<ValueType extends number | undefined>(
  props: TotalLineProps<ValueType>
) {
  const { t } = useTranslation();

  return (
    <IconShortInfo
      valueDescription={
        props.value !== undefined ? (
          <span className="flex gap-2">
            <span>{NumberFormatter.toEuro(props.value)}</span>
            {props.valueTooltip && (
              <InformationTooltip info={props.valueTooltip} />
            )}
          </span>
        ) : (
          <span
            className="h-[1em] rounded-full generic-placeholder text-transparent"
            role="presentation"
          >
            000.00€
          </span>
        )
      }
      {...typeToIconLabel(props.type, t)}
    />
  );
}

import React from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Context, contextToBackgroundColor } from '../../shared/types/context';

export function Block(props: BlockProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'Block rounded-md min-w-1 bg-white',
        props.className,
        ClassUtilities.conditional({
          'transition-[padding] py-3 px-6': !props.noPadding,
          'shadow-md': !props.noShadow,
        })
      )}
      style={{
        minHeight: '2rem',
        backgroundColor: props.context
          ? contextToBackgroundColor(props.context)
          : undefined,
      }}
    >
      {props.children}
    </div>
  );
}

export type BlockProps = {
  children?: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  noShadow?: boolean;
  context?: Context;
};

import { useCallback, useState } from 'react';
import { UniqueId } from '../utilities/uniqueIdGenerator';

export interface UseTriggerValueReturn {
  /** A unique value to use as a dependency change trigger. */
  triggerValue: string;
  /** A function to trigger a change in the trigger value. */
  trigger: () => void;
}

/** Returns a trigger value that changes each time trigger function is called. */
export function useTriggerValue(): UseTriggerValueReturn {
  const [triggerValue, setTriggerValue] = useState(UniqueId.generate());
  const trigger = useCallback(() => {
    setTriggerValue(UniqueId.generate());
  }, []);

  return {
    triggerValue,
    trigger,
  };
}

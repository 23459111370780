import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';
import { Button } from '../Button/Button';
import { LogContext } from './LogProvider';
import { SingleLogWidget } from './subcomponents/SingleLogWidget';

export interface LogsProps {
  className?: string;
  /** The logs splitted into lines. */
  logs: string[];
}

const LOGS_PER_PAGE = 50;

/** Displays logs into a widget.
 * Be warned that this widget should have a maximum height. Without it it would expand to load all the logs at once.
 */
export function Logs(props: LogsProps) {
  const { t } = useTranslation();

  const [hasMore, setHasMore] = useState(props.logs.length > LOGS_PER_PAGE);
  const [count, setCount] = useState(
    Math.min(LOGS_PER_PAGE, props.logs.length)
  );

  const root = useRef<HTMLDivElement>(null);

  // Scroll to bottom a bit after the opening.
  useEffect(() => {
    if (root.current === null) return;
    setTimeout(
      () =>
        root.current?.scrollTo({
          top: root.current.scrollHeight,
          behavior: 'smooth',
        }),
      200
    );
  }, []);

  const showItems = useCallback(
    (logs: string[]) => {
      const items: JSX.Element[] = [];
      for (let i = 0; i < count; ++i) {
        const log = logs[logs.length - 1 - i];
        items.push(
          <SingleLogWidget key={i} log={log} line={logs.length - i} />
        );
      }
      return items;
    },
    [count]
  );

  const loadMore = useCallback(() => {
    if (count === props.logs.length) {
      setHasMore(false);
    } else {
      setCount(Math.min(count + LOGS_PER_PAGE, props.logs.length));
    }
  }, [count, props.logs.length]);

  const downloadLogs = useCallback(() => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(props.logs.join('\n'))
    );
    element.setAttribute('download', 'execution-logs-' + Date.now() + '.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, [props.logs]);

  return (
    <div className="Logs stack">
      <div
        className={ClassUtilities.flatten(
          'bg-black min-h-[2rem] rounded overflow-y-auto',
          props.className
        )}
        ref={root}
      >
        {props.logs.length === 0 ? (
          <div className="text-smd italic text-white h-full flex justify-center items-center opacity-50">
            <span className="px-4">{t('no_logs_to_display')}</span>
          </div>
        ) : (
          <LogContext.Provider
            value={{ maxLineCharNumber: props.logs.length.toString().length }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              isReverse
              className="flex flex-col-reverse"
              useWindow={false}
            >
              {showItems(props.logs)}
            </InfiniteScroll>
          </LogContext.Provider>
        )}
      </div>
      {props.logs.length > 0 && (
        <div className="justify-self-end self-start pr-6 pt-4 LogsDownload opacity-40 hover:opacity-100 transition-opacity">
          <Button
            size="small"
            variant="contained"
            onClick={() => downloadLogs()}
            startIcon={<i className="icon-download" />}
            sx={{
              ':hover': {
                background: Colors.fromTailwind('orange'),
              },
            }}
          >
            {t('download_logs')}
          </Button>
        </div>
      )}
    </div>
  );
}

import { uppercaseFirstLetter } from '../utilities/stringUtilities';
import { Entity } from './entity';
import { User } from './user';

export interface Company extends Entity {
  name: string;
  imgUri?: string;
  users: User['id'][];
  billingContactFullname?: string;
  address?: string;
  zipCode?: string;
  country?: string;
  legalStatus?: string;
  city?: string;
}

export class CompanyHandler {
  public constructor(public data: Company) {}

  /** Returns the full formatted address of a company using its fields ({@link Company.address}, {@link Company.zipCode}, {@link Company.city}, {@link Company.country}). */
  public getFullAddress(): string {
    return (
      (this.data.address ? `${this.data.address}, ` : '') +
      (this.data.zipCode ? `${this.data.zipCode} ` : '') +
      (this.data.city ? `${uppercaseFirstLetter(this.data.city)}` : '') +
      (this.data.city || this.data.zipCode ? ', ' : '') +
      (this.data.country ? uppercaseFirstLetter(this.data.country) : '')
    );
  }
}

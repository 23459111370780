import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import {
  ActionStatus,
  actionStatusToLabel,
} from '../../../models/actionStatus';
import { User } from '../../../models/user';
import { StatusChip } from '../../atoms/Chip/StatusChip';

/**
 * @param author The author entity.
 * @returns A formatted string representing the author identity.
 */
export function formatAuthorName(author: User | undefined) {
  return author
    ? `${author.firstname[0].toUpperCase()}. ${author.lastname}`
    : '-';
}

/**
 * @param status The action status.
 * @returns A chip to visually display the status of an action.
 */
export function actionStatusToChip(status: ActionStatus) {
  switch (status) {
    case ActionStatus.Failed:
      return <StatusChip context="error" label={actionStatusToLabel(status)} />;
    case ActionStatus.Succeeded:
      return (
        <StatusChip context="success" label={actionStatusToLabel(status)} />
      );
    case ActionStatus.Running:
      return (
        <StatusChip context="neutral" label={actionStatusToLabel(status)} />
      );
    default:
      throw new UnhandledSwitchCaseException();
  }
}

import { AutoAnimationPlugin, getTransitionSizes } from '@formkit/auto-animate';
import { unwrap } from '../../../utilities/assertions';

interface Coordinates {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const autoAnimationPlugin: AutoAnimationPlugin = (
  el,
  action,
  oldCoordsRaw,
  newCoordsRaw
) => {
  let keyframes;
  // supply a different set of keyframes for each action
  if (action === 'add') {
    if (el.classList.contains('TransferList__placeholder')) {
      keyframes = [
        { transform: 'scaleY(0)', opacity: 0 },
        { transform: 'scaleY(1)', opacity: 1 },
      ];
    } else {
      keyframes = [{ opacity: 0 }, { opacity: 1 }];
    }
  }
  // keyframes can have as many "steps" as you prefer
  // and you can use the 'offset' key to tune the timing
  if (action === 'remove') {
    keyframes = [{ opacity: 1 }, { opacity: 0 }];
  }
  if (action === 'remain') {
    // for items that remain, calculate the delta
    // from their old position to their new position
    const oldCoords = oldCoordsRaw as Coordinates;
    const newCoords = newCoordsRaw as Coordinates;
    const deltaX = oldCoords.left - newCoords.left;
    const deltaY = oldCoords.top - newCoords.top;
    // use the getTransitionSizes() helper function to
    // get the old and new widths of the elements
    const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(
      el,
      oldCoords,
      newCoords
    );
    // set up our steps with our positioning keyframes
    const start: { transform: string; width?: string; height?: string } = {
      transform: `translate(${deltaX}px, ${deltaY}px)`,
    };
    const end: { transform: string; width?: string; height?: string } = {
      transform: `translate(0, 0)`,
    };
    // if the dimensions changed, animate them too.
    if (widthFrom !== widthTo) {
      start.width = `${widthFrom}px`;
      end.width = `${widthTo}px`;
    }
    if (heightFrom !== heightTo) {
      start.height = `${heightFrom}px`;
      end.height = `${heightTo}px`;
    }
    keyframes = [start, end];
  }
  // return our KeyframeEffect() and pass
  // it the chosen keyframes.
  return new KeyframeEffect(el, unwrap(keyframes), {
    duration: 150,
    easing: 'ease-out',
  });
};

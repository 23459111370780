import { NetworkRequestException } from '../../../exceptions/networkRequestException';

export function errorToTerm(error: unknown): string {
  if (!(error instanceof NetworkRequestException)) {
    return `${error}`;
  } else {
    switch (error.status) {
      case 413:
        return 'company_image_too_big';
      default:
        return error.messageKey ?? error.message;
    }
  }
}

import i18next from 'i18next';
import { ActionTypeName } from './actionTypeName';
import { Entity } from './entity';
import { Environment } from './environment';

export interface ActionType extends Entity {
  environments: Environment['id'][];
  name: ActionTypeName;
}

export class ActionTypeHandler {
  constructor(public data: ActionType) {}

  getDoneFriendlyName(): string {
    return i18next.t(`done_action_labels.${this.data.name}`);
  }

  getFriendlyName(): string {
    return i18next.t(`action_labels.${this.data.name}`);
  }

  getColorClass(): string {
    return i18next.t(`action_colors.${this.data.name}`);
  }

  getIconClass(): string {
    return `icon-${i18next.t(`action_icons.${this.data.name}`)}`;
  }
}

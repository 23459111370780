import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProjectApi } from '../../../api/projectApi';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ProjectPartial } from '../../../models/project';
import { useCurrentUserStore } from '../../../stores/currentUserStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import { ApiPlatformList } from '../../../utilities/baseApi';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { RouteUtilities } from '../../../utilities/routeUtilities';
import { Alert } from '../../atoms/Alert/Alert';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { TablePagination } from '../../atoms/TablePagination/TablePagination';
import { ProjectCard } from '../../molecules/ProjectCard/ProjectCard';

const { REACT_APP_HOSTING_EMAIL } = process.env;

const projectsPerPage = 4;

export function ProjectList() {
  const { t, tFormatted } = useTranslationFormatter();
  const [paginatedProjectsSnapshot, setPaginatedProjects] = useState(
    new PromiseSnapshot<ApiPlatformList<ProjectPartial>>()
  );
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const abortControllerRef = useRef(new AbortController());

  const userError = useCurrentUserStore((state) => state.user.error) as
    | NetworkRequestException
    | undefined;
  const userHasNoRights = userError?.status === 403;

  // Fetch projects if possible
  useEffect(() => {
    if (userError !== undefined) return;

    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ProjectApi().getAllPaginated(
          { itemsPerPage: projectsPerPage, page },
          { abortController }
        ),
      setPaginatedProjects,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [page, userError]);

  // Update local projects whenever promise snapshot changes
  // And if there is only one project navigate to this project workplace.
  useEffect(() => {
    if (!paginatedProjectsSnapshot.isSucceeded()) return;

    const paginatedProjects = paginatedProjectsSnapshot.getSucceededData();
    useProjectsStore
      .getState()
      .localUpdateProjects(...paginatedProjects['hydra:member']);

    if (paginatedProjects['hydra:totalItems'] === 1) {
      const project = paginatedProjects['hydra:member'][0];
      navigate(RouteUtilities.construct('/project/{id}', { id: project.id }));
    }
  }, [navigate, paginatedProjectsSnapshot]);

  return (
    <div className="ProjectList p-2 md:p-8 overflow-y-auto">
      {userHasNoRights ? (
        <Alert severity="warning">
          <div className="flex justify-start flex-col grow">
            <span>
              {tFormatted(
                'insufficient_permission_details',
                { page: t('support') },
                {
                  page: {
                    encapsulator: (translation) => (
                      <Link to="/support">
                        <span className="text-orange">{translation}</span>
                      </Link>
                    ),
                  },
                }
              )}
            </span>
          </div>
        </Alert>
      ) : (
        <>
          {paginatedProjectsSnapshot.state !== PromiseState.Succeeded ? (
            <div className="self-stretch">
              <LoadingBlock />
            </div>
          ) : (
            <>
              {paginatedProjectsSnapshot.data !== undefined && (
                <>
                  {paginatedProjectsSnapshot.data['hydra:member'].length > 0 ? (
                    <div className="flex flex-col justify-between items-stretch h-full">
                      <div className="flex gap-4 md:gap-8 flex-wrap justify-center items-stretch md:justify-start self-start">
                        {paginatedProjectsSnapshot.data['hydra:member'].map(
                          (project) => (
                            <ProjectCard key={project.id} project={project} />
                          )
                        )}
                      </div>
                      <div className="self-end justify-self-end">
                        <TablePagination
                          count={
                            paginatedProjectsSnapshot.data['hydra:totalItems']
                          }
                          page={page}
                          rowsPerPage={projectsPerPage}
                          onPageChange={setPage}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Alert severity="info">
                        {tFormatted(
                          'you_belong_to_no_project',
                          {
                            email: unwrap(
                              REACT_APP_HOSTING_EMAIL,
                              'hosting mail not defined in .env'
                            ),
                          },
                          {
                            email: {
                              encapsulator: (nested) => (
                                <a
                                  className="text-orange"
                                  href={`mailto:${REACT_APP_HOSTING_EMAIL}`}
                                >
                                  {nested}
                                </a>
                              ),
                            },
                          }
                        )}
                      </Alert>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

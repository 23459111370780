import { AbortedException } from '../exceptions/abortedException';
import { fillOptions } from './options';

/** Options for {@link repeatPromiseUntil}. */
export interface RepeatPromiseOptions {
  /** Should delay be used before the execution of the first promise.
   *
   * Defaults to `false`.
   */
  initialDelay: boolean;
  /** The abort controller. Note that this will stop triggering repeat. But will not stop the launched promise if it is running. (So think to pass abort your promise with the same controller if it's what you want to do.)
   *
   * Defaults to `null`.
   */
  abortController: AbortController | null;
}

/** Default for {@link RepeatPromiseOptions}. */
const repeatPromiseOptionsDefault: RepeatPromiseOptions = {
  initialDelay: false,
  abortController: null,
};

/**
 * Repeat a {@link promise} until {@link endCondition} is met.
 * @param promise The asynchronous function to execute.
 * @param endCondition When to stop repeating the call to {@link promise}.
 * @param delay The delay between each call of {@link promise}.
 * @param options The options (see {@link RepeatPromiseOptions}).
 */
export async function repeatPromiseUntil<T>(
  promise: () => Promise<T>,
  endCondition: (data: T) => boolean,
  delay: number,
  options?: Partial<RepeatPromiseOptions>
) {
  const filledOptions = fillOptions(options, repeatPromiseOptionsDefault);

  if (filledOptions.initialDelay) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), delay));
  }

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (filledOptions.abortController?.signal.aborted === true)
      throw new AbortedException();
    const res = await promise();
    if (endCondition(res)) {
      return res;
    }
    await new Promise<void>((resolve) => setTimeout(() => resolve(), delay));
  }
}

import { ActionTypeName } from '../models/actionTypeName';
import { fillOptions } from '../utilities/options';

const routing: Map<ActionTypeName, string> = new Map();
routing.set(ActionTypeName.ScaleFronts, '/scaling');
routing.set(ActionTypeName.UpdateSources, '/source-update');
routing.set(ActionTypeName.Deploy, '/deploy');
routing.set(ActionTypeName.DeleteEnv, '/deletion');
routing.set(ActionTypeName.Maintenance, '/toggle-maintenance');
routing.set(ActionTypeName.RenameEnvironment, '/rename');
routing.set(ActionTypeName.RetryInitEnv, '/retry-init');
routing.set(ActionTypeName.Rollback, '/rollback');
routing.set(ActionTypeName.PickFront, '/pick-front');
routing.set(ActionTypeName.ToggleVm, '/toggle-vm');

export interface Specification {
  requireAttention: boolean;
}

const defaultSpecification: Specification = {
  requireAttention: false,
};

const specifications: Map<ActionTypeName, Partial<Specification>> = new Map();
specifications.set(ActionTypeName.PickFront, { requireAttention: true });

/**
 * @param actionTypeName The action type name.
 * @returns The routing (supposed to be after workplace route) for the given action. Or `undefined` if no routing has been given.
 */
export function getActionRouting(
  actionTypeName: ActionTypeName
): string | undefined {
  return routing.get(actionTypeName);
}

/**
 * @param actionTypeName The action type name.
 * @returns The specification for the given {@link actionTypeName}.
 */
export function getSpecification(
  actionTypeName: ActionTypeName
): Specification {
  return fillOptions(specifications.get(actionTypeName), defaultSpecification);
}

import { ElementType } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import './Title.css';

function levelToHeaderTag(level: TitleLevel): ElementType {
  return `h${level}`;
}

export type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface TitleProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  level: TitleLevel;
}

export function Title(props: TitleProps) {
  const HeaderTag = levelToHeaderTag(props.level);

  return (
    <HeaderTag
      {...props}
      className={ClassUtilities.flatten('Title', props.className)}
    />
  );
}

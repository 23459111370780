import { Exception } from '../../../../exceptions/exception';

export enum SubmissionStep {
  RoleRetrieval,
  RoleDeletion,
}

export class SubmissionException extends Exception {
  constructor(public step: SubmissionStep, public cause: unknown) {
    super();
  }
}

import { styled } from '@mui/material';
import React from 'react';
import { Colors } from '../../../../utilities/colors';
import { Button, ButtonProps } from '../Button';

export const FilledRoundedButtonBase = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'variant'>
>((props: Omit<ButtonProps, 'variant'>, ref) => (
  <Button variant="contained" {...props} ref={ref}></Button>
));
FilledRoundedButtonBase.displayName = 'FilledRoundedButtonBase';

export const FilledRoundedButton = styled(FilledRoundedButtonBase)({
  '&': {
    textTransform: 'none',
    borderRadius: '9999px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: Colors.fromTailwind('blue-200'),
  },
});

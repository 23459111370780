import { ServerSizing } from '../../../models/project';

export function filterServerTypeValues(
  serverSizing: ServerSizing[]
): ServerSizing[] | undefined {
  const serverTypeValues: ServerSizing[] = [];
  serverSizing.forEach((element) => {
    if (
      element.type &&
      ['front', 'db', 'nfs', 'jumphost', 'proxy'].includes(element.type)
    ) {
      serverTypeValues.push(element);
    }
  });
  return serverTypeValues;
}

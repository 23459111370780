import { useTranslationFormatter } from '../../../../hooks/useTranslationFormatter';
import { Project } from '../../../../models/project';
import { User } from '../../../../models/user';
import { DeletionModal } from '../../../molecules/DeletionModal/DeletionModal';

/**
 * Properties for {@link UserDeletionModal}.
 */
export type UserDeletionModalProps = {
  /**
   * If `true`, the component is shown.
   */
  open: boolean;
  /**
   * Callback fired when the user clicks on the confirmation button to remove the user.
   */
  onSubmitDelete: () => void;
  /**
   * Callback fired when the user clicks on the cancel button.
   */
  onCloseModal: () => void;
  /**
   * The user to be removed. `undefined` when the modal is closed.
   */
  user?: User;
  /**
   * The project the user will be removed from.
   */
  project: Project;
  /**
   * If `true`, the request to remove the user is currently processing.
   */
  loading: boolean;
};

/**
 * Confirmation modal to remove a user from a project.
 * @param props See {@link UserDeletionModalProps}
 */
export function UserDeletionModal(props: UserDeletionModalProps) {
  const { t, tFormatted } = useTranslationFormatter();

  return (
    <>
      <DeletionModal
        title={t('remove_user_from_project')}
        text={tFormatted(
          'confirm_remove_user_from_project',
          {
            user: `${props.user?.firstname} ${props.user?.lastname}`,
            project: props.project.name,
          },
          {
            user: {
              classes: 'text-orange',
            },
            project: {
              classes: 'text-orange',
            },
          }
        )}
        open={props.open}
        onSubmitDelete={props.onSubmitDelete}
        onCloseModal={props.onCloseModal}
        loading={props.loading}
        isIrreversible
      ></DeletionModal>
    </>
  );
}

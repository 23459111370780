import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { ProjectApi } from '../../../api/projectApi';
import { useTitle } from '../../../hooks/useTitle';
import { Environment } from '../../../models/environment';
import { Project } from '../../../models/project';
import { unwrap } from '../../../utilities/assertions';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { Breadcrumb } from '../../atoms/Breadcrumb/Breadcrumb';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { EnvironmentActionTypeManager } from './subcomponents/EnvironmentActionTypeManager';
import { EnvironmentStatus } from './subcomponents/EnvironmentStatus';
import { extractProjectEnvironmentFromLocation } from './utilities';

export function AdminEnvironment() {
  const { project: projectContext, environment: environmentContext } =
    extractProjectEnvironmentFromLocation(useLocation());
  const { projectId, environmentId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [projectSnapshot, setProjectSnapshot] = useState<
    PromiseSnapshot<Project>
  >(new PromiseSnapshot());
  const [environmentSnapshot, setEnvironmentSnapshot] = useState<
    PromiseSnapshot<Environment>
  >(new PromiseSnapshot());
  const abortController = useRef(new AbortController());

  useTitle(
    t('administration_environment', {
      environment: environmentSnapshot.data?.name,
    })
  );

  // Ensure we have basic information about environment and project
  useEffect(() => {
    if (projectId === undefined || environmentId === undefined) {
      navigate('/admin');
      return;
    }

    // Fetch project if context does not give it.
    if (projectContext === undefined) {
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new ProjectApi().get(projectId, {
            abortController: abortController.current,
          }),
        setProjectSnapshot
      );
    } else {
      setProjectSnapshot(PromiseSnapshot.buildSucceeded(projectContext));
    }

    // Fetch environment if context does not give it.
    if (environmentContext === undefined) {
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new EnvironmentApi().get(environmentId, {
            abortController: abortController.current,
          }),
        setEnvironmentSnapshot
      );
    } else {
      setEnvironmentSnapshot(
        PromiseSnapshot.buildSucceeded(environmentContext)
      );
    }
  }, [environmentContext, environmentId, navigate, projectContext, projectId]);

  //#region Rendering
  if (
    projectSnapshot.state === PromiseState.Failed ||
    environmentSnapshot.state === PromiseState.Failed
  ) {
    return (
      <AdminPageTemplate className="AdminProject">
        <ErrorBlock>{`${
          projectSnapshot.state === PromiseState.Failed
            ? projectSnapshot.error
            : environmentSnapshot.state
        }`}</ErrorBlock>
      </AdminPageTemplate>
    );
  } else if (
    projectSnapshot.state !== PromiseState.Succeeded ||
    environmentSnapshot.state !== PromiseState.Succeeded
  ) {
    return (
      <AdminPageTemplate className="AdminProject">
        <LoadingBlock></LoadingBlock>
      </AdminPageTemplate>
    );
  }

  const project = unwrap(projectSnapshot.data);
  const environment = unwrap(environmentSnapshot.data);

  return (
    <AdminPageTemplate className="AdminProject">
      <div className="flex flex-col gap-4">
        <Breadcrumb
          homeLink="/admin/projects"
          elements={[
            {
              title: project.name,
              subtitle: t('project'),
              link: `/admin/projects/${project.id}`,
            },
            {
              title: environment.name,
              subtitle: t('environment'),
              link: `/admin/projects/${project.id}/environments/${environment.id}`,
            },
          ]}
        ></Breadcrumb>
        <EnvironmentStatus environment={environment} />
        <EnvironmentActionTypeManager environment={environment} />
      </div>
    </AdminPageTemplate>
  );
  //#endregion
}

import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import { getSchema as getPaymentSchema } from './subcomponents/PaymentLine/schema';

export function getSchema(t: WithTranslation['t']) {
  const paymentSchema = getPaymentSchema(t);

  return z.object({
    payments: paymentSchema.array(),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

import { useEffect, useRef, useState } from 'react';
import {
  PromiseSnapshot,
  PromiseSnapshotData,
} from '../utilities/promiseSnapshot';

/**
 * Wraps the logic for using the {@link PromiseSnapshot} inside a component.
 *
 * @param fetchFunction The function to fetch. Its `abortController` parameter should be used to cancel the fetch request when it is aborted.
 * @warning {@link fetchFunction} must be cached if declared in a component (by using `useCallback` for example), otherwise it will retrigger the request each time.
 * @returns A {@link PromiseSnapshot} containing the state of the fetch function.
 */
export function usePromiseSnapshot<T extends PromiseSnapshotData>(
  fetchFunction: (abortController: AbortController) => Promise<T>
): PromiseSnapshot<T> {
  const [snapshot, setSnapshot] = useState(new PromiseSnapshot<T>());
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () => fetchFunction(abortController),
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [fetchFunction]);

  return snapshot;
}

import { TFunction } from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { useUnparameterizedPath } from '../../../hooks/useUnparameterizedPath';
import { PartialEnvironmentHandler } from '../../../models/environment';
import { useLoadingScreenStore } from '../../../stores/loadingScreenStore';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { InformationBlock } from '../../atoms/InformationBlock/InformationBlock';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';

export interface RequireEnvironmentProps {
  /** Attempt to load one environment. But allow to display outlet if no environment exists.
   * @default false
   */
  allowNone?: boolean;
  /**
   * Attempt to redirect on an existing environment if the given environment id does not exist for the project.
   * @default false
   */
  redirectWhenNonexistent?: boolean;
}

enum State {
  EnvironmentsFetch,
  SelectEnvironmentFromUrl,
  SelectDefaultEnvironment,
  EnvironmentSelected,
  NoMatchingEnvironment,
  NoEnvironment,
}
// Edit state: https://mermaid.live/edit#pako:eNp9U01PwzAM_StRjrCtrIUNKrQT223jMHGiCIXEbQNtUiXuxDTtv-O27APY6Ml97_nZsZMNl1YBj7lHgfCgReZE2V-FiWH0tSDT6WsX3N_L3GoJk0lHP1-8sH5_wqZmpZ01JRj0M0CZd_QfuBUv7BEes8BYBgeAwaf26FlqHcNce1Y5-w4S_3NcQkGKI27mbPnkCjKvXcG0YroxrA0FBn-U8__57s5NPsPLU00KcjxUMBa7Kp3nfmqHFh8gFXWBP8_vQGlH5KP5pk-kL-xcUFfaZL9nh-xM_rmh_F5YpwN1nPW3z3NZbf9Crbvsk222qXRTdpKz1An7nYD3eAmuFFrRTd008oRjDiUkPKZQCfeR8MRsSSdqtMu1kTxGV0OP15U6XGwep6Lwe3SqNFq3UxZWKKC_Dcd11TyJjJZMjtKaVGcNTrsmOEesfBwEDT3INOb120DaMvBa5cJhvrobBaNwdCvCCEbjSNxEkZJvw7vbNLwepmp8NQwF3257HNry8-79tc9w-wUFTTo-
// stateDiagram-v2
//     state if_state <<choice>>
//     [*] --> EnvironmentsFetch
//     EnvironmentsFetch --> NoEnvironment: /no environment exists for this project
//     EnvironmentsFetch --> SelectEnvironmentFromUrl: /url id is found into environments
//     EnvironmentsFetch --> if_state: /1+ environment exists and url id is not found
//     if_state --> SelectDefaultEnvironment: /redirectOnDefault
//     if_state --> NoMatchingEnvironment: /not redirectOnDefault
//     SelectEnvironmentFromUrl --> EnvironmentSelected
//     SelectDefaultEnvironment --> EnvironmentSelected: /ready
//     NoMatchingEnvironment --> [*]
//     NoEnvironment --> [*]
//     EnvironmentSelected --> [*]

/**
 * Guards that try to ensure an environment is selected through the URL. Handles the following tasks:
 * * Uses the environment given in the URL to set stores data.
 * * Sets the URL with a default environment if at least an environment exists for the current project.
 * * Set the URL environment to the store environment if a change is made externally in the store.
 */
export function RequireEnvironment(props: RequireEnvironmentProps) {
  const { t } = useTranslation();
  const { environmentId, ...otherParams } = useParams();
  const rawPath = useUnparameterizedPath();
  const redirect = rawPath;
  const allowNone = props.allowNone ?? false;
  const redirectWhenNonexistent = props.redirectWhenNonexistent ?? false;

  const navigate = useNavigate();

  const [state, setState] = useState(State.EnvironmentsFetch);

  const projectId = useProjectsStore((state) => state.selectedProjectId);
  const fetchedProjectId = useRef<string | undefined>(undefined);

  const [
    fetch,
    environments,
    selectedEnvironmentIndex,
    setSelectedEnvironmentIndex,
    context,
  ] = useProjectEnvironmentsStore((state) => [
    state.fetch,
    state.environments,
    state.selectedEnvironmentIndex,
    state.setSelectedEnvironmentIndex,
    state.context,
  ]);

  //#region State functions
  const environmentsFetch = useCallback(() => {
    if (environments.state === PromiseState.Succeeded) {
      const fetchedEnvironments = unwrap(environments.data);
      // No project
      if (fetchedEnvironments.length === 0) {
        setState(State.NoEnvironment);
      }
      // Url project not found
      else if (
        fetchedEnvironments.findIndex(
          (environment) => environment.id === environmentId
        ) === -1
      ) {
        if (redirectWhenNonexistent) {
          setState(State.SelectDefaultEnvironment);
        } else {
          setState(State.NoMatchingEnvironment);
        }
      }
      // Url project found
      else {
        setState(State.SelectEnvironmentFromUrl);
      }
      return;
    }
    const loadingScreenLabel = t('loading_environments');
    useLoadingScreenStore.setState({
      opened: true,
      title: loadingScreenLabel,
    });
    if (
      environments.state !== PromiseState.Running &&
      projectId !== undefined
    ) {
      fetch(projectId, false);
      fetchedProjectId.current = projectId;
      return;
    }
  }, [
    environments.state,
    environments.data,
    t,
    projectId,
    environmentId,
    redirectWhenNonexistent,
    fetch,
  ]);

  const selectEnvironmentFromUrl = useCallback(() => {
    const fetchedEnvironments = unwrap(environments.data);
    // ready -> EnvironmentId matching store
    if (environmentId === fetchedEnvironments[selectedEnvironmentIndex]?.id) {
      // If environment is accessible
      if (
        new PartialEnvironmentHandler(
          unwrap(fetchedEnvironments[selectedEnvironmentIndex])
        ).isAccessible()
      ) {
        setState(State.EnvironmentSelected);
        // Otherwise attempt to select an accessible one.
      } else {
        const index = fetchedEnvironments.findIndex((env) =>
          new PartialEnvironmentHandler(env).isAccessible()
        );
        if (index === -1) {
          setState(State.NoEnvironment);
        } else {
          const redirectPath = generatePath(redirect, {
            environmentId: unwrap(
              useProjectEnvironmentsStore.getState().environments.data?.[index]
            ).id,
            ...otherParams,
          });
          navigate(redirectPath, { replace: true });
        }
      }
    } else {
      setSelectedEnvironmentIndex(
        fetchedEnvironments.findIndex((env) => env.id === environmentId),
        context
      );
    }
  }, [
    environments.data,
    environmentId,
    selectedEnvironmentIndex,
    redirect,
    otherParams,
    navigate,
    setSelectedEnvironmentIndex,
    context,
  ]);

  const selectDefaultEnvironment = useCallback(() => {
    const fetchedEnvironments = unwrap(environments.data);
    if (selectedEnvironmentIndex === 0) {
      setState(State.EnvironmentSelected);
    } else {
      const redirectPath = generatePath(redirect, {
        environmentId: (
          fetchedEnvironments.find((env) =>
            new PartialEnvironmentHandler(env).isAccessible()
          ) ?? fetchedEnvironments[0]
        ).id,
        ...otherParams,
      });
      navigate(redirectPath, {
        replace: true,
      });
      setState(State.EnvironmentsFetch);
    }
  }, [
    navigate,
    otherParams,
    environments.data,
    redirect,
    selectedEnvironmentIndex,
  ]);
  //#endregion

  // Closes loading screen when all work is done.
  useEffect(() => {
    switch (state) {
      case State.EnvironmentSelected:
      case State.NoEnvironment:
      case State.NoMatchingEnvironment:
        useLoadingScreenStore.setState({ opened: false });
        break;
    }
  }, [state]);

  // Close loading when the component unmount
  useEffect(() => {
    return () => {
      useLoadingScreenStore.setState({
        opened: false,
      });
    };
  }, []);

  // React to external store project changes
  useEffect(() => {
    if (
      state === State.EnvironmentSelected &&
      context !== 'RequireEnvironment'
    ) {
      useProjectEnvironmentsStore.setState({ context: 'RequireEnvironment' });
      const redirectPath = generatePath(redirect, {
        environmentId: unwrap(
          useProjectEnvironmentsStore.getState().environments.data
        )[selectedEnvironmentIndex].id,
        ...otherParams,
      });
      navigate(redirectPath, {
        replace: false,
      });
    }
  }, [
    context,
    navigate,
    otherParams,
    redirect,
    selectedEnvironmentIndex,
    state,
  ]);

  // Triggers state related function, when it changes.
  useEffect(() => {
    switch (state) {
      case State.EnvironmentsFetch:
        environmentsFetch();
        return;
      case State.SelectEnvironmentFromUrl:
        selectEnvironmentFromUrl();
        return;
      case State.SelectDefaultEnvironment:
        selectDefaultEnvironment();
        return;
      case State.EnvironmentSelected:
      case State.NoEnvironment:
      case State.NoMatchingEnvironment:
        return;
      default:
        throw new UnhandledSwitchCaseException(
          `RequireEnvironment unknown state "${state}" (maybe ${
            Object.keys(State)[state]
          } has not been handled).`
        );
    }
  }, [
    environmentsFetch,
    selectDefaultEnvironment,
    selectEnvironmentFromUrl,
    state,
  ]);

  // Refresh in case project id changes.
  useEffect(() => {
    if (
      state === State.NoEnvironment &&
      fetchedProjectId.current !== projectId
    ) {
      fetchedProjectId.current = projectId;
      useProjectEnvironmentsStore.setState({
        environments: new PromiseSnapshot(),
      });
      setState(State.EnvironmentsFetch);
    }
  }, [projectId, state]);

  return stateToWidget(state, allowNone, t);
}

/**
 * For a {@link State}, gives the widget to render.
 */
function stateToWidget(
  state: State,
  allowNone: boolean,
  t: TFunction<'translation', undefined>
): JSX.Element {
  switch (state) {
    case State.EnvironmentSelected:
      return <Outlet />;
    case State.NoMatchingEnvironment:
      return (
        <div className="p-2">
          <WarningBlock>{t('env_does_not_exist')}</WarningBlock>
        </div>
      );
    case State.NoEnvironment:
      return allowNone ? (
        <Outlet />
      ) : (
        <InformationBlock>{t('no_existing_env_for_project')}</InformationBlock>
      );
    default:
      return <></>;
  }
}

import { FormHelperText } from '@mui/material';
import { Key } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ClassUtilities } from '../../../utilities/classUtility';
import {
  ExclusiveToggleButtonGroup,
  ExclusiveToggleButtonGroupProps,
} from './subcomponents/ExclusiveButtonGroup';

export interface FormButtonGroupProps<
  TFieldValues extends FieldValues,
  TValue extends Key
> extends Omit<
    ExclusiveToggleButtonGroupProps<TValue>,
    'aria-label' | 'value' | 'className' | 'onChange'
  > {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, unknown>;
  className?: string;
  buttonGroupClassName?: string;
}

export function FormExclusiveButtonGroup<
  TFieldValues extends FieldValues,
  TValue extends Key
>(props: FormButtonGroupProps<TFieldValues, TValue>) {
  const {
    name,
    control,
    className,
    buttonGroupClassName,
    ...toggleButtonGroupProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={ClassUtilities.flatten('flex flex-col', className)}>
          <ExclusiveToggleButtonGroup
            aria-label="text button group"
            value={value}
            onChange={(val) => onChange(val)}
            {...toggleButtonGroupProps}
          />
          {error && (
            <FormHelperText
              error={!!error}
              sx={{ marginLeft: 0, marginRight: 0 }}
            >
              {error?.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../models/user';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import {
  MultipleSelect,
  MultiSelectActions,
} from '../../../atoms/MultipleSelect/MultipleSelect';
import { formatAuthorName } from '../utilities';

export interface AuthorSelectProps {
  usersSnapshot: PromiseSnapshot<User[]>;
  selectedAuthorIds: User['id'][];
  onSelectedAuthorIdsChanged: (ids: User['id'][]) => void;
}

export const environmentQueryParam = 'environments[]';

export function AuthorSelect(props: AuthorSelectProps) {
  const { t } = useTranslation();

  const usersSnapshot = props.usersSnapshot;

  const availableUsers = usersSnapshot.data ?? [];
  const availableUsersIds = availableUsers.map((env) => env.id);

  const selectedUserIds = useMemo(
    () =>
      props.selectedAuthorIds.filter((el) => availableUsersIds.includes(el)),
    [availableUsersIds, props.selectedAuthorIds]
  );

  return (
    <MultipleSelect
      label={t('author')}
      name="author-filter"
      options={(usersSnapshot.data ?? []).map((user) => ({
        label: formatAuthorName(user),
        value: user.id,
      }))}
      disabled={usersSnapshot.data === undefined}
      values={selectedUserIds}
      loading={usersSnapshot.data === undefined}
      actions={[MultiSelectActions.Clear, MultiSelectActions.Close]}
      onChange={(values) => {
        props.onSelectedAuthorIdsChanged(values);
      }}
    />
  );
}

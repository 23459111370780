import { UnhandledSwitchCaseException } from '../exceptions/unhandledSwitchCaseException';

export enum RoundMethods {
  Floor,
  Ceil,
  Round,
}

export interface ToEuroOptions {
  decimalDigits: number;
  roundMethod: RoundMethods;
}

export const ToEuroOptionsDefaults: ToEuroOptions = {
  decimalDigits: 2,
  roundMethod: RoundMethods.Ceil,
};

export class NumberFormatter {
  /**
   * @param number The number to format
   * @param roundMethod The round method to use to obtain the formatted number.
   * @returns The formatted number with two digits after the decimal point followed by a `€` sign.
   */
  static toEuro(number: number, options?: Partial<ToEuroOptions>): string {
    const filledOptions: ToEuroOptions = {
      ...ToEuroOptionsDefaults,
      ...options,
    };

    return (
      (
        this.roundMethodToFunction(filledOptions.roundMethod)(number * 100) /
        100
      ).toFixed(filledOptions.decimalDigits) + '€'
    );
  }

  /**
   * @example
   * console.log(NumberFormatter.toGB(20000000)); // 20Go
   * @param number The number to format in bytes.
   * @returns The formatted number as an integer followed by a `Go` sign.
   */
  static toGB(number: number): string {
    return `${Math.floor(number / 1000000)}Go`;
  }

  /**
   * @example
   * console.log(NumberFormatter.toDHHMM(31925)); // 22j 04h 05m
   * console.log(NumberFormatter.toDHHMM(10381)); // 7j 05h 01m
   * console.log(NumberFormatter.toDHHMM(91)); // 1h 31m
   * @param number The number of minutes to format in time.
   */
  static toDurationString(number: number): string {
    const days = Math.floor(number / (24 * 60));
    const hours = Math.floor((number - days * 24 * 60) / 60);
    const minutes = number % 60;

    if (days > 0) {
      return `${days}j ${hours.toString().padStart(2, '0')}h ${minutes
        .toString()
        .padStart(2, '0')}min`;
    } else if (minutes > 0) {
      return `${hours}h ${minutes.toString().padStart(2, '0')}min`;
    } else {
      return `${minutes}min`;
    }
  }

  /**
   * From a round method value, returns the associated math function.
   * @param roundMethod The round method.
   * @returns A function associated to the round method.
   */
  private static roundMethodToFunction(
    roundMethod: RoundMethods
  ): (x: number) => number {
    switch (roundMethod) {
      case RoundMethods.Floor:
        return Math.floor;
      case RoundMethods.Ceil:
        return Math.ceil;
      case RoundMethods.Round:
        return Math.round;
      default:
        throw new UnhandledSwitchCaseException();
    }
  }
}

import { AutocompleteRenderInputParams, Box, Grid } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../../../api/userApi';
import DefaultProfilePicture from '../../../../assets/images/default_profile_picture.png';
import { useLazyAutocompleteSearch } from '../../../../hooks/useLazyAutocompleteSearch';
import { Project } from '../../../../models/project';
import { User } from '../../../../models/user';
import { Env } from '../../../../otherBusinessLogic/env';
import { unwrapNull } from '../../../../utilities/assertions';
import { getServerUrl } from '../../../../utilities/fetchUtilities';
import { HashSet } from '../../../../utilities/hashSet';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import { Option } from '../../../atoms/Autocomplete/Autocomplete';
import { FormAutocomplete } from '../../../atoms/Autocomplete/FormAutocomplete';
import { Image } from '../../../atoms/Image/Image';
import { TextField } from '../../../atoms/TextField/TextField';
import { Schema } from '../schema';

export interface FormContactProps {
  defaultContactId: User['id'] | null;
  projectId: Project['id'];
  control: Control<Schema>;
  watch: UseFormWatch<Schema>;
  setValue: UseFormSetValue<Schema>;
  onContactReady: () => void;
}

export function FormContact(props: FormContactProps) {
  const { t } = useTranslation();
  const watchUser = props.watch('contact');
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const defaultContactOverridden = useRef(props.defaultContactId === null);
  const [defaultContactSnapshot, setDefaultContactSnapshot] = useState<
    PromiseSnapshot<User | null>
  >(new PromiseSnapshot());
  const abortControllerRef = useRef(new AbortController());
  const onContactReady = props.onContactReady;

  // Fetch default contact
  useEffect(() => {
    if (!defaultContactSnapshot.isNotStarted()) return; // Do only once.
    if (props.defaultContactId === null) {
      setDefaultContactSnapshot(PromiseSnapshot.buildSucceeded(null));
      return;
    }

    abortControllerRef.current.abort();
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    PromiseSnapshot.trackPromiseSetter(
      async () => {
        const user = await new UserApi().get(
          unwrapNull(props.defaultContactId),
          {
            abortController,
          }
        );
        props.setValue('contact', user);
        return user;
      },
      setDefaultContactSnapshot,
      { abortController }
    );
  }, [defaultContactSnapshot, props, props.defaultContactId]);

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  // Watch when the default contact snapshot is overridden.
  useEffect(() => {
    if (!defaultContactSnapshot.isSucceeded()) return;
    onContactReady();
    const defaultContact = defaultContactSnapshot.getSucceededData();
    if (defaultContact === null) return;
    if (watchUser?.id !== defaultContact.id) {
      defaultContactOverridden.current = true;
    }
  }, [defaultContactSnapshot, watchUser, onContactReady]);

  // Set value when default contact is set

  const matchingUsers = useLazyAutocompleteSearch({
    fetchFunction: (input, _itemsPerPage, abortController) =>
      new UserApi().getAllPaginated(
        {
          full_name: input,
          project_id: props.projectId,
        },
        {
          abortController,
        }
      ),
    input: autocompleteInputValue,
    itemsPerPage: Env.defaultItemsPerPage,
    extractAutocompleteValueFunction: (user) => [
      user.firstname.toLowerCase(),
      user.lastname.toLowerCase(),
    ],
  });

  const userOptions = useMemo<readonly Option<User>[]>(() => {
    const set = HashSet.fromNonHashable((user) => user.id, {
      initialValues: matchingUsers,
    });
    if (watchUser && typeof watchUser === 'object') {
      set.add(watchUser as User);
    }
    if (
      !defaultContactOverridden.current &&
      defaultContactSnapshot.isSucceeded()
    ) {
      set.add(unwrapNull(defaultContactSnapshot.getSucceededData()));
    }

    return Array.from(set.values()).map((user) => ({
      label: `${user.firstname} ${user.lastname}`,
      value: user,
    }));
  }, [defaultContactSnapshot, matchingUsers, watchUser]);

  return (
    <FormAutocomplete
      name="contact"
      control={props.control}
      options={userOptions}
      filterOptions={(x) => x}
      disabled={!defaultContactSnapshot.isOver()}
      onInputChange={(_evt, value) => {
        setAutocompleteInputValue(value.toLowerCase());
      }}
      valueEqualityOperator={(value1, value2) => {
        return value1.id === value2.id;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => {
        if (!defaultContactSnapshot.isOver()) {
          params.InputProps.endAdornment = (
            <div className="flex items-center transform translate-x-6">
              <i className="icon-loader animate-spin-slow" />
            </div>
          );
        }
        return (
          <TextField {...params} label={t('referrer_contact')} fullWidth />
        );
      }}
      renderOption={(props, option) => {
        const useDefaultImage =
          option.value.imgUri === '' || option.value.imgUri === undefined;
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={() => (
                    <Image
                      src={
                        useDefaultImage
                          ? DefaultProfilePicture
                          : getServerUrl() + option.value.imgUri
                      }
                      fallbackSrc={DefaultProfilePicture}
                      alt="User"
                      className="rounded-full w-10 h-10 object-cover"
                    />
                  )}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs className="pl-3">
                <span>{option?.label}</span>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Autocomplete, AutocompleteProps } from './Autocomplete';

export interface FormAutocompleteProps<
  TFieldValues extends FieldValues,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'name' | 'onChange' | 'value' | 'error' | 'helperText'
  > {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, unknown>;
}

export function FormAutocomplete<
  TFieldValues extends FieldValues,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: FormAutocompleteProps<
    TFieldValues,
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >
) {
  const { name, control, className, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className={ClassUtilities.flatten('flex flex-col', className)}>
            <Autocomplete
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error?.message}
              {...fieldProps}
            />
          </div>
        );
      }}
    />
  );
}

import { generatePath } from 'react-router-dom';
import { rawProjectRoute } from '../Routing';
import { useProjectsStore } from '../stores/projectsStore';

/**
 * Gets the route portion associated with the current project.
 */
export function useProjectRoute() {
  const projectIdString =
    useProjectsStore((state) => state.getProject()?.id) ?? 'none';

  return generatePath(rawProjectRoute, { projectId: projectIdString });
}

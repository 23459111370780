import { IconButton } from '@mui/material';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentApi } from '../../../../api/environmentApi';
import { SnackbarDuration } from '../../../../constants/snackbarDuration';
import { Environment } from '../../../../models/environment';
import { VirtualMachine } from '../../../../models/virtualMachine';
import { useSnackbarStore } from '../../../../stores/snackbarStore';
import { unwrap } from '../../../../utilities/assertions';
import { ClassUtilities } from '../../../../utilities/classUtility';
import { NumberFormatter } from '../../../../utilities/numberFormatter';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../utilities/promiseSnapshot';
import { Block } from '../../../atoms/Block/Block';
import { DataTile } from '../../../atoms/DataTile/DataTile';
import { LoadingBlock } from '../../../atoms/LoadingBlock/LoadingBlock';
import { Modal } from '../../../atoms/Modal/Modal';
import { Title } from '../../../atoms/Title/Title';

export interface VMBlockProps {
  envId: Environment['id'];
  className?: string;
}

export function VMBlock(props: VMBlockProps) {
  const { t } = useTranslation();

  const [currentSelectedVM, setCurrentlySelectedVM] = useState<
    VirtualMachine | undefined
  >(undefined);
  const vmAbortController = useRef(new AbortController());
  const vmsEnvId = useRef<string>();

  //#region Copy SSH handling
  const copy = async (text: string, name: VirtualMachine['name']) => {
    try {
      await navigator.clipboard.writeText(text);
      useSnackbarStore.getState().addStandaloneSnackbar(`ssh-copy-${name}`, {
        severity: 'success',
        message: t('ssh_copied'),
        autoHideDuration: SnackbarDuration.Short,
      });
    } catch (e) {
      console.error(`Cannot copy "${text}" into clipboard.`, e);
      useSnackbarStore.getState().addStandaloneSnackbar(`ssh-copy-${name}`, {
        severity: 'error',
        message: t('impossible_to_copy_SSH'),
        autoHideDuration: SnackbarDuration.Short,
      });
    }
  };
  //#endregion

  //#region VMs fetch
  const [vmsSnapshot, setVmsSnapshot] = useState<
    PromiseSnapshot<VirtualMachine[]>
  >(new PromiseSnapshot());

  useEffect(() => {
    if (vmsEnvId.current === props.envId) return;

    vmAbortController.current.abort();
    const abortController = new AbortController();
    vmAbortController.current = abortController;

    vmsEnvId.current = props.envId;

    PromiseSnapshot.trackPromiseSetter(
      () => {
        return new EnvironmentApi().getVirtualMachines(props.envId, {
          abortController,
        });
      },
      (vms) => {
        setVmsSnapshot(vms);
      },
      { abortController }
    );
  }, [props.envId]);

  //#endregion

  const VmsGridCells: React.ReactElement[] | undefined =
    vmsSnapshot.data !== undefined
      ? generateGridCells(vmsSnapshot.data, copy, setCurrentlySelectedVM)
      : undefined;

  const changeCurrentVM = (previousOrNext: 'previous' | 'next') => {
    if (currentSelectedVM !== undefined && vmsSnapshot?.data !== undefined) {
      const currentIndex = vmsSnapshot.data.findIndex((value, index) => {
        return value.name === currentSelectedVM.name;
      });

      if (previousOrNext === 'previous' && ![-1, 0].includes(currentIndex)) {
        setCurrentlySelectedVM(vmsSnapshot.data[currentIndex - 1]);
      }
      if (
        previousOrNext === 'next' &&
        ![-1, vmsSnapshot.data.length - 1].includes(currentIndex)
      ) {
        setCurrentlySelectedVM(vmsSnapshot.data[currentIndex + 1]);
      }
    }
  };

  /**
   * Checks if previous or next button can be clicked according to {@Link previousOrNext} value.
   * @returns if buttons should be enabled or disabled
   */
  const navigationModalButtonClickable = (
    previousOrNext: 'previous' | 'next'
  ) => {
    if (currentSelectedVM === undefined || vmsSnapshot?.data === undefined) {
      return false;
    }

    const currentIndex = vmsSnapshot.data.findIndex((value, index) => {
      return value.name === currentSelectedVM.name;
    });
    if (previousOrNext === 'previous' && currentIndex <= 0) {
      return false;
    }
    if (
      previousOrNext === 'next' &&
      currentIndex >= vmsSnapshot.data.length - 1
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Block className={ClassUtilities.flatten('VMBlock', props.className)}>
        <Title level={2}>{t('vm_full_details')}</Title>
        {vmsSnapshot.state === PromiseState.Succeeded ? (
          vmsSnapshot.data !== undefined && vmsSnapshot.data.length !== 0 ? (
            <div className="vm-grid">
              <div className="vm-grid__title">{t('name')}</div>
              {/* <div className="vm-grid__title">{t('instance_type')}</div> */}
              <div className="vm-grid__title">vCPU</div>
              <div className="vm-grid__title">RAM</div>
              <div className="vm-grid__title">SSD</div>
              <div className="vm-grid__title">SSH</div>
              {VmsGridCells}
            </div>
          ) : (
            <div className="italic text-smd text-grey-500">
              {t('no_vm_for_this_env')}
            </div>
          )
        ) : vmsSnapshot.state === PromiseState.Failed ? (
          <div className="text-gray-500 text-smd italic">
            {t('vm_impossible_to_load')}
          </div>
        ) : (
          <LoadingBlock />
        )}
      </Block>

      <Modal
        open={currentSelectedVM !== undefined}
        onClose={() => setCurrentlySelectedVM(undefined)}
        fixedWidth={'700px'}
      >
        {currentSelectedVM !== undefined && (
          <div className="flex flex-col gap-4 px-4 py-2 w-full">
            <div className="grid grid-cols-2 items-center -mx-2">
              <div className="flex">
                <IconButton
                  size="small"
                  onClick={() => changeCurrentVM('previous')}
                  disabled={!navigationModalButtonClickable('previous')}
                >
                  <i className="icon-arrow-left"></i>
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => changeCurrentVM('next')}
                  disabled={!navigationModalButtonClickable('next')}
                >
                  <i className="icon-arrow-right"></i>
                </IconButton>
                <div className="min-w-4"></div>
                <Title level={3}>{t('vm_detail')}</Title>
              </div>
              <IconButton
                onClick={() => setCurrentlySelectedVM(undefined)}
                className="justify-self-end"
              >
                <i className="icon-x"></i>
              </IconButton>
            </div>
            <div
              className={`vm-details-area ${
                currentSelectedVM.description.length === 0
                  ? 'vm-details-area--no-description'
                  : ''
              }`}
            >
              <div className="vm-name">
                <DataTile
                  label={t('name')}
                  value={currentSelectedVM.name}
                ></DataTile>
              </div>
              {/* <div className="vm-instance-type">
                <DataTile
                  label={t('instance_type')}
                  value={currentSelectedVM.instanceType}
                ></DataTile>
              </div> */}
              {currentSelectedVM.description.length > 0 && (
                <div className="vm-description">
                  <DataTile
                    label={t('description')}
                    value={
                      <div className="max-w-prose text-justify">
                        {currentSelectedVM.description}
                      </div>
                    }
                  ></DataTile>
                </div>
              )}
              <div className="vm-ssh">
                <DataTile
                  label={'SSH'}
                  value={
                    <div className="flex items-center gap-2 min-h-[3rem]">
                      <div className="font-mono text-sm bg-grey-200 border-grey-300 border rounded-md px-2 py-1 w-full max-w-32 overflow-x-auto whitespace-nowrap">
                        {currentSelectedVM.sshCommand}
                      </div>
                      <IconButton
                        onClick={() =>
                          copy(
                            currentSelectedVM.sshCommand,
                            currentSelectedVM.name
                          )
                        }
                        size="small"
                      >
                        <i className="icon-copy"></i>
                      </IconButton>
                    </div>
                  }
                ></DataTile>
              </div>
              <div className="vm-vcpu">
                <DataTile
                  label={'vCPU'}
                  value={currentSelectedVM.vcpu}
                ></DataTile>
              </div>
              <div className="vm-ram">
                <DataTile
                  label={'RAM'}
                  value={NumberFormatter.toGB(currentSelectedVM.ram * 1000000)}
                ></DataTile>
              </div>
              <div className="vm-disk">
                <DataTile
                  label={'SSD'}
                  value={NumberFormatter.toGB(currentSelectedVM.disk * 1000000)}
                ></DataTile>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

function generateGridCells(
  vms: VirtualMachine[],
  copy: (val: string, name: VirtualMachine['name']) => void,
  setCurrentlySelectedVM: (vm: VirtualMachine | undefined) => void
): React.ReactElement[] {
  const cells: React.ReactElement[] = [];

  if (vms.length === 0) return cells;

  for (const vm of vms.slice(0, -1)) {
    const row = generateRow(vm, copy, setCurrentlySelectedVM);
    row.forEach((cell) => cells.push(cell));
    cells.push(<hr key={`${vm.name}-separator`} className="col-span-full" />);
  }

  const row = generateRow(unwrap(_.last(vms)), copy, setCurrentlySelectedVM);
  row.forEach((cell) => cells.push(cell));

  return cells;
}

function generateRow(
  vm: VirtualMachine,
  copy: (val: string, name: VirtualMachine['name']) => void,
  setCurrentlySelectedVM: (vm: VirtualMachine | undefined) => void
): React.ReactElement[] {
  const row: React.ReactElement[] = [];
  row.push(
    <div key={`${vm.name}-name`} className="whitespace-nowrap">
      {vm.name}
    </div>
  );
  // row.push(<div key={`${vm.name}-instance-type`}>{vm.instanceType}</div>);
  row.push(<div key={`${vm.name}-vcpu`}>{vm.vcpu}</div>);
  row.push(
    <div key={`${vm.name}-ram`}>{NumberFormatter.toGB(vm.ram * 1000000)}</div>
  );
  row.push(
    <div key={`${vm.name}-ssd`}>{NumberFormatter.toGB(vm.disk * 1000000)}</div>
  );
  row.push(
    <div
      key={`${vm.name}-ssh`}
      className="cell whitespace-nowrap flex justify-between"
    >
      <IconButton onClick={() => copy(vm.sshCommand, vm.name)}>
        <i className="icon-copy"></i>
      </IconButton>
      <IconButton onClick={() => setCurrentlySelectedVM(vm)}>
        <i className="icon-info"></i>
      </IconButton>
    </div>
  );

  return row;
}

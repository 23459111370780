import { useEffect } from 'react';
import { useUsersStore } from '../stores/usersStore';

export function useUsers() {
  const { snapshot, fetch } = useUsersStore();
  useEffect(() => {
    fetch();
  }, [fetch]);

  return snapshot;
}

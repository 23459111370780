import { FormHelperText } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ProjectApi,
  ProjectSnapshot,
  ProjectSnapshots,
} from '../../../../../api/projectApi';
import { UnhandledSwitchCaseException } from '../../../../../exceptions/unhandledSwitchCaseException';
import { EnvironmentStatus } from '../../../../../models/environmentStatus';
import { useProjectEnvironmentsStore } from '../../../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../../../utilities/assertions';
import { DateFormatter } from '../../../../../utilities/dateFormatter';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../../utilities/promiseSnapshot';
import { uppercaseFirstLetter } from '../../../../../utilities/stringUtilities';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../../../atoms/LoadingBlock/LoadingBlock';
import { FormRadio } from '../../../../atoms/Radio/FormRadio';
import { FormSingleSelect } from '../../../../atoms/SingleSelect/FormSingleSelect';
import { SingleSelect } from '../../../../atoms/SingleSelect/SingleSelect';
import { DataSource } from '../../dataSource';
import { Schema } from '../../schema';

export interface DataDumpPickerProps {
  control: Control<Schema, unknown>;
  watch: UseFormWatch<Schema>;
  setValue: UseFormSetValue<Schema>;
  errors: Partial<FieldErrors<Schema>>;
  clearErrors: UseFormClearErrors<Schema>;
}

export function DataDumpPicker(props: DataDumpPickerProps) {
  const { t } = useTranslation();
  const dataSource = props.watch('dataSource');
  const { clearErrors } = props;
  const projectId = useProjectEnvironmentsStore((state) => state.projectId);
  const environments = useProjectEnvironmentsStore(
    (state) => state.environments.data
  )?.filter((env) => env.status === EnvironmentStatus.Running);
  const abortController = useRef(new AbortController());
  const [snapshotsSnapshot, setSnapshotsSnapshot] = useState(
    new PromiseSnapshot<ProjectSnapshots>()
  );
  const backupIds = props.watch('backupIds');
  const setValue = props.setValue;

  useEffect(() => {
    switch (dataSource) {
      case DataSource.Backup:
        clearErrors('environmentSource');
        break;
      case DataSource.Environment:
        clearErrors('backupIds');
        break;
      default:
        throw new UnhandledSwitchCaseException(
          `DataSource ${dataSource} is not handled in switch case.`
        );
    }
  }, [dataSource, clearErrors]);

  useEffect(() => {
    if (projectId === undefined) return;

    PromiseSnapshot.trackPromiseSetter(
      () => new ProjectApi().getSnapshots(projectId),
      setSnapshotsSnapshot
    );

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [projectId]);

  // Initialize backupTypes
  useEffect(() => {
    if (snapshotsSnapshot.state !== PromiseState.Succeeded) return;

    setValue('backupTypes', Object.keys(unwrap(snapshotsSnapshot.data)));
  }, [setValue, snapshotsSnapshot.data, snapshotsSnapshot.state]);

  const backupIdsChanged = useCallback(
    (
      snapshotType: string,
      snapshotId: ProjectSnapshot['id'],
      onChange: (...event: unknown[]) => void
    ) => {
      const newBackupIds: Schema['backupIds'] =
        backupIds !== undefined ? Object.assign({}, backupIds) : {};
      newBackupIds[snapshotType] = snapshotId;
      onChange(newBackupIds);
    },
    [backupIds]
  );

  return (
    <div className="NewEnvironment__DataDumpPicker NewEnvironment__form-group__controls">
      <div className="xl:grid xl:grid-cols-2 gap-y-4 justify-items-stretch">
        <FormRadio
          control={props.control}
          name="dataSource"
          label={t('based_on_existing_env') as string}
          onValue={DataSource.Environment}
        />

        <div className="flex">
          <FormSingleSelect
            control={props.control}
            label="Environnement"
            disabled={
              dataSource !== DataSource.Environment ||
              environments === undefined
            }
            name="environmentSource"
            className="grow"
            options={
              environments?.map((env) => ({
                label: env.name,
                value: env.identifier,
              })) ?? []
            }
            loading={environments === undefined}
          />
        </div>
        <FormRadio
          control={props.control}
          name="dataSource"
          label={t('based_on_prod_backup') as string}
          onValue={DataSource.Backup}
          disabled={snapshotsSnapshot.state !== PromiseState.Succeeded}
        />

        <div className="flex flex-col">
          {snapshotsSnapshot.map({
            notStarted: 'running',
            running: () => <LoadingBlock text={t('retrieving_backups')} />,
            succeeded: (data) => (
              <Controller
                control={props.control}
                name="backupIds"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-2">
                    {Object.entries(unwrap(snapshotsSnapshot.data)).map(
                      ([snapshotType, snapshotInfo]) => (
                        <SingleSelect
                          key={`snapshot_${snapshotType}`}
                          name={`snapshot_${snapshotType}`}
                          label={uppercaseFirstLetter(
                            snapshotType.replace('_snap_id', '')
                          )}
                          error={props.errors.backupIds !== undefined}
                          options={snapshotInfo.map((snapshotElement) => ({
                            label: (
                              <div className="flex gap-[1em]">
                                <span>
                                  {DateFormatter.toDate(snapshotElement.date)}
                                </span>
                                <span className="text-grey-300">
                                  ({snapshotElement.id})
                                </span>
                              </div>
                            ),
                            value: snapshotElement.id,
                          }))}
                          onChange={(snapshotId) =>
                            backupIdsChanged(snapshotType, snapshotId, onChange)
                          }
                          value={value?.[snapshotType] ?? ''}
                          disabled={dataSource === DataSource.Environment}
                        />
                      )
                    )}
                    {error !== undefined && (
                      <FormHelperText
                        error
                        sx={{ marginLeft: 0, marginRight: 0 }}
                      >
                        {error?.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            ),
            failed: (err) => {
              console.error('Error while retrieving snapshot', err);
              return (
                <ErrorBlock noShadow>
                  {t('retrieving_backups_failed')}
                </ErrorBlock>
              );
            },
          })}
        </div>
      </div>
    </div>
  );
}

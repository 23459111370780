import { Path } from 'react-hook-form';
import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import { GitReferenceType } from '../../../models/gitReferenceType';
import { unwrap } from '../../../utilities/assertions';
import {
  GitReferenceInfo,
  GitReferenceSchemaHolderInterface,
} from './FormGitReferencePicker';

export function getGitReferenceSchema(t: WithTranslation['t']) {
  return z
    .object({
      type: z.enum([
        GitReferenceType.Branch,
        GitReferenceType.Tag,
        GitReferenceType.Hash,
      ]),
      branch: z.string(),
      tag: z.string(),
      hash: z
        .string()
        .regex(/^[A-Za-z0-9]*$/, t('hash_invalid_format'))
        .transform((val) => val.toLowerCase()),
    })
    .superRefine((data, ctx) => {
      const refFieldsMatching = new Map<
        GitReferenceType,
        keyof GitReferenceSchema
      >();
      refFieldsMatching.set(GitReferenceType.Branch, 'branch');
      refFieldsMatching.set(GitReferenceType.Tag, 'tag');
      refFieldsMatching.set(GitReferenceType.Hash, 'hash');

      const requiredRefFieldName = unwrap(refFieldsMatching.get(data.type));

      if (data[requiredRefFieldName] === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [requiredRefFieldName],
          message: t('required_field'),
        });
      }
    });
}

export type GitReferenceSchema = z.infer<
  ReturnType<typeof getGitReferenceSchema>
>;

export const gitReferenceSchemaDefaultValues: GitReferenceSchema = {
  type: GitReferenceType.Branch,
  branch: '',
  tag: '',
  hash: '',
};

/**
 * From a schema data respecting {@link GitReferenceSchemaHolderInterface}, extracts the git reference picked by the user.
 * @param data The schema data.
 * @returns The git reference.
 */
export function getGitReferenceInfo(
  data: GitReferenceSchemaHolderInterface
): GitReferenceInfo {
  const refFieldsMatching = new Map<
    GitReferenceType,
    Path<GitReferenceSchema>
  >();
  refFieldsMatching.set(GitReferenceType.Branch, 'branch');
  refFieldsMatching.set(GitReferenceType.Tag, 'tag');
  refFieldsMatching.set(GitReferenceType.Hash, 'hash');

  const reference = data.gitReference[
    unwrap(refFieldsMatching.get(data.gitReference.type))
  ] as string;

  return {
    referenceType: data.gitReference.type,
    reference,
  };
}

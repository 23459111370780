import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType, ActionTypeHandler } from '../../../../models/actionType';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';
import {
  MultipleSelect,
  MultiSelectActions,
} from '../../../atoms/MultipleSelect/MultipleSelect';

export interface ActionTypeSelectProps {
  actionTypesSnapshot: PromiseSnapshot<ActionType[]>;
  selectedActionTypeNames: ActionType['name'][];
  onSelectedActionTypeNamesChanged: (names: ActionType['name'][]) => void;
}

export const environmentQueryParam = 'environments[]';

export function ActionTypeSelect(props: ActionTypeSelectProps) {
  const { t } = useTranslation();

  const actionTypesSnapshot = props.actionTypesSnapshot;

  const actionTypeNamesAndLabel = useMemo(() => {
    const namesAndLabel = (actionTypesSnapshot.data ?? []).map((at) => ({
      name: at.name,
      label: new ActionTypeHandler(at).getDoneFriendlyName(),
    }));

    return _.sortBy(_.uniqBy(namesAndLabel, 'name'), 'label');
  }, [actionTypesSnapshot.data]);

  const selectedActionTypeNames = useMemo(() => {
    const names = actionTypeNamesAndLabel.map((el) => el.name);
    return props.selectedActionTypeNames.filter((el) => names.includes(el));
  }, [actionTypeNamesAndLabel, props.selectedActionTypeNames]);

  return (
    <MultipleSelect
      label={t('action')}
      name="action-type-filter"
      options={actionTypeNamesAndLabel.map((nameAndLabel) => ({
        label: nameAndLabel.label,
        value: nameAndLabel.name,
      }))}
      disabled={actionTypesSnapshot.data === undefined}
      values={selectedActionTypeNames}
      loading={actionTypesSnapshot.data === undefined}
      actions={[MultiSelectActions.Clear, MultiSelectActions.Close]}
      onChange={(values) => {
        props.onSelectedActionTypeNamesChanged(values);
      }}
    />
  );
}

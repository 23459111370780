import {
  Box,
  Chip,
  createTheme,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  styled,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Button } from '../Button/Button';

export enum MultiSelectActions {
  Clear = 'clear',
  Close = 'close',
}

export interface Option<T> {
  label: string;
  value: T;
}

export interface MultipleSelectProps<T> {
  name: string;
  className?: string;
  options: Option<T>[];
  label: string;
  values: T[];
  onChange?: (values: T[]) => void;
  disabled?: boolean;
  loading?: boolean;
  actions?: MultiSelectActions[];
}

export function MultipleSelect<T extends Key>(props: MultipleSelectProps<T>) {
  const { t } = useTranslation();
  const valueToLabel = optionsToValueLabel(props.options);
  const actions = props.actions ?? [];
  const [open, setOpen] = useState(false);

  return (
    <FormControl
      className={ClassUtilities.flatten(
        'MultipleSelect min-w-80',
        props.className
      )}
    >
      <ThemeProvider theme={overrideInputLabelTheme}>
        <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
      </ThemeProvider>
      <StyledSelect
        IconComponent={
          props.loading
            ? () => (
                <div className="w-6 h-6 mr-2 shrink-0 flex items-center justify-center">
                  <i className="icon-loader text-grey-500 animate-spin-slow" />
                </div>
              )
            : undefined
        }
        labelId={`${props.name}-label`}
        id="env-id"
        multiple
        onChange={(ev) => {
          props.onChange?.(ev.target.value as unknown as T[]);
        }}
        open={open}
        onClick={() => {
          if (open === false) {
            if (props.disabled !== true) {
              setOpen(true);
            }
          } else {
            setOpen(false);
            setTimeout(() => {
              (document.activeElement as HTMLDivElement).blur();
            }, 0);
          }
        }}
        size="small"
        value={props.values}
        name={props.name}
        disabled={props.disabled}
        input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
        renderValue={(selectedValues: T[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedValues.map((selectedValue) => (
              <Chip
                key={selectedValue}
                size="small"
                label={valueToLabel.get(selectedValue)}
              />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={(e) => e.stopPropagation()}
          >
            {option.label}
          </MenuItem>
        ))}
        {actions.length > 0 && <Divider />}
        {actions.length > 0 && (
          <Box className="flex justify-end px-2 gap-2">
            {actions.includes(MultiSelectActions.Clear) && (
              <Button
                onClick={() => props.onChange?.([])}
                startIcon={<i className="icon-trash" />}
              >
                {t('reset').toUpperCase()}
              </Button>
            )}
            {actions.includes(MultiSelectActions.Close) && (
              <Button startIcon={<i className="icon-x" />}>
                {t('close').toUpperCase()}
              </Button>
            )}
          </Box>
        )}
      </StyledSelect>
    </FormControl>
  );
}

function optionsToValueLabel<T>(options: Option<T>[]): Map<T, string> {
  const map = new Map<T, string>();
  for (const option of options) {
    map.set(option.value, option.label);
  }
  return map;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const StyledSelect = (function () {
  const res = styled(Select)({
    minHeight: '40px',
    '& > .MuiSelect-select': {
      padding: '8px 16px 8px 7px',
    },
  }) as unknown as <T>(props: SelectProps<T>) => JSX.Element;
  return res;
})();

const overrideInputLabelTheme = function (baseTheme: Theme) {
  return createTheme({
    ...baseTheme,
    components: {
      ...baseTheme.components,
      MuiInputLabel: {
        ...baseTheme.components?.MuiInputLabel,
        styleOverrides: {
          root: {
            transform: 'translate(10px, 9px) scale(1)',
          },
          shrink: {
            transform: 'translate(14px, -8px) scale(0.75)',
          },
        },
      },
    },
  });
};

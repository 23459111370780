import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../../../../api/userApi';
import { useCurrentUserStore } from '../../../../../stores/currentUserStore';
import { useSnackbarStore } from '../../../../../stores/snackbarStore';
import { getServerUrl } from '../../../../../utilities/fetchUtilities';
import { Block } from '../../../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../../../atoms/Button/variations/RoundedButtonVariations';
import { FormImagePicker } from '../../../../atoms/FormImagePicker/FormImagePicker';
import { LoadingBlock } from '../../../../atoms/LoadingBlock/LoadingBlock';
import { FormTextField } from '../../../../atoms/TextField/FormTextField';
import { Title } from '../../../../atoms/Title/Title';
import { Schema, getSchema } from './schema';

export function IdBlock() {
  const { t } = useTranslation();
  const abortController = useRef(new AbortController());
  const [loading, setLoading] = useState(false);
  const [user, replaceUser] = useCurrentUserStore((state) => [
    state.user.data,
    state.replaceUser,
  ]);
  const snackbarHandlePromise = useSnackbarStore(
    (state) => state.handlePromise
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Schema>({
    defaultValues: {
      firstname: '',
      lastname: '',
      image: undefined,
    },
    resolver: zodResolver(getSchema(t)),
  });

  const onSubmit = async (data: Schema) => {
    setLoading(true);
    await snackbarHandlePromise(
      'user-id-update',
      async () => {
        //#region Image patch
        // -- Deletion
        if (data.image === null) {
          await UserApi.deleteProfilePicture('me', {
            abortController: abortController.current,
          });
        }
        // -- Patch
        else if (data.image !== undefined) {
          await UserApi.attachProfilePicture('me', data.image, {
            abortController: abortController.current,
          });
        }
        //#endregion

        //#region Textual information patch
        const user = await new UserApi().patch(
          'me',
          {
            firstname: data.firstname,
            lastname: data.lastname,
          },
          { abortController: abortController.current }
        );
        //#endregion

        // Prevent browser image caching troubles
        user.imgUri = user.imgUri ? user.imgUri + '?' + Date.now() : undefined;

        // Reset image value to unchanged.
        setValue('image', undefined);

        replaceUser(user);

        return user;
      },
      {
        getErrorMessage: () => t('info_edit_failure'),
        getLoadingMessage: () => t('info_edit_loading'),
        getSuccessMessage: () => t('info_edit_successful'),
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    const newAbortController = new AbortController();
    abortController.current = newAbortController;

    return () => {
      newAbortController.abort();
    };
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      setValue('firstname', user.firstname);
      setValue('lastname', user.lastname);
    }
  }, [setValue, user]);

  return (
    <Block className="IdBlock flex flex-col justify-between items-stretch gap-4">
      {user ? (
        <>
          <Title level={2} className="flex items-center gap-2">
            <span>{t('your_identity')}</span>
            <Tooltip title={t('your_identity_tooltip')}>
              <i className="icon-help-circle transition-opacity opacity-25 hover:opacity-100" />
            </Tooltip>
          </Title>
          <form className="flex flex-col gap-4 pb-2 md:pb-0">
            <div className="flex justify-center">
              <div className="flex flex-col grow items-stretch max-w-xs px-2">
                <FormImagePicker
                  control={control}
                  name="image"
                  initialURL={
                    user.imgUri ? getServerUrl() + user.imgUri : undefined
                  }
                />
                <div className="h-8" />
                <FormTextField
                  control={control}
                  label={t('firstname')}
                  type="text"
                  name="firstname"
                />
                <div className="h-4" />
                <FormTextField
                  control={control}
                  label={t('lastname')}
                  type="text"
                  name="lastname"
                />
              </div>
            </div>
            <div className="h-4"></div>
            <RoundedFilledBlueButton
              className="self-end"
              onClick={handleSubmit(onSubmit)}
              loading={loading}
              disabled={Object.keys(errors).length > 0 || loading}
            >
              {t('edit')}
            </RoundedFilledBlueButton>
          </form>
        </>
      ) : (
        <LoadingBlock />
      )}
    </Block>
  );
}

import { ThemeProvider } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './assets/css/global.css';
import { ErrorBoundary } from './components/atoms/ErrorBoundary/ErrorBoundary';
import { GlobalSnackbars } from './components/organisms/GlobalSnackbars/GlobalSnackbars';
import theme from './muiTheme';
import { Routing } from './Routing';

function App() {
  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <Routing></Routing>
            <GlobalSnackbars />
          </ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default App;

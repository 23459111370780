import { NetworkRequestException } from '../../../exceptions/networkRequestException';

export function errorToTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 429:
        return 'toggle_maintenance_already_occurring';
      case 409:
        return exception.messageKey ?? 'toggle_maintenance_unknown_error';
    }
  }

  return 'toggle_maintenance_unknown_error';
}

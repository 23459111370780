import { zodResolver } from '@hookform/resolvers/zod';
import { FormGroup, FormLabel } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ProjectApi } from '../../../../../api/projectApi';
import { ProjectAddUserException } from '../../../../../exceptions/app/projectAddUserException';
import { NetworkRequestException } from '../../../../../exceptions/networkRequestException';
import { useTranslationFormatter } from '../../../../../hooks/useTranslationFormatter';
import { Project } from '../../../../../models/project';
import { UserProjectRoleType } from '../../../../../models/userProjectRole';
import { RoundedFilledBlueButton } from '../../../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { FormExclusiveButtonGroup } from '../../../../atoms/FormButtonGroup/FormButtonGroup';
import { Modal } from '../../../../atoms/Modal/Modal';
import { FormTextField } from '../../../../atoms/TextField/FormTextField';
import { Title } from '../../../../atoms/Title/Title';
import { getRoleInputOptions } from '../../../../shared/types/roleInputOptions';
import { Schema, schema } from './schema';

interface AddUserWithMailModalProps {
  project: Project;
  /**
   * Callback called when the user is done with this modal.
   * @param user The newly added user if one was added to the project. `null` otherwise.
   */
  onCompleted: (userAdded: boolean) => void;
}

export function AddUserWithMailModal(props: AddUserWithMailModalProps) {
  const { t, tFormatted } = useTranslationFormatter();

  const { control, handleSubmit } = useForm<Schema>({
    defaultValues: {
      email: '',
      role: UserProjectRoleType.Viewer,
    },
    resolver: zodResolver(schema),
  });

  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (data: Schema) => {
    setSubmitting(true);
    try {
      await new ProjectApi().addUser(props.project.id, data.email, data.role);
      props.onCompleted(true);
    } catch (e) {
      if (e instanceof ProjectAddUserException) {
        switch (e.details.type) {
          case 'role_not_allowed':
            setErrorMessage(
              t('project_add_user_role_not_allowed', {
                replace: { role: e.details.givenRole },
              })
            );
            break;
          case 'user_already_there':
            setErrorMessage(t('project_add_user_user_already_there'));
            break;
          case 'user_not_found':
            setErrorMessage(
              tFormatted(
                'project_add_user_user_not_found',
                { email: e.details.email },
                { email: { classes: 'font-semibold' } }
              )
            );
        }
      } else {
        setErrorMessage(
          e instanceof NetworkRequestException
            ? t(e.messageKey ?? '') ?? ''
            : `${e}`
        );
      }
    }
    setSubmitting(false);
  };

  return (
    <Modal open={true} onClose={() => props.onCompleted(false)}>
      <div className="flex flex-col gap-6 items-stretch">
        <div className="flex justify-between gap-2 items-center">
          <Title level={3} className="grow shrink flex items-center gap-[1ch]">
            <i className="icon-user-plus text-xl" />
            {t('add_a_user_to_project')}
          </Title>
        </div>
        {errorMessage && <ErrorBlock>{errorMessage}</ErrorBlock>}
        <div className="md:min-w-96 flex flex-col gap-4">
          <FormTextField
            className="w-full"
            control={control}
            name="email"
            label={t('email')}
          />
          <FormGroup>
            <FormLabel>{t('role')}</FormLabel>
            <FormExclusiveButtonGroup
              control={control}
              name="role"
              expandWidth
              options={getRoleInputOptions(t)}
            />
          </FormGroup>
        </div>
        <RoundedFilledBlueButton
          className="self-end"
          onClick={handleSubmit(onSubmit)}
          loading={submitting}
          disabled={submitting}
        >
          {t('add')}
        </RoundedFilledBlueButton>
      </div>
    </Modal>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectApi } from '../../../../api/projectApi';
import { UserProjectRoleApi } from '../../../../api/userProjectRoleApi';
import { useCheckAdmin } from '../../../../hooks/useCheckAdmin';
import { Project } from '../../../../models/project';
import { User } from '../../../../models/user';
import {
  UserProjectRole,
  UserProjectRoleHandler,
} from '../../../../models/userProjectRole';
import { useCurrentUserStore } from '../../../../stores/currentUserStore';
import { useSnackbarStore } from '../../../../stores/snackbarStore';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../utilities/promiseSnapshot';
import { RoundedFilledBlueButton } from '../../../atoms/Button/variations/RoundedButtonVariations';
import { FormCheckbox } from '../../../atoms/Checkbox/FormCheckbox';
import { FormExclusiveButtonGroup } from '../../../atoms/FormButtonGroup/FormButtonGroup';
import { LoadingBlock } from '../../../atoms/LoadingBlock/LoadingBlock';
import { Modal } from '../../../atoms/Modal/Modal';
import { Title } from '../../../atoms/Title/Title';
import { getRoleInputOptions } from '../../../shared/types/roleInputOptions';
import { UserManagementSchema, schema } from './userManagementSchema';

interface Props {
  user: User; // We need id and roles
  project: Project;
  onClose: () => void;
  onProjectChanged: (project: Project) => void;
}

export function RoleModal({ user, project, onClose, onProjectChanged }: Props) {
  const { t } = useTranslation();
  const [userRolesSnapshot, setUserRolesSnapshot] = useState<
    PromiseSnapshot<UserProjectRole[]>
  >(new PromiseSnapshot());
  const isAdmin = useCheckAdmin();
  const isEditingSelf =
    useCurrentUserStore((state) => state.user).getSucceededData().id ===
    user.id;
  const canEditProjectRole = !isEditingSelf || isAdmin;

  const abortController = useRef(new AbortController());
  const [submitted, setSubmitted] = useState<boolean>(false);
  const formMethods = useForm<UserManagementSchema>({
    defaultValues: {
      role: '',
      userId: user.id,
      projectId: project.id,
      isContactProject: false,
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit, control, setValue, watch } = formMethods;
  const selectedRole = watch('role');

  const snackbarPromiseHandle = useSnackbarStore(
    (state) => state.handlePromise
  );

  useEffect(() => {
    setValue('projectId', project.id);
  }, [project, setValue]);

  useEffect(() => {
    // Get current roles for this user for ALL projects
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new UserProjectRoleApi().getAll({
          'targeted_user[]': [user.id],
        }),
      setUserRolesSnapshot
    );
  }, [user, setValue]);

  useEffect(() => {
    const oldAbortController = abortController.current;

    return () => {
      oldAbortController.abort();
      abortController.current = new AbortController();
    };
  }, []);

  useEffect(() => {
    if (!userRolesSnapshot.isSucceeded() || selectedRole !== '') return;
    const roles =
      userRolesSnapshot.data?.filter((value) => {
        return value.project === project.id;
      }) ?? [];

    const roleValues = roles.map((role) => role.role);
    if (roleValues.length > 0) {
      setValue(
        'role',
        UserProjectRoleHandler.getMostImportantRoleType(roleValues)
      );
    }
  }, [userRolesSnapshot, project, setValue, selectedRole]);

  useEffect(() => {
    if (project.contact === user.id) {
      setValue('isContactProject', true);
    }
  }, [project, user, setValue]);

  const onSubmit = async (data: UserManagementSchema) => {
    // prevents other clicks while posting
    setSubmitted(true);

    await snackbarPromiseHandle<null>(
      'admin_update_user_project_role',
      async () => {
        await new UserProjectRoleApi().post(data, {
          abortController: abortController.current,
        });

        if (data.isContactProject !== (project.contact === user.id)) {
          const project = await new ProjectApi().patchContact(
            data.projectId,
            data.isContactProject ? user.id : null,
            {
              abortController: abortController.current,
            }
          );
          onProjectChanged(project);
        }

        return null;
      },
      {
        getErrorMessage: (error) =>
          t('project_user_management_update_error', { error }),
        getLoadingMessage: () =>
          t('project_user_management_update_in_progress'),
        getSuccessMessage: () => t('project_user_management_update_succeeded'),
      },
      {
        abortController: abortController.current,
        autoHideSuccessDuration: 3000,
      }
    );

    setSubmitted(false);
    onClose();
    return;
  };

  return (
    <>
      <Modal open={true} onClose={() => onClose()}>
        <div className="flex flex-col gap-4 px-4 py-2 w-full">
          <div className="grid grid-cols-2 items-center -mx-2">
            <div className="flex">
              <div className="min-w-2"></div>
              <Title level={3}>{t('edit_role')}</Title>
            </div>
            <IconButton onClick={() => onClose()} className="justify-self-end">
              <i className="icon-x"></i>
            </IconButton>
          </div>

          {userRolesSnapshot.state === PromiseState.Succeeded ? (
            <>
              <div className="">{t('edit_role_explanation')}</div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormExclusiveButtonGroup
                  name="role"
                  control={control}
                  options={getRoleInputOptions(t)}
                  disabled={!canEditProjectRole}
                />
                {!canEditProjectRole && (
                  <FormHelperText className="flex gap-[0.5em] items-center">
                    <i className="icon-info" />
                    {t('you_cannot_lower_your_own_role')}
                  </FormHelperText>
                )}
                <div className="h-4"></div>
                <FormCheckbox
                  control={control}
                  name="isContactProject"
                  label={t('project_contact_user')}
                />
                <div className="flex justify-end">
                  <RoundedFilledBlueButton
                    color="info"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitted}
                  >
                    {t('edit')}
                  </RoundedFilledBlueButton>
                </div>
              </form>
            </>
          ) : (
            <>
              <LoadingBlock></LoadingBlock>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

import { create } from 'zustand';
import { ProjectApi } from '../api/projectApi';
import { Environment } from '../models/environment';
import { Estimate } from '../models/estimate';
import { Project } from '../models/project';
import { asMapFunc } from '../utilities/array';
import { fillOptions } from '../utilities/options';
import { PromiseSnapshot, PromiseState } from '../utilities/promiseSnapshot';

/**
 * The options for the {@link EstimatesStoreState.fetch} function.
 */
export interface FetchOptions {
  /**
   * Should the estimates be fetched no matter what. Default to `false`.
   */
  force: boolean;
}

const fetchOptionsDefaults: FetchOptions = {
  force: false,
};

export type Estimates = Map<Environment['id'], Estimate>;

export interface EstimatesStoreState {
  /**
   * The {@link Project} id of the fetched estimates.
   */
  _projectId: Project['id'] | undefined;
  /**
   * The {@link AbortController} associated to the fetch.
   */
  _abortController: AbortController | null;
  /**
   * The snapshot for the {@link Estimates}.
   */
  snapshot: PromiseSnapshot<Estimates>;
  /**
   * Fetches the {@link Estimates}, the result of the fetch is available through the {@link snapshot}.
   * @param projectId The {@link Project} id.
   * @param options The options. See {@link FetchOptions}.
   */
  fetch: (projectId: Project['id'], options?: Partial<FetchOptions>) => void;
}

export const useEstimatesStore = create<EstimatesStoreState>(
  (set, get): EstimatesStoreState => {
    return {
      _projectId: undefined,
      _abortController: null,
      snapshot: new PromiseSnapshot(),
      fetch(projectId, options) {
        const { force } = fillOptions(options, fetchOptionsDefaults);

        const oldProjectId = get()._projectId;
        const snapshot = get().snapshot;

        // Don't fetch again if promise is running or succeeded
        if (
          !force &&
          oldProjectId === projectId &&
          (snapshot.state === PromiseState.Running ||
            snapshot.state === PromiseState.Succeeded)
        )
          return;

        get()._abortController?.abort();

        const abortController = new AbortController();
        const tmpPromiseSnapshot = PromiseSnapshot.buildRunning<Estimates>();

        set({
          _projectId: projectId,
          _abortController: abortController,
          snapshot: tmpPromiseSnapshot,
        });

        PromiseSnapshot.trackPromiseSetter(
          async () => {
            const estimates = await new ProjectApi().getEstimates(projectId, {
              abortController,
            });
            return asMapFunc(estimates, (estimate) => estimate.environment.id);
          },
          (snapshot) => set({ snapshot }),
          { abortController }
        );
      },
    };
  }
);

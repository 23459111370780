import { create } from 'zustand';
import { LoadingScreenProps } from '../components/atoms/LoadingScreen/LoadingScreen';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LoadingScreen } from '../components/atoms/LoadingScreen/LoadingScreen';

export type LoadingScreenStoreState = LoadingScreenProps;

/**
 * A store handling the props of the global {@link LoadingScreen}.
 * Change directly the store data to affect the loading screen.
 *
 * @example
 * // Opening the loading screen
 * useLoadingScreenStore.setState({
 *   opened: true,
 *   title: t('loading_projects'),
 * });
 *
 * // Closing the loading screen
 * useLoadingScreenStore.setState({
 *   opened: false,
 * });
 */
export const useLoadingScreenStore = create<LoadingScreenStoreState>(
  (set, get) => ({
    opened: false,
  })
);

import { Tooltip, TooltipProps } from '@mui/material';

export interface InformationTooltipProps {
  info: TooltipProps['title'];
}

export function InformationTooltip(props: InformationTooltipProps) {
  return (
    <Tooltip title={props.info}>
      <i className="icon-info opacity-25 hover:opacity-100 transition-opacity" />
    </Tooltip>
  );
}

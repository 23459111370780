import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ProjectApi } from '../../../../api/projectApi';
import { Project } from '../../../../models/project';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';

/**
 * Gets the project to edit
 * @returns The {@link Project} to edit
 */
export function useProject(): PromiseSnapshot<Project> {
  const { projectId } = useParams();
  const projectFromState = useLocation().state?.project;
  const [projectSnapshot, setProjectSnapshot] = useState(
    new PromiseSnapshot<Project>()
  );
  const abortControllerRef = useRef(new AbortController());

  // Fetch company if necessary. But try to retrieve it from the state first.
  useEffect(() => {
    if (projectId === undefined) {
      console.warn('Cannot find projectId in URL.');
      return;
    }

    if (projectFromState !== undefined) {
      setProjectSnapshot(
        PromiseSnapshot.buildSucceeded(projectFromState as Project)
      );
      return;
    }

    const abortController = abortControllerRef.current;
    PromiseSnapshot.trackPromiseSetter(
      () => new ProjectApi().get(projectId, { abortController }),
      setProjectSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [projectFromState, projectId]);

  return projectSnapshot;
}

import { useEffect, useRef, useState } from 'react';
import { EnvironmentApi } from '../../../../api/environmentApi';
import {
  Environment,
  PartialEnvironment,
} from '../../../../models/environment';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';

export function useCompleteEnvironment(
  partialEnvironment: PartialEnvironment
): PromiseSnapshot<Environment> {
  const abortControllerRef = useRef(new AbortController());
  const [snapshot, setSnapshot] = useState(new PromiseSnapshot<Environment>());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new EnvironmentApi().get(partialEnvironment.id, { abortController }),
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [partialEnvironment.id]);

  return snapshot;
}

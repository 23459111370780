import { generatePath } from 'react-router-dom';
import { rawEnvironmentRoute } from '../Routing';
import { useProjectEnvironmentsStore } from '../stores/projectEnvironmentsStore';

/**
 * Gets the route portion associated with the current environment.
 */
export function useEnvironmentRoute() {
  const environmentIdString =
    useProjectEnvironmentsStore((state) => state.getEnvironment())?.id ??
    'none';

  return generatePath(rawEnvironmentRoute, {
    environmentId: environmentIdString,
  });
}

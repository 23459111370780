import { Company } from '../models/company';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import {
  AbortOptions,
  AbortOptionsDefault,
  postJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const {
  REACT_APP_ROUTE_COMPANIES,
  REACT_APP_ROUTE_COMPANIES_SET_PROFILE_PICTURE,
} = process.env;

export interface GetOptions {
  page?: number;
  name?: string;
  id?: Company['id'];
}

class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      name: ParamMappingHelper.identity,
      id: ParamMappingHelper.identity,
    };
  }
}

export type PostArgs = {
  img?: File;
  name: string;
  legalStatus?: string;
  address?: string;
  zipCode?: string;
  country?: string;
  city?: string;
  billingContactFullname?: string;
};

export type PatchArgs = Omit<PostArgs, 'img'>;

export class CompanyApi extends BaseApi<
  Company,
  GetOptions,
  Company,
  PostArgs,
  PatchArgs
> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_COMPANIES, 'Companies route not defined'),
      GetOptionsHandler,
      { allowDelete: false }
    );
  }

  transformPostArgs(postArgs: PostArgs) {
    const formPayload = new FormData();
    if (postArgs.img !== undefined) {
      formPayload.append('img', postArgs.img);
    }

    formPayload.append('name', postArgs.name);
    formPayload.append('legalStatus', postArgs.legalStatus ?? '');
    formPayload.append('address', postArgs.address ?? '');
    formPayload.append('zipCode', postArgs.zipCode ?? '');
    formPayload.append('country', postArgs.country ?? '');
    formPayload.append('city', postArgs.city ?? '');
    formPayload.append(
      'billingContactFullname',
      postArgs.billingContactFullname ?? ''
    );

    return formPayload;
  }

  transformGottenPartialEntity(entity: Company): Company {
    return this.transformGottenEntity(entity);
  }

  transformGottenEntity(entity: Company): Company {
    entity.users = entity.users.map((user) => BaseApi.getIdFromIri(user));
    return entity;
  }

  /**
   * Changes the profile picture of the company.
   * @param id The {@link Company} id.
   * @param imgFile The new image file or `null` if it's a removal request.
   * @param options See {@link AbortOptions}.
   * @returns The updated {@link Company.}
   */
  static changePicture(
    id: Company['id'],
    imgFile: File | null,
    options?: Partial<AbortOptions>
  ): Promise<Company> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_COMPANIES_SET_PROFILE_PICTURE,
      'Company set profile picture route not defined'
    );

    const formPayload = new FormData();
    if (imgFile === null) {
      formPayload.append('delete', 'true');
      formPayload.append('img', '');
    } else {
      formPayload.append('delete', 'false');
      formPayload.append('img', imgFile);
    }

    return postJsonFromServerRoute(route, {
      abortController: filledOptions.abortController,
      args: { id },
      formPayload,
    });
  }
}

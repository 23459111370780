import { Tooltip } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { UnhandledSwitchCaseException } from '../../../../../../exceptions/unhandledSwitchCaseException';
import { Bill, BillStatus } from '../../../../../../models/bill';
import {
  NumberFormatter,
  RoundMethods,
} from '../../../../../../utilities/numberFormatter';

export interface PaymentStatusProps {
  bill: Bill;
}

export function PaymentStatus(props: PaymentStatusProps) {
  const { t } = useTranslation();
  const { tooltipTitle, iconClassName } = getTooltipTitleAndIconClasses(
    props.bill,
    t
  );

  return (
    <Tooltip className="PaymentStatus" title={tooltipTitle}>
      <i
        className={
          iconClassName +
          ' transform translate-y-[0.0625em] cursor-help transition-transform hover:scale-125'
        }
      />
    </Tooltip>
  );
}

export function getTooltipTitleAndIconClasses(
  bill: Bill,
  t: TFunction<'translation', undefined>
): {
  tooltipTitle: string;
  iconClassName: string;
} {
  // Paid
  switch (bill.status) {
    case BillStatus.New:
    case BillStatus.Sent:
      return {
        tooltipTitle: t('bill_status_unpaid'),
        iconClassName: 'icon-x text-pink-500',
      };
    case BillStatus.PartiallyPaid:
      return {
        tooltipTitle: t('bill_status_partially_paid', {
          remaining: NumberFormatter.toEuro(
            bill.totalInclTax - bill.totalPaid,
            { roundMethod: RoundMethods.Ceil }
          ),
        }),
        iconClassName: 'icon-triangle text-orange',
      };
    case BillStatus.Paid:
      return {
        tooltipTitle: t('bill_status_paid'),
        iconClassName: 'icon-circle text-deepgreen-500',
      };
    default:
      throw new UnhandledSwitchCaseException();
  }
}

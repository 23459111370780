import { Project } from '../models/project';
import { User } from '../models/user';
import { Uuid } from '../models/uuid';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import {
  getJsonFromServerRoute,
  postJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const {
  REACT_APP_ROUTE_USER_ME,
  REACT_APP_ROUTE_USERS,
  REACT_APP_ROUTE_USER_SET_PROFILE_PICTURE,
  REACT_APP_ROUTE_SSO_CONNECT,
} = process.env;

export interface GetOptions {
  page?: number;
  id?: string;
  'company.name'?: string;
  full_name?: string;
  project_id?: Project['id'];
}
class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      id: ParamMappingHelper.identity,
      'company.name': ParamMappingHelper.identity,
      full_name: ParamMappingHelper.identity,
      project_id: ParamMappingHelper.identity,
    };
  }
}

export class UserApi extends BaseApi<
  User,
  GetOptions,
  User,
  null,
  Partial<Pick<User, 'firstname' | 'lastname'>>
> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_USERS, 'User route not defined'),
      GetOptionsHandler
    );
  }

  static async getMe(): Promise<User> {
    return getJsonFromServerRoute(
      unwrap(REACT_APP_ROUTE_USER_ME)
    ) as Promise<User>;
  }

  /**
   * @returns The route to connect through the SSO.
   * @param redirectUri The redirect URI, if not defined will redirect to the current location.
   * @details This route contains a redirect link to the current location.
   */
  static getSSORoute(redirectUri?: string): string {
    return unwrap(
      REACT_APP_ROUTE_SSO_CONNECT,
      'SSO connect route is not defined'
    ).replace(
      '{redirect_uri}',
      encodeURIComponent(redirectUri ?? window.location.href)
    );
  }

  /**
   * Attaches an image to an user.
   * @param id The id of the {@link User} to change.
   * @param imgFile The file of the image.a
   * @param options See {@link AbortControllerOptions}
   * @returns The user updated information.
   */
  static async attachProfilePicture(
    id: User['id'] | 'me',
    imgFile: File,
    options?: Partial<AbortControllerOptions>
  ): Promise<User> {
    const filledOptions = fillOptions(options, abortControllerOptionsDefault);
    const route = unwrap(REACT_APP_ROUTE_USER_SET_PROFILE_PICTURE);
    const formPayload = new FormData();
    formPayload.append('img', imgFile);
    formPayload.append('delete', 'false');

    return await postJsonFromServerRoute(route, {
      method: 'POST',
      abortController: filledOptions.abortController,
      args: {
        id,
      },
      formPayload,
    });
  }

  /**
   * Deletes the profile picture attached to the user.
   * @param id The id of the {@link User} to delete.
   * @param options See {@link AbortControllerOptions}
   * @returns The user updated information.
   */
  static deleteProfilePicture(
    id: Uuid | 'me',
    options?: Partial<AbortControllerOptions>
  ): Promise<User> {
    const filledOptions = fillOptions(options, abortControllerOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_USER_SET_PROFILE_PICTURE,
      'User set profile picture route not defined.'
    );
    const formPayload = new FormData();
    formPayload.append('delete', 'true');
    formPayload.append('img', '');

    return postJsonFromServerRoute(route, {
      method: 'POST',
      abortController: filledOptions.abortController,
      args: {
        id,
      },
      formPayload,
    });
  }
}

/**
 * Options for aborting a pending request.
 */
export interface AbortControllerOptions {
  /**
   * A controller containing a method to abort the asynchronous tasks using it.
   */
  abortController: AbortController | null;
}

export const abortControllerOptionsDefault: AbortControllerOptions = {
  abortController: null,
};

import { StoreApi, UseBoundStore } from 'zustand';

/**
 * Subscribes to a store for one change and then returns the store state once it has mutated.
 * @description The function automatically unsubscribes from the store state changes.
 * @param storeFunc The store function.
 * @returns The state of the store after a single update.
 */
export async function reactiveStoreState<T>(
  storeFunc: UseBoundStore<StoreApi<T>>
): Promise<T> {
  let unsubscribeFunc: (() => void) | undefined = undefined;

  const state = await new Promise<T>((resolve) => {
    unsubscribeFunc = storeFunc.subscribe((state) => resolve(state));
  });

  (unsubscribeFunc as unknown as () => void)();

  return state;
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BillApi } from '../../../../api/billApi';
import { Bill } from '../../../../models/bill';
import { useProjectsStore } from '../../../../stores/projectsStore';
import { ApiPlatformList } from '../../../../utilities/baseApi';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';

/**
 * @returns {@link Bill}s from the current project.
 */
export function useBills(): {
  paginatedBillsSnapshot: PromiseSnapshot<ApiPlatformList<Bill>>;
  page: number;
  setPage: (page: number) => void;
} {
  const projectId = useProjectsStore((state) => state.getProject()?.id);
  const [billsSnapshot, setBillsSnapshot] = useState(
    new PromiseSnapshot<ApiPlatformList<Bill>>()
  );
  const abortControllerRef = useRef(new AbortController());
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    const pageSearchParam = parseInt(searchParams.get('page') ?? '1');
    return isNaN(pageSearchParam) ? 1 : pageSearchParam;
  }, [searchParams]);

  const setPage = useCallback(
    (page: number) => {
      setSearchParams((prev) => {
        prev.set('page', page.toString());
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (projectId === undefined) return;

    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new BillApi().getAllPaginated(
          {
            'project[]': [projectId],
            page,
          },
          { abortController }
        ),
      setBillsSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [page, projectId]);

  return {
    paginatedBillsSnapshot: billsSnapshot,
    page,
    setPage,
  };
}

import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  MainPageTemplateHeaderContext,
  MainPageTemplateHeaderContextType,
} from '../MainPageTemplate/contexts/MainPageTemplateHeaderContext';

/**
 * Meant to be used as {@link Outlet} of {@link MainPageTemplate} component.
 */
export function MainPageHeaderSetter(props: MainPageHeaderSetterProps) {
  const { setHeader, setHeaderAlignBottom } = useContext(
    MainPageTemplateHeaderContext
  );

  useEffect(() => {
    setHeader(props.header);
    setHeaderAlignBottom(props.headerAlignBottom ?? false);
  }, [props.header, props.headerAlignBottom, setHeader, setHeaderAlignBottom]);

  return <Outlet></Outlet>;
}

export interface MainPageHeaderSetterProps {
  header: MainPageTemplateHeaderContextType['header'];
  headerAlignBottom?: MainPageTemplateHeaderContextType['headerAlignBottom'];
}

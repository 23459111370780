import { NetworkRequestException } from '../exceptions/networkRequestException';
import { assert } from './assertions';
import { fillOptions } from './options';
import { ResponseMiddlewareHandler } from './responseMiddleware';
import { RouteArgType, RouteUtilities } from './routeUtilities';
import { getToken } from './tokenUtilities';

export const responseMiddlewares = new ResponseMiddlewareHandler();

export type AbortOptions = {
  /**
   * The {@link AbortController} to abort the request (default to `null`).
   */
  abortController: AbortController | null;
};

export const AbortOptionsDefault: AbortOptions = { abortController: null };

export interface GetJsonOptions extends AbortOptions {
  /**
   * Arguments to the route. (default to `{}`)
   */
  args: Record<string, RouteArgType>;
  /**
   * Does the request needs to include credentials? (default to `true`)
   */
  includeCredentials: boolean;
}

const GetJsonOptionsDefaults: GetJsonOptions = {
  args: {},
  includeCredentials: true,
  ...AbortOptionsDefault,
};

export interface GetJsonExternalOptions
  extends Omit<GetJsonOptions, 'includeCredentials'> {
  /**
   * Does the request needs to include credentials? (default to `false`)
   */
  includeCredentials: boolean;
}

const GetJsonExternalOptionsDefault: GetJsonExternalOptions = {
  args: {},
  includeCredentials: false,
  ...AbortOptionsDefault,
};

export type SendMethod = 'POST' | 'PATCH' | 'DELETE';
export type ContentType =
  | 'application/merge-patch+json'
  | 'application/json'
  | 'multipart/form-data';

export interface PostJsonOptions extends GetJsonOptions {
  /**
   * Send method.
   */
  method: SendMethod;
  /**
   * Payload to send with the request. (default to `{}`)
   */
  payload: Record<string, unknown>;
  /**
   * Payload to send with the request. (With {@link FormData} format.)
   *
   * Warning: {@link payload} and {@link formPayload} must not be both defined (as only one of them will be sent).
   */
  formPayload: FormData | null;
  /**
   * Should the response be a Json? (default to `true`)
   */
  expectJsonResponse: boolean;
}

const PostJsonOptionsDefaults: PostJsonOptions = {
  method: 'POST',
  payload: {},
  formPayload: null,
  expectJsonResponse: true,
  ...GetJsonOptionsDefaults,
};

export interface BackendError {
  code: number;
  message: string;
  'hydra:description': string;
  messageKey: string;
}

/**
 * @returns The server URL.
 */
export function getServerUrl(): string {
  return process.env.REACT_APP_SERVER_ADDRESS as string;
}

/**
 * @param response The response that is not OK.
 * @returns A ready-to-throw exception based on backend response if available.
 */
export async function responseErrorToException(
  response: Response
): Promise<NetworkRequestException> {
  try {
    const responseContent = await response.json();
    const backendError = responseContent as Partial<BackendError> | undefined;

    if (backendError !== undefined) {
      return new NetworkRequestException(
        backendError.code ?? response.status,
        backendError.message ?? backendError['hydra:description'],
        backendError.messageKey
      );
    }
    // eslint-disable-next-line no-empty
  } catch (_e) {}
  return new NetworkRequestException(response.status);
}

/**
 * Performs a GET request to retrieve a JSON content.
 * @param url The url where the JSON content is requestable.
 * @param includeCredentials Does the request needs to include credentials?
 * @returns The JSON result resulting from the request send.
 * @throws NetworkRequestException if the response to the request is not ok.
 */
export async function getJsonExternal<T>(
  urlModel: string,
  options?: Partial<GetJsonExternalOptions>
): Promise<T> {
  const filledOptions: GetJsonExternalOptions = fillOptions(
    options,
    GetJsonExternalOptionsDefault
  );
  const parameterizedUrl = RouteUtilities.construct(
    urlModel,
    filledOptions.args
  );
  return getJson(
    parameterizedUrl,
    filledOptions.includeCredentials,
    filledOptions.abortController,
    null
  );
}

/**
 * Performs a GET request to retrieve a JSON content.
 * @param url The url where the JSON content is requestable.
 * @param includeCredentials Does the request needs to include credentials?
 * @returns The JSON result resulting from the request send.
 * @throws NetworkRequestException if the response to the request is not ok.
 */
export async function getJson<T>(
  url: string,
  includeCredentials: boolean,
  abortController: AbortController | null,
  authHeader: string | null
): Promise<T> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (authHeader) {
    headers['Authorization'] = authHeader;
  }

  let res = await fetch(url, {
    method: 'GET',
    headers,
    credentials: includeCredentials ? 'include' : 'omit',
    signal: abortController?.signal,
  });
  res = await responseMiddlewares.execute(res);

  if (!res.ok) {
    throw await responseErrorToException(res);
  }
  return res.json();
}

/**
 * Performs a POST request with optionally associated JSON payload to retrieve a JSON content.
 * @param url The url where the JSON content is requestable.
 * @param payload The payload to send with the request.
 * @param expectJsonResponse Should the response be a Json?
 * @param includeCredentials Does the request needs to include credentials?
 * @returns The JSON result resulting from the request send.
 * @throws NetworkRequestException if the response to the request is not ok.
 */
export async function postJson<T>(
  url: string,
  method: SendMethod,
  payload: Record<string, unknown> | FormData,
  expectJsonResponse: boolean,
  includeCredentials: boolean,
  contentType: ContentType,
  abortController: AbortController | null,
  authHeader: string | null = null
): Promise<T> {
  const headers: HeadersInit = {};

  // In case this is a multipart/form-data this need to be set automatically by fetch.
  if (contentType !== 'multipart/form-data') {
    headers['Content-Type'] = contentType;
  }

  if (authHeader !== null) {
    headers['Authorization'] = authHeader;
  }

  let res = await fetch(url, {
    body: payload instanceof FormData ? payload : JSON.stringify(payload),
    method,

    headers,

    credentials: includeCredentials ? 'include' : 'omit',
    signal: abortController?.signal,
  });
  res = await responseMiddlewares.execute(res);

  if (!res.ok) {
    throw await responseErrorToException(res);
  }

  return expectJsonResponse ? res.json() : null;
}

/**
 * Fetches a GET request to the server.
 * @param routeModel The route model as defined in {@link RouteUtilities.construct}.
 * @param options {@link GetJsonOptions}
 * @returns The object returned from the request if it has been successful.
 */
export async function getJsonFromServerRoute<T>(
  routeModel: string,
  options?: Partial<GetJsonOptions>
): Promise<T> {
  const filledOptions: GetJsonOptions = fillOptions(
    options,
    GetJsonOptionsDefaults
  );
  const parameterizedRoute = RouteUtilities.construct(
    routeModel,
    filledOptions.args
  );

  const url = getServerUrl() + parameterizedRoute;
  return getJson(
    url,
    filledOptions.includeCredentials,
    filledOptions.abortController,
    `Bearer ${getToken()}`
  );
}

/**
 * Fetches a POST request to the server.
 * @param routeModel The route model as defined in {@link RouteUtilities.construct}.
 * @param options {@link PostJsonOptions}
 * @returns The object returned from the request if it has been successful (may be `null` depending on the options).
 */
export async function postJsonFromServerRoute<T>(
  routeModel: string,
  options?: Partial<PostJsonOptions>
): Promise<T> {
  const filledOptions: PostJsonOptions = fillOptions(
    options,
    PostJsonOptionsDefaults
  );
  const parameterizedRoute = RouteUtilities.construct(
    routeModel,
    filledOptions.args
  );
  const url = getServerUrl() + parameterizedRoute;

  assert(
    !(
      Object.keys(filledOptions.payload).length > 0 &&
      filledOptions.formPayload !== null
    ),
    'postJsonFromServerRoute(): payload and formPayload should not be both filled'
  );

  //#region Determine content type
  let contentType: ContentType = 'application/json';
  if (filledOptions.formPayload !== null) {
    contentType = 'multipart/form-data';
  } else {
    if (filledOptions.method === 'PATCH') {
      contentType = 'application/merge-patch+json';
    }
  }
  //#endregion

  return postJson(
    url,
    filledOptions.method,
    filledOptions.formPayload ?? filledOptions.payload,
    filledOptions.expectJsonResponse,
    filledOptions.includeCredentials,
    contentType,
    filledOptions.abortController,
    `Bearer ${getToken()}`
  );
}

import { useEffect } from 'react';
import { Project } from '../models/project';
import { Estimates, useEstimatesStore } from '../stores/estimatesStore';
import { PromiseSnapshot } from '../utilities/promiseSnapshot';

/**
 * Get estimates for the given {@link projectId}, note that if the {@link projectId} is not given, then an empty (not started) snapshot will be returned.
 * @returns
 */
export function useEstimates(
  projectId: Project['id'] | undefined
): PromiseSnapshot<Estimates> {
  const { snapshot, fetch } = useEstimatesStore();

  useEffect(() => {
    if (projectId === undefined) return;
    fetch(projectId);
  }, [fetch, projectId]);

  return projectId === undefined ? new PromiseSnapshot() : snapshot;
}

import {
  UserProjectRoleHandler,
  UserProjectRoleType,
} from '../models/userProjectRole';
import { useProjectRoles } from './useProjectRoles';

/**
 * @returns The user most relevant project role (if the context allows to retrieve his roles, see {@link useProjectRoles} documentation for more details about this).
 * Can return `null` if user have no rights on this project.
 */
export function useMostRelevantProjectRole():
  | UserProjectRoleType
  | undefined
  | null {
  const roles = useProjectRoles();
  if (roles === undefined) return undefined;
  if (roles.length === 0) return null;
  return UserProjectRoleHandler.getMostImportantRoleType(roles);
}

import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { ClassUtilities } from '../../../utilities/classUtility';
import { SingleSelect, SingleSelectProps } from './SingleSelect';

export interface FormSingleSelectProps<TFieldValues extends FieldValues>
  extends Omit<
    SingleSelectProps<PathValue<TFieldValues, Path<TFieldValues>>>,
    'name' | 'onChange' | 'value' | 'error' | 'helperText'
  > {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, unknown>;
}

export function FormSingleSelect<TFieldValues extends FieldValues>(
  props: FormSingleSelectProps<TFieldValues>
) {
  const { name, control, className, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={ClassUtilities.flatten('flex flex-col', className)}>
          <SingleSelect
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
            {...fieldProps}
          />
        </div>
      )}
    />
  );
}

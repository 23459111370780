import { Navigate } from 'react-router-dom';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';

/**
 * Properties for {@link Require}.
 */
export interface RequireProps {
  /**
   * The function deciding whether the user has enough rights to see {@link children} or not.
   * `undefined` means this decision cannot be made immediatly (a loader will be displayed while the result of the function stays `undefined`).
   */
  checkPredicate: (() => boolean | undefined) | (boolean | undefined);
  /**
   * The children to display if the user has sufficient rights.
   */
  children: JSX.Element;
  /**
   * The redirection path to navigate to if the user does not have sufficient rights.
   */
  redirect: string;
}

/**
 * Ensures the user has admin rights to see the page.
 * @param props See {@link RequireProps}
 */
export function Require(props: RequireProps): JSX.Element {
  const res =
    typeof props.checkPredicate === 'function'
      ? props.checkPredicate()
      : props.checkPredicate;

  if (res === undefined) {
    return <LoadingBlock />;
  } else if (res) {
    return props.children;
  } else {
    return <Navigate to={props.redirect} replace />;
  }
}

import { Tooltip } from '@mui/material';
import { User } from '../../../../models/user';
import {
  USER_GROUP_ELEMENT_OVERLAP,
  USER_GROUP_ELEMENT_SIZE,
} from './UserGroupElement';

/**
 * Props for {@link UserGroupRemaining}.
 */
export interface UserGroupRemainingProps {
  /** The information about the users. */
  users: Pick<User, 'firstname' | 'lastname'>[];
  /** How many times to apply the offset used to make the element overlaps. This can be negative and a floating number. */
  offsetMultiplier: number;
}

/**
 * Displays a compacted representation of users that could not be displayed in a UserGroupElement.
 * @param props See {@link UserGroupRemainingProps}.
 */
export function UserGroupRemaining(props: UserGroupRemainingProps) {
  return (
    <Tooltip
      title={
        <div className="flex flex-col items-start">
          {props.users.map((user, index) => (
            <span key={index}>{`${user.firstname} ${user.lastname}`}</span>
          ))}
        </div>
      }
      placement="top"
    >
      <div
        className="UserGroupRemaining group/user-group-remaining"
        style={{
          minWidth: USER_GROUP_ELEMENT_SIZE,
          minHeight: USER_GROUP_ELEMENT_SIZE,
          width: USER_GROUP_ELEMENT_SIZE,
          height: USER_GROUP_ELEMENT_SIZE,
          transform: `translateX(-${
            USER_GROUP_ELEMENT_OVERLAP * props.offsetMultiplier
          }px)`,
        }}
      >
        <div className="UserGroup__shadowy-element rounded-full w-full h-full group-hover/user-group-remaining:mt-[-10px] transition-[margin] shadow-md bg-grey-500 text-white text-smd text-raleway font-medium flex items-center justify-center">
          {`+${props.users.length}`}
        </div>
      </div>
    </Tooltip>
  );
}

import { ProjectApi } from '../../../api/projectApi';
import { AbortedException } from '../../../exceptions/abortedException';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { Project, ProjectPartial } from '../../../models/project';
import { useProjectsStore } from '../../../stores/projectsStore';
import { assert } from '../../../utilities/assertions';
import {
  AbortOptions,
  AbortOptionsDefault,
} from '../../../utilities/fetchUtilities';
import { fillOptions } from '../../../utilities/options';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { reactiveStoreState } from '../../../utilities/store';

/**
 * State machine diagram
 * =====================
 *
 * ## Preview
 *
 * https://kroki.io/mermaid/svg/eNp1UD1PwzAQ3fkVNyMCiBGpnSoklgqJETFc43NyyI0j3yXQf49ru0lQg4dYufdxz08UlXaMTcBjNT7dQDwft59QVVt4Ia3b_XA8UEjzxX_C34L_olrT-Bkeuoxs4XGVvfeFP1M3m8JdOi2t38nFL5kosWeMDMhQ1yRiB-dO69os2pHFwem882JgkV28-ggAG2AB7kZ0bJLbmvifRIHQ5AjT0xIz1rcMdlFMWAI7rwSBm1bB26umAXYsvcOTgPNouGvuyzw_U1uC0mFU93mRZA51Jrkv9jiyac2yqiu_4gIo4mvGc2L1hVWw11iXBVagHxaVO_BRGL5ZCArRTnaWg-hsOsbW8eDob8ZfnczMag
 *
 * ## Description
 *
 * ```mermaid
 * stateDiagram-v2
 *   [*] --> FetchNumber
 *   FetchNumber --> ProjectFetch: /number > 0
 *   FetchNumber --> NoProject: /number == 0
 *   ProjectFetch --> ProjectSelected: /fetched successfully
 *   ProjectFetch --> SelectDefaultProject: /fetched failed proj id is invalid
 *   SelectDefaultProject --> ProjectSelected: /ready
 *   NoProject --> [*]
 *   ProjectSelected --> [*]
 *
 *   note right of FetchNumber
 *     Displays loading.
 *     Fetch the number of projects.
 *   end note
 *
 *   note left of ProjectFetch
 *     Fetch the project associated to
 *     projectId if it exists, otherwise
 *     fetch the first project available.
 *   end note
 *   ```
 *
 *
 */

/**
 * @param projectId The {@link Project} id. Will try to find a project that matches this id or fallback on the default project if no project match.
 * @param options The options. See {@link AbortOptions}.
 * @returns A {@link Project} if one could be retrieved, `null` otherwise.
 */
export async function process(
  projectId: string | undefined,
  options?: Partial<AbortOptions>
): Promise<Project | ProjectPartial | null> {
  const filledOptions = fillOptions(options, AbortOptionsDefault);

  // If there is an existing fetched project, do not do all the work and just return it.
  const existingProject = useProjectsStore.getState().getProject();
  if (existingProject !== undefined && existingProject.id === projectId) {
    return existingProject;
  }

  const projectNumber = await fetchNumber(filledOptions.abortController);

  useProjectsStore.setState({
    projectNumber: PromiseSnapshot.buildSucceeded(projectNumber),
  });

  if (options?.abortController?.signal.aborted) throw new AbortedException();

  if (projectNumber === 0) {
    return null;
  }

  let project: Project | undefined = undefined;
  if (projectId) {
    try {
      project = await fetchSpecificProject(
        projectId,
        filledOptions.abortController
      );
    } catch (err) {
      if (err instanceof NetworkRequestException && err.status === 404) {
        project = await fetchDefaultProject(filledOptions.abortController);
      } else {
        throw err;
      }
    }
  } else {
    project = await fetchDefaultProject(filledOptions.abortController);
  }

  if (options?.abortController?.signal.aborted) throw new AbortedException();

  assert(
    project !== undefined,
    'project is supposed to be defined at this point.'
  );

  useProjectsStore.setState({ selectedProjectId: project.id });

  return project;
}

async function fetchNumber(
  abortController: AbortOptions['abortController']
): Promise<number> {
  let snapshot = useProjectsStore.getState().projectNumber;
  if (snapshot.isSucceeded()) {
    return snapshot.getSucceededData();
  }

  useProjectsStore.getState().fetchProjectNumber();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    snapshot = useProjectsStore.getState().projectNumber;
    switch (snapshot.state) {
      case PromiseState.Failed:
        throw snapshot.error;
      case PromiseState.Succeeded:
        return snapshot.getSucceededData();
      case PromiseState.NotStarted:
      case PromiseState.Running:
        break;
      default:
        throw new UnhandledSwitchCaseException();
    }
    if (abortController?.signal.aborted) throw new AbortedException();
    await reactiveStoreState(useProjectsStore);
  }
}

function fetchDefaultProject(
  abortController: AbortOptions['abortController']
): Promise<Project> {
  return fetchFirstProject(abortController);
}

async function fetchFirstProject(
  abortController: AbortOptions['abortController']
): Promise<Project> {
  const projectApi = new ProjectApi();
  const projectId = (
    await projectApi.getAll({ itemsPerPage: 1 }, { abortController })
  )[0].id;
  const project = await projectApi.get(projectId, { abortController });
  if (abortController?.signal.aborted) throw new AbortedException();
  useProjectsStore.getState().localUpdateProjects(project);
  return project;
}

async function fetchSpecificProject(
  projectId: string,
  abortController: AbortOptions['abortController']
): Promise<Project> {
  const project = await new ProjectApi().get(projectId, { abortController });
  if (abortController?.signal.aborted) throw new AbortedException();
  useProjectsStore.getState().localUpdateProjects(project);
  return project;
}

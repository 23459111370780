import { useEffect, useRef, useState } from 'react';
import { BillApi } from '../../../../../../api/billApi';
import { Bill, BillStatus } from '../../../../../../models/bill';
import { ApiPlatformList } from '../../../../../../utilities/baseApi';
import { PromiseSnapshot } from '../../../../../../utilities/promiseSnapshot';
import { groupByDateDesc } from '../../../utilities';
export interface UseBillsResults {
  /** The paginated bills grouped by date. */
  billsSnapshot: PromiseSnapshot<ApiPlatformList<[Date, Bill[]]>>;
  /** The fetched page. */
  page: number;
  /** Setter for the page to fetch. */
  setPage: (page: number) => void;
  /** Replaces the fetched bill with an identical id by {@link updatedBill}. If the bill does not appear in the snapshot, nothing will be changed. */
  patch: (updatedBill: Bill) => void;
}

/**
 * @param hidePaidBills Should the paid bills be excluded from the fetch?
 * @returns See {@link UseBillsResults}.
 */
export function useBills(hidePaidBills: boolean): UseBillsResults {
  const [snapshot, setSnapshot] = useState(
    new PromiseSnapshot<ApiPlatformList<[Date, Bill[]]>>()
  );
  const [page, setPage] = useState(1);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      async () => {
        const paginatedBills = await new BillApi().getAllPaginated(
          {
            'status[]': hidePaidBills
              ? [BillStatus.New, BillStatus.Sent, BillStatus.PartiallyPaid]
              : undefined,
            page,
          },
          { abortController }
        );

        return {
          'hydra:totalItems': paginatedBills['hydra:totalItems'],
          'hydra:view': paginatedBills['hydra:view'],
          'hydra:member': groupByDateDesc(paginatedBills['hydra:member']),
        };
      },
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [hidePaidBills, page]);

  const patch = (updatedBill: Bill) => {
    if (!snapshot.isSucceeded()) return;
    const grouppedBills = snapshot.getSucceededData();
    const indices = (() => {
      let groupIndex = 0;
      let index = 0;

      for (const [, bills] of grouppedBills['hydra:member']) {
        index = 0;
        for (const bill of bills) {
          if (bill.id === updatedBill.id) return [groupIndex, index];
          ++index;
        }
        ++groupIndex;
      }

      return undefined;
    })();

    if (indices === undefined) return;

    const [groupIndex, index] = indices;
    grouppedBills['hydra:member'][groupIndex][1][index] = updatedBill;

    setSnapshot(PromiseSnapshot.buildSucceeded(grouppedBills));
  };

  return {
    billsSnapshot: snapshot,
    page,
    setPage,
    patch,
  };
}

import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import { ZodImageEdit } from '../../../../atoms/ImagePicker/ImagePicker';

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    firstname: z.string().min(1, 'required_field'),
    lastname: z.string().min(1, 'required_field'),
    image: ZodImageEdit,
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';
import './LoadingBlock.css';

export interface LoadingBlockProps {
  /** Overrides the icon of the loading block.  */
  icon?: React.ReactElement;
  /** Overrides the text of the loading block. */
  text?: React.ReactNode;
  /** Do not use rounded corner, default: `false`. */
  noRoundedCorner?: boolean;
}

export function LoadingBlock(props: LoadingBlockProps) {
  const { t } = useTranslation();
  const noRoundedCorner = props.noRoundedCorner ?? false;

  return (
    <div
      className={ClassUtilities.flatten(
        'LoadingBlock flex gap-4 justify-center p-4 bg-orange-150 items-center text-orange',
        ClassUtilities.conditional({
          rounded: !noRoundedCorner,
        })
      )}
    >
      {props.icon ?? <i className="icon-loader animate-spin-slow" />}
      <span className="">{props.text ?? t('loading')}</span>
    </div>
  );
}

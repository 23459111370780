import dayjs from 'dayjs';
import { ActionHistory } from '../models/actionHistory';
import { ActionStatus } from '../models/actionStatus';
import { ActionType } from '../models/actionType';
import { Environment } from '../models/environment';
import { Project } from '../models/project';
import { User } from '../models/user';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import {
  getJsonFromServerRoute,
  GetJsonOptions,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const {
  REACT_APP_ROUTE_ACTION_HISTORIES,
  REACT_APP_ROUTE_ACTION_HISTORIES_LOG,
} = process.env;

export interface GetOptions {
  page?: number;
  itemsPerPage?: number;
  'environment[]'?: Environment['id'][];
  'author[]'?: User['id'][];
  'status[]'?: ActionStatus[];
  'action_type[]'?: ActionType['id'][];
  'environment.project[]'?: Project['id'][];
  'started_at[before]'?: Date;
  'started_at[strictly_before]'?: Date;
  'started_at[after]'?: Date;
  'started_at[strictly_after]'?: Date;
  'ended_at[before]'?: Date;
  'ended_at[strictly_before]'?: Date;
  'ended_at[after]'?: Date;
  'ended_at[strictly_after]'?: Date;
}

/**
 * Get options for the {@link ActionHistoryApi}.
 */
class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      itemsPerPage: ParamMappingHelper.mapNumber,
      'environment[]': ParamMappingHelper.identity,
      'author[]': ParamMappingHelper.identity,
      'status[]': ParamMappingHelper.identity,
      'action_type[]': ParamMappingHelper.identity,
      'environment.project[]': ParamMappingHelper.identity,
      'started_at[before]': ParamMappingHelper.mapDate,
      'started_at[strictly_before]': ParamMappingHelper.mapDate,
      'started_at[after]': ParamMappingHelper.mapDate,
      'started_at[strictly_after]': ParamMappingHelper.mapDate,
      'ended_at[before]': ParamMappingHelper.mapDate,
      'ended_at[strictly_before]': ParamMappingHelper.mapDate,
      'ended_at[after]': ParamMappingHelper.mapDate,
      'ended_at[strictly_after]': ParamMappingHelper.mapDate,
    };
  }
}

/**
 * API for the {@link ActionHistory}.
 */
export class ActionHistoryApi extends BaseApi<ActionHistory, GetOptions> {
  constructor() {
    super(
      unwrap(
        REACT_APP_ROUTE_ACTION_HISTORIES,
        'ActionHistory route not defined'
      ),
      GetOptionsHandler
    );
  }

  transformGottenPartialEntity(entity: ActionHistory): ActionHistory {
    return this.transformGottenEntity(entity);
  }

  transformGottenEntity(entity: ActionHistory): ActionHistory {
    if (entity.actionType !== undefined) {
      entity.actionType = BaseApi.getIdFromIri(entity.actionType);
    }

    if (entity.author !== undefined) {
      entity.author = BaseApi.getIdFromIri(entity.author);
    }

    if (entity.environment !== undefined) {
      entity.environment = BaseApi.getIdFromIri(entity.environment);
    }

    entity.startedAt = dayjs(entity.startedAt).toDate();
    entity.updatedAt = dayjs(entity.updatedAt).toDate();
    entity.endedAt = entity.endedAt ? dayjs(entity.endedAt).toDate() : null;

    return entity;
  }

  /**
   * Returns the snapshots of a specific action history logs.
   * @param id The id of the {@link ActionHistory} to get the snapshots from.
   * @param options The options. See {@link AbortOptions}.
   * @returns the logs lines snapshots; string[].
   */
  async getActionHistoryLogs(
    id: ActionHistory['id'],
    options?: Partial<AbortOptions>
  ): Promise<string[]> {
    const filledOptions = fillOptions(options, abortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_ACTION_HISTORIES_LOG,
      'Action history logs snapshots route not defined'
    );

    return getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    });
  }
}

export type AbortOptions = Pick<GetJsonOptions, 'abortController'>;

export const abortOptionsDefault: AbortOptions = {
  abortController: null,
};

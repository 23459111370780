import { FormHelperText } from '@mui/material';
import { useMemo, useState } from 'react';
import { Brightness, Colors } from '../../../utilities/colors';
import { Button } from '../Button/Button';
import { ColorPickerModal } from './subcomponents/ColorPickerModal';

export interface ColorPickerProps {
  label: string;
  modalTitle: string;
  color?: string;
  onColorChanged: (color: string) => void;
  error?: boolean;
  helperText?: React.ReactNode;
}

export function ColorPicker(props: ColorPickerProps) {
  const [modalOpened, setModalOpened] = useState(false);

  const hexColor = useMemo(
    () => (props.color ? Colors.fromTailwind(props.color) : undefined),
    [props.color]
  );

  const foregroundColor = useMemo(() => {
    const brightColor = '#FFFFFF';
    const darkColor = Colors.fromTailwind('grey-800');
    if (hexColor === undefined) return darkColor;

    return Colors.getPreferredForegroundBrightness(
      Colors.hexToRGB(hexColor)
    ) === Brightness.Bright
      ? brightColor
      : darkColor;
  }, [hexColor]);

  return (
    <div className="ColorPicker flex flex-col">
      <Button
        className="flex gap-2"
        variant="text"
        sx={{
          color: Colors.fromTailwind('grey-800'),
          ':hover': {
            backgroundColor: '#00000011',
          },
        }}
        onClick={() => setModalOpened(true)}
      >
        <div
          style={{
            backgroundColor: hexColor,
            color: foregroundColor,
            fontSize: '16px',
          }}
          className="p-1 rounded-full transition-colors"
        >
          <i className="icon-droplet" />
        </div>
        <span>{props.label}</span>
      </Button>
      {props.helperText && (
        <FormHelperText error={props.error} className="pl-2">
          {props.helperText}
        </FormHelperText>
      )}
      <ColorPickerModal
        title={props.modalTitle}
        color={props.color}
        onSelectedColorChanged={props.onColorChanged}
        onClose={() => setModalOpened(false)}
        opened={modalOpened}
      />
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CompanyApi } from '../../../../api/companyApi';
import { Company } from '../../../../models/company';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';

/**
 * Gets the company to edit if this is necessary.
 * @returns The {@link Company} to edit or `null` if this is a company creation.
 */
export function useCompany(): PromiseSnapshot<Company> | null {
  const { companyId } = useParams();
  const companyFromState = useLocation().state?.company;
  const [companySnapshot, setCompanySnapshot] = useState(
    new PromiseSnapshot<Company>()
  );
  const abortControllerRef = useRef(new AbortController());

  // Fetch company if necessary. But try to retrieve it from the state first.
  useEffect(() => {
    if (companyId === undefined) return;

    if (companyFromState !== undefined) {
      setCompanySnapshot(
        PromiseSnapshot.buildSucceeded(companyFromState as Company)
      );
      return;
    }

    const abortController = abortControllerRef.current;
    PromiseSnapshot.trackPromiseSetter(
      () => new CompanyApi().get(companyId, { abortController }),
      setCompanySnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [companyFromState, companyId]);

  if (companyId === undefined) return null;
  else return companySnapshot;
}

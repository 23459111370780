import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BillApi } from '../../../../../../../api/billApi';
import { PaymentApi } from '../../../../../../../api/paymentApi';
import {
  Formats,
  useTranslationFormatter,
} from '../../../../../../../hooks/useTranslationFormatter';
import { Bill } from '../../../../../../../models/bill';
import { useSnackbarStore } from '../../../../../../../stores/snackbarStore';
import { contain, filterSplit } from '../../../../../../../utilities/array';
import { unwrap, unwrapNull } from '../../../../../../../utilities/assertions';
import { DateFormatter } from '../../../../../../../utilities/dateFormatter';
import { NumberFormatter } from '../../../../../../../utilities/numberFormatter';
import {
  RoundedFilledBlueButton,
  RoundedFilledLightGrayButton,
} from '../../../../../../atoms/Button/variations/RoundedButtonVariations';
import { Modal } from '../../../../../../atoms/Modal/Modal';
import { Title } from '../../../../../../atoms/Title/Title';
import { Schema, getSchema } from './schema';
import { FormPaymentLines } from './subcomponents/PaymentLine/FormPaymentLines';
import { equals } from './types/paymentLineInfo';

export type CloseReason =
  | 'backdropClick'
  | 'escapeKeyDown'
  | 'cancel'
  | 'submit';

export interface PaymentModalProps {
  bill: Bill | null;
  onClose: (reason: CloseReason) => void;
  onBillUpdateStart: () => void;
  onBillUpdatedEnd: (updatedBill: Bill) => void;
}

export function PaymentModal(props: PaymentModalProps) {
  const [loading, setLoading] = useState(false);
  const { t, tFormatted } = useTranslationFormatter();
  const snackbarHandlePromise = useSnackbarStore(
    (state) => state.handlePromise
  );

  const formMethods = useForm<Schema>({
    defaultValues: {
      payments: [],
    },
    mode: 'onSubmit',
    resolver: zodResolver(getSchema(t)),
  });

  // Set value with bills payments
  useEffect(() => {
    formMethods.reset({
      payments: props.bill ? _.sortBy(props.bill.payments, 'paidAt') : [],
    });
  }, [formMethods, props.bill, props.bill?.payments]);

  const watchPayments = formMethods.watch('payments');

  const hasModifications = useMemo(() => {
    if (!props.bill) return false;
    if (watchPayments.length !== props.bill.payments.length) return true;
    if (contain(watchPayments, (payment) => payment.id === undefined))
      return true;

    for (let i = 0; i < watchPayments.length; ++i) {
      const tempPayment = watchPayments[i];
      const billPayment = unwrap(
        props.bill.payments.find((payment) => payment.id === tempPayment.id)
      );
      if (!equals(billPayment, tempPayment)) return true;
    }
    return false;
  }, [props.bill, watchPayments]);

  const onSubmit = useCallback(
    async (data: Schema) => {
      const bill = unwrapNull(props.bill);
      const billId = bill.id;

      // Get addded, modified and removed elements.
      const [added, existing] = filterSplit(
        data.payments,
        (payment) => payment.id === undefined
      );
      const modified = existing.filter(
        (payment) =>
          !equals(
            unwrap(
              bill.payments.find((billPayment) => billPayment.id === payment.id)
            ),
            payment
          )
      );
      const removed = bill.payments.filter(
        (payment) =>
          existing.find((formPayment) => formPayment.id === payment.id) ===
          undefined
      );

      props.onClose('submit');

      const transDetails = {
        project: bill.project.name,
        date: DateFormatter.toMonth(bill.date),
      };

      const transFormat: Formats = {
        bold: {
          classes: 'font-semibold',
        },
      };

      await snackbarHandlePromise(
        `payments_for_bill_${bill.id}`,
        async () => {
          setLoading(true);
          props.onBillUpdateStart();

          const paymentApi = new PaymentApi();
          const promises: Promise<unknown>[] = [
            added.map((payment) =>
              paymentApi.post({
                amount: payment.amount,
                paidAt: payment.paidAt,
                bill: billId,
              })
            ),
            removed.map((payment) => paymentApi.delete(unwrap(payment.id))),
            modified.map((payment) =>
              paymentApi.delete(unwrap(payment.id)).then(() =>
                paymentApi.post({
                  amount: payment.amount,
                  paidAt: payment.paidAt,
                  bill: billId,
                })
              )
            ),
          ].flatMap((promises) => promises as Promise<unknown>[]);

          await Promise.all(promises);

          props.onBillUpdatedEnd(await new BillApi().get(billId));

          setLoading(false);

          return null;
        },
        {
          getErrorMessage: (error) =>
            tFormatted(
              'payment_update_failed',
              { ...transDetails, error: `${error}` },
              transFormat
            ),
          getLoadingMessage: () =>
            tFormatted('payment_update_in_progress', transDetails, transFormat),
          getSuccessMessage: () =>
            tFormatted('payment_update_succeeded', transDetails, transFormat),
        },
        {
          autoHideSuccessDuration: 3000,
        }
      );
    },
    [props, snackbarHandlePromise, tFormatted]
  );

  return (
    <Modal
      className="PaymentModal"
      open={props.bill !== null}
      onClose={(_ev, reason) => props.onClose(reason)}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <Title level={2}>
            <span className="flex gap-[0.5em] items-center">
              {t('payment_handling')}
            </span>
          </Title>
          <Title level={4} className="flex gap-2 items-center">
            <i className="icon-corner-down-right inline transform -translate-y-[0.125em]" />
            {` ${props.bill?.project.name} ${
              props.bill?.project.billingCompany
                ? `(${props.bill?.project.billingCompany.name})`
                : ''
            } - ${props.bill ? DateFormatter.toMonth(props.bill.date) : ''}`}
          </Title>
          {props.bill && (
            <p className="py-4">
              {tFormatted(
                'bill_amount_text',
                {
                  amount: NumberFormatter.toEuro(props.bill.totalInclTax),
                  amountLeft: NumberFormatter.toEuro(
                    props.bill.totalInclTax - props.bill.totalPaid
                  ),
                },
                {
                  amount: {
                    classes: 'font-semibold',
                  },
                  amountLeft: {
                    classes: 'font-semibold',
                  },
                }
              )}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <Title level={3}>
              {t('payment', { count: watchPayments.length })}
            </Title>
            <p>
              {tFormatted(
                'payment_amount_total',
                {
                  amount: NumberFormatter.toEuro(
                    watchPayments.reduce(
                      (acc, cur) => acc + (cur.amount ?? 0),
                      0
                    )
                  ),
                },
                {
                  amount: {
                    classes: 'font-semibold',
                  },
                }
              )}
            </p>
          </div>
          <FormPaymentLines control={formMethods.control} name="payments" />
        </div>
        <div className="flex gap-2 justify-end">
          <RoundedFilledLightGrayButton onClick={() => props.onClose('cancel')}>
            {t('cancel')}
          </RoundedFilledLightGrayButton>
          <RoundedFilledBlueButton
            disabled={!hasModifications || loading}
            onClick={formMethods.handleSubmit(onSubmit)}
            loading={loading}
          >
            {t('validate')}
          </RoundedFilledBlueButton>
        </div>
      </div>
    </Modal>
  );
}

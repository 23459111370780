import { Chip, ChipProps, styled } from '@mui/material';
import { Colors } from '../../../utilities/colors';
import { Context } from '../../shared/types/context';

export interface StatusChipProps extends ChipProps {
  context: Context;
}

export function StatusChip(props: StatusChipProps) {
  const StyledComponent = styled(BaseStyledChip)(contextColors[props.context]);
  return <StyledComponent {...props}></StyledComponent>;
}

const BaseStyledChip = styled(Chip)({
  minWidth: '6em',
  fontWeight: 700,
});

type ContextColors = {
  [context in Context]: {
    backgroundColor?: string;
    color?: string;
  };
};

const contextColors: ContextColors = {
  neutral: {
    backgroundColor: Colors.fromTailwind('grey-300'),
    color: Colors.fromTailwind('grey-600'),
  },
  info: {
    backgroundColor: Colors.fromTailwind('blue-200'),
    color: Colors.fromTailwind('blue-700'),
  },
  success: {
    backgroundColor: Colors.fromTailwind('green-100'),
    color: Colors.fromTailwind('green-800'),
  },
  warning: {
    backgroundColor: Colors.fromTailwind('yellow-200'),
    color: Colors.fromTailwind('yellow-500'),
  },
  error: {
    backgroundColor: Colors.fromTailwind('pink-300'),
    color: Colors.fromTailwind('pink-500'),
  },
};

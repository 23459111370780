import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../../../../../../../../atoms/DatePicker/DatePicker';
import { TextField } from '../../../../../../../../atoms/TextField/TextField';
import { PaymentLineInfo } from '../../types/paymentLineInfo';

/** The props for the {@link Payment}. */
export interface PaymentLineProps {
  /** The value of the fields. May be partially incomplete. */
  value: Partial<PaymentLineInfo>;
  /** The function called whenever the value of the fields changes.
   * If `null` is passed to the function, the payment line requests
   * to be deleted.
   */
  onChange: (value: Partial<PaymentLineInfo> | null) => void;
  /** Is there on error global to the payment line? */
  error?: boolean;
  /** The helper text to show below this component. */
  helperText?: ReactNode;
  /** Is there an error on the amount field? */
  amountError?: boolean;
  /** The helper text to show below the amount field. */
  amountHelperText?: ReactNode;
  /** Is there an error on the paidAt field? */
  paidAtError?: boolean;
  /** The helper text to show below the paidAt field. */
  paidAtHelperText?: ReactNode;
}

/**
 * A payment line containing a field for the payment date and for its amount.
 * @param props See {@link PaymentLineProps}.
 */
export function PaymentLine(props: PaymentLineProps) {
  const { t } = useTranslation();
  const [amountError, setAmountError] = useState<string | undefined>(undefined);
  const [strAmountValue, setStrAmountValue] = useState<string>('');

  useEffect(() => {
    const localAmountValue = parseFloat(strAmountValue);
    if (props.value?.amount === undefined) return;
    if (localAmountValue === props.value.amount) return;

    setStrAmountValue(props.value.amount.toFixed(2));
  }, [props.value, strAmountValue]);

  const onAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const strValue = event.target.value;
      const strippedValue = strValue.replaceAll(/[^\d.]/g, '');
      setStrAmountValue(strippedValue);

      if (strippedValue.length === 0) {
        props.onChange(overrideValue(props.value, { amount: undefined }));
        setAmountError(undefined);
        return;
      }

      const value = parseFloat(strippedValue);

      //#region Error checking
      if (isNaN(value)) {
        const error = t('not_a_decimal_number');
        setAmountError(error);
        return;
      }
      if (value < 0) {
        const error = t('not_negative_value_allowed');
        setAmountError(error);
        return;
      }
      //#endregion

      props.onChange(
        overrideValue(props.value, { amount: parseFloat(value.toFixed(2)) })
      );
      setAmountError(undefined);
    },
    [props, t]
  );

  return (
    <div className="PaymentLine flex flex-col">
      <div className="flex gap-4 items-start">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <DatePicker
              label={t('payment_date')}
              value={props.value?.paidAt ? dayjs(props.value.paidAt) : null}
              onChange={(date) =>
                props.onChange?.(
                  overrideValue(props.value, {
                    paidAt: date?.toDate() ?? undefined,
                  })
                )
              }
              error={Boolean(props.paidAtError)}
            />
            <FormHelperText error={props.paidAtError}>
              {props.paidAtHelperText}
            </FormHelperText>
          </div>
          <div className="flex flex-col">
            <TextField
              name="amount"
              value={strAmountValue}
              onChange={onAmountChange}
              error={amountError !== undefined || Boolean(props.amountError)}
              helperText={amountError}
              label={t('amount_price')}
              onBlur={(event) => {
                setStrAmountValue(props.value?.amount?.toFixed(2) ?? '');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="icon-dollar-sign" />
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText error={props.amountError}>
              {props.amountHelperText}
              {amountError}
            </FormHelperText>
          </div>
        </div>
        <Tooltip title={t('delete')} placement="right">
          <IconButton className="group" onClick={() => props.onChange(null)}>
            <i className="icon-trash opacity-25 group-hover:opacity-100 transition-opacity" />
          </IconButton>
        </Tooltip>
      </div>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </div>
  );
}

export function overrideValue(
  oldValue: PaymentLineProps['value'],
  newValue: Partial<PaymentLineInfo> | null
): Partial<PaymentLineInfo> | null {
  if (!newValue) return newValue;
  return { ...oldValue, ...newValue };
}

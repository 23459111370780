import { UserProjectRoleType } from '../models/userProjectRole';
import { useCheckAdmin } from './useCheckAdmin';
import { RoleCheckType, useProjectRoleCheck } from './useProjectRoleCheck';

export function useCanEditProjectSettings(): boolean | undefined {
  const isManager = useProjectRoleCheck(
    [UserProjectRoleType.Manager],
    RoleCheckType.All
  );
  const isAdmin = useCheckAdmin();
  if (isManager === undefined || isAdmin === undefined) return undefined;
  return isManager || isAdmin;
}

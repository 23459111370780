import { Alert, Collapse, CollapseProps } from '@mui/material';
import { useEffect } from 'react';
import { UnhandledSwitchCaseException } from '../../../../exceptions/unhandledSwitchCaseException';
import { SnackbarInfo } from '../../../../stores/snackbarStore';
import { Colors } from '../../../../utilities/colors';

export interface GlobalSnackbarProps extends CollapseProps {
  name: string;
  info: SnackbarInfo;
  onDeleteRequested: () => void;
}

/**
 * WARNING, excepted very exceptional case, this should not be used in another place than GlobalSnackbars component.
 */
export function GlobalSnackbar(props: GlobalSnackbarProps) {
  const { info, name, onDeleteRequested, ...collapseProps } = props;

  useEffect(
    () => {
      if (info.autoHideDuration !== null) {
        const timeoutId = setTimeout(onDeleteRequested, info.autoHideDuration);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    },
    // Intentionally include all info to reset timeout if one of them change.
    [info.message, info.severity, info.autoHideDuration, onDeleteRequested]
  );

  let alert: JSX.Element;

  switch (info.severity) {
    case 'info':
      alert = (
        <Alert
          severity="info"
          key={name}
          elevation={2}
          sx={{
            background: Colors.fromTailwind('blue-200'),
            color: Colors.fromTailwind('grey-800'),
          }}
          icon={<i className="icon-info text-grey-800" />}
        >
          {info.message}
        </Alert>
      );
      break;
    case 'pending':
      alert = (
        <Alert
          severity="warning"
          key={name}
          elevation={2}
          icon={<i className="icon-loader animate-spin-slow" />}
        >
          {info.message}
        </Alert>
      );
      break;
    case 'error':
    case 'success':
      alert = (
        <Alert
          severity={info.severity}
          elevation={2}
          onClick={onDeleteRequested}
          className="cursor-pointer"
        >
          {info.message}
        </Alert>
      );
      break;
    default:
      throw new UnhandledSwitchCaseException(
        `Severity ${info.severity} is not handled.`
      );
  }

  return (
    <Collapse key={name} {...collapseProps}>
      <div className="p-1">
        <div className="h-2"></div>
        <div className="select-none">{alert}</div>
      </div>
    </Collapse>
  );
}

export interface DataTileProps {
  label: string;
  value: React.ReactNode;
}

/**
 * A tile used to display simple data
 * @param label a bold label that describes the data
 * @param value a custom displayable value (ReactNode) - the data itself
 * @returns A component that displays data vertically
 */
export function DataTile({ label, value }: DataTileProps) {
  return (
    <>
      <div className="font-bold font-heebo text-smd text-grey-700">{label}</div>
      <div className="font-heebo text-smd text-grey-700 font-light">
        {value}
      </div>
    </>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, TextField, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompanyApi } from '../../../api/companyApi';
import { ProjectApi } from '../../../api/projectApi';
import DefaultCompanyPicture from '../../../assets/images/default-company-picture.png';
import { useTitle } from '../../../hooks/useTitle';
import { Company } from '../../../models/company';
import { getServerUrl } from '../../../utilities/fetchUtilities';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { FormAutocomplete } from '../../atoms/Autocomplete/FormAutocomplete';
import { Block } from '../../atoms/Block/Block';
import { Breadcrumb } from '../../atoms/Breadcrumb/Breadcrumb';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { FormImagePicker } from '../../atoms/FormImagePicker/FormImagePicker';
import { Option } from '../../atoms/MultipleSelect/MultipleSelect';
import { FormTextField } from '../../atoms/TextField/FormTextField';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { NewProjectSchema, schema } from './schema';

export function NewAdminProject() {
  const { t } = useTranslation();
  const abortController = useRef(new AbortController());
  const navigate = useNavigate();
  const [companiesSnapshot, setCompaniesSnapshot] =
    useState<PromiseSnapshot<Company[]>>();
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [companyOptions, setCompanyOptions] = useState<
    readonly Option<Company>[]
  >([]);

  useTitle(t('administration_new_project'));

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewProjectSchema>({
    defaultValues: {
      name: '',
      gitRepositoryUrl: '',
      image: undefined,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    // Fetch users
    if (autocompleteInputValue.length < 3) {
      return;
    }
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new CompanyApi().getAll(
          {
            name: autocompleteInputValue,
          },
          { abortController: abortController.current }
        ),
      setCompaniesSnapshot
    );
  }, [autocompleteInputValue]);

  useEffect(() => {
    if (
      companiesSnapshot?.state === PromiseState.Succeeded &&
      companiesSnapshot.data !== undefined
    ) {
      setCompanyOptions(
        companiesSnapshot.data.map((company) => {
          return {
            label: company.name + ' / ' + company.billingContactFullname,
            value: company,
          };
        })
      );
    }
  }, [companiesSnapshot]);

  const onSubmit = async (data: NewProjectSchema) => {
    const project = await new ProjectApi().post(data, {
      abortController: abortController.current,
    });

    navigate(`/admin/projects/${project.id}`);
  };

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <AdminPageTemplate className="NewAdminProject">
      <div className="flex flex-col gap-4">
        <Breadcrumb
          homeLink={'/admin/projects'}
          elements={[
            {
              title: t('create_a_new_project'),
              subtitle: t('project'),
              link: '',
            },
          ]}
        ></Breadcrumb>
        <div className="h-4"></div>
        <div className="grid xl:grid-cols-6">
          <Block className="col-span-4 2xl:col-span-3">
            <Title level={2}>{t('create_a_new_project')}</Title>
            <div className="h-8"></div>
            <form className="flex flex-col gap-6">
              <FormImagePicker
                control={control}
                name="image"
                initialURL={undefined}
              />
              <FormTextField
                control={control}
                label={t('project_name')}
                name="name"
                required
              ></FormTextField>
              <FormTextField
                control={control}
                label={t('git_repository_url')}
                name="gitRepositoryUrl"
              ></FormTextField>
              <div className="flex align-center justify-between gap-3">
                <FormAutocomplete
                  name="billingCompany"
                  control={control}
                  options={companyOptions}
                  filterOptions={(x) => x}
                  className={'flex-1'}
                  onInputChange={(evt, value) =>
                    setAutocompleteInputValue(value)
                  }
                  valueEqualityOperator={(value1, value2) => {
                    return value1.id === value2.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('select_company_one')}
                      fullWidth
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    const useDefaultImage =
                      option?.value.imgUri === '' ||
                      option?.value.imgUri === undefined;
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={() => (
                                <img
                                  src={
                                    useDefaultImage
                                      ? DefaultCompanyPicture
                                      : getServerUrl() + option?.value.imgUri
                                  }
                                  alt="Company"
                                  className="rounded-full w-10 h-10"
                                  onError={(ev) => {
                                    console.warn(
                                      'Company logo could not be loaded, replacing with the default one.'
                                    );
                                    ev.currentTarget.src =
                                      DefaultCompanyPicture;
                                  }}
                                />
                              )}
                              sx={{ color: 'text.secondary', mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs className="pl-3">
                            <span>
                              {option?.value.name} /{' '}
                              {option?.value.billingContactFullname}
                            </span>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                ></FormAutocomplete>
                <Tooltip title={t('user_autocomplete_usage_notes')}>
                  <i className="icon-info self-center text-lg"></i>
                </Tooltip>
              </div>
              <div className="h-4"></div>

              {hasErrors && (
                <WarningBlock>{t('warning_error_in_form')}</WarningBlock>
              )}

              <RoundedFilledBlueButton
                color="info"
                className="self-end"
                disabled={hasErrors}
                onClick={handleSubmit(onSubmit)}
              >
                {t('create')}
              </RoundedFilledBlueButton>
            </form>
          </Block>
        </div>
      </div>
    </AdminPageTemplate>
  );
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../../api/userApi';
import { useQuery } from '../../../hooks/useQuery';
import { Button } from '../../atoms/Button/Button';
import { ErrorPageTemplate } from '../../templates/ErrorPageTemplate/ErrorPageTemplate';

/**
 * The user lands on this page whenever its information could not
 * be retrieved using the `/me` API route.
 * The URL is expected to contain a query string, and this query string should
 * contain the URL where the user wanted to go (the parameter name of this URL
 * must be `redirect_url`). If this is not provided the user will be redirected
 * to the front `/` route.
 *
 * 💡 Tips: The value of `redirect_url` must be encoded using the JS
 * vanilla function `encodeURIComponent`.
 */
export function NotLogged() {
  const { t } = useTranslation();
  const { params } = useQuery();
  const redirectUrl = useMemo(() => {
    const encodedUrl = params.get('redirect_url');
    if (encodedUrl) return decodeURIComponent(encodedUrl);
    else return undefined;
  }, [params]);

  return (
    <ErrorPageTemplate
      title={t('not_logged_title')}
      details={t('not_logged_details')}
      actions={
        <>
          <Button
            variant="contained"
            startIcon={<i className="icon-refresh-cw" />}
            onClick={() => {
              window.location.href = UserApi.getSSORoute(
                redirectUrl ?? window.location.origin
              );
            }}
          >
            {t('reconnect')}
          </Button>
        </>
      }
    />
  );
}

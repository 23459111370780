import { Hashable } from './hashable';
import { fillOptions } from './options';

/** The options for the {@link HashSet} constructor. */
type ConstructorOptions<V> = {
  /** The initial values to put in the map.
   * @default []
   **/
  initialValues: V[];
};

/** The default values for {@link ConstructorOptions}. */
const ConstructorOptionsDefault: ConstructorOptions<unknown> = {
  initialValues: [],
};

/** The map type holding the keys and values for the {@link HashSet}. */
type InternalMap<V> = Map<string | number, V>;

/** The iterator on {@link HashSet} values. */
class HashSetValueIterator<V> implements IterableIterator<V> {
  private _internalIterator: IterableIterator<V>;

  constructor(_internalMap: InternalMap<V>) {
    this._internalIterator = _internalMap.values();
  }

  [Symbol.iterator](): IterableIterator<V> {
    return this;
  }

  next(): IteratorResult<V, V | undefined> {
    const iteratorResult = this._internalIterator.next();
    if (iteratorResult.done) {
      return {
        done: true,
        value: undefined,
      };
    }

    return {
      done: false,
      value: iteratorResult.value,
    };
  }
}

/**
 * Map that acts as a proxy for storing element with {@link Hashable} keys.
 * The map allows you to get your key and value as you would expect but compares identity with an hash function instead.
 * @details Tests available in `src/tests/utilities/array.test.ts`.
 */
export class HashSet<V> {
  private _internalMap: InternalMap<V> = new Map();
  private _hashFunc: (key: V) => string | number;

  //#region Constructors and factories
  /**
   * @param hashFunc The function to transform the key to an hashable element which can be used for equality operations internally.
   * @param options The options. See {@link ConstructorOptions}.
   */
  protected constructor(
    hashFunc: (key: V) => string | number,
    options?: Partial<ConstructorOptions<V>>
  ) {
    this._hashFunc = hashFunc;

    const filledOptions = fillOptions(
      options,
      ConstructorOptionsDefault
    ) as ConstructorOptions<V>;

    for (const value of filledOptions.initialValues) {
      this.add(value);
    }
  }

  /**
   * Create a set with {@link Hashable} elements defining the equality of elements in the set.
   * @param options The options. See {@link ConstructorOptions}.
   * @returns a {@link HashMap} instance.
   */
  public static fromHashable<V extends Hashable>(
    options?: Partial<ConstructorOptions<V>>
  ): HashSet<V> {
    return new this((val) => val.toHash(), options);
  }

  /**
   * Create a set with non-{@link Hashable} elements.
   * @param hashFunc The function to convert the key to a unique value that can be used to check equality between elements. Note that this function should be quite fast for performance issues.
   * @param options The options. See {@link ConstructorOptions}.
   * @returns a {@link HashMap} instance.
   */
  public static fromNonHashable<V>(
    hashFunc: (key: V) => string | number,
    options?: Partial<ConstructorOptions<V>>
  ): HashSet<V> {
    return new this(hashFunc, options);
  }
  //#endregion

  /**
   * @returns the number of elements in the Map.
   */
  public get size(): number {
    return this._internalMap.size;
  }

  /**
   * Returns a specified element from the Set object. If the value that is associated to the provided {@link key} is an object, then you will get a reference to that object and any change made to that object will effectively modify it inside the Map.
   * @remarks Modify elements provoking the hash value to change is strongly discouraged since it will lead to undefined behavior (potential duplicated elements in the set).
   * @returns — Returns the element associated with the specified key. If no element is associated with the specified {@link key}, `undefined` is returned.
   */
  public get(value: V): V | undefined {
    return this._internalMap.get(this._hashFunc(value));
  }

  /**
   * Adds a new element with a specified {@link value} to the Set. If an element with the same hash already exists, the element will be updated.
   * @returns `this` instance.
   */
  public add(value: V): this {
    this._internalMap.set(this._hashFunc(value), value);
    return this;
  }

  /**
   * Clears all data in map.
   */
  public clear(): void {
    this._internalMap.clear();
  }

  /**
   * @returns boolean indicating whether an element with the specified {@link value} exists or not.
   */
  public has(value: V): boolean {
    return this._internalMap.has(this._hashFunc(value));
  }

  /**
   * @returns an iterable of values in the set.
   */
  public values(): HashSetValueIterator<V> {
    return new HashSetValueIterator(this._internalMap);
  }
}

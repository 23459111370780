import { useLocation } from 'react-router-dom';
import { ClassUtilities } from '../../../utilities/classUtility';
import {
  MenuButtonBase,
  MenuButtonBaseProps,
} from '../../atoms/MenuButtonBase/MenuButtonBase';
import './MenuButton.css';
import { menuButtonIsSelected } from './utilities';

/**
 * Describes when the menu button should be selected.
 */
export enum SelectMode {
  /** Is selected if the current pathname matches exactly the target route of the button. */
  RouteExact,
  /** Is selected if the current pathname starts with the target route of the button. */
  RouteStart,
}

/**
 * A menu button intended to be used with a simple icon.
 */
export function MenuButton(props: MenuButtonProps) {
  const { icon, className, ...baseProps } = props;
  const { pathname } = useLocation();

  const selected = menuButtonIsSelected(
    props.selectMode,
    props.route,
    pathname
  );

  return (
    <MenuButtonBase
      className={ClassUtilities.flatten(props.className, 'MenuButton')}
      icon={<i className={icon} />}
      selected={selected}
      {...baseProps}
    />
  );
}

export interface MenuButtonProps
  extends Omit<MenuButtonBaseProps, 'icon' | 'selected'> {
  /** Name of the icon (example: `icon-home`). */
  icon: string;
  /* DEV Documentation */
  selectMode: SelectMode;
}

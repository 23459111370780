import { TFunction } from 'i18next';
import { UserProjectRoleType } from '../../../models/userProjectRole';

export function getRoleInputOptions(t: TFunction) {
  return [
    { label: t('role_viewer'), value: UserProjectRoleType.Viewer },
    {
      label: t('role_customer_viewer'),
      value: UserProjectRoleType.CustomerViewer,
    },
    { label: t('role_developer'), value: UserProjectRoleType.Developer },
    { label: t('role_manager'), value: UserProjectRoleType.Manager },
  ];
}

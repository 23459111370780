import { useEffect, useRef, useState } from 'react';
import { EnvironmentActionTypeApi } from '../api/environmentActionTypeApi';
import { ActionType } from '../models/actionType';
import { Environment } from '../models/environment';
import { EnvironmentActionTypeEstimate } from '../models/environmentActionType';
import { PromiseSnapshot } from '../utilities/promiseSnapshot';

export function useEnvironmentActionTypesEstimates<
  T extends EnvironmentActionTypeEstimate = EnvironmentActionTypeEstimate
>(
  environmentId: Environment['id'] | undefined,
  actionTypeName: ActionType['name']
): PromiseSnapshot<T> {
  const [
    environmentActionTypeEstimateSnapshot,
    setEnvironmentActionTypeEstimateSnapshot,
  ] = useState(new PromiseSnapshot<EnvironmentActionTypeEstimate>());
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    if (environmentId === undefined) {
      return;
    }

    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      async () => {
        const id = (
          await new EnvironmentActionTypeApi().getAll(
            {
              'environment[]': [environmentId],
              'actionType.name[]': [actionTypeName],
            },
            { abortController }
          )
        )[0].id;

        return new EnvironmentActionTypeApi().getEstimatedBill(id, {
          abortController,
        });
      },
      setEnvironmentActionTypeEstimateSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [environmentId, actionTypeName]);

  return environmentActionTypeEstimateSnapshot as PromiseSnapshot<T>;
}

import { AssertionException } from '../exceptions/assertionException';
import { UnwrapException } from '../exceptions/unwrapException';

/**
 * Returns the unwrapped `value` or throw an {@link UnwrapException} if `value` cannot be unwrapped.
 * @param value The value to unwrap.
 * @returns The unwrapped value.
 */
export function unwrap<T>(value: T | undefined, details?: string) {
  return unwrapWithException(value, new UnwrapException(details));
}

/**
 * Returns the unwrapped `value` or throw an {@link UnwrapException} if `value` cannot be unwrapped.
 * @param value The value to unwrap.
 * @returns The unwrapped value.
 */
export function unwrapNull<T>(value: T | null, details?: string) {
  if (value === null) {
    throw new UnwrapException(details);
  } else {
    return value;
  }
}

/**
 * Returns the unwrapped `value` or throw an {@link UnwrapException} if `value` cannot be unwrapped.
 * @param value The value to unwrap.
 * @returns The unwrapped value.
 */
export function unwrapAll<T>(value: T | null | undefined, details?: string) {
  unwrapNull(value, details);
  return unwrap(value, details);
}

/**
 * Returns the unwrapped `value` or throw `exception` if `value` cannot be unwrapped.
 * @param value The value to unwrap.
 * @param exception The exception to throw if `value` cannot be unwrapped.
 * @returns The unwrapped value.
 */
export function unwrapWithException<T, E extends Error>(
  value: T | undefined,
  exception: E
) {
  if (value === undefined) {
    throw exception;
  }
  return value;
}

/**
 * Asserts that `value` is `true` or throws a {@link AssertionException}.
 * @param value The value to assert.
 * @param message The message to associate with the exception if the assertion failed.
 * @throws {@link AssertionException}
 */
export function assert(value: unknown, message: string): asserts value is true {
  if (!value) {
    throw new AssertionException(message);
  }
}

import { UserProjectRoleType } from '../models/userProjectRole';
import { useProjectEnvironmentsStore } from '../stores/projectEnvironmentsStore';
import { useCheckAdmin } from './useCheckAdmin';
import { RoleCheckType, useProjectRoleCheck } from './useProjectRoleCheck';

/**
 * Checks for user action rights.
 * @returns
 * * `true` if the user has sufficient rights to execute actions.
 * * `false` otherwise.
 * * `undefined` will be returned while the user info are being retrieved.
 */
export function useActionAuthorization(): boolean | undefined {
  const isSuperAdmin = useCheckAdmin();
  const isAdminOrManager = useProjectRoleCheck(
    [UserProjectRoleType.Admin, UserProjectRoleType.Manager],
    RoleCheckType.Any
  );
  const isDeveloper = useProjectRoleCheck(
    [UserProjectRoleType.Developer],
    RoleCheckType.Any
  );
  const environment = useProjectEnvironmentsStore((state) =>
    state.getEnvironment()
  );

  if (
    isSuperAdmin === undefined ||
    isAdminOrManager === undefined ||
    isDeveloper === undefined ||
    environment === undefined
  )
    return undefined;
  if (isSuperAdmin || isAdminOrManager) return true;
  if (!isDeveloper) return false;
  return environment.isMonoVM;
}

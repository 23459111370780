import React from 'react';
import { insertBetween } from './array';

/**
 * Converts `\n` from {@link text} to  `<br />`s.
 */
export function newLineToBr(text: string): JSX.Element {
  return (
    <>
      {insertBetween<React.ReactNode>(text.split('\n'), <br />).map(
        (el, index) => (
          <React.Fragment key={index}>{el}</React.Fragment>
        )
      )}
    </>
  );
}

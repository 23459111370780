import { WithTranslation } from 'react-i18next';
import { record, z } from 'zod';
import { PostArgs } from '../../../api/environmentApi';
import { ProjectApi } from '../../../api/projectApi';
import { BadLogicException } from '../../../exceptions/badLogicException';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { Project } from '../../../models/project';
import { contain } from '../../../utilities/array';
import { unwrap } from '../../../utilities/assertions';
import { stripUndefined } from '../../../utilities/recordUtilities';
import {
  getGitReferenceInfo,
  getGitReferenceSchema,
} from '../../molecules/FormGitReferencePicker/schema';
import { DataSource } from './dataSource';

export function getSchema(t: WithTranslation['t']) {
  return z
    .object({
      name: z
        .string()
        .min(1, t('required_field'))
        .regex(/^[a-zA-Z0-9- ]+$/, t('env_name_invalid_format')),
      isMonoVM: z.literal(0).or(z.literal(1)),
      template: z.string().min(1),
      gitReference: getGitReferenceSchema(t),
      dataSource: z.enum([DataSource.Environment, DataSource.Backup]),
      environmentSource: z.string(),
      backupIds: record(z.string(), z.string().min(1).optional())
        .optional()
        .refine(
          (backupIds) =>
            backupIds === undefined || Object.keys(backupIds).length > 0
        ),
      backupTypes: z.array(z.string()).optional(), // Optional cause there may be errors
      isDeletable: z.boolean(),
    })
    .superRefine((data, ctx) => {
      //#region Check we have a filled data source
      const dataSourceMatching = new Map<DataSource, keyof Schema>();
      dataSourceMatching.set(DataSource.Environment, 'environmentSource');
      dataSourceMatching.set(DataSource.Backup, 'backupIds');

      switch (data.dataSource) {
        case DataSource.Environment:
          if (data.environmentSource === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['environmentSource'],
              message: t('required_field'),
            });
          }
          break;
        case DataSource.Backup:
          if (data.backupTypes === undefined) {
            throw new BadLogicException(
              'Backup option should not selectable when backup types could not be loaded'
            );
          }
          if (
            data.backupIds === undefined ||
            contain(
              Object.values(data.backupTypes),
              (backupType) => unwrap(data.backupIds)[backupType] === undefined
            )
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['backupIds'],
              message: t('required_field'),
            });
          }
          break;
        default:
          throw new UnhandledSwitchCaseException(
            `${data.dataSource} is unhandled in switch-case`
          );
      }

      //#endregion
    });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

/**
 * @param schema The schema data.
 * @param completer The data to add to schema to complete it.
 * @param isAdmin Is the user admin?
 * @returns The POST args extracted from the {@link schema}.
 */
export function schemaToPostArgs(
  schema: Schema,
  completer: { projectId: Project['id'] },
  isAdmin: boolean
): PostArgs {
  const gitReference = getGitReferenceInfo(schema).reference;

  const dataFrom =
    schema.dataSource === DataSource.Environment
      ? schema.environmentSource
      : undefined;

  const backupIds =
    schema.dataSource === DataSource.Backup
      ? Object.entries(unwrap(schema.backupIds)).reduce<Record<string, string>>(
          (acc, [key, val]) => {
            acc[key] = unwrap(val);
            return acc;
          },
          {}
        )
      : undefined;

  return stripUndefined({
    name: schema.name,
    project: new ProjectApi().getIriFromId(completer.projectId),
    isMonoVM: Boolean(schema.isMonoVM),
    isScalable: !schema.isMonoVM,
    gitReference,
    dataFromType: schema.dataSource,
    template: schema.template,
    dataFrom,
    backupIds,
    isDeletable: isAdmin ? schema.isDeletable : undefined,
  });
}

import React, { useEffect, useState } from 'react';
import { unwrapNull } from '../utilities/assertions';

/**
 * The properties for the {@link useHorizontalOverflowDetector}
 */
export interface UseHorizontalOverflowDetectorProps {
  /**
   * The element to check the overflow.
   */
  element: React.RefObject<HTMLElement>;
  /**
   * The parent to observe. Each time it is resized, the element overflow check will be performed.
   * Choose it wisely to save performance.
   *
   * If no parent is provided the html root element will be observed instead. Be aware,
   * that this is not the best solution performance-wise.
   */
  parent?: React.RefObject<HTMLElement>;
}

/**
 * @param props See {@link UseHorizontalOverflowDetectorProps}.
 * @returns `true` if the element overflows. `false` otherwise.
 */
export function useHorizontalOverflowDetector(
  props: UseHorizontalOverflowDetectorProps
) {
  const [overflow, setOverflow] = useState(
    props.element.current === null
      ? false
      : checkElementOverflow(props.element.current)
  );

  useEffect(() => {
    if (props.element.current === null) {
      setOverflow(false);
      return;
    }

    setOverflow(checkElementOverflow(props.element.current));

    let observee: HTMLElement = unwrapNull(document.querySelector('html'));
    if (props.parent !== undefined) {
      if (props.parent.current === null) {
        return;
      } else {
        observee = props.parent.current;
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      const element = props.element.current;
      if (element === null) return;

      setOverflow(checkElementOverflow(element));
    });

    resizeObserver.observe(observee);

    return () => {
      resizeObserver.disconnect();
    };
  }, [overflow, props.element, props.parent]);

  return overflow;
}

/**
 * Returns `true` if {@link el} overflows, `false` otherwise.
 */
function checkElementOverflow(el: HTMLElement): boolean {
  return el.offsetWidth < el.scrollWidth;
}

import { ClassUtilities } from '../../../../utilities/classUtility';

export interface ColorElementProps {
  color: string;
  selected: boolean;
  onSelected?: () => void;
  disabled?: boolean;
}

export function ColorElement(props: ColorElementProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'ColorElement w-8 h-8 stack',
        ClassUtilities.conditional({ 'cursor-pointer': !props.disabled })
      )}
      onClick={props.disabled ? undefined : props.onSelected}
    >
      <div
        className={ClassUtilities.flatten(
          'ColorElement-color rounded-full transition-[margin]',
          ClassUtilities.conditional({
            'm-1': props.selected,
          })
        )}
        style={{ background: props.color }}
      ></div>
      <div
        className={ClassUtilities.flatten(
          'ColorElement-selection-ring w-full h-full border-2 border-grey-800 rounded-full transition-opacity',
          ClassUtilities.conditional({
            'opacity-0': !props.selected,
          })
        )}
      ></div>
    </div>
  );
}

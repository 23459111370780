import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Checkbox, CheckboxProps } from './Checkbox';

export interface FormCheckboxProps<TFieldValues extends FieldValues>
  extends Omit<
    CheckboxProps,
    'name' | 'error' | 'helperText' | 'onChange' | 'value'
  > {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, unknown>;
}

export function FormCheckbox<TFieldValues extends FieldValues>(
  props: FormCheckboxProps<TFieldValues>
) {
  const { name, control, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Checkbox
          helperText={error?.message}
          error={!!error}
          onChange={onChange}
          value={value}
          checked={Boolean(value)}
          {...fieldProps}
        />
      )}
    />
  );
}

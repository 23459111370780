import { AlertColor } from '@mui/material';
import React from 'react';
import { create } from 'zustand';
import { UnhandledSwitchCaseException } from '../exceptions/unhandledSwitchCaseException';
import { fillOptions } from '../utilities/options';
import {
  PromiseSnapshot,
  PromiseSnapshotData,
  PromiseState,
} from '../utilities/promiseSnapshot';

/**
 * The arguments of the {@link SnackbarStoreState.handlePromise}.
 * Mostly functions to get the messages to display in various promise state.
 */
export interface HandlePromiseArgs<T> {
  //#region Display messages
  /**
   * @brief Returns the success message.
   * @param res The result of the successful promise.
   */
  getSuccessMessage: (res: T) => React.ReactNode;
  /**
   * @brief Returns the error message.
   * @param error The error thrown by the promise execution.
   */
  getErrorMessage: (error: unknown) => React.ReactNode;
  /**
   * @brief Returns the loading message.
   */
  getLoadingMessage: () => React.ReactNode;
  //#endregion
}

/**
 * Options for {@link SnackbarStoreState.handlePromise}.
 */
export interface HandlePromiseOptions {
  //#region Display durations
  /** Duration the success should be shown. `null` means it stays on screen indifinitely.
   * @default null
   */
  autoHideSuccessDuration: number | null;
  /** Duration the success should be shown. `null` means it stays on screen indifinitely.
   * @default null
   */
  autoHideErrorDuration: number | null;
  //#endregion

  /** Abort controller passed to the promise. */
  abortController: AbortController | null;
}

/**
 * Default values for {@link HandlePromiseOptions}.
 */
export const handlePromiseOptionsDefault: HandlePromiseOptions = {
  autoHideSuccessDuration: null,
  autoHideErrorDuration: null,
  abortController: null,
};

export type SnackbarSeverity = Exclude<AlertColor, 'warning'> | 'pending';

export interface SnackbarInfo {
  severity: SnackbarSeverity;
  message: React.ReactNode;
  /** Duration the success should be shown. `null` means it stays on screen indifinitely. */
  autoHideDuration: number | null;
}

export interface SnackbarStoreState {
  /**
   * Handles the promise, storing info about its state.
   * The aim of this information is to be displayed in a global snackbar widget.
   * @param name The unique name of the promise. (Please be careful, if several promises with the same name are handled by this function, the behavior will be most certainly unexpected.)
   * @param promise The function to call the promise to handle.
   * @param args See {@link HandlePromiseArgs}.
   * @param options See {@link HandlePromiseOptions}, defaults to {@link handlePromiseOptionsDefault}.
   */
  handlePromise: <T extends PromiseSnapshotData>(
    name: string,
    promise: () => Promise<T>,
    args: HandlePromiseArgs<T>,
    options?: Partial<HandlePromiseOptions>
  ) => Promise<T>;

  /**
   * Adds a standalone snackbar (not tighted to a promise).
   * @param name The unique name of the snackbar. (Please be careful, if several snackbar with the same name are handled by this store, the snackbar will not be shown.)
   * @param info See {@link SnackbarInfo}
   * @return `true` if the snackbar has been shown, `false` otherwise. See explanation in {@link name} parameters.
   */
  addStandaloneSnackbar(name: string, info: SnackbarInfo): boolean;

  /**
   * The snackbar information to allow those informations to be displayed by a widget.
   */
  widget_snackbarsInfo: Record<string, SnackbarInfo>;
  /**
   * Removes the information about the promise with the given {@link name}. This should only be called by the widget handling the display of this information.
   */
  widget_deleteSnackbarInfo: (name: string) => void;
}

export const useSnackbarStore = create<SnackbarStoreState>((set, get) => {
  return {
    async handlePromise<T extends PromiseSnapshotData>(
      name: string,
      promise: () => Promise<T>,
      args: HandlePromiseArgs<T>,
      options?: Partial<HandlePromiseOptions>
    ) {
      const filledOptions = fillOptions(options, handlePromiseOptionsDefault);

      function getInfo(snapshot: PromiseSnapshot<T>): SnackbarInfo {
        switch (snapshot.state) {
          case PromiseState.Running:
            return {
              severity: 'pending',
              message: args.getLoadingMessage(),
              autoHideDuration: null,
            };
          case PromiseState.Succeeded:
            return {
              severity: 'success',
              message: args.getSuccessMessage(snapshot.data as T),
              autoHideDuration: filledOptions.autoHideSuccessDuration,
            };
          case PromiseState.Failed:
            return {
              severity: 'error',
              message: args.getErrorMessage(snapshot.error),
              autoHideDuration: filledOptions.autoHideErrorDuration,
            };

          default:
            throw new UnhandledSwitchCaseException(
              'useSnackbarStore.handlePromise.getMessage'
            );
        }
      }

      let snapshot: PromiseSnapshot<T> = new PromiseSnapshot();
      for await (const current of PromiseSnapshot.trackPromise(promise, {
        abortController: filledOptions.abortController ?? undefined,
      })) {
        snapshot = current;

        if (snapshot.state === PromiseState.NotStarted) continue;

        const infos = { ...get().widget_snackbarsInfo };
        infos[name] = getInfo(current);
        set(() => ({
          widget_snackbarsInfo: infos,
        }));
      }

      if (snapshot.state === PromiseState.Succeeded) {
        return snapshot.data as T;
      } else {
        throw snapshot.error;
      }
    },
    addStandaloneSnackbar(name: string, info: SnackbarInfo) {
      const snackbarInfos = get().widget_snackbarsInfo;
      if (name in snackbarInfos) return false;
      snackbarInfos[name] = info;

      set({
        widget_snackbarsInfo: { ...snackbarInfos },
      });

      return true;
    },
    widget_snackbarsInfo: {},
    widget_deleteSnackbarInfo(name) {
      const info = { ...get().widget_snackbarsInfo };
      delete info[name];
      set({
        widget_snackbarsInfo: info,
      });
    },
  };
});

import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useTitle } from '../../../hooks/useTitle';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { Title } from '../../atoms/Title/Title';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { errorToTerm } from './utilities';

import { useEnvironmentActionTypesEstimates } from '../../../hooks/useEnvironmentActionTypesEstimates';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ToggleVirtualMachineType } from '../../../models/environment';
import { ClassUtilities } from '../../../utilities/classUtility';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { InformationText } from '../../atoms/InformationText/InformationText';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import {
  ActionShortInfoBlocks,
  ActionShortInfoBlocksProps,
} from '../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';
import { useEnvironmentVmsState } from './hooks/useEnvironmentVMsState';

export function ToggleVM() {
  const { t, tFormatted } = useTranslationFormatter();

  const [environmentId, environment] = useProjectEnvironmentsStore((state) => [
    state.environments.data?.[state.selectedEnvironmentIndex]?.id,
    state.getEnvironment(),
  ]);
  const vmsStateSnapshot = useEnvironmentVmsState(environmentId);

  useTitle(
    vmsStateSnapshot.data
      ? t('done_action_labels.stop-vm')
      : t('done_action_labels.start-vm')
  );

  // Fetch action of this env to know if another source update is in progress
  const anotherToggleVMInProgress = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.ToggleVm,
    environmentId,
  });

  const toggleVMEstimate = useEnvironmentActionTypesEstimates(
    environmentId,
    ActionTypeName.ToggleVm
  );

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t(
        vmsStateSnapshot.data
          ? 'done_action_labels.stop-vm'
          : 'done_action_labels.start-vm'
      ),
      environmentName: unwrap(environment).name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = () => {
    const currentlyOnline = unwrap(vmsStateSnapshot.data);
    submitAndWatchResult(() =>
      new EnvironmentApi().toggleVirtualMachines(unwrap(environmentId), {
        toggle: currentlyOnline
          ? ToggleVirtualMachineType.Stop
          : ToggleVirtualMachineType.Start,
      })
    );
  };

  const blocksStyleProps: ActionShortInfoBlocksProps['stylesProps'] = [
    {
      icon: {
        color: 'yellow-200',
        icon: 'icon-clock',
      },
      label: t('operation_estimated_duration'),
      className: 'grow',
    },
  ];

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.ToggleVm}>
      <div className="ToggleVM grid grid-cols-1 lg:grid-cols-aside-md gap-6 justify-items-stretch">
        <Block className="grow shrink flex flex-col gap-8">
          <Title
            level={2}
            className={ClassUtilities.conditional({
              'generic-placeholder text-transparent rounded-full':
                !vmsStateSnapshot.isSucceeded(),
            })}
          >
            {t(
              vmsStateSnapshot.data
                ? 'action_labels.stop-vm'
                : 'action_labels.start-vm'
            )}
          </Title>

          <InformationText>
            <span
              className={ClassUtilities.conditional({
                'generic-placeholder rounded-full':
                  !vmsStateSnapshot.isSucceeded(),
              })}
            >
              {tFormatted(
                vmsStateSnapshot.data
                  ? 'toggle_vm_vm_online'
                  : 'toggle_vm_vm_offline',
                { environment: environment?.name ?? '' },
                {
                  env: {
                    classes:
                      vmsStateSnapshot.data !== undefined ? 'text-orange' : '',
                  },
                  bold: {
                    classes: 'font-semibold',
                  },
                }
              )}
            </span>
          </InformationText>
          {anotherToggleVMInProgress && (
            <WarningBlock noShadow>
              {t('toggle_vm_already_occurring')}
            </WarningBlock>
          )}
          <RoundedFilledBlueButton
            color="info"
            onClick={onSubmit}
            disabled={loading || !vmsStateSnapshot.isSucceeded()}
            loading={loading}
            className="self-end"
          >
            <span
              className={ClassUtilities.conditional({
                'generic-placeholder rounded-full':
                  !vmsStateSnapshot.isSucceeded(),
              })}
            >
              {t(vmsStateSnapshot.data ? 'turn_off' : 'turn_on')}
            </span>
          </RoundedFilledBlueButton>
        </Block>
        <div className="min-w-48 flex flex-col gap-4 shrink grow">
          {toggleVMEstimate.map({
            succeeded: (data) => (
              <ActionShortInfoBlocks
                stylesProps={blocksStyleProps}
                valueDescriptions={[
                  DateFormatter.toDuration(data.estimatedDuration, t, {
                    maximumParts: 1,
                  }),
                ]}
              />
            ),
            failed: (err) => <ErrorBlock title="">{`${err}`}</ErrorBlock>,
            notStarted: 'running',
            running: () => (
              <ActionShortInfoBlocks stylesProps={blocksStyleProps} />
            ),
          })}
        </div>
      </div>

      {feedbackWidget}
    </ActionPageTemplate>
  );
}

import dayjs from 'dayjs';
import { create } from 'zustand';
import { EnvironmentApi, GetOptions } from '../api/environmentApi';
import { AbortedException } from '../exceptions/abortedException';
import { PartialEnvironment } from '../models/environment';
import { EnvironmentStatus } from '../models/environmentStatus';
import { Project } from '../models/project';
import { unwrap } from '../utilities/assertions';
import { PromiseSnapshot, PromiseState } from '../utilities/promiseSnapshot';
import { repeatPromiseUntil } from '../utilities/repeatedPromise';

export interface ProjectEnvironmentsStoreState {
  projectId: Project['id'] | undefined;
  context?: string;
  environments: PromiseSnapshot<PartialEnvironment[]>;
  selectedEnvironmentIndex: number;
  /**
   * Returns the current selected environment if there is any.
   */
  getEnvironment: () => PartialEnvironment | undefined;
  setSelectedEnvironmentIndex: (index: number, context?: string) => void;
  /** Lazily fetch environments */
  fetch: (projectId: Project['id'], force?: boolean) => void;
  _abortController: AbortController;
  /** Deletes the environment locally, no distant call is performed.
   *
   * @description Will also change the selected environment index if the index of the selected environment is the one of the environment to delete.
   */
  localDeleteEnvironment: (environmentId: PartialEnvironment['id']) => void;
  /** Replaces the environment locally, no distant call is performed. */
  localPatchEnvironment: (environment: PartialEnvironment) => void;
}

export const useProjectEnvironmentsStore =
  create<ProjectEnvironmentsStoreState>(
    (set, get): ProjectEnvironmentsStoreState => {
      const fetchEnvironments = async () => {
        const projectId = get().projectId;
        const options: GetOptions = {
          'deleted_at[strictly_after]': dayjs()
            .add(dayjs.duration(1, 'year'))
            .toDate(),
        };

        if (projectId !== undefined) {
          options['project[]'] = [projectId];
        }

        await PromiseSnapshot.trackPromiseSetter(
          () => new EnvironmentApi().getAll(options),
          (snapshot) =>
            set({
              environments: snapshot,
            }),
          { abortController: get()._abortController }
        );

        const snapshot = get().environments;
        if (snapshot.state !== PromiseState.Succeeded) return;
        for (const env of unwrap(snapshot.data)) {
          if (env.status === EnvironmentStatus.Creating) {
            refreshEnvironmentUntilCreated(env.id, get()._abortController);
          }
        }
      };

      /**
       * Refresh the environment while its status is {@link EnvironmentStatus.Creating}
       * @param environmentId The environment to check.
       * @param abortController The abort controller to abort the request.
       */
      const refreshEnvironmentUntilCreated = async (
        environmentId: PartialEnvironment['id'],
        abortController: AbortController
      ) => {
        const api = new EnvironmentApi();
        try {
          const runningEnv = await repeatPromiseUntil(
            () => api.get(environmentId, { abortController }),
            (env) => env.status !== EnvironmentStatus.Creating,
            10000,
            {
              initialDelay: true,
              abortController,
            }
          );

          const currentData = get().environments.data;
          if (currentData === undefined) return;

          const snapshot = new PromiseSnapshot<PartialEnvironment[]>();
          snapshot.state = PromiseState.Succeeded;
          snapshot.data = currentData.map((env) => {
            if (env.id === runningEnv.id) {
              return runningEnv;
            } else {
              return env;
            }
          });

          set({
            environments: snapshot,
          });
        } catch (e) {
          if (!(e instanceof AbortedException)) throw e;
        }
      };

      return {
        projectId: undefined,
        environments: new PromiseSnapshot(),
        selectedEnvironmentIndex: -1,
        setSelectedEnvironmentIndex(index, context) {
          set({ selectedEnvironmentIndex: index, context });
        },
        getEnvironment() {
          return get().environments.data?.[get().selectedEnvironmentIndex];
        },
        async fetch(projectId, force) {
          const oldSelectedEnvironment = get().getEnvironment();

          // If project id has changed
          if (projectId !== get().projectId || force === true) {
            // Abort any running promises
            get()._abortController.abort();
            set({
              _abortController: new AbortController(),
              environments: new PromiseSnapshot(),
              projectId,
            });
          }

          const fetchState = get().environments.state;
          if (
            fetchState === PromiseState.NotStarted ||
            fetchState === PromiseState.Failed
          ) {
            // Needed in order to avoid multiple useless calls.
            set({
              environments: PromiseSnapshot.buildRunning(),
            });
            await fetchEnvironments();
            const environments = get().environments.data;
            if (
              oldSelectedEnvironment !== undefined &&
              environments !== undefined
            ) {
              const newIndex = environments.findIndex(
                (env) => env.id === oldSelectedEnvironment.id
              );
              if (newIndex !== -1) {
                set({
                  selectedEnvironmentIndex: newIndex,
                });
              }
            }
          }
        },
        _abortController: new AbortController(),
        localDeleteEnvironment(environmentId) {
          //#region Filters environment to remove the environment with the given id.
          const currentEnvironments = unwrap(get().environments.data);
          const filteredEnvironments = currentEnvironments.filter(
            (env) => env.id !== environmentId
          );
          //#endregion

          //#region Handle selection change due to the environment deletion.
          const selectedEnvironmentId = get().getEnvironment()?.id;
          let selectedEnvironmentIndex: undefined | number = undefined;
          // No environment was selected
          if (selectedEnvironmentId === undefined) {
            // Keep selection on no environment.
            selectedEnvironmentIndex = -1;
          }
          // The selected environment is the environment to delete
          else if (selectedEnvironmentId === environmentId) {
            // Position the selection on the first environment if it exists or select none.
            selectedEnvironmentIndex = filteredEnvironments.length > 0 ? 0 : -1;
          }
          // The selected environment is not the environment to delete
          else {
            // Find the previously selected environment index into the new environment list.
            selectedEnvironmentIndex = unwrap(
              filteredEnvironments.findIndex(
                (env) => env.id === selectedEnvironmentId
              )
            );
          }
          //#endregion

          // Set the new values.
          set({
            environments: PromiseSnapshot.buildSucceeded(filteredEnvironments),
            selectedEnvironmentIndex,
          });
        },
        localPatchEnvironment(environment) {
          set({
            environments: PromiseSnapshot.buildSucceeded(
              unwrap(get().environments.data).map((env) =>
                env.id === environment.id ? environment : env
              )
            ),
          });
        },
      };
    }
  );

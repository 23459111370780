import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { GlobalLoadingScreen } from './components/molecules/GlobalLoadingScreen/GlobalLoadingScreen';
import { ProjectPicker } from './components/molecules/ProjectPicker/ProjectPicker';
import { Require } from './components/molecules/Require/Require';
import { RequireAdmin } from './components/molecules/RequireAdmin/RequireAdmin';
import { RequireAuth } from './components/molecules/RequireAuth/RequireAuth';
import { RequireEnvironment } from './components/molecules/RequireEnvironment/RequireEnvironment';
import { RequireProject } from './components/molecules/RequireProject/RequireProject';
import TokenWatcher from './components/molecules/TokenWatcher/TokenWatcher';
import { AdminTabPicker } from './components/organisms/AdminTabPicker/AdminTabPicker';
import { BillingCurrentMonth } from './components/organisms/BillingCurrentMonth/BillingCurrentMonth';
import { BillingDetails } from './components/organisms/BillingDetails/BillingDetails';
import { BillingHistory } from './components/organisms/BillingHistory/BillingHistory';
import { HistoryAction } from './components/organisms/HistoryAction/HistoryAction';
import { HistoryEnvironment } from './components/organisms/HistoryEnvironment/HistoryEnvironment';
import { Account } from './components/pages/Account/Account';
import { AdminBills } from './components/pages/AdminBills/AdminBills';
import { AdminCompanies } from './components/pages/AdminCompanies/AdminCompanies';
import { AdminCompanyEdition } from './components/pages/AdminCompanyEdition/AdminCompanyEdition';
import { AdminEnvironment } from './components/pages/AdminEnvironment/AdminEnvironment';
import { AdminProject } from './components/pages/AdminProject/AdminProject';
import { AdminProjectAddUser } from './components/pages/AdminProjectAddUser/AdminProjectAddUser';
import { AdminProjectEdition } from './components/pages/AdminProjectEdition/AdminProjectEdition';
import { AdminProjects } from './components/pages/AdminProjects/AdminProjects';
import { AdminUsers } from './components/pages/AdminUsers/AdminUsers';
import { Billing } from './components/pages/Billing/Billing';
import ComponentLibrary from './components/pages/ComponentLibrary/ComponentLibrary';
import { Deletion } from './components/pages/Deletion/Deletion';
import { Deploy } from './components/pages/Deploy/Deploy';
import { History } from './components/pages/History/History';
import { NewAdminProject } from './components/pages/NewAdminProject/NewAdminProject';
import { NewEnvironment } from './components/pages/NewEnvironment/NewEnvironment';
import { NotLogged } from './components/pages/NotLogged/NotLogged';
import { PageNotFound } from './components/pages/PageNotFound/PageNotFound';
import { PickFront } from './components/pages/PickFront/PickFront';
import { ProjectList } from './components/pages/ProjectList/ProjectList';
import { ProjectSettings } from './components/pages/ProjectSettings/ProjectSettings';
import { RenameEnvironment } from './components/pages/RenameEnvironment/RenameEnvironment';
import { RetryInitEnv } from './components/pages/RetryInitEnv/RetryInitEnv';
import { Rollback } from './components/pages/Rollback/Rollback';
import { Scaling } from './components/pages/Scaling/Scaling';
import { Specifications } from './components/pages/Specifications/Specifications';
import { Support } from './components/pages/Support/Support';
import { ToggleMaintenance } from './components/pages/ToggleMaintenance/ToggleMaintenance';
import { ToggleVM } from './components/pages/ToggleVM/ToggleVM';
import { UpdateSources } from './components/pages/UpdateSources/UpdateSources';
import { Workplace } from './components/pages/Workplace/Workplace';
import { MainPageHeaderSetter } from './components/templates/MainPageHeaderSetter/MainPageHeaderSetter';
import { MainPageTemplate } from './components/templates/MainPageTemplate/MainPageTemplate';
import { useCanEditProjectSettings } from './hooks/useCanEditProjectSettings';
import { useCanUseBilling } from './hooks/useCanUseBilling';
import { Development } from './utilities/development';

export const rawProjectRoute = '/project/:projectId';
export const rawEnvironmentRoute = '/environment/:environmentId';

export function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<TokenWatcher />}>
          <Route element={<GlobalLoadingScreen />}>
            {/* Public DEV routes */}
            {Development.environmentIsDev() && (
              <>
                <Route
                  path="/component-library"
                  element={<ComponentLibrary />}
                ></Route>
              </>
            )}

            <Route element={<MainPageTemplate />}>
              <Route
                index
                element={<Navigate to="project-list" replace />}
              ></Route>

              {/* Non authenticated routes */}
              <Route element={<MainPageHeaderSetter header={undefined} />}>
                <Route
                  path="project-list-no-rights"
                  element={<ProjectList />}
                />
                <Route path="support" element={<Support />} />
              </Route>

              {/* Authenticated routes */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Outlet />
                  </RequireAuth>
                }
              >
                {/* Route with no header */}
                <Route element={<MainPageHeaderSetter header={undefined} />}>
                  <Route path="project-list" element={<ProjectList />} />
                  <Route path="account" element={<Account />} />
                </Route>

                {/* Routes using main page template with project picker */}
                <Route
                  element={<MainPageHeaderSetter header={<ProjectPicker />} />}
                >
                  <Route path="project/:projectId" element={<RequireProject />}>
                    <Route
                      index
                      element={<Navigate to="environment/none" replace />}
                    />
                    <Route path="environment/:environmentId">
                      <Route
                        element={
                          <RequireEnvironment
                            allowNone
                            redirectWhenNonexistent
                          />
                        }
                      >
                        <Route index element={<Workplace />} />
                      </Route>

                      <Route element={<RequireEnvironment />}>
                        <Route
                          path="source-update"
                          element={<UpdateSources />}
                        ></Route>
                        <Route
                          path="new-environment"
                          element={<NewEnvironment />}
                        ></Route>
                        <Route path="rollback" element={<Rollback />} />
                        <Route path="scaling" element={<Scaling />} />
                        <Route path="deploy" element={<Deploy />} />
                        <Route path="deletion" element={<Deletion />} />
                        <Route path="retry-init" element={<RetryInitEnv />} />
                        <Route
                          path="toggle-maintenance"
                          element={<ToggleMaintenance />}
                        />
                        <Route path="toggle-vm" element={<ToggleVM />} />
                        <Route path="rename" element={<RenameEnvironment />} />
                        <Route path="pick-front" element={<PickFront />} />
                      </Route>
                    </Route>

                    <Route path="history" element={<History />}>
                      <Route path="action" element={<HistoryAction />}></Route>
                      <Route
                        path="environment"
                        element={<HistoryEnvironment />}
                      ></Route>
                      <Route index element={<Navigate to="action" replace />} />
                    </Route>

                    <Route
                      path="billing/"
                      element={
                        <Require
                          checkPredicate={useCanUseBilling()}
                          redirect="/"
                        >
                          <Billing />
                        </Require>
                      }
                    >
                      <Route
                        path="current-month"
                        element={<BillingCurrentMonth></BillingCurrentMonth>}
                      ></Route>
                      <Route
                        path="details"
                        element={<BillingDetails></BillingDetails>}
                      ></Route>
                      <Route
                        path="history"
                        element={<BillingHistory></BillingHistory>}
                      ></Route>
                      <Route
                        index
                        element={<Navigate to="current-month" replace />}
                      />
                    </Route>

                    <Route
                      path="specifications"
                      element={<Specifications />}
                    ></Route>

                    <Route
                      path="settings"
                      element={
                        <Require
                          checkPredicate={useCanEditProjectSettings()}
                          redirect={'/'}
                        >
                          <Outlet />
                        </Require>
                      }
                    >
                      <Route index element={<ProjectSettings />} />
                    </Route>
                  </Route>
                </Route>

                {/* Admin routes */}
                <Route
                  path="admin"
                  element={
                    <RequireAdmin redirect="/">
                      <MainPageHeaderSetter
                        header={<AdminTabPicker />}
                        headerAlignBottom
                      />
                    </RequireAdmin>
                  }
                >
                  <Route path="projects" element={<AdminProjects />}></Route>
                  <Route index element={<Navigate to="projects" replace />} />

                  <Route path="projects/new" element={<NewAdminProject />} />
                  <Route
                    path="projects/:projectId"
                    element={<AdminProject />}
                  />
                  <Route
                    path="projects/:projectId/edit"
                    element={<AdminProjectEdition />}
                  />
                  <Route
                    path="projects/:projectId/environments/:environmentId"
                    element={<AdminEnvironment />}
                  />
                  <Route
                    path="projects/:projectId/users/new"
                    element={<AdminProjectAddUser />}
                  />
                  <Route path="users" element={<AdminUsers />} />

                  <Route path="companies" element={<AdminCompanies />} />

                  <Route
                    path="companies/new"
                    element={<AdminCompanyEdition />}
                  />
                  <Route
                    path="companies/:companyId/edit"
                    element={<AdminCompanyEdition />}
                  />

                  <Route path="bills" element={<AdminBills />} />
                </Route>
              </Route>

              {/* Non authenticated route */}
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
        <Route path="/connexion-problem" element={<NotLogged />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

import { Entity } from './entity';
import { Payment } from './payment';
import { Project } from './project';

export interface BillLine {
  '@id': string;
  description: string;
  quantity: number;
  unitPrice: number;
  total: number;
}

export enum BillStatus {
  New = 'new',
  Sent = 'sent',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid',
}

export interface Bill extends Entity {
  name: string;
  referenceNumber: number;
  date: Date;
  totalExclTax: number;
  totalInclTax: number;
  totalVAT: number;
  lines: BillLine[];
  pdfUri: string;
  project: Project;
  updatedAt: Date;
  formattedReference: string;
  status: BillStatus;
  payments: Payment[];
  totalPaid: number;
}

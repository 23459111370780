import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionHistoryApi } from '../../../../api/actionHistoryApi';
import { ActionHistory } from '../../../../models/actionHistory';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../utilities/promiseSnapshot';
import { LoadingBlock } from '../../../atoms/LoadingBlock/LoadingBlock';
import { Logs } from '../../../atoms/Logs/Logs';
import { Modal, ModalProps } from '../../../atoms/Modal/Modal';
import { Title } from '../../../atoms/Title/Title';

export interface ActionHistoryModalProps
  extends Pick<ModalProps, 'open' | 'onClose'> {
  actionHistoryId: ActionHistory['id'];
}

export function ActionHistoryModal(props: ActionHistoryModalProps) {
  const { actionHistoryId, ...modalProps } = props;
  const { t } = useTranslation();
  const [logsSnapshot, setLogsSnapshot] = useState(
    new PromiseSnapshot<string[]>()
  );
  const abortController = useRef(new AbortController());

  // Abort any log fetch if modal is destroyed.
  useEffect(() => {
    const currentAbortController = abortController.current;

    return () => {
      currentAbortController.abort();
    };
  }, []);

  // Abort any log fetch if modal is closed
  useEffect(() => {
    if (!props.open) {
      abortController.current.abort();
    }
  }, [props.open]);

  // Fetch logs if modal if opened
  useEffect(() => {
    if (logsSnapshot.state === PromiseState.Succeeded) return;
    if (!props.open) return;

    const anAbortController = new AbortController();
    abortController.current = anAbortController;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ActionHistoryApi().getActionHistoryLogs(props.actionHistoryId, {
          abortController: anAbortController,
        }),
      setLogsSnapshot,
      { abortController: anAbortController }
    );
  }, [logsSnapshot.state, props.actionHistoryId, props.open]);

  return (
    <Modal className="ActionHistoryModal" {...modalProps}>
      <div className="flex flex-col gap-2 lg:gap-6 max-w-full transition-[gap]">
        <Title level={2}>{t('logs')}</Title>
        {logsSnapshot.state === PromiseState.Succeeded ? (
          <Logs
            logs={logsSnapshot.getSucceededData()}
            className="max-w-7xl max-h-96"
          />
        ) : (
          <LoadingBlock />
        )}
      </div>
    </Modal>
  );
}

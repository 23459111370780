import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';
import './LogsLoadingBlock.css';

export interface LogsLoadingBlockProps {
  /** Overrides the icon of the loading block.  */
  icon?: React.ReactElement;
  /** Overrides the text of the loading block. */
  text?: React.ReactNode;
  /** Do not use rounded corner, default: `false`. */
  noRoundedCorner?: boolean;
}

export function LogsLoadingBlock(props: LogsLoadingBlockProps) {
  const { t } = useTranslation();
  const noRoundedCorner = props.noRoundedCorner ?? false;

  return (
    <div
      className={ClassUtilities.flatten(
        'LogsLoadingBlock flex gap-4 justify-center p-4 bg-black items-center text-white min-h-full',
        ClassUtilities.conditional({
          rounded: !noRoundedCorner,
        })
      )}
    >
      {props.icon ?? <i className="icon-loader animate-spin-slow" />}
      <span className="">{props.text ?? t('loading_logs')}</span>
    </div>
  );
}

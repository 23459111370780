import { useTranslation } from 'react-i18next';
import { Title } from '../../../atoms/Title/Title';

export function InformationBlock() {
  const { t } = useTranslation();

  return (
    <div className="order-1 lg:-order-1">
      <Title level={3}>{t('informations')}</Title>
      <ul className="list-disc ml-4 flex flex-col transition-[gap] lg:gap-2">
        <li>{t('monthly_estimation_info_1')}</li>
        <li>{t('monthly_estimation_info_2')}</li>
        <li>{t('monthly_estimation_info_3')}</li>
        <li>{t('monthly_estimation_info_4')}</li>
      </ul>
    </div>
  );
}

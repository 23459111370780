/**
 * A container for a nested level of menu.
 */
export function SubMenu(props: SubMenuProps) {
  return (
    <div className="SubMenu shadow-inner flex md:flex-col items-stretch bg-grey-100">
      {props.children}
    </div>
  );
}

export interface SubMenuProps {
  children?: React.ReactNode;
}

import { z } from 'zod';

export const schema = z.object({
  enabled: z.boolean(),
  succeededAction: z.boolean(),
  failedAction: z.boolean(),
  costWarning: z.boolean(),
});

export type Schema = z.infer<typeof schema>;

import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { Bill } from './bill';
import { Company } from './company';
import { Entity } from './entity';
import { Environment } from './environment';
import { User } from './user';
import { UserProjectRole } from './userProjectRole';

export interface Project extends Entity {
  name: string;
  identifier: string;
  imgUri?: string;
  environments: Environment['id'][];
  bills: Bill['id'][];
  userProjectRoles: UserProjectRole['id'][];
  contact?: User['id'];
  billingCompany:
    | Pick<Company, 'id' | 'imgUri' | 'name' | 'billingContactFullname'>
    | undefined;
  gitRepositoryURL: string;
  monoVMDataUpdateCountPerWeek?: number;
  monoVMEnvironmentLimit?: number;
  multiVMEnvironmentLimit?: number;
  margin?: number;
  canAnImageBeUsedInAnyEnvironment?: boolean;
}

export interface ProjectPartial extends Omit<Project, 'billingCompany'> {
  billingCompany?: Company['id'];
}

export interface RawInitEnvEstimate {
  estimatedDuration: {
    inMinutes: number;
  };
  estimatedTotalInclTax: {
    monoVm: number;
    multiVm: number;
  };
}

export interface InitEnvEstimate
  extends Omit<RawInitEnvEstimate, 'estimatedDuration'> {
  estimatedDuration: Duration;
}

export function refineInitEnvEstimate(
  rawInitEnvEstimate: RawInitEnvEstimate
): InitEnvEstimate {
  return {
    ...rawInitEnvEstimate,
    estimatedDuration: dayjs.duration({
      minutes: rawInitEnvEstimate.estimatedDuration.inMinutes,
    }),
  };
}

export interface ProjectPricing {
  nameTransKey: string;
  descriptionTransKey: string;
  provisioningTransKey: string;
  price: number;
}

export interface ProjectSpecifications {
  gitRepositoryUrl: string | null;
  monoVMDataUpdateCountPerWeek: number | null;
  dataBackupPolicy: DataBackupPolicy;
  environmentCount: {
    monoVm: number | null;
    multiVm: number | null;
  };
  monoServerSizing: ServerSizing[];
  multiServerSizing: ServerSizing[];
}

export interface ServerSizing {
  sizing: string;
  isTina: boolean;
  count: number;
  type?: string;
  cpu?: number;
  disk?: number;
  memory?: number;
}

export type DataBackupPolicy = {
  days?: number;
  periodicity?: 'second' | 'hour' | 'day' | 'month' | 'year';
  retentionPeriodicity?: 'second' | 'hour' | 'day' | 'month' | 'year';
  retentionDays?: number;
} | null;

export type ContactPatch = {
  contactId: User['id'] | null;
};

export type PatchArgs = Pick<Project, 'gitRepositoryURL'> & {
  /** The id of the billing {@link Company}. */
  billingCompany: Company['id'] | null;
  monoVMDataUpdateCountPerWeek: number | undefined;
  monoVMEnvironmentLimit: number | undefined;
  multiVMEnvironmentLimit: number | undefined;
  margin: number | undefined;
  canAnImageBeUsedInAnyEnvironment: boolean | undefined;
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useProjectAndEnvironmentRoute } from '../../../hooks/useProjectAndEnvironmentRoute';
import { useProjectRoute } from '../../../hooks/useProjectRoute';
import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ActionStatus } from '../../../models/actionStatus';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import {
  RoundedFilledGrayButton,
  RoundedFilledRedButton,
} from '../../atoms/Button/variations/RoundedButtonVariations';
import { Modal } from '../../atoms/Modal/Modal';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { errorToTerm } from './utilities';

export function Deletion() {
  const { t, tFormatted } = useTranslationFormatter();
  useTitle(t('deletion'));

  const navigate = useNavigate();
  const workplaceRoute = useProjectAndEnvironmentRoute();
  const projectRoute = useProjectRoute();

  const [
    environmentsSnapshot,
    selectedEnvironmentIndex,
    localDeleteEnvironment,
  ] = useProjectEnvironmentsStore((state) => [
    state.environments,
    state.selectedEnvironmentIndex,
    state.localDeleteEnvironment,
  ]);
  const environmentId =
    environmentsSnapshot.data?.[selectedEnvironmentIndex]?.id;
  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  const alreadyDeleting = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.DeleteEnv,
    environmentId,
  });
  const [alreadyDeletingModalOpen, setAlreadyDeletingModalOpen] =
    useState(false);
  const [disableBackRoute, setDisableBackRoute] = useState(false);

  useEffect(() => {
    if (alreadyDeleting === true) {
      setAlreadyDeletingModalOpen(true);
    }
  }, [alreadyDeleting]);

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('env_delete'),
      environmentName: selectedEnvironment?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = () => {
    setDisableBackRoute(true);
    submitAndWatchResult(
      () =>
        new EnvironmentApi().deleteAndGetActionHistory(unwrap(environmentId)),
      {
        onSubmissionSuccess: (actionHistory) => {
          if (actionHistory.status !== ActionStatus.Failed) {
            const envId = unwrap(environmentId);
            localDeleteEnvironment(envId);
            if (window.location.href.includes(envId)) {
              navigate(projectRoute);
            }
          }
        },
      }
    ).then((success) => setDisableBackRoute(!success));
  };

  return (
    <ActionPageTemplate
      actionTypeName={ActionTypeName.DeleteEnv}
      disableBackRoute={disableBackRoute}
    >
      <div className="Delete flex">
        <Block className="flex flex-col gap-8">
          <Title level={2}>{t('delete_environment')}</Title>
          <WarningBlock noShadow>
            {t('warning_action_irreversible')}
          </WarningBlock>
          <Title level={4}>
            {tFormatted(
              'confirm_delete_environment',
              {
                environment: selectedEnvironment?.name ?? '',
              },
              {
                environment: {
                  classes: 'text-orange',
                },
              }
            )}
          </Title>
          <div className="flex justify-end gap-2">
            <RoundedFilledRedButton
              color="info"
              disabled={alreadyDeleting !== false || loading}
              loading={alreadyDeleting === undefined || loading}
              className="flex gap-2"
              onClick={onSubmit}
            >
              {t('yes_delete')}
            </RoundedFilledRedButton>
            <RoundedFilledGrayButton
              color="info"
              onClick={() => navigate(workplaceRoute)}
            >
              {t('no_cancel')}
            </RoundedFilledGrayButton>
          </div>
        </Block>
        <Modal
          open={alreadyDeletingModalOpen}
          onClose={() => setAlreadyDeletingModalOpen(false)}
        >
          <div className="flex flex-col gap-4">
            <Title level={3}>{t('env_deletion_in_progress')}</Title>
            <p className="text-smd">
              {t('env_deletion_already_in_progress')}
              <br />
              {t('wait_till_the_end_of_operation')}
            </p>
          </div>
        </Modal>
      </div>
      {feedbackWidget}
    </ActionPageTemplate>
  );
}

import { useTranslationFormatter } from '../../../../hooks/useTranslationFormatter';
import { ProjectPartial } from '../../../../models/project';
import { DeletionModal } from '../../../molecules/DeletionModal/DeletionModal';

/**
 * Properties for {@link ProjectDeletionModal}.
 */
export type ProjectDeletionModalProps = {
  /**
   * If `true`, the component is shown.
   */
  open: boolean;
  /**
   * Callback fired when the user clicks on the confirmation button to delete the project.
   */
  onSubmitDelete: () => void;
  /**
   * Callback fired when the user clicks on the cancel button.
   */
  onCloseModal: () => void;
  /**
   * The project to be deleted. `undefined` when the modal is closed.
   */
  project?: ProjectPartial;
  /**
   * If `true`, the request to delete the project is currently processing.
   */
  loading: boolean;
};

/**
 * Confirmation modal to delete a project.
 * @param props See {@link ProjectDeletionModalProps}
 */
export function ProjectDeletionModal(props: ProjectDeletionModalProps) {
  const { t, tFormatted } = useTranslationFormatter();

  return (
    <>
      <DeletionModal
        title={t('delete_project')}
        text={tFormatted(
          'confirm_delete_project',
          { project: props.project?.name ?? '' },
          {
            project: {
              classes: 'text-orange',
            },
          }
        )}
        open={props.open}
        onSubmitDelete={props.onSubmitDelete}
        onCloseModal={props.onCloseModal}
        loading={props.loading}
        isIrreversible
        confirmUsingText={props.project?.name}
      ></DeletionModal>
    </>
  );
}

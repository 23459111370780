import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import { Bill } from '../../../models/bill';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { NumberFormatter } from '../../../utilities/numberFormatter';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { TablePagination } from '../../atoms/TablePagination/TablePagination';
import { BillingLinesModal } from '../../molecules/BillingLinesModal';
import { useBills } from './hooks/useBills';
import { DetailsButton } from './subcomponents/DetailsButton';
import { DownloadButton } from './subcomponents/DownloadButton';
import { errorToTerm } from './utilities';

export function BillingHistory() {
  const { t } = useTranslation();

  const { paginatedBillsSnapshot, page, setPage } = useBills();

  useTitle(t('billing_history'));

  const [openedBillForDetails, setOpenedBillForDetails] = useState<Bill | null>(
    null
  );

  return (
    <div className="BillingHistory py-8">
      {paginatedBillsSnapshot.map({
        notStarted: 'running',
        running: () => <LoadingBlock />,
        failed: (error) => (
          <ErrorBlock noShadow>
            {t('bills_retrieval_error', { reason: t(errorToTerm(error)) })}
          </ErrorBlock>
        ),
        succeeded: (paginatedBills) => (
          <>
            <TableContainer component="div" className="hidden lg:block">
              <Table size="small" stickyHeader sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('period')}</TableCell>
                    <TableCell>{t('total_excl_tax')}</TableCell>
                    <TableCell>{t('total_incl_tax')}</TableCell>
                    <TableCell align="center" sx={{ minWidth: 0 }}>
                      {t('bill')}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: 0 }}>
                      {t('details')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="whitespace-nowrap">
                  {paginatedBills['hydra:member'].map((bill) => (
                    <TableRow key={bill.id} className="table-row">
                      <TableCell>{DateFormatter.toMonth(bill.date)}</TableCell>
                      <TableCell>
                        {NumberFormatter.toEuro(bill.totalExclTax)}
                      </TableCell>
                      <TableCell>
                        {NumberFormatter.toEuro(bill.totalInclTax)}
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          <DownloadButton bill={bill} />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          <DetailsButton
                            onClick={() => setOpenedBillForDetails(bill)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="lg:hidden flex flex-col gap-4">
              {paginatedBills['hydra:member'].map((bill) => (
                <DataPaper
                  key={bill.id}
                  header={DateFormatter.toMonth(bill.date)}
                  content={[
                    <DataPaperElement
                      key="total_excl_tax"
                      label={t('total_excl_tax')}
                      value={NumberFormatter.toEuro(bill.totalExclTax)}
                    />,
                    <DataPaperElement
                      key="total_incl_tax"
                      label={t('total_incl_tax')}
                      value={NumberFormatter.toEuro(bill.totalInclTax)}
                    />,
                    <DataPaperElement
                      key="bill-download"
                      label={t('bill')}
                      value={<DownloadButton bill={bill} />}
                    />,
                    <DataPaperElement
                      key="details"
                      label="Détails"
                      value={
                        <DetailsButton
                          onClick={() => setOpenedBillForDetails(bill)}
                        />
                      }
                    />,
                  ]}
                />
              ))}
            </div>

            <TablePagination
              count={paginatedBills['hydra:totalItems']}
              onPageChange={setPage}
              page={page}
            />
          </>
        ),
      })}

      <BillingLinesModal
        openedBill={openedBillForDetails}
        onCloseBill={() => setOpenedBillForDetails(null)}
      />
    </div>
  );
}

import { all } from '../utilities/array';
import { Environment } from './environment';

export interface VirtualMachine {
  name: string;
  description: string;
  instanceType: string;
  vcpu: number;
  ram: number;
  disk: number;
  sshCommand: string;
  status: NetboxStatus;

  // Linked entities
  environmentId?: Environment['id'];
}

/**
 * The status of the {@link VirtualMachine}.
 *
 * Extracted from Netbox API: https://demo.netbox.dev/api/docs/
 */
export enum NetboxStatus {
  Offline = 'offline',
  Active = 'active',
  Planned = 'planned',
  Staged = 'staged',
  Failed = 'failed',
  Decommissionning = 'decommissionning',
}

export enum VirtualMachineType {
  Database,
  Proxy,
  JumpHost,
  Front,
  Unknown,
}

export class VirtualMachineHandler {
  constructor(public virtualMachine: VirtualMachine) {}

  /**
   * @remarks The {@link vms} are considered online if none of them are offline.
   *
   * @param vms The {@link VirtualMachine}s.
   * @returns `true` is the vms are considered online. `false` otherwise.
   */
  public static consideredOnline(vms: VirtualMachine[]): boolean {
    return all(vms, (vm) => vm.status !== NetboxStatus.Offline);
  }

  /**
   * @param vms The {@link VirtualMachine}s.
   * @returns `true` is the vms are considered offline. `false` otherwise.
   */
  public static consideredOffline(vms: VirtualMachine[]): boolean {
    return !VirtualMachineHandler.consideredOnline(vms);
  }
}

import { AssertionException } from '../../../exceptions/assertionException';
import { Option } from './Autocomplete';

/**
 * @returns `true` if {@link value} is an {@link Option}.
 */
export function isOption<T>(value: unknown): value is Option<T> {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 2 &&
    'label' in value &&
    'value' in value
  );
}

/**
 * Asserts {@link value} is an {@link Option<T>}.
 */
export function assertIsOption<T>(value: unknown): asserts value is Option<T> {
  if (!isOption(value)) {
    throw new AssertionException('Value is not an option as it was assumed.');
  }
}

import { WithTranslation } from 'react-i18next';
import { z } from 'zod';

export function getSchema(t: WithTranslation['t']) {
  return z
    .object({
      prescalingFrontNumber: z.literal('').or(z.number()),
      frontNumber: z
        .literal('')
        .or(z.number())
        .refine((val) => val !== '', 'required_field'),
    })
    .superRefine((data, ctx) => {
      if (data.prescalingFrontNumber === data.frontNumber) {
        ctx.addIssue({
          code: 'custom',
          path: ['frontNumber'],
          message: 'scaling_front_number_unchanged',
        });
      }
    });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

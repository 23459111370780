import {
  Alert,
  ButtonGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProjectApi } from '../../../api/projectApi';
import { Exception } from '../../../exceptions/exception';
import { useTitle } from '../../../hooks/useTitle';
import { ProjectPartial } from '../../../models/project';
import { ApiPlatformList } from '../../../utilities/baseApi';
import { MockData } from '../../../utilities/mockData';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { Button } from '../../atoms/Button/Button';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { Modal } from '../../atoms/Modal/Modal';
import { TablePagination } from '../../atoms/TablePagination/TablePagination';
import { TextField } from '../../atoms/TextField/TextField';
import { Title } from '../../atoms/Title/Title';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { ActionBlock } from './subcomponents/ActionBlock';
import { AdminUserLink } from './subcomponents/AdminUserLink';
import { ProjectDeletionModal } from './subcomponents/ProjectDeletionModal';
import { errorToTerm } from './utilities';

type ProjectFilters = {
  identifier?: string;
  name?: string;
};

export function AdminProjects() {
  const [projects, setProjects] = useState<
    PromiseSnapshot<ApiPlatformList<ProjectPartial>>
  >(new PromiseSnapshot());
  const [page, setPage] = useState<number>(1);
  const count = useMemo(
    () => projects.data?.['hydra:totalItems'] ?? 0,
    [projects]
  );
  const abortController = useRef(new AbortController());
  const identifierFieldRef = useRef<HTMLInputElement>(null);
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useTitle(t('administration_projects'));

  const fetch = useCallback(function (page = 1, filters: ProjectFilters = {}) {
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ProjectApi().getAllPaginated(
          {
            page,
            identifier: filters.identifier || undefined,
            name: filters.name || undefined,
          },
          { abortController: abortController.current }
        ),
      setProjects
    );
  }, []);

  useEffect(() => {
    fetch();

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [fetch]);

  const [openProjectDeletionModal, setOpenProjectDeletionModal] =
    useState(false);
  const [projectToDelete, setProjectToDelete] = useState<
    ProjectPartial | undefined
  >(undefined);

  const handleProjectDeletionModal = (project: ProjectPartial) => {
    setOpenProjectDeletionModal(true);
    setProjectToDelete(project);
  };

  const closeProjectDeletionModal = () => {
    setOpenProjectDeletionModal(false);
    setProjectToDelete(undefined);
  };

  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [deletingProject, setDeletingProject] = useState(false);
  const [errorModalText, setErrorModalText] = useState<string | undefined>(
    undefined
  );

  const onSubmitDelete = async () => {
    if (projectToDelete) {
      setDeletingProject(true);
      await new ProjectApi()
        .delete(projectToDelete.id)
        .then(() => {
          closeProjectDeletionModal();
          fetch();
          setDeletionSuccess(true);
        })
        .catch((reason: Exception) => {
          setErrorModalText(t(errorToTerm(reason)) ?? undefined);
        })
        .finally(() => setDeletingProject(false));
    }
  };

  const deletionFeedbackWidget = (
    <>
      <Modal
        open={deletionSuccess}
        onClose={() => setDeletionSuccess(false)}
        doNotUseCardWrapper
      >
        <Alert severity="success">{t('project_deleted')}</Alert>
      </Modal>
      <Modal
        open={errorModalText !== undefined}
        onClose={() => setErrorModalText(undefined)}
        doNotUseCardWrapper
      >
        <Alert severity="error">{errorModalText && errorModalText}</Alert>
      </Modal>
    </>
  );

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    fetch(newPage);
  };

  const handleChangeFilters = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    filters: ProjectFilters
  ) => {
    setPage(newPage);
    fetch(newPage, filters);
  };

  return (
    <AdminPageTemplate>
      <div>
        <ActionBlock></ActionBlock>
        <div className="h-6"></div>
        <Block>
          <Title level={2}>{t('projects_list')}</Title>
          <div className="h-6"></div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <TextField
              label={t('project_identifier')}
              inputRef={identifierFieldRef}
              placeholder={MockData.uuidV4}
            />
            <TextField label={t('project_name')} inputRef={nameFieldRef} />
            <Button
              variant="contained"
              onClick={() => {
                handleChangeFilters(null, 1, {
                  name: nameFieldRef.current?.value,
                  identifier: identifierFieldRef.current?.value,
                });
              }}
              sx={{}}
              startIcon={<i className="icon-search"></i>}
            >
              {t('search')}
            </Button>
          </div>
          <div className="h-6"></div>
          {projects.map({
            notStarted: 'running',
            running: () => (
              <LoadingBlock
                icon={<i className="icon-search animate-search" />}
                text={t('search_in_progress')}
              />
            ),
            failed: (error) => {
              console.error(error);
              return <ErrorBlock noShadow>{`${error}`}</ErrorBlock>;
            },
            succeeded: (paginatedProjects) => (
              <>
                <TableContainer className="hidden lg:block" component={'div'}>
                  <Table sx={{ minWidth: 650 }} aria-label="projects table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('project_identifier')}</TableCell>
                        <TableCell>{t('project_name')}</TableCell>
                        <TableCell>{t('referrer_contact')}</TableCell>
                        <TableCell>{t('actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedProjects['hydra:member'].map(
                        (project: ProjectPartial) => {
                          return (
                            <TableRow key={project.id}>
                              <TableCell>{project.identifier}</TableCell>
                              <TableCell>{project.name}</TableCell>
                              <TableCell>
                                <AdminUserLink
                                  userId={project.contact}
                                ></AdminUserLink>
                              </TableCell>
                              <TableCell>
                                <ButtonGroup
                                  variant="text"
                                  aria-label="text button group"
                                >
                                  <Tooltip title={t('show_edit_project')}>
                                    <Link to={'/admin/projects/' + project.id}>
                                      <IconButton size="small">
                                        <i className="icon-edit text-orange"></i>
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                  <div className="w-2"></div>
                                  <Tooltip title={t('delete_project')}>
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleProjectDeletionModal(project)
                                      }
                                    >
                                      <i className="icon-trash text-orange"></i>
                                    </IconButton>
                                  </Tooltip>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="flex flex-col gap-4 px-1 lg:hidden">
                  {paginatedProjects['hydra:member'].map(
                    (project: ProjectPartial) => (
                      <DataPaper
                        key={project.id}
                        header={project.id}
                        content={[
                          <DataPaperElement
                            key="project_name"
                            label={t('project_name')}
                            value={project.name}
                          />,
                          <DataPaperElement
                            key="referrer_contact"
                            label={t('referrer_contact')}
                            value={
                              <AdminUserLink
                                userId={project.contact}
                              ></AdminUserLink>
                            }
                          />,
                          <DataPaperElement
                            key="actions"
                            label={t('actions')}
                            value={
                              <ButtonGroup
                                variant="text"
                                aria-label="text button group"
                              >
                                <Tooltip title={t('show_edit_project')}>
                                  <IconButton
                                    size="small"
                                    href={'/admin/projects/' + project.id}
                                  >
                                    <i className="icon-edit text-orange"></i>
                                  </IconButton>
                                </Tooltip>
                                <div className="w-4"></div>
                                <Tooltip title={t('delete_project')}>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleProjectDeletionModal(project)
                                    }
                                  >
                                    <i className="icon-trash text-orange"></i>
                                  </IconButton>
                                </Tooltip>
                              </ButtonGroup>
                            }
                          />,
                        ]}
                      ></DataPaper>
                    )
                  )}
                </div>
                <TablePagination
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                />
                <ProjectDeletionModal
                  open={openProjectDeletionModal}
                  project={projectToDelete}
                  loading={deletingProject}
                  onCloseModal={closeProjectDeletionModal}
                  onSubmitDelete={onSubmitDelete}
                ></ProjectDeletionModal>
                {deletionFeedbackWidget}
              </>
            ),
          })}
        </Block>
      </div>
    </AdminPageTemplate>
  );
}

import { ParameterException } from '../exceptions/parameterException';

/**
 * @param begin The minimum number to include in the array.
 * @param end The maximum number to include in the array.
 * @param step The step to use for generating the numbers between `min` and `max`, should not be lesser or equals to 0.
 * @returns A generated array going from min to max (included) where number are incremented by `step`.
 */
export function range(begin: number, end: number, step = 1): number[] {
  if (step === 0) {
    throw new ParameterException(
      `step parameter is 0, expected an integer different than 0.`
    );
  }
  if (!((step > 0 && begin <= end) || (step < 0 && begin >= end))) {
    throw new ParameterException(
      step > 0
        ? `begin must be less than or equals to end if step is positive. (begin:${begin} <= end:${end} with step:${step}) is not true`
        : `begin must be greater than or equals to end if step is negative. (begin:${begin} >= end:${end} with step:${step}) is not true`
    );
  }

  const res: number[] = [];
  if (step > 0) {
    for (let i = begin; i <= end; i += step) {
      res.push(i);
    }
  } else {
    for (let i = begin; i >= end; i += step) {
      res.push(i);
    }
  }
  return res;
}

import { ClassUtilities } from '../../../utilities/classUtility';

/**
 * Props for {@link DiscreteTextLine}.
 */
export interface DiscreteTextLineProps {
  /** Mandatory children to style. */
  children: React.ReactNode;
  /** Additional classes to add. */
  className?: string;
}

/** Styles text to be discrete. */
export function DiscreteTextLine(props: DiscreteTextLineProps) {
  return (
    <span
      className={ClassUtilities.flatten(
        'text-smd text-grey-500 italic',
        props.className
      )}
    >
      {props.children}
    </span>
  );
}

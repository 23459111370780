import { RouteParameterMissingException } from '../exceptions/routeParameterMissingException';
import { unwrap } from './assertions';

export type RouteArgType = number | string | boolean;

/**
 * Regroup useful method to route management.
 */
export class RouteUtilities {
  /**
   * @param fullRoute The full route to split.
   * @returns The route splitted into two parts, the beginning of the route and its last portion.
   * @example
   * const result = RouteUtilities.splitRouteIntoOriginLast('some/route/to/some/place');
   * console.log(result); // ["some/route/to/some", "part"]
   */
  static splitRouteIntoOriginLast(fullRoute: string): [string, string] {
    const portions = fullRoute.split('/');
    return [portions.slice(0, -1).join('/'), unwrap(portions.at(-1))];
  }

  /**
   * Constructs a route with a route model and its parameters.
   *
   * @example ```ts
   * const routeModel = 'project/{projectName}/task/{taskId}';
   * const args = {
   *     projectName: 'codeops',
   *     taskId: 12345
   * };
   * constructRoute(routeModel, args); // => 'project/codeops/task/12345'
   * ```
   *
   * @param routeModel The route model, use a similar syntax as Symfony route annotation.
   * @param args The arguments for filling the route model parameters.
   * @returns The constructed route.
   */
  static construct(
    routeModel: string,
    args: Record<string, RouteArgType> = {}
  ): string {
    let parameterizedRoute = routeModel;

    const regex = /{([^{}]+)}/g;
    const matches: Array<RegExpMatchArray> = [];

    for (const match of routeModel.matchAll(regex)) {
      matches.push(match);
    }

    // Process from right to left to preserve correct index while replacing.
    for (const match of matches.reverse()) {
      const paramName = match[1];
      if (!(paramName in args)) {
        throw new RouteParameterMissingException(paramName, routeModel);
      }

      const matchIndex = unwrap(match.index);
      const parameterString = this.stringifyParameter(args[paramName]);

      if (parameterString.length === 0) {
        throw new RouteParameterMissingException(
          paramName + ' (empty)',
          routeModel
        );
      }

      //#region Replace the match by the parameter value
      parameterizedRoute =
        parameterizedRoute.substring(0, matchIndex) +
        this.stringifyParameter(args[paramName]) +
        parameterizedRoute.substring(matchIndex + match[0].length);
      //#endregion
    }

    return parameterizedRoute;
  }

  /**
   * @param param The parameter to stringify
   * @returns The stringified parameter ready to be used as a route parameter.
   */
  static stringifyParameter(param: RouteArgType) {
    if (typeof param == 'boolean') {
      return param ? '1' : '0';
    }
    return param.toString();
  }
}

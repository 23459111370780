import { styled } from '@mui/material';
import { FilledRoundedButton } from './FilledRoundedButton';
import {
  blueFilledStyle,
  grayFilledStyle,
  greenFilledButton,
  lightGrayFilledStyle,
  redFilledButton,
} from './sharedBase';

export const RoundedFilledBlueButton =
  styled(FilledRoundedButton)(blueFilledStyle);
export const RoundedFilledLightGrayButton =
  styled(FilledRoundedButton)(lightGrayFilledStyle);
export const RoundedFilledGrayButton =
  styled(FilledRoundedButton)(grayFilledStyle);
export const RoundedFilledGreenButton =
  styled(FilledRoundedButton)(greenFilledButton);
export const RoundedFilledRedButton =
  styled(FilledRoundedButton)(redFilledButton);

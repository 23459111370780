import createTheme from '@mui/material/styles/createTheme';
import tailwindRawConfig from './tailwind.config.js';

const theme = createTheme({
  palette: {
    primary: {
      main: tailwindRawConfig.theme.extend.colors.orange.DEFAULT,
    },
    secondary: {
      main: tailwindRawConfig.theme.extend.colors.blue.DEFAULT,
    },
    info: {
      main: tailwindRawConfig.theme.extend.colors.blue[200],
      contrastText: tailwindRawConfig.theme.extend.colors.purple[500],
    },
  },
  typography: {
    fontFamily: 'Heebo',
    body1: {
      color: tailwindRawConfig.theme.extend.colors.grey[700],
    },
  },
});
export default theme;

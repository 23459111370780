import dayjs from 'dayjs';

export interface Image {
  id: string;
  createdAt: Date;
  environment: string;
  gitReference: string;
  isInUse: boolean;
}

/**
 * @param imageRaw The image as it is received in the request.
 * @returns The image ready to be used in the front.
 */
export function refine(imageRaw: RawImage): Image {
  const { createdAt, imageId, ...notTransformedAttributes } = imageRaw;
  return {
    id: imageId,
    createdAt: dayjs(createdAt).toDate(),
    ...notTransformedAttributes,
  };
}

/**
 * The image as it is received in the request.
 */
export interface RawImage {
  imageId: string;
  createdAt: string;
  environment: string;
  gitReference: string;
  isInUse: boolean;
}

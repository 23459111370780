import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { ActionType } from './actionType';
import { Entity } from './entity';
import { Environment } from './environment';

export interface EnvironmentActionType extends Entity {
  environment: Environment;
  actionType: ActionType;
  position: number;
}

export interface RawEnvironmentActionTypeEstimate {
  estimatedDuration: {
    inSeconds: number;
    inMinutes: number;
  };
  estimatedTotalInclTax?: {
    perMonth: number;
    perHour: number;
  };
}

export interface EnvironmentActionTypeServerSizingEstimate
  extends EnvironmentActionTypeEstimate {
  serverSizing: {
    cpu: number;
    memory: number;
    disk: number;
  };
}

export interface EnvironmentActionTypeEstimate
  extends Omit<RawEnvironmentActionTypeEstimate, 'estimatedDuration'> {
  estimatedDuration: Duration;
}

export function refineEnvironmentActionTypeEstimate(
  rawInitEnvEstimate: RawEnvironmentActionTypeEstimate
): EnvironmentActionTypeEstimate {
  return {
    ...rawInitEnvEstimate,
    estimatedDuration: dayjs.duration({
      seconds:
        rawInitEnvEstimate.estimatedDuration.inSeconds < 60
          ? rawInitEnvEstimate.estimatedDuration.inSeconds
          : rawInitEnvEstimate.estimatedDuration.inMinutes * 60,
    }),
  };
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EnvironmentApi } from '../api/environmentApi';
import { PartialEnvironment } from '../models/environment';
import { Project } from '../models/project';
import { ApiPlatformList } from '../utilities/baseApi';
import { fillOptions } from '../utilities/options';
import { PromiseSnapshot } from '../utilities/promiseSnapshot';
import * as URLSearchParamUtils from '../utilities/urlSearchParamUtilities';

export interface UseAllEnvironmentsFromProjectOptions {
  /**
   * Does the deleted environment needs to be excluded? Defaults to `false`.
   */
  excludeDeleted: boolean;
  /**
   * Page url key. Default: `page`.
   */
  pageUrlKey: string;
}

const useAllEnvironmentsFromProjectOptionsDefault: UseAllEnvironmentsFromProjectOptions =
  {
    excludeDeleted: false,
    pageUrlKey: 'page',
  };

type Return = {
  paginatedEnvironmentsSnapshot: PromiseSnapshot<
    ApiPlatformList<PartialEnvironment>
  >;
  page: number;
  setPage: (page: number) => void;
};

/**
 * @param projectId The {@link Project} id.
 * @param options See {@link UseAllEnvironmentsFromProjectOptions}.
 * @returns The environments belonging to the project as a snapshot.
 */
export function useAllEnvironmentsFromProject(
  projectId: Project['id'],
  options?: Partial<UseAllEnvironmentsFromProjectOptions>
): Return {
  const filledOptions = fillOptions(
    options,
    useAllEnvironmentsFromProjectOptionsDefault
  );
  const [snapshot, setSnapshot] = useState(
    new PromiseSnapshot<ApiPlatformList<PartialEnvironment>>()
  );
  const abortControllerRef = useRef(new AbortController());
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(
    () =>
      URLSearchParamUtils.getInt(searchParams, filledOptions.pageUrlKey) ?? 1,
    [filledOptions.pageUrlKey, searchParams]
  );

  const setPage = useCallback(
    (page: number) => {
      setSearchParams((prev) => {
        prev.set(filledOptions.pageUrlKey, page.toString());
        return prev;
      });
    },
    [filledOptions.pageUrlKey, setSearchParams]
  );

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new EnvironmentApi().getAllPaginated(
          {
            'project[]': [projectId],
            'deleted_at[after]': filledOptions.excludeDeleted
              ? null
              : undefined,
            page,
          },
          {
            abortController,
          }
        ),
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [filledOptions.excludeDeleted, page, projectId]);

  return { paginatedEnvironmentsSnapshot: snapshot, page, setPage };
}

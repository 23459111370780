import { styled } from '@mui/system';
import { Button, ButtonProps } from '../Button';

function OutlineRoundedButtonBase(props: Omit<ButtonProps, 'variant'>) {
  return <Button variant="outlined" {...props}></Button>;
}

export const OutlineRoundedButton = styled(OutlineRoundedButtonBase)({
  '&': {
    textTransform: 'none',
    borderRadius: '9999px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    paddingLeft: '20px',
    paddingRight: '20px',
    border: '1px solid',
  },
});

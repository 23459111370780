import { getKeys } from './recordUtilities';

/** Mapper type for each key of request options to sendable type. */
export type ParameterifyFunctionMapper<D> = {
  [K in keyof D]-?: (val: D[K]) => string | string[] | undefined;
};

/**
 * Defines how to convert options to query.
 * @template QO The query options.
 */
export abstract class QueryOptionsHandler<QO> {
  private _queryOptions: QO;

  /**
   * Constructs the {@link QueryOptionsHandler}.
   * @param queryOptions The raw options.
   */
  public constructor(queryOptions: QO) {
    this._queryOptions = queryOptions;
  }

  /**
   * @returns The search parameters for the URL.
   */
  public toQueryParams(): URLSearchParams {
    const urlSearchParams = new URLSearchParams();
    const mapper = this.stringMapper();

    for (const key of getKeys(this._queryOptions)) {
      const param = mapper[key](this._queryOptions[key]);
      if (param !== undefined) {
        if (typeof param === 'string') {
          urlSearchParams.append(key.toString(), param);
        } else {
          for (const el of param) {
            urlSearchParams.append(key.toString(), el);
          }
        }
      }
    }

    return urlSearchParams;
  }

  /**
   * @returns The string mapper specifying the way each field should be converted to string.
   */
  protected abstract stringMapper(): ParameterifyFunctionMapper<QO>;
}

import { useEffect, useMemo, useState } from 'react';
import { SnackbarDuration } from '../../../../constants/snackbarDuration';
import { useSnackbarStore } from '../../../../stores/snackbarStore';
import { TextField } from '../../../atoms/TextField/TextField';

export function IconPicker() {
  const [iconsClasses, setIconsClasses] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  const matchingIconClasses: string[] = useMemo(() => {
    if (searchText.trim().length === 0) return iconsClasses;

    function iconClassMatch(iconClass: string, queryTerms: string[]): boolean {
      const importantPart = keepIconMeaningfulPart(iconClass);
      const iconTerms = importantPart.split('-');
      for (const queryTerm of queryTerms) {
        for (const iconTerm of iconTerms) {
          if (iconTerm.includes(queryTerm)) return true;
        }
      }
      return false;
    }

    const queryTerms = searchText.trim().split(' ');
    return iconsClasses.filter((iconClass) =>
      iconClassMatch(iconClass, queryTerms)
    );
  }, [iconsClasses, searchText]);

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      useSnackbarStore.getState().addStandaloneSnackbar('icon-picker', {
        severity: 'success',
        message: (
          <>
            <pre>{text}</pre> copied into clipboard.
          </>
        ),
        autoHideDuration: SnackbarDuration.Short,
      });
    } catch (e) {
      console.error(`Cannot copy "${text}" into clipboard.`, e);
      useSnackbarStore.getState().addStandaloneSnackbar('icon-picker', {
        severity: 'error',
        message: (
          <>
            <p>Cannot copy into clipboard.</p>
            <p>
              You should check you are using HTTPS or{' '}
              <pre className="inline">localhost</pre> as base address to be able
              to copy things from the browser.
            </p>
          </>
        ),
        autoHideDuration: SnackbarDuration.Medium,
      });
    }
  };

  useEffect(() => {
    setIconsClasses(retrieveIconsClasses());
  }, []);

  return (
    <div className="IconPicker flex flex-col gap-2">
      <div className="flex gap-2 items-baseline">
        <i className="icon-search" />
        <TextField
          label="Icon name"
          onChange={(ev) => setSearchText(ev.target.value)}
        />
      </div>
      {matchingIconClasses.length > 0 || iconsClasses.length === 0 ? (
        <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-1 select-none">
          {matchingIconClasses.map((el) => (
            <div
              key={el}
              onClick={() => copy(`<i className="${el}"/>`)}
              className="flex flex-col gap-2 rounded pt-4 px-2 pb-2 items-center text-center bg-white cursor-pointer"
            >
              <i className={el}></i>
              <span className="text-sm">{keepIconMeaningfulPart(el)}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-smd text-grey-500 italic">
          No icon match your search.
        </div>
      )}
    </div>
  );
}

function retrieveIconsClasses(): string[] {
  const styleElements = document.getElementsByTagName('STYLE');
  const matches: string[] = [];

  const regex = /\.(icon-[^:]+):before {\n\s+content: '\\[a-zA-Z0-9]+';\n}/gm;
  for (const styleElement of styleElements) {
    for (const match of styleElement.outerHTML.matchAll(regex)) {
      const className = match[1];
      if (className) {
        matches.push(className);
      }
    }
  }

  return matches;
}

function keepIconMeaningfulPart(iconClass: string) {
  return iconClass.substring(5);
}

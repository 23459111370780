import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { ActionStatus } from '../../../models/actionStatus';
import { RoundedIconProps } from '../../atoms/RoundedIcon/RoundedIcon';

/**
 * @param actionStatus An action status
 * @returns An icon and a color to represent the action status.
 */
export function actionStatusToIconProps(
  actionStatus: ActionStatus
): Pick<RoundedIconProps, 'icon' | 'color'> {
  switch (actionStatus) {
    case ActionStatus.Failed:
      return { icon: 'icon-x', color: 'pink-300' };
    case ActionStatus.Succeeded:
      return { icon: 'icon-check', color: 'green-200' };
    case ActionStatus.Running:
      return { icon: 'icon-refresh-cw', color: 'orange-300' };
    default:
      throw new UnhandledSwitchCaseException();
  }
}

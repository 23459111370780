import { ActionHistory } from '../models/actionHistory';
import {
  DeployPayload,
  Environment,
  PartialEnvironment,
  PickFrontPayload,
  RollbackPayload,
  ScalingPayload,
  SourceUpdatePayload,
  ToggleVirtualMachinePayload,
} from '../models/environment';
import { Image, RawImage, refine as refineRawImage } from '../models/image';
import { Project } from '../models/project';
import { VirtualMachine } from '../models/virtualMachine';
import { unwrap } from '../utilities/assertions';
import {
  BaseApi,
  BaseApiDeleteOption,
  baseApiDeleteOptionDefaults,
} from '../utilities/baseApi';
import {
  AbortOptions,
  AbortOptionsDefault,
  getJsonFromServerRoute,
  postJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const {
  REACT_APP_ROUTE_ENVIRONMENTS,
  REACT_APP_ROUTE_ENVIRONMENTS_SCALING,
  REACT_APP_ROUTE_ENVIRONMENTS_UPDATE_SOURCE,
  REACT_APP_ROUTE_ENVIRONMENTS_MAINTENANCE,
  REACT_APP_ROUTE_ENVIRONMENTS_VIRTUAL_MACHINES,
  REACT_APP_ROUTE_ENVIRONMENTS_RENAME,
  REACT_APP_ROUTE_ENVIRONMENTS_DEPLOY,
  REACT_APP_ROUTE_ENVIRONMENTS_IMAGES,
  REACT_APP_ROUTE_ENVIRONMENTS_ROLLBACK,
  REACT_APP_ROUTE_ENVIRONMENTS_PICK_FRONT,
  REACT_APP_ROUTE_ENVIRONMENTS_RETRY_INIT,
  REACT_APP_ROUTE_ENVIRONMENTS_MARK_AS_RUNNING,
} = process.env;

/**
 * Internal data for {@link GetOptionsHandler}.
 */
export interface GetOptions {
  page?: number;
  name?: string;
  'project[]'?: Project['id'][];
  'created_at[before]'?: Date;
  'created_at[strictly_before]'?: Date;
  'created_at[after]'?: Date;
  'created_at[strictly_after]'?: Date;
  'deleted_at[before]'?: Date;
  'deleted_at[strictly_before]'?: Date;
  'deleted_at[after]'?: Date | null;
  'deleted_at[strictly_after]'?: Date | null;
}

/**
 * Get options for the {@link EnvironmentApi}.
 */
class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      name: ParamMappingHelper.identity,
      'project[]': ParamMappingHelper.identity,
      'created_at[before]': ParamMappingHelper.mapDate,
      'created_at[strictly_before]': ParamMappingHelper.mapDate,
      'created_at[after]': ParamMappingHelper.mapDate,
      'created_at[strictly_after]': ParamMappingHelper.mapDate,
      'deleted_at[before]': ParamMappingHelper.mapDate,
      'deleted_at[strictly_before]': ParamMappingHelper.mapDate,
      'deleted_at[after]': ParamMappingHelper.mapOptionalDate,
      'deleted_at[strictly_after]': ParamMappingHelper.mapOptionalDate,
    };
  }
}

/**
 * API for the environments.
 */
export class EnvironmentApi extends BaseApi<
  Environment,
  GetOptions,
  PartialEnvironment,
  PostArgs
> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_ENVIRONMENTS, 'Environment route not defined.'),
      GetOptionsHandler,
      {
        allowDelete: false,
      }
    );
  }

  transformGottenPartialEntity(entity: PartialEnvironment): PartialEnvironment {
    return entity;
  }

  transformGottenEntity(entity: Environment): Environment {
    if (entity.projectId !== undefined) {
      entity.projectId = BaseApi.getIdFromIri(entity.projectId);
    }

    if (entity.actionHistories !== undefined) {
      entity.actionHistories = entity.actionHistories.map((id) =>
        BaseApi.getIdFromIri(id)
      );
    }

    return entity;
  }
  // TODO DOC
  async postScaling(
    id: Environment['id'],
    payload: ScalingPayload,
    options?: AbortOptions
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_SCALING,
      'Post scaling route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return await (postJsonFromServerRoute(route, {
      args: { id },
      expectJsonResponse: true,
      payload,
      abortController: filledOptions.abortController,
    }) as Promise<ActionHistory>);
  }

  /**
   * @param id The id of the environment.
   * @param options The options. See {@link AbortOptions}
   * @returns The front number of this environment.
   */
  getScaling(
    id: Environment['id'],
    options?: Partial<AbortOptions>
  ): Promise<ScalingPayload['frontNumber']> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_SCALING,
      'GET scaling route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    });
  }

  // TODO Doc
  async postSourceUpdate(
    id: Environment['id'],
    payload: SourceUpdatePayload,
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_UPDATE_SOURCE,
      'POST scaling route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return postJsonFromServerRoute(route, {
      args: { id },
      payload,
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Post a deploy request on environment identified by its {@link id}.
   * @returns
   */
  async postDeploy(
    id: Environment['id'],
    payload: DeployPayload,
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_DEPLOY,
      'POST deploy route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return postJsonFromServerRoute(route, {
      args: { id },
      payload,
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Retry the initialization of an {@link Environment} (identified by its {@link id}).
   * This should be only used on an environment that failed to be created.
   * @param id The id of the {@link Environment} we want to retry to create
   * @param options The options. See {@link AbortOptions}.
   * @returns The {@link ActionHistory} associated to this action. (it will be a init-env action)
   */
  async postRetryInitEnv(
    id: Environment['id'],
    options?: AbortOptions
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_RETRY_INIT,
      'Post retry init env route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return await (postJsonFromServerRoute(route, {
      args: { id },
      expectJsonResponse: true,
      abortController: filledOptions.abortController,
    }) as Promise<ActionHistory>);
  }

  /**
   * Mark the environment as running.
   * This should be only used on an environment that failed to be created.
   * @param id The id of the {@link Environment} we want to retry to create
   * @param options The options. See {@link AbortOptions}.
   * @returns The {@link ActionHistory} associated to this action. (it will be a init-env action)
   */
  async postMarkEnvironmentAsRunning(
    id: Environment['id'],
    options?: AbortOptions
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_MARK_AS_RUNNING,
      'Post mark env as running route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return await (postJsonFromServerRoute(route, {
      args: { id },
      expectJsonResponse: true,
      abortController: filledOptions.abortController,
    }) as Promise<ActionHistory>);
  }

  /**
   * Toggle maintenance state of an {@link Environment} (identified by its {@link id}) to {@link enable} value.
   * @param options The options. See {@link AbortOptions}.
   * @returns The {@link ActionHistory} associated to this action.
   */
  async postToggleMaintenance(
    id: Environment['id'],
    enable: boolean,
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_MAINTENANCE,
      'POST maintenance route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return postJsonFromServerRoute(route, {
      args: { id },
      payload: { enable },
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Rename an {@link Environment}.
   * @param id The id of the {@link Environment} to rename.
   * @param name The new name of the environment.
   * @param options The options. See {@link AbortOptions}.
   * @returns The {@link ActionHistory} associated to this action.
   */
  async rename(
    id: Environment['id'],
    name: Environment['name'],
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_RENAME,
      'environment rename route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return postJsonFromServerRoute(route, {
      method: 'PATCH',
      args: { id },
      payload: { name },
      abortController: filledOptions.abortController,
    });
  }

  // TODO Doc
  async deleteAndGetActionHistory(
    id: Environment['id'],
    options?: Partial<BaseApiDeleteOption>
  ): Promise<ActionHistory> {
    const filledOptions = fillOptions(options, baseApiDeleteOptionDefaults);
    return (await postJsonFromServerRoute(this.entityRoute + '/{id}', {
      args: { id },
      abortController: filledOptions.abortController,
      expectJsonResponse: true,
      method: 'DELETE',
    })) as ActionHistory;
  }

  /**
   * Returns the snapshot of the virtual machines for the environment.
   * @param id The id of the {@link Environment} to get the snapshot from.
   * @param options The options. See {@link AbortOptions}.
   * @returns VMs snapshot; VirtualMachine[].
   */
  async getVirtualMachines(
    id: Environment['id'],
    options?: Partial<AbortOptions>
  ): Promise<VirtualMachine[]> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_VIRTUAL_MACHINES,
      'Virtual machines route not defined'
    );

    return await getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Returns the images of the environment.
   * @param id The id of the {@link Environment} to get the images from.
   * @param options The options. See {@link AbortOptions}.
   * @returns The images of this environment.
   */
  async getImages(
    id: Environment['id'],
    options?: Partial<AbortOptions>
  ): Promise<Image[]> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_IMAGES,
      'Images route not defined'
    );

    const rawImagesRecord = (await getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    })) as Record<number, RawImage>;

    return Object.values(rawImagesRecord).map(refineRawImage);
  }

  /**
   * Rollback an environment to a previous image.
   * @param id The id of the {@link Environment} to rollback.
   * @param payload The rollback payload. See {@link RollbackPayload}.
   * @param options The options. See {@link AbortOptions}.
   * @returns An {@link ActionHistory} related to this action.
   */
  rollback(
    id: Environment['id'],
    payload: RollbackPayload,
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_ROLLBACK,
      'Rollback route not defined'
    );

    return postJsonFromServerRoute(route, {
      args: { id },
      payload: payload,
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Picks the environment front version switching traffic to this one and removing the other one.
   * @param id The {@link Environment} id.
   * @param payload The payload. See {@link PickFrontPayload}.
   * @param options The options. See {@link AbortOptions}.
   * @returns The {@link ActionHistory} associated to this action.
   */
  pickFront(
    id: Environment['id'],
    payload: PickFrontPayload,
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_PICK_FRONT,
      'Pick front route not defined'
    );

    return postJsonFromServerRoute(route, {
      args: { id },
      payload,
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Switch the virtual machines on or off.
   * @param id The {@link Environment} id.
   * @param payload The payload. See {@link ToggleVirtualMachinePayload}.
   * @param options The options. See {@link AbortOptions}.
   * @returns The associated {@link ActionHistory}.
   */
  toggleVirtualMachines(
    id: Environment['id'],
    payload: ToggleVirtualMachinePayload,
    options?: Partial<AbortOptions>
  ): Promise<ActionHistory> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_ENVIRONMENTS_VIRTUAL_MACHINES,
      'POST virtual machines route not defined'
    );

    return postJsonFromServerRoute(route, {
      args: { id },
      payload,
      abortController: filledOptions.abortController,
    });
  }
}

export type PostArgs = {
  /** The name in slug format lowercase, '-' only. */
  name: string;
  /** The project IRI. */
  project: string;
  isMonoVM: boolean;
  isScalable: boolean;
  /**
   * Example:
   * Branch: feature/example-branch
   * Tag: v1.0.0
   * Hash: fr5a8dfr487f
   */
  gitReference: string;
  /** Like {@link gitReference}. */
  template: string;
  dataFromType: 'environment' | 'backup';
  dataFrom?: string;
  backupIds?: Record<string, string>;
  isDeletable?: boolean;
};

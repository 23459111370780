import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProjectApi } from '../../../api/projectApi';
import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { useSnackbarStore } from '../../../stores/snackbarStore';
import { unwrap } from '../../../utilities/assertions';
import { getServerUrl } from '../../../utilities/fetchUtilities';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { FormImagePicker } from '../../atoms/FormImagePicker/FormImagePicker';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { FormTextField } from '../../atoms/TextField/FormTextField';
import { Title } from '../../atoms/Title/Title';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { useProject } from './hooks/useProject';
import { Schema, getSchema, schemaToPatchArgs } from './schema';

import { FormCheckbox } from '../../atoms/Checkbox/FormCheckbox';
import './style.css';
import { FormBillingCompany } from './subcomponents/FormBillingCompany';
import { FormContact } from './subcomponents/FormContact';

export function AdminProjectEdition() {
  const { t, tFormatted } = useTranslationFormatter();
  const navigate = useNavigate();
  const location = useLocation();
  const [contactReady, setContactReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { control, setValue, handleSubmit, watch } = useForm<Schema>({
    defaultValues: {
      img: undefined,
      gitRepositoryURL: '',
      name: '',
      contact: null,
      billingCompany: null,
    },
    resolver: zodResolver(getSchema(t)),
  });
  const projectSnapshot = useProject();
  useTitle(t('project_edition', { name: projectSnapshot?.data?.name }));

  const [snackbarHandlePromise, addSnackbar] = useSnackbarStore((state) => [
    state.handlePromise,
    state.addStandaloneSnackbar,
  ]);

  // Fill form with project data.
  useEffect(() => {
    if (!projectSnapshot.isSucceeded()) return;
    const project = projectSnapshot.getSucceededData();

    setValue('gitRepositoryURL', project.gitRepositoryURL);
    setValue('name', project.name);
    // setValue for contact is managed by FormContact
    setValue('billingCompany', project.billingCompany ?? null);
    setValue(
      'monoVMDataUpdateCountPerWeek',
      project.monoVMDataUpdateCountPerWeek ?? null
    );
    setValue('monoVMEnvironmentLimit', project.monoVMEnvironmentLimit ?? null);
    setValue(
      'multiVMEnvironmentLimit',
      project.multiVMEnvironmentLimit ?? null
    );
    setValue('margin', project.margin ?? null);
    setValue(
      'canAnImageBeUsedInAnyEnvironment',
      project.canAnImageBeUsedInAnyEnvironment ?? null
    );
  }, [projectSnapshot, setValue]);

  const onSubmit = async (data: Schema) => {
    setSubmitting(true);

    const project = projectSnapshot.getSucceededData();
    const promises: Promise<unknown>[] = [];
    const projectApi = new ProjectApi();

    // Update contact if necessary
    if (data.contact?.id !== project.contact) {
      promises.push(
        snackbarHandlePromise(
          `project_${project.id}_contact_patch`,
          () => projectApi.patchContact(project.id, data.contact?.id ?? null),
          {
            getErrorMessage: (error) =>
              t('referrer_contact_update_failed', { error: `${error}` }),
            getLoadingMessage: () => t('referrer_contact_update_in_progress'),
            getSuccessMessage: () => t('referrer_contact_update_succeeded'),
          },
          {
            autoHideSuccessDuration: 3000,
          }
        )
      );
    }

    // Update project if necessary
    const patchArgs = schemaToPatchArgs(data);
    if (ProjectApi.isPatchNeeded(project, patchArgs)) {
      promises.push(
        snackbarHandlePromise(
          `project_${project.id}_patch`,
          () => projectApi.patch(project.id, patchArgs),
          {
            getErrorMessage: (error) =>
              t('project_patch_error', { error: `${error}` }),
            getLoadingMessage: () => t('project_patch_in_progress'),
            getSuccessMessage: () => t('project_patch_succeeded'),
          },
          {
            autoHideSuccessDuration: 3000,
          }
        )
      );
    }

    // Update image if needed
    if (data.img !== undefined && !(!data.img && !project.imgUri)) {
      promises.push(
        snackbarHandlePromise(
          `project_${project.id}_patch_image`,
          () => ProjectApi.patchPicture(project.id, unwrap(data.img)),
          {
            getErrorMessage: (error) =>
              t('project_patch_image_error', { error: `${error}` }),
            getLoadingMessage: () => t('project_patch_image_in_progress'),
            getSuccessMessage: () => t('project_patch_image_succeeded'),
          },
          {
            autoHideSuccessDuration: 3000,
          }
        )
      );
    }

    const returnToProjectPage = () =>
      navigate(location.pathname.split('/').slice(0, -1).join('/'));

    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        returnToProjectPage();
      } finally {
        setSubmitting(false);
      }
    } else {
      addSnackbar('project_patch_nothing_to_do', {
        message: t('no_changes_applied'),
        severity: 'info',
        autoHideDuration: 3000,
      });
      returnToProjectPage();
    }
  };

  return (
    <AdminPageTemplate className="AdminProjectEdit">
      {projectSnapshot.map({
        notStarted: 'running',
        running: () => <LoadingBlock />,
        failed: (error) => <ErrorBlock>{`${error}`}</ErrorBlock>,
        succeeded: (project) => {
          return (
            <Block>
              <div className="flex flex-col gap-8">
                <Title level={2}>
                  {tFormatted(
                    'project_edition_formatted',
                    { name: project.name },
                    {
                      name: {
                        classes: 'text-orange',
                      },
                    }
                  )}
                </Title>
                <div className="flex flex-col gap-2 form">
                  <section>
                    <Title level={3}>{t('general')}</Title>
                    <div className="flex flex-col gap-4 lg:flex-row lg:items-center">
                      <FormImagePicker
                        control={control}
                        name="img"
                        initialURL={
                          project.imgUri
                            ? getServerUrl() + project.imgUri
                            : undefined
                        }
                      />
                      <FormTextField
                        control={control}
                        className="grow"
                        name="name"
                        label={t('name')}
                      />
                    </div>
                    <FormContact
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      projectId={project.id}
                      defaultContactId={project.contact ?? null}
                      onContactReady={() => setContactReady(true)}
                    />
                    <FormTextField
                      control={control}
                      name="gitRepositoryURL"
                      label={t('git_repository_url')}
                    />
                  </section>
                  <section>
                    <Title level={3}>{t('billing')}</Title>
                    <FormBillingCompany control={control} watch={watch} />
                    <FormTextField
                      control={control}
                      name="margin"
                      label={t('markup_in_percent')}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
                    />
                  </section>
                  <section>
                    <Title level={3}>{t('technical_details')}</Title>
                    <FormTextField
                      control={control}
                      name="monoVMDataUpdateCountPerWeek"
                      label={t('mono_vm_update_count_per_week')}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
                      onBlur={(ev) =>
                        (ev.target.value = ev.target.value.replaceAll(
                          /\d/g,
                          ''
                        ))
                      }
                    />
                    <FormTextField
                      control={control}
                      name="monoVMEnvironmentLimit"
                      label={t('mono_vm_enviroment_limit')}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
                    />
                    <FormTextField
                      control={control}
                      name="multiVMEnvironmentLimit"
                      label={t('multi_vm_environment_limit')}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
                    />
                    <FormCheckbox
                      control={control}
                      name="canAnImageBeUsedInAnyEnvironment"
                      label={t('can_an_image_be_used_in_any_environment')}
                    />
                  </section>
                  <RoundedFilledBlueButton
                    className="self-end"
                    loading={!contactReady || submitting}
                    disabled={!contactReady || submitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('edit')}
                  </RoundedFilledBlueButton>
                </div>
              </div>
            </Block>
          );
        },
      })}
    </AdminPageTemplate>
  );
}

import { MissingElementException } from '../exceptions/missingElementException';
import { replace } from './array';
import { fillOptions } from './options';

/**
 * The result given by an execution of a single middleware.
 */
export interface MiddlewareResult {
  /**
   * The potentially mutated response from the middleware.
   */
  result: Response;
  /**
   * Should the next middlewares be executed after this execution?
   */
  shouldContinue: boolean;
}

/**
 * A middleware that treats a {@link Response}.
 */
export abstract class ResponseMiddleware {
  public get name() {
    return this.constructor['name'];
  }

  /**
   * Executes the middleware. This may alter the {@link response}.
   * @param response The response to examine/modify.
   */
  public abstract execute(response: Response): Promise<MiddlewareResult>;
}

/**
 * Options for {@link ResponseMiddlewareHandler.add}.
 * Default to {@link responseMiddlewareHanderAddOptionsDefaults}.
 */
export interface ResponseMiddlewareHanderAddOptions {
  /** Should the middleware be the only one of its kind in the handler?
   * (A middleware is identified by its name.)
   */
  unique: boolean;
}

/**
 * Default value for {@link ResponseMiddlewareHanderAddOptions}.
 */
const responseMiddlewareHanderAddOptionsDefaults: ResponseMiddlewareHanderAddOptions =
  {
    unique: false,
  };

/**
 * A quite generic class to handle response middlewares.
 */
export class ResponseMiddlewareHandler {
  private _middlewares: Array<ResponseMiddleware> = [];

  /**
   * Adds a new middleware to the handler.
   * @param middleware The middleware to add to the middlewares used.
   * @param options See {@link ResponseMiddlewareHanderAddOptions}.
   */
  public add(
    middleware: ResponseMiddleware,
    options?: Partial<ResponseMiddlewareHanderAddOptions>
  ) {
    const { unique } = fillOptions(
      options,
      responseMiddlewareHanderAddOptionsDefaults
    );

    if (unique) {
      try {
        replace(
          this._middlewares,
          (el) => el.name === middleware.name,
          middleware
        );
      } catch (e: unknown) {
        if (!(e instanceof MissingElementException)) throw e;
        this._middlewares.push(middleware);
      }
    } else {
      this._middlewares.push(middleware);
    }
  }

  /**
   * Removes all middlewares from this.
   */
  public clear() {
    this._middlewares = [];
  }

  /**
   * Passes the {@link response} through the middlewares of this handler sequentially.
   * @param response The response to treat.
   * @returns The response altered by the middlewares of this handler.
   */
  public async execute(response: Response): Promise<Response> {
    let modifiedResponse = response;
    for (const middleware of this._middlewares) {
      const res = await middleware.execute(modifiedResponse);
      modifiedResponse = res.result;
      if (!res.shouldContinue) break;
    }
    return modifiedResponse;
  }
}

/**
 * @param str The input string.
 * @returns A new string with its first letter in uppercase.
 */
export function uppercaseFirstLetter(str: string): string {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}

/**
 * Compares {@link a} and {@link b}.
 */
export function stringCompare(a: string, b: string): number {
  return a.localeCompare(b);
}

/**
 * Compares {@link a} and {@link b}. But result is reversed.
 */
export function stringCompareReversed(a: string, b: string): number {
  return b.localeCompare(a);
}

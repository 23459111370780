import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import { ProjectApi } from '../../../api/projectApi';
import { useCheckAdmin } from '../../../hooks/useCheckAdmin';
import { useCurrentUserStore } from '../../../stores/currentUserStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { ClassUtilities } from '../../../utilities/classUtility';
import { PromiseState } from '../../../utilities/promiseSnapshot';
import { Logo } from '../../atoms/Logo/Logo';
import { CurrentProjectMenu } from '../../molecules/CurrentProjectMenu/CurrentProjectMenu';
import { MenuButton, SelectMode } from '../../molecules/MenuButton/MenuButton';
import { UserTile } from '../../molecules/UserTile/UserTile';
import './MainPageTemplate.css';
import { MainPageTemplateHeaderContext } from './contexts/MainPageTemplateHeaderContext';

export function MainPageTemplate() {
  const { t } = useTranslation();
  const isAdmin = useCheckAdmin();

  const [
    project,
    projectNumberSnapshot,
    fetchProjectNumber,
    projectsSnapshotsMap,
    localUpdateProjects,
    setSelectedProjectId,
  ] = useProjectsStore((state) => [
    state.getProject(),
    state.projectNumber,
    state.fetchProjectNumber,
    state.projects,
    state.localUpdateProjects,
    state.setSelectedProjectId,
  ]);

  // Fetch project number
  useEffect(() => {
    if (projectNumberSnapshot.isNotStarted()) {
      fetchProjectNumber();
    }
  }, [fetchProjectNumber, projectNumberSnapshot]);

  // Select default project is there is only one project
  useEffect(() => {
    if (!projectNumberSnapshot.isSucceeded()) return;
    const projectNumber = projectNumberSnapshot.getSucceededData();

    if (projectNumber !== 1) return;

    if (projectsSnapshotsMap.size === 1) {
      setSelectedProjectId(projectsSnapshotsMap.keys().next().value);
    } else {
      new ProjectApi().getAll().then((projects) => {
        const project = projects[0];
        localUpdateProjects(project);
        setSelectedProjectId(project.id);
      });
    }
  }, [
    localUpdateProjects,
    projectNumberSnapshot,
    projectsSnapshotsMap,
    setSelectedProjectId,
  ]);

  const routes = {
    projectList: '/project-list',
    support: '/support',
    admin: '/admin',
  };

  const userIsAuthenticated = useCurrentUserStore(
    (state) => state.user.state === PromiseState.Succeeded
  );

  //#region Children context overrides
  const [header, setHeader] = useState<React.ReactNode | undefined>(undefined);
  const [headerAlignBottom, setHeaderAlignBottom] = useState(false);

  const projectListButton = (
    <MenuButton
      selectMode={SelectMode.RouteStart}
      route={routes.projectList}
      icon="icon-home"
      label={t('projects_list')}
    />
  );

  //#endregion

  const menu = (
    <>
      {(!projectNumberSnapshot.isSucceeded() ||
        projectNumberSnapshot.getSucceededData() !== 1) &&
        projectListButton}
      {userIsAuthenticated && (
        <>{project !== undefined && <CurrentProjectMenu project={project} />}</>
      )}
      <MenuButton
        icon="icon-life-buoy"
        selectMode={SelectMode.RouteExact}
        route={routes.support}
        label={t('support')}
      />
      {isAdmin && (
        <MenuButton
          icon="icon-chess-king"
          selectMode={SelectMode.RouteStart}
          route={'/admin'}
          label={t('administration')}
        />
      )}
    </>
  );

  return (
    <div className="MainPage">
      <div className="flex items-stretch h-full">
        <aside
          id="menu"
          className="hidden md:flex flex-col py-8 gap-32 items-center h-full border-r border-grey-500 bg-white"
        >
          <Link to="/">
            <Logo className="w-64 transform hover:scale-105 transition-transform" />
          </Link>
          <div className="flex flex-col items-stretch self-stretch">{menu}</div>
        </aside>
        <div className="grow flex flex-col max-w-[100vw] overflow-x-hidden">
          <header
            className={ClassUtilities.flatten(
              'bg-white flex',
              ClassUtilities.conditional({
                'px-8 py-4 justify-between': header !== undefined,
                'sm:px-8 sm:py-4 justify-end': header === undefined,
              })
            )}
          >
            {header !== undefined && (
              <div
                className={ClassUtilities.conditional({
                  'self-end -mb-4': headerAlignBottom === true,
                })}
              >
                {header}
              </div>
            )}
            <div className="hidden sm:flex items-center">
              <UserTile />
            </div>
          </header>
          <main
            id="mainpage-outlet-container"
            className="grow flex flex-col items-stretch overflow-hidden"
          >
            <MainPageTemplateHeaderContext.Provider
              value={{
                header,
                setHeader,
                headerAlignBottom,
                setHeaderAlignBottom,
              }}
            >
              <Outlet />
            </MainPageTemplateHeaderContext.Provider>
          </main>
          <footer
            id="menu-mobile"
            className="flex md:hidden bg-white border-t overflow-x-auto items-stretch shrink-0"
          >
            {menu}
          </footer>
        </div>
      </div>
    </div>
  );
}

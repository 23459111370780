import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio as MUIRadio,
  RadioProps as MUIRadioProps,
} from '@mui/material';

export interface RadioProps
  extends Omit<
    MUIRadioProps,
    'checked' | 'label' | 'value' | 'error' | 'helperText' | 'onChange'
  > {
  label?: string;
  error?: boolean;
  helperText?: string | null;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

export function Radio(props: RadioProps) {
  const { label, error, helperText, checked, onChange, ...radioProps } = props;

  const radio = (
    <MUIRadio
      checked={props.checked}
      onChange={(_ev, value) => props.onChange?.(value)}
      {...radioProps}
    />
  );

  return (
    <FormControl>
      {label !== undefined && label.length > 0 ? (
        <FormControlLabel label={props.label} control={radio} />
      ) : (
        radio
      )}
      <FormHelperText
        error={props.error}
        sx={{ marginLeft: 0, marginRight: 0 }}
      >
        {props.helperText}
      </FormHelperText>
    </FormControl>
  );
}

import { Tooltip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ClassUtilities } from '../../../utilities/classUtility';
import './MenuButtonBase.css';

/**
 * A button intended to be used in a vertical left-aligned menu.
 */
export function MenuButtonBase(props: MenuButtonBaseProps) {
  return (
    <Link
      className={ClassUtilities.flatten('MenuButtonBase', props.className)}
      to={props.route}
    >
      <div
        className={ClassUtilities.flatten(
          'MenuButtonBase__desktop',
          ClassUtilities.conditional({
            selected: props.selected,
          })
        )}
      >
        <div className="w-1 h-full">
          <div className="indicator bg-orange h-full w-0"></div>
        </div>
        <div className="transition-[width] w-10"></div>
        <div className="icon-wrapper w-8 h-8 flex items-center justify-center rounded-md">
          {props.icon}
        </div>
        <div className="w-6 transition-[width]"></div>
        <span className="transition-[width] overflow-hidden w-56">
          {props.label}
        </span>
      </div>
      <div
        className={ClassUtilities.flatten(
          'MenuButtonBase__mobile',
          ClassUtilities.conditional({
            selected: props.selected,
          })
        )}
      >
        <Tooltip title={props.label}>
          <div className="flex flex-col gap-1 justify-center items-center py-1 px-1 transition h-12">
            <div className="w-7 h-7 flex items-center justify-center icon-wrapper rounded-md text-grey transition">
              {props.icon}
            </div>
          </div>
        </Tooltip>
      </div>
    </Link>
  );
}

export interface MenuButtonBaseProps {
  /** Is the button currenly selected? */
  selected: boolean;
  /** Name of the icon (example: `icon-home`). */
  icon: React.ReactNode;
  label: string;
  /** Route link associated to this button click. */
  route: string;
  className?: string;
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentApi } from '../../../../api/environmentApi';
import { Environment } from '../../../../models/environment';
import { EnvironmentStatus as EnvironmentStatusType } from '../../../../models/environmentStatus';
import { Block } from '../../../atoms/Block/Block';
import { Button } from '../../../atoms/Button/Button';
import { Title } from '../../../atoms/Title/Title';

/**
 * Props for {@link EnvironmentStatus}
 */
export interface EnvironmentStatusProps {
  /** The {@link Environment} */
  environment: Environment;
}

/**
 * A component to see and manage an environment status {@link ActionType}s.
 * @param props See {@link EnvironmentStatusProps}
 */
export function EnvironmentStatus(props: EnvironmentStatusProps) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(props.environment.status);

  const [isRunning, setIsRunning] = useState(
    status === EnvironmentStatusType.Running
  );
  const [isLoading, setIsLoading] = useState(false);

  const environmentApi = new EnvironmentApi();
  const environmentId = props.environment.id;

  const setAsRunning = async () => {
    setIsLoading(true);

    await environmentApi
      .postMarkEnvironmentAsRunning(environmentId)
      .then(() => {
        setIsLoading(false);
        setStatus(EnvironmentStatusType.Running);
      })
      .catch(() => {
        setIsLoading(false);
        setStatus(EnvironmentStatusType.Failed);
      });
  };

  useEffect(() => {
    setIsRunning(status === EnvironmentStatusType.Running);
  }, [status]);

  return (
    <Block className="flex flex-col gap-4">
      <Title level={2}>{t('environment_status')}</Title>
      <p>
        {t('environment_status')}:{' '}
        <span className={`text-${isRunning ? 'deepgreen-500' : 'orange-400'}`}>
          {status}
        </span>
      </p>
      <Button
        className="self-end"
        variant="contained"
        loading={isLoading}
        disabled={isRunning}
        onClick={setAsRunning}
      >
        {t('mark_as_running')}
      </Button>
    </Block>
  );
}

import { Exception } from './exception';

export class NetworkRequestException extends Exception {
  private _status: number;
  private _backendDescription?: string;
  private _messageKey?: string;

  constructor(status: number, description?: string, messageKey?: string) {
    super(
      description !== undefined
        ? `Request ended with status ${status}: "${description}" (messageKey: ${messageKey})`
        : `Request ended with status ${status} (no backend message provided)`
    );
    this._status = status;
    this._backendDescription = description;
    this._messageKey = messageKey;
  }

  /**
   * @returns The error status associated with this error.
   */
  public get status() {
    return this._status;
  }

  /**
   * @returns The message associated with this error.
   */
  public get backendDescription() {
    return this._backendDescription;
  }

  /**
   * @returns The message key associated with this error.
   */
  public get messageKey() {
    return this._messageKey;
  }
}

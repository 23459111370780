import { ClassUtilities } from '../../../utilities/classUtility';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';

// TODO Documentation
export interface AdminPageTemplateProps {
  className?: string;
  children: React.ReactNode;
}

export function AdminPageTemplate(props: AdminPageTemplateProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'AdminPageTemplate h-full',
        props.className
      )}
    >
      <div className="flex flex-col h-full">
        <MainPageContainer>{props.children}</MainPageContainer>
      </div>
    </div>
  );
}

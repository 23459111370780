import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useTitle } from '../../../hooks/useTitle';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { errorToTerm } from './utilities';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnvironmentActionTypesEstimates } from '../../../hooks/useEnvironmentActionTypesEstimates';
import { Environment } from '../../../models/environment';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { newLineToBr } from '../../../utilities/textFormatter';
import { InformationText } from '../../atoms/InformationText/InformationText';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import {
  ActionShortInfoBlocks,
  ActionShortInfoBlocksProps,
} from '../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';
import { EstimateErrorBlock } from '../../molecules/EstimateErrorBlock/EstimateErrorBlock';

export function ToggleMaintenance() {
  const { t } = useTranslation();

  const [environmentId, localPatchEnvironment] = useProjectEnvironmentsStore(
    (state) => [
      state.environments.data?.[state.selectedEnvironmentIndex]?.id,
      state.localPatchEnvironment,
    ]
  );
  const [environmentSnapshot, setEnvironmentSnapshot] = useState(
    new PromiseSnapshot<Environment>()
  );
  useTitle(
    environmentSnapshot.data?.isInMaintenance
      ? t('done_action_labels.disable_maintenance')
      : t('done_action_labels.enable_maintenance')
  );
  const abortController = useRef(new AbortController());

  // Fetch action of this env to know if another source update is in progress
  const anotherToggleMaintenanceInProgress = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.Maintenance,
    environmentId,
  });

  const maintenanceEstimate = useEnvironmentActionTypesEstimates(
    environmentId,
    ActionTypeName.Maintenance
  );

  useEffect(() => {
    if (environmentId === undefined) return;

    PromiseSnapshot.trackPromiseSetter(
      () => new EnvironmentApi().get(environmentId),
      setEnvironmentSnapshot
    );

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [environmentId]);

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t(
        environmentSnapshot.data?.isInMaintenance
          ? 'done_action_labels.disable_maintenance'
          : 'done_action_labels.enable_maintenance'
      ),
      environmentName: environmentSnapshot.data?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = () => {
    const currentlyInMaintenance = unwrap(
      environmentSnapshot.data
    ).isInMaintenance;
    submitAndWatchResult(
      () =>
        new EnvironmentApi().postToggleMaintenance(
          unwrap(environmentId),
          !currentlyInMaintenance
        ),
      {
        onSuccessPostAction: async () => {
          const updatedEnv = await new EnvironmentApi().get(
            unwrap(environmentId)
          );
          setEnvironmentSnapshot(PromiseSnapshot.buildSucceeded(updatedEnv));
          localPatchEnvironment(updatedEnv);
        },
      }
    );
  };

  const blocksStyleProps: ActionShortInfoBlocksProps['stylesProps'] = [
    {
      icon: {
        color: 'yellow-200',
        icon: 'icon-clock',
      },
      label: t('operation_estimated_duration'),
      className: 'grow',
    },
  ];

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.Maintenance}>
      <div className="ToggleMaintenance grid grid-cols-1 lg:grid-cols-aside-md gap-6 justify-items-stretch">
        <Block className="grow shrink flex flex-col gap-8">
          {environmentSnapshot.data !== undefined ? (
            <>
              <Title level={2}>
                {t(
                  environmentSnapshot.data.isInMaintenance
                    ? 'do_disable_env_maintenance'
                    : 'do_enable_env_maintenance'
                )}
              </Title>
              <InformationText className="max-w-prose">
                {newLineToBr(t('maintenance_description'))}
              </InformationText>
              <RoundedFilledBlueButton
                color="info"
                onClick={onSubmit}
                disabled={loading}
                loading={loading}
                className="self-end"
              >
                {t(
                  environmentSnapshot.data.isInMaintenance
                    ? 'action_labels.disable_maintenance'
                    : 'action_labels.enable_maintenance'
                )}
              </RoundedFilledBlueButton>
              {anotherToggleMaintenanceInProgress && (
                <div className="-order-1 md:order-2 flex flex-col gap-4">
                  <WarningBlock>
                    {t('toggle_maintenance_already_occurring')}
                  </WarningBlock>
                </div>
              )}
            </>
          ) : (
            <LoadingBlock text={t('env_information_get')} />
          )}
        </Block>
        <div className="min-w-48 flex flex-col gap-4 shrink grow">
          {maintenanceEstimate.map({
            succeeded: (data) => (
              <ActionShortInfoBlocks
                stylesProps={blocksStyleProps}
                valueDescriptions={[
                  DateFormatter.toDuration(data.estimatedDuration, t, {
                    maximumParts: 1,
                  }),
                ]}
              />
            ),
            failed: () => <EstimateErrorBlock />,
            notStarted: 'running',
            running: () => (
              <ActionShortInfoBlocks stylesProps={blocksStyleProps} />
            ),
          })}
        </div>
      </div>

      {feedbackWidget}
    </ActionPageTemplate>
  );
}

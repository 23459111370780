import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, TextField, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectApi } from '../../../api/projectApi';
import { UserApi } from '../../../api/userApi';
import { UserProjectRoleApi } from '../../../api/userProjectRoleApi';
import DefaultProfilePicture from '../../../assets/images/default_profile_picture.png';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { Project } from '../../../models/project';
import { User } from '../../../models/user';
import { UserProjectRoleType } from '../../../models/userProjectRole';
import { unwrap } from '../../../utilities/assertions';
import { getServerUrl } from '../../../utilities/fetchUtilities';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { Option } from '../../atoms/Autocomplete/Autocomplete';
import { FormAutocomplete } from '../../atoms/Autocomplete/FormAutocomplete';
import { Block } from '../../atoms/Block/Block';
import { Breadcrumb } from '../../atoms/Breadcrumb/Breadcrumb';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { FormExclusiveButtonGroup } from '../../atoms/FormButtonGroup/FormButtonGroup';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { getRoleInputOptions } from '../../shared/types/roleInputOptions';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import { ProjectAddUserSchema, schema } from './schema';

export function AdminProjectAddUser() {
  const { t, tFormatted } = useTranslationFormatter();
  const abortController = useRef(new AbortController());
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [projectSnapshot, setProjectSnapshot] = useState<
    PromiseSnapshot<Project>
  >(new PromiseSnapshot());
  const [usersSnapshot, setUsersSnapshot] = useState<PromiseSnapshot<User[]>>();

  const [userOptions, setUserOptions] = useState<readonly Option<User>[]>([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ProjectAddUserSchema>({
    defaultValues: {
      role: UserProjectRoleType.Viewer,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (projectId !== undefined) {
      setValue('projectId', projectId);
      // Fetch project
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new ProjectApi().get(projectId, {
            abortController: abortController.current,
          }),
        setProjectSnapshot
      );
    }
  }, [projectId, setValue]);

  useEffect(() => {
    // Fetch users
    if (autocompleteInputValue.length < 3) {
      return;
    }
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new UserApi().getAll(
          {
            full_name: autocompleteInputValue,
          },
          { abortController: abortController.current }
        ),
      setUsersSnapshot
    );
  }, [autocompleteInputValue]);

  useEffect(() => {
    if (
      usersSnapshot?.state === PromiseState.Succeeded &&
      usersSnapshot.data !== undefined
    ) {
      setUserOptions(
        usersSnapshot.data.map((user) => {
          return { label: user.firstname + ' ' + user.lastname, value: user };
        })
      );
    }
  }, [usersSnapshot]);

  const hasErrors = Object.keys(errors).length > 0;

  //#region Rendering
  if (projectSnapshot.state === PromiseState.Failed) {
    return (
      <div className="AdminProject">
        <ErrorBlock>{`${
          projectSnapshot.state === PromiseState.Failed
            ? projectSnapshot.error
            : ''
        }`}</ErrorBlock>
      </div>
    );
  } else if (projectSnapshot.state !== PromiseState.Succeeded) {
    return (
      <div className="AdminProject">
        <LoadingBlock></LoadingBlock>
      </div>
    );
  }

  const project = unwrap(projectSnapshot.data);

  const onSubmit = async (data: ProjectAddUserSchema) => {
    const userProjectRoles = await new UserProjectRoleApi().post(
      {
        projectId: data.projectId,
        userId: data.user.id,
        role: data.role,
      },
      {
        abortController: abortController.current,
      }
    );
    if (userProjectRoles && userProjectRoles.id) {
      navigate(`/admin/projects/${project.id}`);
    }
  };

  return (
    <AdminPageTemplate className="AdminProject">
      <div className="flex flex-col gap-4">
        <Breadcrumb
          homeLink={'/admin/projects'}
          elements={[
            {
              title: project.name,
              subtitle: t('project'),
              link: `/admin/projects/${project.id}`,
            },
            {
              title: t('add_a_user_to_project'),
              subtitle: t('project'),
              link: '',
            },
          ]}
        ></Breadcrumb>
        <div className="h-4"></div>
        <div className="grid xl:grid-cols-6">
          <Block className="col-span-4 2xl:col-span-3">
            <Title level={2}>
              {tFormatted(
                'add_a_user_to_project_name',
                { project: project.name },
                {
                  project: {
                    classes: 'text-orange',
                  },
                }
              )}
            </Title>
            <div className="h-8"></div>
            <form className="flex flex-col gap-6">
              <div className="flex align-center justify-between gap-3">
                <FormAutocomplete
                  name="user"
                  control={control}
                  options={userOptions}
                  filterOptions={(x) => x}
                  className={'flex-1'}
                  onInputChange={(evt, value) =>
                    setAutocompleteInputValue(value)
                  }
                  valueEqualityOperator={(value1, value2) => {
                    return value1.id === value2.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('select_user_one')}
                      fullWidth
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    const useDefaultImage =
                      option?.value.imgUri === '' ||
                      option?.value.imgUri === undefined;
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={() => (
                                <img
                                  src={
                                    useDefaultImage
                                      ? DefaultProfilePicture
                                      : getServerUrl() + option?.value.imgUri
                                  }
                                  alt="Utilisateur"
                                  className="rounded-full w-10 h-10"
                                  onError={(ev) => {
                                    console.warn(
                                      'Profile picture could not be loaded, replacing with the default one.'
                                    );
                                    ev.currentTarget.src =
                                      DefaultProfilePicture;
                                  }}
                                />
                              )}
                              sx={{ color: 'text.secondary', mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs className="pl-3">
                            <span>
                              {option?.value.firstname} {option?.value.lastname}
                            </span>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                ></FormAutocomplete>
                <Tooltip title={t('user_autocomplete_usage_notes')}>
                  <i className="icon-info self-center text-lg"></i>
                </Tooltip>
              </div>
              <FormExclusiveButtonGroup
                name="role"
                control={control}
                options={getRoleInputOptions(t)}
              ></FormExclusiveButtonGroup>

              <div className="h-4"></div>

              {hasErrors && (
                <WarningBlock>{t('warning_error_in_form')}</WarningBlock>
              )}

              <RoundedFilledBlueButton
                color="info"
                className="self-end"
                disabled={hasErrors}
                onClick={handleSubmit(onSubmit)}
              >
                {t('add_user_to_project')}
              </RoundedFilledBlueButton>
            </form>
          </Block>
        </div>
      </div>
    </AdminPageTemplate>
  );
}

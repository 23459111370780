import { drawOneRandom } from './array';

/**
 * Mock data usable by the interface when the functionality is not implemented.
 */
export class MockData {
  static get number(): number {
    return 3547;
  }

  static get euro(): number {
    return this.number / 100;
  }

  static get uuidV4(): string {
    if (!this._uuid) {
      const characters = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
      ];
      const specialCharacters = ['8', '9', 'a', 'b'];
      const sequenceParameters: [number, string[]][] = [
        [8, characters],
        [4, characters],
        [3, characters],
        [1, specialCharacters],
        [3, characters],
        [12, characters],
      ];
      const sequenceModel = '{}-{}-4{}-{}{}-{}';
      let fullSequence = sequenceModel;

      for (const [length, characterSet] of sequenceParameters) {
        let sequence = '';
        for (let i = 0; i < length; ++i) {
          sequence += drawOneRandom(characterSet);
        }
        fullSequence = fullSequence.replace('{}', sequence);
      }
      this._uuid = fullSequence;
    }
    return this._uuid + '';
  }

  private static _uuid: string | undefined = undefined;
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UnhandledSwitchCaseException } from '../../../exceptions/unhandledSwitchCaseException';
import { ClassUtilities } from '../../../utilities/classUtility';
import {
  UserGroupElement,
  UserGroupElementProps,
} from './subcomponents/UserGroupElement';
import { UserGroupRemaining } from './subcomponents/UserGroupRemaining';
import './UserGroup.css';
import { computeMaxElementCount } from './utilities';

/**
 * The horizontal justification for {@link UserGroup}.
 */
export type UserGroupJustify = 'start' | 'center' | 'end';

/**
 * Props for {@link UserGroup}.
 */
export interface UserGroupProps {
  /**
   * The information about the user to display.
   */
  users: UserGroupElementProps['user'][];
  /**
   * The horizontal justification. The children of this widget will be packed in the direction indicated by this property.
   *
   * Defaults to: `start`.
   */
  justify?: UserGroupJustify;
  /**
   * If `true`, the widget will use no shadow.
   *
   * Defaults to: `false`.
   */
  noShadow?: boolean;
}

/** The minimum element to display. This includes the indicator of the remaining users. */
const MIN_USER_ELEMENT = 2;

/**
 * Displays several users in a row.
 * @param props See {@link UserGroupProps}.
 */
export function UserGroup(props: UserGroupProps) {
  const [maxSlots, setMaxSlots] = useState(MIN_USER_ELEMENT);
  const justify = props.justify ?? 'start';
  const shadow = !(props.noShadow ?? false);
  const displayedUsers = useMemo(
    // Reserve a slot to indicate there are hidden users if needed.
    () =>
      maxSlots >= props.users.length
        ? props.users
        : props.users.slice(0, maxSlots - 1),
    [maxSlots, props.users]
  );
  const hiddenUsers = useMemo(
    () =>
      maxSlots >= props.users.length ? [] : props.users.slice(maxSlots - 1),
    [maxSlots, props.users]
  );
  const rootElement = useRef<HTMLDivElement>(null);

  // Update max slots depending on horizontal space available.
  useEffect(() => {
    if (rootElement.current === null) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const rootElement = entries[0];
      const maxElementInThisSize = computeMaxElementCount(
        rootElement.contentBoxSize[0].inlineSize
      );
      setMaxSlots(Math.max(maxElementInThisSize, MIN_USER_ELEMENT));
    });
    resizeObserver.observe(rootElement.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // The function to get how (and how many times) to apply the offset to the element at given index.
  // This depends on the chosen justification.
  const getOffsetMultiplier = useCallback(
    (index: number) => {
      switch (justify) {
        case 'start':
          return index;
        case 'center':
          return index - (Math.min(maxSlots, props.users.length) - 1) / 2.0;
        case 'end':
          return -(Math.min(maxSlots, props.users.length) - 1 - index);
        default:
          throw new UnhandledSwitchCaseException(
            `"${justify}" is not handled by UserGroup::getOffsetMultiplier`
          );
      }
    },
    [maxSlots, justify, props.users.length]
  );

  return (
    <div
      className={ClassUtilities.flatten(
        `UserGroup flex justify-${justify} select-none`,
        ClassUtilities.conditional({ 'UserGroup--shadow-yes': shadow })
      )}
      ref={rootElement}
    >
      {displayedUsers.map((user, index) => (
        <UserGroupElement
          key={`${user.firstname}${user.lastname}${index}`}
          user={user}
          hue={index * (360 / displayedUsers.length)}
          offsetMultiplier={getOffsetMultiplier(index)}
        />
      ))}
      {hiddenUsers.length > 0 && (
        <UserGroupRemaining
          offsetMultiplier={getOffsetMultiplier(displayedUsers.length)}
          users={hiddenUsers}
        />
      )}
    </div>
  );
}

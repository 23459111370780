import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionAuthorization } from '../../../hooks/useActionAuthorization';
import { useCanUseBilling } from '../../../hooks/useCanUseBilling';
import { useTitle } from '../../../hooks/useTitle';
import { Environment } from '../../../models/environment';
import { EnvironmentStatus } from '../../../models/environmentStatus';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import { ClassUtilities } from '../../../utilities/classUtility';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { Alert } from '../../atoms/Alert/Alert';
import { Block } from '../../atoms/Block/Block';
import { ExternalLink } from '../../atoms/ExternalLink/ExternalLink';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';
import { PlaceholderText } from '../../atoms/PlaceholderText/PlaceholderText';
import { Title } from '../../atoms/Title/Title';
import { EnvironmentPicker } from '../../organisms/EnvironmentPicker/EnvironmentPicker';
import './Workplace.css';
import { ActionBlock } from './subcomponents/ActionBlock/ActionBlock';
import { ActionHistoryBlock } from './subcomponents/ActionHistoryBlock';
import { CostBlock } from './subcomponents/CostBlock/CostBlock';
import { VMBlock } from './subcomponents/VMBlock';

export function Workplace() {
  const { t } = useTranslation();
  const canExecuteAction = useActionAuthorization();
  const canUseBilling = useCanUseBilling();

  useTitle(t('workplace'));

  const [environmentsSnapshot, selectedEnvironmentIndex] =
    useProjectEnvironmentsStore((state) => [
      state.environments,
      state.selectedEnvironmentIndex,
      // We cannot use projectId here as it not refresh as soon as we expect, it can still be the previous one at the beginning.
    ]);

  const [projectId] = useProjectsStore((state) => [
    unwrap(state.getProject()).id,
  ]);

  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  //#region Get complete environment info.
  const [completedEnvironmentSnapshot, setCompletedEnvironmentSnapshot] =
    useState<PromiseSnapshot<Environment>>(new PromiseSnapshot());
  const completedEnvironmentAbortController = useRef(new AbortController());
  const completedEnvironment = completedEnvironmentSnapshot.data;

  // Fetch complete environment.
  useEffect(() => {
    if (selectedEnvironment === undefined) {
      setCompletedEnvironmentSnapshot(new PromiseSnapshot());
      return;
    }

    if (completedEnvironmentSnapshot.data?.id === selectedEnvironment.id) {
      // Already fetched so return
      return;
    }

    const abortController = completedEnvironmentAbortController.current;

    PromiseSnapshot.trackPromiseSetter(
      () => new EnvironmentApi().get(selectedEnvironment.id),
      setCompletedEnvironmentSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      completedEnvironmentAbortController.current = new AbortController();
    };
  }, [completedEnvironmentSnapshot.data?.id, selectedEnvironment]);
  //#endregion

  return (
    <div className="Workplace h-full">
      <div className="flex flex-col h-full">
        <EnvironmentPicker />
        <div
          className={ClassUtilities.flatten(
            'transition-[height,opacity] duration-500',
            ClassUtilities.conditional({
              'h-14 opacity-100':
                selectedEnvironment?.status === EnvironmentStatus.Failed,
              'h-0 opacity-0': !(
                selectedEnvironment?.status === EnvironmentStatus.Failed
              ),
            })
          )}
        >
          <Alert severity="warning">
            {t('this_env_creation_failed_details')}
          </Alert>
        </div>
        <MainPageContainer
          className={ClassUtilities.flatten(
            'grow',
            ClassUtilities.conditional({
              'single-element': selectedEnvironment === undefined,
              'no-actions': canExecuteAction !== true,
            })
          )}
        >
          {selectedEnvironment !== undefined ? (
            <>
              {canExecuteAction === true && (
                <div className="Workplace__actions-costs">
                  <ActionBlock environment={selectedEnvironment} />
                  {canUseBilling && (
                    <CostBlock
                      projectId={unwrap(projectId)}
                      environmentId={selectedEnvironment.id}
                    />
                  )}
                </div>
              )}
              <Block className="flex flex-col gap-6 Workplace__informations">
                <Title level={2}>{t('env_information')}</Title>
                <table>
                  <thead>
                    <tr>
                      <th>{t('data', { count: 2 })}</th>
                      <th>{t('value', { count: 2 })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{t('name')}</th>
                      <td>{selectedEnvironment.name}</td>
                    </tr>
                    <tr>
                      <th>{t('identifier')}</th>
                      <td>{selectedEnvironment.identifier}</td>
                    </tr>
                    <tr>
                      <th>{t('git_reference')}</th>
                      <td>{selectedEnvironment.gitReference}</td>
                    </tr>
                    <tr>
                      <th>{t('creation_date')}</th>
                      <td>
                        {DateFormatter.toDateTime(
                          selectedEnvironment.createdAt
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('architecture')}</th>
                      <td>
                        {t(
                          selectedEnvironment.isMonoVM ? 'mono_vm' : 'multi_vm'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('scalable')}</th>
                      <td>
                        {completedEnvironment !== undefined ? (
                          t(completedEnvironment.isScalable ? 'yes' : 'no')
                        ) : (
                          <PlaceholderText animate />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('template')}</th>
                      <td>
                        {completedEnvironment !== undefined ? (
                          completedEnvironment.template !== null ? (
                            completedEnvironment.template
                          ) : (
                            '-'
                          )
                        ) : (
                          <PlaceholderText animate />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>HTTP</th>
                      {completedEnvironment !== undefined ? (
                        <td>
                          <ExternalLink href={completedEnvironment.websiteURL}>
                            {completedEnvironment.websiteURL}
                          </ExternalLink>
                        </td>
                      ) : (
                        <td>
                          <PlaceholderText animate />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th>{t('metrology')}</th>
                      {completedEnvironment !== undefined ? (
                        <td>
                          <ExternalLink
                            href={completedEnvironment.monitoringURL}
                          >
                            {completedEnvironment.monitoringURL}
                          </ExternalLink>
                        </td>
                      ) : (
                        <td>
                          <PlaceholderText animate />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th>{t('log')}</th>
                      {completedEnvironment !== undefined ? (
                        <td>
                          <ExternalLink href={completedEnvironment.logsURL}>
                            {completedEnvironment.logsURL}
                          </ExternalLink>
                        </td>
                      ) : (
                        <td>
                          <PlaceholderText animate />
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </Block>
              <ActionHistoryBlock
                className="self-stretch Workplace__historic"
                environmentId={selectedEnvironment.id}
                projectId={unwrap(projectId)}
              />
              <VMBlock
                className="flex flex-col gap-6 Workplace__details"
                envId={selectedEnvironment.id}
              />
            </>
          ) : (
            <div className="text-grey-500 flex items-center justify-center text-smd italic w-full self-stretch">
              {t('no_env_selected')}
            </div>
          )}
        </MainPageContainer>
      </div>
    </div>
  );
}

import { styled, TableCell } from '@mui/material';
import { Colors } from '../../../../utilities/colors';

export const DateCell = styled(TableCell)({
  color: Colors.fromTailwind('grey-700'),
  background: Colors.fromTailwind('grey-200'),
  fontWeight: 500,
});

export const GrowTableCell = styled(TableCell)({
  width: '99%',
});

export const ShrinkTableCell = styled(TableCell)({
  width: 'min-content',
  whiteSpace: 'nowrap',
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { BadLogicException } from '../exceptions/badLogicException';
import { Development } from '../utilities/development';
import {
  extractSentenceSkeleton,
  FormattedSentenceSkeleton,
} from '../utilities/translationFormat';
import { TFunction } from 'i18next';

export type Formats = Record<string, FormatInterpolationHandler>;
export type FormatInterpolationHandler =
  | {
      /** How to encapsulate the translated? This option is not compatible with {@link classes} option.
       * Those are mutually exclusive.
       */
      encapsulator: (
        formattedTranslationPart?: React.ReactNode
      ) => React.ReactNode;
    }
  | {
      /** The classes to apply to a `<span>` wrapping the translation. */
      classes: string;
    };

/**
 * Translates a term (expects the return type to be an array of string).
 * For each element of the array that matches a key in {@link interpolations} parameter,
 * interpolate the term and apply the classes to this portion of the translation.
 *
 * @example
 * // Taking this translation...
 * "confirm_delete_environment": [
 *   "Voulez-vous supprimer l'environnement <format-env>{{environment}}</format-env>\u00a0?"
 * ],
 *
 * // And this function call...
 * <p>{tArray('confirm_delete_environment',
 *           { environment: 'prod' }
 *           {
 *             env: {
 *               classes: 'text-orange',
 *             },
 *           })}</p>
 *
 * // The DOM result will look like this:
 * <p>
 *   Voulez-vous supprimer l'environnement <span class="text-orange">prod</span> ?
 * </p>
 */
export type FormattedTranslationFunction = (
  term: string,
  translationOptions: Record<string, string> | undefined,
  interpolations: Formats
) => React.ReactNode;

/**
 * Gives access to a formatting function for complex translations (returning arrays of string).
 * See the given example in the documentation of {@link FormattedTranslationFunction}.
 */
export function useTranslationFormatter(): {
  t: TFunction<'translation'>;
  tFormatted: FormattedTranslationFunction;
} {
  const { t } = useTranslation();
  const tFormatted: FormattedTranslationFunction = (
    term: string,
    translationOptions: Record<string, string> | undefined,
    formats: Formats
  ) => {
    const translationWithoutFormat = t(term, {
      replace: translationOptions,
    });
    const sentenceSkeleton = extractSentenceSkeleton(translationWithoutFormat);
    return generateComponents(sentenceSkeleton, formats, 'tFormatted.' + term);
  };

  return { t, tFormatted };
}

function generateComponents(
  sentenceSkeleton: FormattedSentenceSkeleton,
  formats: Formats,
  id: string
): React.ReactNode {
  return (
    <>
      {sentenceSkeleton.map((part, index) => {
        const identifier = `${id}[${index}]`;

        if (typeof part === 'string')
          return <React.Fragment key={identifier}>{part}</React.Fragment>;

        const [formatKey, nestedSkeleton] = part;

        if (!(formatKey in formats)) {
          if (Development.missingTranslationFormatKeyWarnings()) {
            console.warn(
              `Missing format key ${formatKey} in translation. (debug: ${id})`
            );
          }
          return (
            <React.Fragment key={identifier}>
              {generateComponents(
                nestedSkeleton,
                formats,
                `${identifier}[index=${formatKey}]`
              )}
            </React.Fragment>
          );
        }

        const handler = formats[formatKey];

        if ('encapsulator' in handler) {
          const encapsulator = handler.encapsulator;
          return (
            <React.Fragment key={identifier}>
              {encapsulator(
                generateComponents(
                  nestedSkeleton,
                  formats,
                  `${identifier}[index=${formatKey}]`
                )
              )}
            </React.Fragment>
          );
        } else if ('classes' in handler) {
          const classes = handler.classes;
          return (
            <React.Fragment key={identifier}>
              <span className={classes}>
                {generateComponents(
                  nestedSkeleton,
                  formats,
                  `${identifier}[index=${formatKey}]`
                )}
              </span>
            </React.Fragment>
          );
        } else {
          throw new BadLogicException(
            `Nor "encapsulation", nor "classes" could be found for format key "${formatKey}"`
          );
        }
      })}
    </>
  );
}

import { useEnvironmentRoute } from './useEnvironmentRoute';
import { useProjectRoute } from './useProjectRoute';

/**
 * Gets the portions of route containing the project and the environment selection.
 */
export function useProjectAndEnvironmentRoute() {
  const projectRoute = useProjectRoute();
  const environmentRoute = useEnvironmentRoute();

  return projectRoute + environmentRoute;
}

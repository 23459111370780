import { t } from 'i18next';
import React from 'react';
import { ErrorPageTemplate } from '../../templates/ErrorPageTemplate/ErrorPageTemplate';
import { Button } from '../Button/Button';
import { MailButton } from './subcomponents/MailButton';

interface State {
  error: { error: Error; errorInfo: React.ErrorInfo } | null;
}

interface Props {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error({ error, errorInfo });
    this.setState({ error: { error, errorInfo } });
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPageTemplate
          title={t('unexpected_error_big_title') ?? 'Oops...'}
          details={
            t('unexpected_error_details') ??
            'An unexpected error surfaced! We are sorry for the inconvenience.'
          }
          actions={
            <>
              <MailButton error={this.state.error} />
              {/* Button reload */}
              <Button
                variant="contained"
                startIcon={<i className="icon-refresh-cw" />}
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t('refresh_page') ?? 'Refresh the page'}
              </Button>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}

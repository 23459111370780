import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ImagePicker, ImagePickerProps } from '../ImagePicker/ImagePicker';

export interface FormImagePickerProps<TFieldValues extends FieldValues>
  extends Omit<
    ImagePickerProps,
    'name' | 'error' | 'helperText' | 'onChange' | 'value'
  > {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, unknown>;
}

export function FormImagePicker<TFieldValues extends FieldValues>(
  props: FormImagePickerProps<TFieldValues>
) {
  const { name, control, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ImagePicker
          helperText={error?.message}
          error={!!error}
          onChange={onChange}
          value={value}
          {...fieldProps}
        />
      )}
    />
  );
}

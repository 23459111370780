import { Bill } from '../../../../models/bill';

type BillUpdate = Partial<Bill> & Pick<Bill, 'id'>;

/**
 * Utility class to notify components manipulating bills when a patch in their data may be needed.
 */
export class BillPatchEventNotifier {
  private _listeners: Map<string, (updatedBill: BillUpdate) => void> =
    new Map();

  addListener(name: string, callback: (updatedBill: BillUpdate) => void) {
    this._listeners.set(name, callback);
  }

  removeListener(name: string) {
    this._listeners.delete(name);
  }

  notifyPatch(updatedBill: BillUpdate) {
    for (const callback of this._listeners.values()) {
      callback(updatedBill);
    }
  }
}

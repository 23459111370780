import { useEffect, useRef, useState } from 'react';
import { EnvironmentApi } from '../../../../api/environmentApi';
import { PartialEnvironment } from '../../../../models/environment';
import { Project } from '../../../../models/project';
import { PromiseSnapshot } from '../../../../utilities/promiseSnapshot';

export function useEnvironments(
  projectId: Project['id']
): PromiseSnapshot<PartialEnvironment[]> {
  const [snapshot, setSnapshot] = useState(
    new PromiseSnapshot<PartialEnvironment[]>()
  );
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new EnvironmentApi().getAll(
          {
            'project[]': [projectId],
          },
          { abortController }
        ),
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [projectId]);

  return snapshot;
}

import { useTranslation } from 'react-i18next';
import { Bill } from '../../../../models/bill';
import { getServerUrl } from '../../../../utilities/fetchUtilities';
import { Button } from '../../../atoms/Button/Button';

export function DownloadButton(props: { bill: Bill }) {
  const { t } = useTranslation();

  return (
    <a
      href={getServerUrl() + props.bill.pdfUri}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Button startIcon={<i className="icon-file-pdf"></i>}>
        {t('download')}
      </Button>
    </a>
  );
}

import { z } from 'zod';
import { UserProjectRoleType } from '../../../../../models/userProjectRole';

export const schema = z.object({
  email: z.string().email('invalid_email'),
  role: z.enum([
    UserProjectRoleType.Viewer,
    UserProjectRoleType.Developer,
    UserProjectRoleType.CustomerViewer,
    UserProjectRoleType.Manager,
  ]),
});

export type Schema = z.infer<typeof schema>;

import { NetworkRequestException } from '../../../exceptions/networkRequestException';

export function errorToTerm(exception: unknown): string {
  if (exception instanceof NetworkRequestException) {
    switch (exception.status) {
      case 400:
        return 'env_already_deleted';
      case 408:
        return 'env_rundeck_check_timeout';
      case 429:
        return 'deletion_already_occurring';
      case 409:
        return (
          exception.messageKey ?? 'deletion_unknown_error_environment_deletion'
        );
    }
  }

  return 'deletion_unknown_error_environment_deletion';
}

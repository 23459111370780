import { WithTranslation } from 'react-i18next';
import { z } from 'zod';

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    imageId: z
      .string({ required_error: t('rollback_no_image_selected') })
      .min(1),
    activateMaintenance: z.literal(false),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

import { useCurrentUserStore } from '../stores/currentUserStore';
import { responseErrorToException } from '../utilities/fetchUtilities';
import {
  MiddlewareResult,
  ResponseMiddleware,
} from '../utilities/responseMiddleware';

/**
 * Middleware automatically redirecting the user to the login page if he performs a request that need to be authenticated.
 */
export class AuthenticationMiddleware extends ResponseMiddleware {
  public constructor() {
    super();
  }

  public async execute(response: Response): Promise<MiddlewareResult> {
    if (response.status !== 401) {
      return {
        result: response,
        shouldContinue: true,
      };
    } else {
      // Redirect to login page.
      useCurrentUserStore
        .getState()
        .invalidateUser(await responseErrorToException(response.clone()));

      return {
        result: response,
        shouldContinue: false,
      };
    }
  }
}

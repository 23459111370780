import { useState } from 'react';
import { ClassUtilities } from '../../../utilities/classUtility';
import { BlockTab } from '../BlockTab/BlockTab';
import { BlockTabBody } from '../BlockTabBody/BlockTabBody';
import { BlockTabsHeader } from '../BlockTabsHeader/BlockTabsHeader';

export interface BlockTabsProps {
  tabsContent: React.ReactElement[] | React.ReactElement;
  body: React.ReactElement;
  fullHeightBody?: boolean;
  shownTabIndex: number;
  onTabClicked?: (tabIndex: number) => void;
}

export function BlockTabs(props: BlockTabsProps) {
  const [tabsTakeFullWidth, setTabsTakeFullWidth] = useState(false);
  const tabsContent: React.ReactElement[] =
    'length' in props.tabsContent ? props.tabsContent : [props.tabsContent];
  const body = props.body;

  return (
    <div className="BlockTabs w-full h-full flex flex-col">
      <BlockTabsHeader
        onTabsTakesFullWidthChanged={(newTabsTakeFullWidth) =>
          setTabsTakeFullWidth(newTabsTakeFullWidth)
        }
      >
        {tabsContent.map((tabContent, index) => (
          <BlockTab
            key={index}
            isCurrentTab={props.shownTabIndex === index}
            onClick={() => props.onTabClicked?.(index)}
          >
            {tabContent}
          </BlockTab>
        ))}
      </BlockTabsHeader>
      <div className="grow mb-2">
        <BlockTabBody
          className={ClassUtilities.flatten(
            'max-h-full',
            ClassUtilities.conditional({ 'h-full': !!props.fullHeightBody })
          )}
          fullWidth={tabsTakeFullWidth}
        >
          {body}
        </BlockTabBody>
      </div>
    </div>
  );
}

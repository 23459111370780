import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { ActionTypeName } from '../../../models/actionTypeName';
import { Image } from '../../../models/image';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import { ClassUtilities } from '../../../utilities/classUtility';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { OutlinedRoundedPinkButton } from '../../atoms/Button/variations/OutlinedRoundedButtonVariations';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { InformationBlock } from '../../atoms/InformationBlock/InformationBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { FormRadio } from '../../atoms/Radio/FormRadio';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { Schema, getSchema } from './schema';
import { errorToTerm } from './utilities';

import { useEnvironmentActionTypesEstimates } from '../../../hooks/useEnvironmentActionTypesEstimates';
import { EnvironmentActionTypeServerSizingEstimate } from '../../../models/environmentActionType';
import { NumberFormatter } from '../../../utilities/numberFormatter';
import {
  ActionShortInfoBlocks,
  ActionShortInfoBlocksProps,
} from '../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';
import { EstimateErrorBlock } from '../../molecules/EstimateErrorBlock/EstimateErrorBlock';
import './Rollback.css';

export function Rollback() {
  const { t, tFormatted } = useTranslationFormatter();
  useTitle(t('done_action_labels.rollback'));

  const environment = useProjectEnvironmentsStore((state) =>
    state.getEnvironment()
  );
  const environmentId = useProjectEnvironmentsStore(
    (state) => state.environments.data?.[state.selectedEnvironmentIndex]?.id
  );
  const [imagesSnapshot, setImagesSnapshot] = useState<
    PromiseSnapshot<Image[]>
  >(new PromiseSnapshot());
  const [imagesFetchToggle, setImagesFetchToggle] = useState(false);
  const imagesAbortController = useRef(new AbortController());

  const anotherRollbackInProgress = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.Rollback,
    environmentId: environment?.id,
  });

  const rollbackEstimates =
    useEnvironmentActionTypesEstimates<EnvironmentActionTypeServerSizingEstimate>(
      environmentId,
      ActionTypeName.Rollback
    );

  const formMethods = useForm<Schema>({
    defaultValues: {
      activateMaintenance: false,
    },
    resolver: zodResolver(getSchema(t)),
  });
  const {
    control,
    handleSubmit,
    formState: {
      errors: { imageId: imageIdError },
    },
  } = formMethods;

  // Fetch images
  useEffect(() => {
    if (environment === undefined) return;

    const abortController = imagesAbortController.current;

    PromiseSnapshot.trackPromiseSetter(
      () => new EnvironmentApi().getImages(environment.id),
      setImagesSnapshot,
      {
        abortController,
      }
    );

    return () => {
      abortController.abort();
      imagesAbortController.current = new AbortController();
    };
  }, [environment, imagesFetchToggle]);

  // Submission handling
  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('done_action_labels.rollback'),
      environmentName: environment?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  // Submission callback
  const onSubmit = (data: Schema) => {
    submitAndWatchResult(async () => {
      return new EnvironmentApi().rollback(unwrap(environment).id, data);
    });
  };

  const fetchImages = () => setImagesFetchToggle(!imagesFetchToggle);

  const interactionDisabled =
    environment === undefined || anotherRollbackInProgress || loading;

  const blocksStyleProps: ActionShortInfoBlocksProps['stylesProps'] = [
    {
      icon: {
        color: 'yellow-200',
        icon: 'icon-clock',
      },
      label: t('operation_estimated_duration'),
      className: 'grow',
    },
    {
      icon: {
        color: 'deepgreen-200',
        icon: 'icon-server',
      },
      label: t('server_sizing'),
      className: 'grow',
    },
    {
      icon: {
        color: 'orange-200',
        icon: 'icon-bill',
      },
      label: t('operation_estimated_cost'),
      className: 'grow',
    },
  ];

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.Rollback}>
      <div className="Rollback flex flex-col lg:grid grid-cols-aside-md gap-4">
        <div className="flex flex-col gap-4">
          <WarningBlock>
            {tFormatted('rollback_warning', undefined, {
              bold: {
                classes: 'font-semibold underline',
              },
            })}
          </WarningBlock>
          <Block className="flex flex-col gap-6">
            <Title level={2}>{t('done_action_labels.rollback')}</Title>

            {anotherRollbackInProgress && (
              <WarningBlock>{t('rollback_already_occurring')}</WarningBlock>
            )}

            {imagesSnapshot.map({
              failed: () => (
                <ErrorBlock
                  noShadow
                  actions={
                    <OutlinedRoundedPinkButton onClick={fetchImages}>
                      {t('retry')}
                    </OutlinedRoundedPinkButton>
                  }
                >
                  {t('images_retrieval_failed')}
                </ErrorBlock>
              ),
              notStarted: 'running',
              running: () => (
                <LoadingBlock text={t('images_retrieval_in_progress')} />
              ),
              succeeded: (images) => (
                <div className="flex flex-col gap-2 pb-2">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <CustomTableCellHead>
                          {t('git_reference')}
                        </CustomTableCellHead>
                        <CustomTableCellHead>
                          {t('creation_date')}
                        </CustomTableCellHead>
                        <CustomTableCellHead>
                          {t('reference')}
                        </CustomTableCellHead>
                        <CustomTableCellHead sx={{ textAlign: 'center' }}>
                          {t('selection')}
                        </CustomTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {images.map((image) => (
                        <TableRow
                          key={image.id}
                          className={ClassUtilities.conditional({
                            'image-in-use': image.isInUse,
                          })}
                        >
                          <CustomTableCell>
                            {image.gitReference}
                          </CustomTableCell>
                          <CustomTableCell>
                            {DateFormatter.toDate(image.createdAt)}
                          </CustomTableCell>
                          <CustomTableCell>{image.id}</CustomTableCell>
                          <CustomTableCell>
                            <div className="flex justify-center">
                              {image.isInUse ? (
                                <Tooltip
                                  title={t('this_is_the_current_env_image')}
                                >
                                  <i className="icon-activity text-xl text-orange-350 hover:text-orange transition-colors py-2 cursor-help" />
                                </Tooltip>
                              ) : (
                                <FormRadio
                                  control={control}
                                  name="imageId"
                                  onValue={image.id}
                                  disabled={
                                    interactionDisabled || image.isInUse
                                  }
                                  hideErrorMessage
                                />
                              )}
                            </div>
                          </CustomTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {imageIdError?.message && (
                    <FormHelperText error>
                      {imageIdError.message}
                    </FormHelperText>
                  )}
                </div>
              ),
            })}
            <RoundedFilledBlueButton
              className="self-end"
              onClick={handleSubmit(onSubmit)}
              loading={loading}
              disabled={
                interactionDisabled || imageIdError?.message !== undefined
              }
            >
              {t('restore')}
            </RoundedFilledBlueButton>
          </Block>
        </div>
        <div className="flex flex-col gap-4">
          {rollbackEstimates.map({
            succeeded: (data) => (
              <>
                <ActionShortInfoBlocks
                  stylesProps={blocksStyleProps}
                  valueDescriptions={[
                    DateFormatter.toDuration(data.estimatedDuration, t, {
                      maximumParts: 1,
                    }),
                    t('server_sizing_specs', data.serverSizing),
                    t('price_per_hour_template', {
                      price: NumberFormatter.toEuro(
                        unwrap(data.estimatedTotalInclTax?.perHour)
                      ),
                    }),
                  ]}
                />
              </>
            ),
            failed: () => <EstimateErrorBlock />,
            notStarted: 'running',
            running: () => (
              <>
                <ActionShortInfoBlocks stylesProps={blocksStyleProps} />
              </>
            ),
          })}
          <InformationBlock>{t('rollback_information')}</InformationBlock>
        </div>
      </div>

      {feedbackWidget}
    </ActionPageTemplate>
  );
}

// #region Styling
const xCellPadding = 16;
const yCellPadding = 6;

const CustomTableCell = styled(TableCell)({
  padding: `${yCellPadding}px ${xCellPadding}px`,
});

const CustomTableCellHead = styled(CustomTableCell)({
  verticalAlign: 'bottom',
});
// #endregion Styling

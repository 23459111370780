import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import { DeployPayload } from '../../../models/environment';
import {
  getGitReferenceInfo,
  getGitReferenceSchema,
} from '../../molecules/FormGitReferencePicker/schema';

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    gitReference: getGitReferenceSchema(t),
    switchTraffic: z.boolean(),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

export function schemaToPostArgs(schema: Schema): DeployPayload {
  const { switchTraffic } = schema;
  const { referenceType: gitReferenceType, reference: gitReference } =
    getGitReferenceInfo(schema);

  return {
    gitReferenceType,
    gitReference,
    switchTraffic,
  };
}

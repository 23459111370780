import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionHistoryApi } from '../../../../api/actionHistoryApi';
import { ActionHistory } from '../../../../models/actionHistory';
import { ActionTypeHandler } from '../../../../models/actionType';
import { Environment } from '../../../../models/environment';
import { useActionTypesStore } from '../../../../stores/actionTypesStore';
import { unwrap } from '../../../../utilities/assertions';
import { DateFormatter } from '../../../../utilities/dateFormatter';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../../utilities/promiseSnapshot';
import { StatusChip } from '../../../atoms/Chip/StatusChip';
import { DiscreteTextLine } from '../../../atoms/DiscreteTextLine/DiscreteTextLine';
import { PlaceholderChip } from '../../../atoms/PlaceholderChip/PlaceholderChip';
import { PlaceholderText } from '../../../atoms/PlaceholderText/PlaceholderText';
import { RoundedIcon } from '../../../atoms/RoundedIcon/RoundedIcon';
import { actionStatusToIconProps } from '../utilities';

interface LastActionProps {
  environmentIds?: Environment['id'][];
}

export function LastAction(props: LastActionProps) {
  const { t } = useTranslation();
  const [actionHistorySnapshot, setActionHistorySnapshot] = useState(
    new PromiseSnapshot<ActionHistory | null>()
  );
  const [fetchActionTypes, actionTypesSnapshot] = useActionTypesStore(
    (state) => [state.fetch, state.snapshot]
  );
  const abortControllerRef = useRef(new AbortController());

  const actionType = useMemo(() => {
    if (actionTypesSnapshot.data === undefined) return undefined;
    if (actionHistorySnapshot.data === undefined) return undefined;
    return actionTypesSnapshot.data.find(
      (anActionType) =>
        anActionType.id === actionHistorySnapshot.data?.actionType
    );
  }, [actionHistorySnapshot.data, actionTypesSnapshot.data]);

  const ready = useMemo(
    () =>
      actionHistorySnapshot.state === PromiseState.Succeeded &&
      (actionHistorySnapshot.data === null || actionType !== undefined),
    [actionHistorySnapshot, actionType]
  );

  // Fetch action types
  useEffect(() => {
    fetchActionTypes();
  }, [fetchActionTypes]);

  useEffect(() => {
    if (props.environmentIds === undefined) return;
    const abortController = abortControllerRef.current;

    if (props.environmentIds.length === 0) {
      setActionHistorySnapshot(PromiseSnapshot.buildSucceeded(null));
    } else {
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new ActionHistoryApi()
            .getAllPaginated({
              'environment[]': props.environmentIds,
              itemsPerPage: 1,
            })
            .then((actionHistories) => {
              return actionHistories['hydra:member'][0];
            }),
        setActionHistorySnapshot,
        {
          abortController,
        }
      );
    }

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [props.environmentIds]);

  return (
    <div className="LastAction section">
      <div className="section-title">{t('last_action')}</div>
      <div className="flex gap-2 items-center">
        {ready ? (
          <>
            {actionHistorySnapshot.data !== null ? (
              <>
                <RoundedIcon
                  {...actionStatusToIconProps(
                    unwrap(actionHistorySnapshot.data).status
                  )}
                />
                <StatusChip
                  context="info"
                  label={new ActionTypeHandler(
                    unwrap(actionType)
                  ).getDoneFriendlyName()}
                />
                <span className="text-grey-800 text-sm font-medium grow text-right whitespace-nowrap">
                  {DateFormatter.toLongDate(
                    unwrap(actionHistorySnapshot.data).updatedAt
                  )}
                </span>
              </>
            ) : (
              <DiscreteTextLine>
                {t('no_action_for_this_project')}
              </DiscreteTextLine>
            )}
          </>
        ) : (
          <>
            <div className="h-8 w-8 rounded-full generic-placeholder"></div>
            <PlaceholderChip />
            <div className="grow" />
            <PlaceholderText className="w-16" />
          </>
        )}
      </div>
    </div>
  );
}

import dayjs from 'dayjs';
import { default as i18next, default as i18nRaw } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationBASE from './assets/locales/base/translation.json';
import translationEN from './assets/locales/en/translation.json';
import translationFR from './assets/locales/fr/translation.json';

import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';

export const i18n = i18nRaw
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      fallbackLng: ['en', 'base'],
      returnNull: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      compatibilityJSON: 'v4',
      resources: {
        en: {
          translation: translationEN,
        },
        fr: {
          translation: translationFR,
        },
        base: {
          translation: translationBASE,
        },
      },
      saveMissing: true, // must be enabled
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      missingKeyHandler: (_lng, _ns, _key, _fallbackValue) => {},
    },
    (err) => {
      if (err) {
        console.error(err);
        return;
      }
      dayjs.locale(i18next.language);
    }
  );

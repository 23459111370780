import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectApi } from '../../../api/projectApi';
import { useTitle } from '../../../hooks/useTitle';
import {
  DataBackupPolicy,
  ProjectSpecifications,
} from '../../../models/project';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';
import { Title } from '../../atoms/Title/Title';
import { filterServerTypeValues } from './utilities';

export function Specifications() {
  const { t } = useTranslation();
  useTitle(t('specifications'));
  const projectId = unwrap(useProjectsStore((state) => state.getProject()?.id));

  const [snapshot, setSnapshot] = useState<
    PromiseSnapshot<ProjectSpecifications>
  >(new PromiseSnapshot<ProjectSpecifications>());

  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ProjectApi().getSpecifications(projectId, {
          abortController,
        }),
      setSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [projectId]);

  function translateDataBackupPolicy(
    dataBackupPolicy: DataBackupPolicy
  ): string {
    // ? Since it's "backup per day", count = 1
    const periodicityTranslation = t(
      (dataBackupPolicy?.periodicity as string) ?? 'day',
      { count: 1 }
    );
    const retentionPeriodicityTranslation = t(
      dataBackupPolicy?.retentionPeriodicity ?? 'day',
      {
        count: dataBackupPolicy?.retentionDays,
      }
    );

    return t('data_backup_policy_values', {
      days: dataBackupPolicy?.days,
      periodicity: periodicityTranslation,
      retentionDays: dataBackupPolicy?.retentionDays,
      retentionPeriodicity: retentionPeriodicityTranslation,
    });
  }

  return (
    <div className="Parameters overflow-y-auto">
      <MainPageContainer>
        <Block className="flex flex-col gap-4">
          <Title level={2}>{t('specifications')}</Title>
          {snapshot.map({
            notStarted: 'running',
            running: () => (
              <>
                <LoadingBlock></LoadingBlock>
              </>
            ),
            failed: (error) => (
              <>
                <ErrorBlock>{`${error}`}</ErrorBlock>
              </>
            ),
            succeeded: (data) => {
              return (
                <>
                  <TableContainer className="hidden xl:block" component={'div'}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('data', { count: 2 })}</TableCell>
                          <TableCell>{t('value', { count: 2 })}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="whitespace-nowrap">
                        {filterServerTypeValues(data.multiServerSizing)?.map(
                          (serverSizing, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  {t(`template_${serverSizing.type}`)}
                                </TableCell>
                                <TableCell>
                                  {t('template_server_values', serverSizing)}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                        <TableRow>
                          <TableCell>{t('standard_server_sizing')}</TableCell>
                          <TableCell>
                            {data.multiServerSizing.map(
                              (serverType, index, array) => {
                                return (
                                  <React.Fragment key={index}>
                                    {serverType.count}{' '}
                                    {t(
                                      serverType.type !== undefined
                                        ? serverType.type
                                        : serverType.sizing
                                    )}
                                    {index < array.length - 1 ? ' - ' : ''}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                        {data.monoServerSizing.length > 0 && (
                          <TableRow>
                            <TableCell>{t('template_mono_server')}</TableCell>
                            <TableCell>
                              {t(
                                'template_server_values',
                                data.monoServerSizing[0]
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {data.environmentCount.multiVm &&
                          data.environmentCount.multiVm !== null && (
                            <TableRow>
                              <TableCell>
                                {t('multi_server_max_allowed')}
                              </TableCell>
                              <TableCell>
                                {data.environmentCount.multiVm}
                              </TableCell>
                            </TableRow>
                          )}
                        {data.environmentCount.monoVm &&
                          data.environmentCount.monoVm !== null && (
                            <TableRow>
                              <TableCell>
                                {t('mono_server_max_allowed')}
                              </TableCell>
                              <TableCell>
                                {data.environmentCount.monoVm}
                              </TableCell>
                            </TableRow>
                          )}
                        {data.gitRepositoryUrl &&
                          data.gitRepositoryUrl !== null && (
                            <TableRow>
                              <TableCell>{t('gitlab_address')}</TableCell>
                              <TableCell>{data.gitRepositoryUrl}</TableCell>
                            </TableRow>
                          )}
                        {data.monoVMDataUpdateCountPerWeek &&
                          data.monoVMDataUpdateCountPerWeek !== null && (
                            <TableRow>
                              <TableCell>
                                {t('updates_mono_server_max_allowed')}
                              </TableCell>
                              <TableCell>
                                {data.monoVMDataUpdateCountPerWeek}
                              </TableCell>
                            </TableRow>
                          )}
                        {data.dataBackupPolicy &&
                          data.dataBackupPolicy !== null && (
                            <TableRow>
                              <TableCell>{t('data_backup_policy')}</TableCell>
                              <TableCell>
                                {translateDataBackupPolicy(
                                  data.dataBackupPolicy
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="flex flex-col gap-4 xl:hidden py-2">
                    {filterServerTypeValues(data.multiServerSizing)?.map(
                      (serverSizing, index) => {
                        return (
                          <Paper
                            key={index}
                            className="text-smd flex flex-col p-4"
                          >
                            <span className="font-semibold">
                              {t(`template_${serverSizing.type}`)}
                            </span>
                            <span>
                              {t('template_server_values', serverSizing)}
                            </span>
                          </Paper>
                        );
                      }
                    )}
                    <Paper className="text-smd flex flex-col p-4">
                      <span className="font-semibold">
                        {t('standard_server_sizing')}
                      </span>
                      <span>
                        {data.multiServerSizing.map(
                          (serverType, index, array) => {
                            return (
                              <React.Fragment key={index}>
                                {serverType.count}{' '}
                                {t(
                                  serverType.type !== undefined
                                    ? serverType.type
                                    : serverType.sizing
                                )}
                                {index < array.length - 1 ? ' - ' : ''}
                              </React.Fragment>
                            );
                          }
                        )}
                      </span>
                    </Paper>
                    {data.monoServerSizing.length > 0 && (
                      <Paper className="text-smd flex flex-col p-4">
                        <span className="font-semibold">
                          {t('template_mono_server')}
                        </span>
                        <span>
                          {t(
                            'template_server_values',
                            data.monoServerSizing[0]
                          )}
                        </span>
                      </Paper>
                    )}
                    {data.environmentCount.multiVm &&
                      data.environmentCount.multiVm !== null && (
                        <Paper className="text-smd flex flex-col p-4">
                          <span className="font-semibold">
                            {t('multi_server_max_allowed')}
                          </span>
                          <span>{data.environmentCount.multiVm}</span>
                        </Paper>
                      )}
                    {data.environmentCount.monoVm &&
                      data.environmentCount.monoVm !== null && (
                        <Paper className="text-smd flex flex-col p-4">
                          <span className="font-semibold">
                            {t('mono_server_max_allowed')}
                          </span>
                          <span>{data.environmentCount.monoVm}</span>
                        </Paper>
                      )}
                    {data.gitRepositoryUrl &&
                      data.gitRepositoryUrl !== null && (
                        <Paper className="text-smd flex flex-col p-4">
                          <span className="font-semibold">
                            {t('gitlab_address')}
                          </span>
                          <span>{data.gitRepositoryUrl}</span>
                        </Paper>
                      )}
                    {data.monoVMDataUpdateCountPerWeek &&
                      data.monoVMDataUpdateCountPerWeek !== null && (
                        <Paper className="text-smd flex flex-col p-4">
                          <span className="font-semibold">
                            {t('updates_mono_server_max_allowed')}
                          </span>
                          <span>{data.monoVMDataUpdateCountPerWeek}</span>
                        </Paper>
                      )}
                    {data.dataBackupPolicy &&
                      data.dataBackupPolicy !== null && (
                        <Paper className="text-smd flex flex-col p-4">
                          <span className="font-semibold">
                            {t('data_backup_policy')}
                          </span>
                          <span>
                            {translateDataBackupPolicy(data.dataBackupPolicy)}
                          </span>
                        </Paper>
                      )}
                  </div>
                </>
              );
            },
          })}
        </Block>
      </MainPageContainer>
    </div>
  );
}

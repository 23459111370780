import dayjs from 'dayjs';
import { Bill, BillStatus } from '../models/bill';
import { Project } from '../models/project';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import {
  AbortOptions,
  AbortOptionsDefault,
  postJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const { REACT_APP_ROUTE_BILLS, REACT_APP_ROUTE_BILLS_SEND } = process.env;

export interface GetOptionsType {
  page?: number;
  'project[]'?: Project['id'][];
  'date[before]'?: Date;
  'date[strictly_before]'?: Date;
  'date[after]'?: Date;
  'date[stricly_after]'?: Date;
  'status[]'?: BillStatus[];
}

class GetOptionsHandler extends QueryOptionsHandler<GetOptionsType> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptionsType> {
    return {
      page: ParamMappingHelper.mapNumber,
      'project[]': ParamMappingHelper.identity,
      'date[before]': ParamMappingHelper.mapDate,
      'date[strictly_before]': ParamMappingHelper.mapDate,
      'date[after]': ParamMappingHelper.mapDate,
      'date[stricly_after]': ParamMappingHelper.mapDate,
      'status[]': ParamMappingHelper.identity,
    };
  }
}

export type PatchArgs = {
  status: BillStatus;
};

export class BillApi extends BaseApi<
  Bill,
  GetOptionsType,
  Bill,
  null,
  PatchArgs
> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_BILLS, 'Bills route is not defined'),
      GetOptionsHandler
    );
  }

  transformGottenPartialEntity(entity: Bill): Bill {
    return this.transformGottenEntity(entity);
  }

  transformGottenEntity(entity: Bill): Bill {
    entity.date = dayjs(entity.date).toDate();
    entity.updatedAt = dayjs(entity.date).toDate();

    entity.payments = entity.payments.map((payment) => {
      payment.paidAt = dayjs(payment.paidAt).toDate();
      return payment;
    });

    return entity;
  }

  /**
   * Sends the bill to the client.
   * @param id The id of the bill.
   * @param options The options. See {@link AbortOptions}.
   * @returns The updated {@link Bill}.
   */
  send(id: Bill['id'], options?: Partial<AbortOptions>): Promise<void> {
    const route = unwrap(REACT_APP_ROUTE_BILLS_SEND, 'send route not defined');
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return postJsonFromServerRoute(route, {
      payload: { billId: id },
      expectJsonResponse: false,
      abortController: filledOptions.abortController,
    });
  }
}

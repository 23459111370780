import { Button, TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface DatePickerProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  label?: ReactNode;
  error?: boolean;
}

export function DatePicker(props: DatePickerProps) {
  const { t } = useTranslation();

  return (
    <DesktopDatePicker
      inputFormat="DD/MM/YYYY"
      value={props.value}
      onChange={props.onChange}
      components={{
        OpenPickerIcon: () => <i className="icon-calendar" />,
        ActionBar: (actionBarProps: PickersActionBarProps) => {
          return (
            <div className="p-4 flex justify-end">
              <Button onClick={() => actionBarProps.onSetToday()}>
                {t('today').toUpperCase()}
              </Button>
              <Button
                disabled={props.value === null}
                onClick={() => actionBarProps.onClear()}
              >
                {t('reset').toUpperCase()}
              </Button>
            </div>
          );
        },
      }}
      OpenPickerButtonProps={{
        sx: {
          fontSize: '16px',
          marginRight: '-4px',
        },
      }}
      renderInput={(params) => {
        const truncatedParams: TextFieldProps = params;
        if (truncatedParams.inputProps?.placeholder) {
          truncatedParams.inputProps = {
            ...truncatedParams.inputProps,
            placeholder: 'jj/mm/aaaa',
          };
        }
        return (
          <TextField
            {...truncatedParams}
            label={props.label}
            variant="outlined"
            size="small"
            sx={{ minWidth: '11em' }}
            error={props.error}
          />
        );
      }}
    />
  );
}

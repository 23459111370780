import { ClassUtilities } from '../../../utilities/classUtility';

export interface DotBadgeProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
}

/**
 * Adds a pulsating dot badge to the top-right corner.
 * @param props
 * @returns
 */
export function DotBadge(props: DotBadgeProps) {
  return (
    <div className={ClassUtilities.flatten('relative', props.className)}>
      {props.children}
      <div
        className="w-2 h-2 absolute top-[-0.25rem] right-[-0.25rem] bg-pink-500 rounded-full animate-ping"
        style={{ backgroundColor: props.color }}
      ></div>
      <div
        className="w-2 h-2 absolute top-[-0.25rem] right-[-0.25rem] bg-pink-500 rounded-full"
        style={{ backgroundColor: props.color }}
      ></div>
    </div>
  );
}

import { useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { Colors } from '../../../utilities/colors';
import * as Number from '../../../utilities/numberUtilities';
import { Block } from '../../atoms/Block/Block';
import { Button } from '../../atoms/Button/Button';
import { ChipGroup } from '../../atoms/ChipGroup/ChipGroup';
import { ColorPicker } from '../../atoms/ColorPicker/ColorPicker';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { DebugTable } from '../../atoms/DebugTable/DebugTable';
import { DotBadge } from '../../atoms/DotBadge/DotBadge';
import { InformationText } from '../../atoms/InformationText/InformationText';
import { LoadingScreen } from '../../atoms/LoadingScreen/LoadingScreen';
import { Logs } from '../../atoms/Logs/Logs';
import { TextField } from '../../atoms/TextField/TextField';
import { Title, TitleLevel } from '../../atoms/Title/Title';
import { UserGroup } from '../../atoms/UserGroup/UserGroup';
import { ActionButton } from '../../molecules/ActionButton/ActionButton';
import { IconParagraph } from '../../molecules/IconParagraph/IconParagraph';
import { IconShortInfoBlock } from '../../molecules/IconShortInfoBlock/IconShortInfoBlock';
import { MenuButton, SelectMode } from '../../molecules/MenuButton/MenuButton';
import { Context } from '../../shared/types/context';
import './ComponentLibrary.css';
import { ErrorThrower } from './subcomponents/ErrorThrower';
import { IconPicker } from './subcomponents/IconPicker';

export default function ComponentLibrary() {
  useTitle('Component library');

  const contexts: Context[] = ['info', 'neutral', 'success', 'warning'];
  const [loaderIndeterminateOpened, setLoaderIndeterminateOpened] =
    useState(false);
  const [loaderDeterminateProgress, setLoaderDeterminateProgress] = useState<
    number | null
  >(null);
  const logs: string[] = [
    '[region][info] -- Logs begin at Mon 2021-05-03 10:24:23 CEST, end at Mon 2022-01-24 15:30:08 CET. --',
    '[success] mai 03 10:24:23 serv-17461 kernel: microcode: microcode updated early to revision',
    ' mai 03 10:24:23 serv-17461 kernel: KERNEL supported cpus:',
    '[endregion] mai 03 10:24:23 serv-17461 kernel: Intel GenuineIntel',
    "[region] mai 03 10:24:23 serv-17461 kernel: x86/fpu: Supporting XSAVE feature 0x001: 'x87 floating point registers'",
    " mai 03 10:24:23 serv-17461 kernel: x86/fpu: Supporting XSAVE feature 0x002: 'SSE registers'",
    "[region] mai 03 10:24:23 serv-17461 kernel: x86/fpu: Supporting XSAVE feature 0x004: 'AVX registers'",
    "[endregion] mai 03 10:24:23 serv-17461 kernel: x86/fpu: Enabled xstate features 0x1f, context size is 960 bytes, using 'compacted' format.",
    ' mai 03 10:24:23 serv-17461 kernel: BIOS-provided physical RAM map:',
    '[info] mai 03 10:24:23 serv-17461 kernel: BIOS-e820: [mem 0x0000000000000000-0x0000000000057fff] usable',
    '[warning] mai 03 10:24:23 serv-17461 kernel: NX (Execute Disable) protection: active',
    '[error] mai 03 10:24:23 serv-17461 kernel: e820: update [mem 0x9b547018-0x9b564a57] usable ==> usable',
    '[endregion] mai 03 10:24:23 serv-17461 kernel: e820: update [mem 0x9b547018-0x9b564a57] usable',
    '[region] mai 03 10:24:23 serv-17461 kernel: e820: update [mem 0x9b536018-0x9b546457] usable ==> usable',
    ' mai 03 10:24:23 serv-17461 kernel: extended physical RAM map:',
    'mai 03 10:24:23 serv-17461 kernel: reserve setup_data: [mem 0x0000000000000000-0x0000000000057fff] usable ',
  ];

  const simulateLoaderDeterminate = async () => {
    for (let i = 0; i <= 100; i += 10) {
      setLoaderDeterminateProgress(i);
      await new Promise((resolve) =>
        setTimeout(resolve, 200 + Math.random() * 300)
      );
    }
    await new Promise((resolve) => setTimeout(resolve, 700));
    setLoaderDeterminateProgress(null);
  };

  const [color, setSelectedColor] = useState<string | undefined>(undefined);

  return (
    <div className="ComponentLibrary flex flex-col gap-8">
      <h1 className="text-xl font-raleway font-bold">Component Library</h1>

      <div>
        <h2>Input fields</h2>
        <div>
          <div className="block">
            <h3>
              <span>Text fields</span>
              <pre>TextField (MUI styled)</pre>
            </h3>
            <TextField label={'Label'} />
            <h3>
              <span>Codeops color picker</span>
              <pre>ColorPicker (Custom)</pre>
            </h3>
            <div className="flex justify-start">
              <ColorPicker
                label="Pick a color"
                modalTitle="Pick a color"
                color={color}
                onColorChanged={setSelectedColor}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2>
          <span>Buttons</span>
        </h2>
        <div className="block">
          <h3>
            <span>Button</span>
            <pre>Button (MUI styled)</pre>
          </h3>
          <div className="flex flex-col gap-2 items-start">
            <div className="flex gap-2">
              <Button variant="contained">Contained</Button>
              <Button variant="outlined">Outlined</Button>
              <Button variant="text">Text</Button>
            </div>
            <Button loading={true} variant="contained" disabled>
              Loading...
            </Button>
          </div>
          <h3>
            <span>ActionButton</span>
            <pre>Button (MUI styled)</pre>
          </h3>
          <div className="flex gap-2">
            <ActionButton color={'blue-200'} icon={'icon-user'}>
              Action 1
            </ActionButton>
            <ActionButton color={'purple-300'} icon={'icon-user'}>
              Action 2
            </ActionButton>
            <ActionButton color={'orange-350'} icon={'icon-user'}>
              Action 3
            </ActionButton>
          </div>
          <h3>
            <span>MenuButton</span>
            <pre>(Custom)</pre>
          </h3>
          <div className="flex">
            <div className="w-full py-2 bg-white">
              <MenuButton
                selectMode={SelectMode.RouteStart}
                route="/component-library"
                icon="icon-home"
                label="A (selected) first menu item"
              />
              <MenuButton
                selectMode={SelectMode.RouteStart}
                route="/"
                icon="icon-home"
                label="A second menu item"
              />
              <MenuButton
                selectMode={SelectMode.RouteStart}
                route="/"
                icon="icon-home"
                label="A third menu item"
              />
              <MenuButton
                selectMode={SelectMode.RouteStart}
                route="/"
                icon="icon-home"
                label="A fourth menu item"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2>
          <span>Presentation</span>
        </h2>
        <div className="block">
          <h3>
            <span>Title</span> <pre>(Custom)</pre>
          </h3>
          <div className="flex flex-col gap-1">
            {Number.range(1, 6).map((level) => (
              <Title key={level} level={level as TitleLevel}>
                Title (level {level})
              </Title>
            ))}
          </div>
          <h3>
            <span>Block</span> <pre>(Custom)</pre>
          </h3>
          <div className="flex flex-col gap-1">
            {contexts.map((context) => (
              <Block key={context} context={context}>
                Context: {context}
              </Block>
            ))}
          </div>
          <h3>
            <span>IconParagraph</span> <pre>(Custom)</pre>
          </h3>
          <div className="flex flex-col gap-1">
            <IconParagraph
              squaredIcon={{
                color: 'purple.300',
                icon: 'icon-server',
                hoverAnimation: true,
              }}
            >
              Création d&apos;environnement en mono ou multiserveur, prêt au
              scaling à la demande.
            </IconParagraph>
            <IconParagraph
              squaredIcon={{
                color: 'blue.200',
                icon: 'icon-cloud',
                hoverAnimation: true,
              }}
            >
              Des déploiements blue-green gage de fiabilité dans un
              environnement cloud native.
            </IconParagraph>
            <IconParagraph
              squaredIcon={{
                color: 'orange.300',
                icon: 'icon-user',
                hoverAnimation: true,
              }}
            >
              Une véritable infogérence réalisée par les équipes d&apos;experts
              système de Codéin.
            </IconParagraph>
            <IconParagraph
              squaredIcon={{
                color: 'green.200',
                icon: 'icon-home',
                hoverAnimation: true,
              }}
            >
              Un hébergement sur le cloud public souverain Outscale de Dassault
              système.
            </IconParagraph>
            <IconParagraph
              squaredIcon={{
                color: 'pink.300',
                icon: 'icon-dollar-sign',
                hoverAnimation: true,
              }}
            >
              Une tarification sans surprises .
            </IconParagraph>
          </div>

          <h3>
            <span>IconShortInfoBlock</span> <pre>(Custom)</pre>
          </h3>
          <div className="flex">
            <div className="flex flex-col gap-1">
              <IconShortInfoBlock
                icon={{
                  icon: 'icon-alert-triangle',
                  color: 'blue-200',
                }}
                label={'Description/Titre de la valeur'}
                valueDescription={'Valeur'}
              />
              <IconShortInfoBlock
                icon={{
                  icon: 'icon-clock',
                  color: 'orange-200',
                }}
                label={"Coût de l'opération"}
                valueDescription={'2€'}
              />
              <IconShortInfoBlock
                icon={{
                  icon: 'icon-home',
                  color: 'deepgreen-200',
                }}
                label={'Description/Titre de la valeur'}
                valueDescription={'Valeur'}
                details={'Ligne de détails optionnelle'}
              />
            </div>
          </div>

          <h3>
            <span>InformationText</span> <pre>(Custom)</pre>
          </h3>
          <div>
            <InformationText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </InformationText>
          </div>

          <h3>
            <span>LoadingScreen</span> <pre>(Custom)</pre>
          </h3>
          <div className="flex gap-2">
            <Button
              variant="contained"
              onClick={() => setLoaderIndeterminateOpened(true)}
            >
              Open indeterminate progress
            </Button>
            <LoadingScreen
              opened={loaderIndeterminateOpened}
              details={"Cliquez n'importe où pour me faire disparaître."}
              onClick={() => setLoaderIndeterminateOpened(false)}
            />
            <Button variant="contained" onClick={simulateLoaderDeterminate}>
              Open determinate progress
            </Button>
            <LoadingScreen
              opened={loaderDeterminateProgress !== null}
              details={
                'Discobambulage du chargement multisouverain.' +
                (loaderDeterminateProgress
                  ? ` (Paquet ${(loaderDeterminateProgress ?? 100) / 10} / 10)`
                  : '')
              }
              progressBar={{
                variant: 'determinate',
                value: loaderDeterminateProgress ?? 100,
              }}
            />
          </div>

          <h3>
            <span>Logs</span> <pre>(Custom)</pre>
          </h3>
          <div>
            <Logs className="h-72" logs={logs} />
          </div>

          <h3>
            <span>DataPaper</span> <pre>(Custom)</pre>{' '}
            <pre>(Works with DataPaperElement)</pre>
          </h3>
          <div className="flex justify-start">
            <div className="min-w-64">
              <DataPaper
                header={<div>Title</div>}
                content={[
                  <DataPaperElement key="0" label="Element index" value="0" />,
                  <DataPaperElement key="1" label="Example" value="elpmaxE" />,
                  <DataPaperElement
                    key="2"
                    label="Fullspan line"
                    value="This is a full span line"
                    fullSpan
                  />,
                  <DataPaperElement
                    key="3"
                    label="Another data label"
                    value="And its value"
                  />,
                ]}
              ></DataPaper>
            </div>
          </div>

          <h3>
            <span>UserGroup</span> <pre>(Custom)</pre>
          </h3>
          <div>
            <UserGroup
              justify="start"
              users={[
                {
                  firstname: 'Soline',
                  lastname: 'Benoit',
                },
                {
                  firstname: 'Meryll',
                  lastname: 'Essig',
                },
                {
                  firstname: 'Alexis',
                  lastname: 'Langlet',
                },
                {
                  firstname: 'Julien',
                  lastname: 'Lesinski',
                },
              ]}
            />
          </div>

          <h3>
            <span>ChipGroup</span> <pre>(Custom)</pre>
          </h3>
          <div>
            <ChipGroup
              chips={[
                {
                  key: 'prod',
                  label: 'prod',
                  props: {
                    context: 'neutral',
                  },
                },
                {
                  key: 'inte',
                  label: 'inte',
                  props: {
                    context: 'neutral',
                  },
                },
                {
                  key: 'dev',
                  label: 'dev',
                  props: {
                    context: 'neutral',
                  },
                },
                {
                  key: 'test',
                  label: 'test',
                  props: {
                    context: 'neutral',
                  },
                },
              ]}
            />
          </div>

          <h3>
            <span>DotBadge</span>
            <pre>(Custom)</pre>
          </h3>
          <div className="flex flex-wrap gap-2 items-start">
            <DotBadge>
              <div className="bg-grey-300 rounded-md px-2 text-grey-800">
                Something that need some attention.
              </div>
            </DotBadge>
            <DotBadge color={Colors.fromTailwind('deepgreen-500')}>
              <div className="bg-grey-300 rounded-md px-2 text-grey-800">
                DotBadge in green.
              </div>
            </DotBadge>
            <DotBadge color={Colors.fromTailwind('orange')}>
              <div className="bg-grey-300 rounded-md px-2 text-grey-800">
                DotBadge in orange.
              </div>
            </DotBadge>
          </div>
        </div>
      </div>

      <div>
        <h2>
          <span>Developer helper</span>
        </h2>
        <div className="block">
          <h3>
            <span>DebugTable</span> <pre>(Custom)</pre>
          </h3>
          <DebugTable
            object={{
              key_number: 1,
              key_boolean: true,
              key_empty_string: '',
              key_string: 'string',
              key_undefined: undefined,
              key_null: null,
              key_object: { bonjour: 'hello', 'au revoir': 'good bye' },
            }}
          />
        </div>
      </div>

      <div>
        <h2>
          <span>Error handling</span>
        </h2>
        <div className="block">
          <h3>
            <span>ErrorBoundary</span>
            <pre>(Custom)</pre>
          </h3>
          <ErrorThrower />
        </div>
      </div>

      <div>
        <h2>
          <span>Icons</span>
        </h2>
        <div className="block">
          <h3>
            <span>Icons</span> <pre>(Just use classes to use them)</pre>
          </h3>
          <IconPicker />
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Block } from '../../../atoms/Block/Block';
import { Title } from '../../../atoms/Title/Title';
import { ActionButton } from '../../../molecules/ActionButton/ActionButton';
import './ActionBlock.css';

export function ActionBlock() {
  const { t } = useTranslation();
  //#region Fetch the action types
  const navigate = useNavigate();

  //#endregion

  return (
    <Block className="ActionBlock flex flex-col gap-6">
      <Title level={2}>{t('actions')}</Title>
      <div className="flex flex-col lg:flex-row gap-2 justify-between items-center">
        <div className="action-buttons">
          <ActionButton
            color={'purple-300'}
            icon={'icon-plus'}
            justifyContent="start"
            onClick={() => navigate('new')}
          >
            {t('create_a_new_project')}
          </ActionButton>
        </div>
      </div>
    </Block>
  );
}

import { ClassUtilities } from '../../../utilities/classUtility';
import { Block } from '../../atoms/Block/Block';
import {
  IconShortInfo,
  IconShortInfoProps,
} from '../IconShortInfo/IconShortInfo';

/** Warning {@link IconShortInfoBlockProps["className"]} refers to class name
 * given to {@link IconShortInfoBlock} and not class name given to {@link IconShortInfo}.*/
export type IconShortInfoBlockProps = IconShortInfoProps;

export function IconShortInfoBlock(props: IconShortInfoBlockProps) {
  const iconShortInfoProps: IconShortInfoProps = Object.assign({}, props, {
    className: undefined,
  });

  return (
    <Block className={ClassUtilities.flatten('IconTextBlock', props.className)}>
      <IconShortInfo {...iconShortInfoProps} />
    </Block>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EnvironmentApi } from '../../../api/environmentApi';
import { useActionSubmitHandler } from '../../../hooks/useActionSubmitHandler';
import { useCheckExistingRunningAction } from '../../../hooks/useCheckExistingRunningAction';
import { useTitle } from '../../../hooks/useTitle';
import { ActionTypeName } from '../../../models/actionTypeName';
import { ScalingPayload as ScalingInterface } from '../../../models/environment';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { unwrap } from '../../../utilities/assertions';
import * as Number from '../../../utilities/numberUtilities';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { InformationText } from '../../atoms/InformationText/InformationText';
import { FormSingleSelect } from '../../atoms/SingleSelect/FormSingleSelect';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { ActionPageTemplate } from '../../templates/ActionPageTemplate/ActionPageTemplate';
import { Schema, getSchema } from './schema';
import { errorToTerm } from './utilities';

import { useEnvironmentActionTypesEstimates } from '../../../hooks/useEnvironmentActionTypesEstimates';
import { EnvironmentActionTypeServerSizingEstimate } from '../../../models/environmentActionType';
import { DateFormatter } from '../../../utilities/dateFormatter';
import { NumberFormatter } from '../../../utilities/numberFormatter';
import { IconTooltip } from '../../atoms/IconTooltip/IconTooltip';
import {
  ActionShortInfoBlocks,
  ActionShortInfoBlocksProps,
} from '../../molecules/ActionShortInfoBlocks/ActionShortInfoBlocks';
import { EstimateErrorBlock } from '../../molecules/EstimateErrorBlock/EstimateErrorBlock';
import './Scaling.css';

export function Scaling() {
  const { t } = useTranslation();

  useTitle(t('scaling_page_title'));

  const [scaling, setScaling] = useState<
    PromiseSnapshot<ScalingInterface['frontNumber']>
  >(new PromiseSnapshot());

  const environmentId = useProjectEnvironmentsStore(
    (state) => state.environments.data?.[state.selectedEnvironmentIndex]?.id
  );

  // Fetch action of this env to know if another scaling is in progress
  const anotherScalingInProgress = useCheckExistingRunningAction({
    actionTypeName: ActionTypeName.ScaleFronts,
    environmentId,
  });

  const scalingEstimate =
    useEnvironmentActionTypesEstimates<EnvironmentActionTypeServerSizingEstimate>(
      environmentId,
      ActionTypeName.ScaleFronts
    );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<Schema>({
    defaultValues: {
      prescalingFrontNumber: '',
      frontNumber: '',
    },
    resolver: zodResolver(getSchema(t)),
  });

  const [environmentsSnapshot, selectedEnvironmentIndex] =
    useProjectEnvironmentsStore((state) => [
      state.environments,
      state.selectedEnvironmentIndex,
    ]);

  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];

  const abortController = useRef(new AbortController());

  // Fetch pre-scaling front number
  useEffect(() => {
    // Here, make call to GET Scaling
    if (selectedEnvironment && selectedEnvironment.id) {
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new EnvironmentApi().getScaling(selectedEnvironment.id, {
            abortController: abortController.current,
          }),
        setScaling
      );
    }

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [selectedEnvironment]);

  // Set pre-scaling front number
  useEffect(() => {
    if (scaling.state === PromiseState.Succeeded) {
      setValue('prescalingFrontNumber', unwrap(scaling.data));
    }
  }, [scaling, selectedEnvironment, setValue]);

  const { loading, submitAndWatchResult, feedbackWidget } =
    useActionSubmitHandler({
      actionName: t('done_action_labels.scaling'),
      environmentName: selectedEnvironment?.name,
      errorToText: (e) => t(errorToTerm(e)),
    });

  const onSubmit = (data: Schema) => {
    submitAndWatchResult(
      () =>
        new EnvironmentApi().postScaling(unwrap(environmentId), {
          frontNumber: data.frontNumber as number,
        }),
      {
        onSuccessPostAction: () =>
          setValue('prescalingFrontNumber', data.frontNumber),
      }
    );
  };

  const blocksStyleProps: ActionShortInfoBlocksProps['stylesProps'] = [
    {
      icon: {
        color: 'yellow-200',
        icon: 'icon-clock',
      },
      label: t('operation_estimated_duration'),
      className: 'grow',
    },
    {
      icon: {
        color: 'deepgreen-200',
        icon: 'icon-server',
      },
      label: t('server_sizing'),
      className: 'grow',
    },
    {
      icon: {
        color: 'orange-200',
        icon: 'icon-bill',
      },
      label: (
        <>
          {t('operation_estimated_cost')}{' '}
          <IconTooltip title={t('scaling_cost_info')}></IconTooltip>
        </>
      ),
      className: 'grow',
    },
  ];

  return (
    <ActionPageTemplate actionTypeName={ActionTypeName.ScaleFronts}>
      <div className="Scaling grid grid-cols-1 lg:grid-cols-aside-md gap-6 justify-items-stretch">
        <Block className="grow shrink flex flex-col gap-8">
          <Title level={2}>{t('scaling_page_title')}</Title>
          <div className="grid Scaling__form-block gap-8">
            <div className="min-w-48 flex flex-col items-stretch gap-4 pb-2">
              <FormSingleSelect
                control={control}
                label={t('current_front_number')}
                name="prescalingFrontNumber"
                disabled
                loading={scaling.state === PromiseState.Running}
                options={Number.range(0, 10).map((num) => ({
                  label: num.toString(),
                  value: num,
                }))}
              />
              <FormSingleSelect
                control={control}
                label={t('desired_front_number')}
                name="frontNumber"
                options={Number.range(2, 4).map((num) => ({
                  label: num.toString(),
                  value: num,
                }))}
              />

              <div className="h-2 md:h-12"></div>
              <RoundedFilledBlueButton
                color="info"
                onClick={handleSubmit(onSubmit)}
                disabled={
                  Object.keys(errors).length > 0 ||
                  loading ||
                  anotherScalingInProgress
                }
              >
                {t('scale')}
              </RoundedFilledBlueButton>
            </div>
            <div className="-order-1 md:order-2 flex flex-col gap-4">
              <InformationText>{t('scaling_info')}</InformationText>
              {anotherScalingInProgress && (
                <WarningBlock>{t('scaling_already_occurring')}</WarningBlock>
              )}
            </div>
          </div>
        </Block>
        <div className="min-w-48 flex flex-col gap-4 shrink grow">
          {scalingEstimate.map({
            succeeded: (data) => (
              <ActionShortInfoBlocks
                stylesProps={blocksStyleProps}
                valueDescriptions={[
                  DateFormatter.toDuration(data.estimatedDuration, t, {
                    maximumParts: 1,
                  }),
                  t('server_sizing_specs', data.serverSizing),
                  t('price_per_month_template', {
                    price: NumberFormatter.toEuro(
                      unwrap(data.estimatedTotalInclTax?.perMonth)
                    ),
                  }),
                ]}
              />
            ),
            failed: () => <EstimateErrorBlock />,
            notStarted: 'running',
            running: () => (
              <ActionShortInfoBlocks stylesProps={blocksStyleProps} />
            ),
          })}
        </div>
      </div>

      {feedbackWidget}
    </ActionPageTemplate>
  );
}

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Bill, BillStatus } from '../../../../../models/bill';
import { unwrapNull } from '../../../../../utilities/assertions';
import { DateFormatter } from '../../../../../utilities/dateFormatter';
import {
  NumberFormatter,
  RoundMethods,
} from '../../../../../utilities/numberFormatter';
import { Block } from '../../../../atoms/Block/Block';
import { Checkbox } from '../../../../atoms/Checkbox/Checkbox';
import { StatusChip } from '../../../../atoms/Chip/StatusChip';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../../../atoms/LoadingBlock/LoadingBlock';
import { TablePagination } from '../../../../atoms/TablePagination/TablePagination';
import { Title } from '../../../../atoms/Title/Title';
import { BillingLinesModal } from '../../../../molecules/BillingLinesModal';
import { BillPatchEventNotifier } from '../../utilities/billPatchEventNotifier';
import { DateCell, GrowTableCell, ShrinkTableCell } from '../TableCells';
import { useBills } from './hooks/useBills';
import { PaymentModal } from './subcomponents/PaymentModal/PaymentModal';
import { PaymentStatus } from './subcomponents/PaymentStatus';

interface BillHistoryBlockProps {
  billPatchEventNotifier: BillPatchEventNotifier;
}

export function BillHistoryBlock(props: BillHistoryBlockProps) {
  const { t } = useTranslation();

  //#region Handle search parameters
  const [searchParams, setSearchParams] = useSearchParams({
    hideEmittedBills: 'true',
  });
  const hidePaidBills = searchParams.get('hidePaidBills') !== 'false';
  const setHideEmittedBills = (val: boolean) =>
    setSearchParams((prev) => {
      prev.set('hidePaidBills', val ? 'true' : 'false');
      return prev;
    });
  //#endregion

  const [openedBillForDetails, setOpenedBillForDetails] = useState<Bill | null>(
    null
  );

  const [openedBillForPayments, setOpenedBillForPayments] =
    useState<Bill | null>(null);

  const {
    billsSnapshot: paginatedBillsSnapshot,
    page,
    setPage,
    patch,
  } = useBills(hidePaidBills);

  const [loadingPaymentBills, setLoadingPaymentBills] = useState<
    Set<Bill['id']>
  >(new Set());

  // React to external bill changes (from the other block for example)
  useEffect(() => {
    const billPatchEventNotifier = props.billPatchEventNotifier;

    billPatchEventNotifier.addListener('billHistoryBlock', (billUpdate) => {
      if (!paginatedBillsSnapshot.isSucceeded()) return;

      const groupedBills = paginatedBillsSnapshot.getSucceededData();

      const billToPatch = groupedBills['hydra:member']
        .flatMap(([, bills]) => bills)
        .find((bill) => bill.id === billUpdate.id);
      if (billToPatch === undefined) return;

      patch({ ...billToPatch, ...billUpdate });
    });

    return () => {
      billPatchEventNotifier.removeListener('billHistoryBlock');
    };
  }, [paginatedBillsSnapshot, patch, props.billPatchEventNotifier]);

  const tExclTax = t('excl_tax');
  const tInclTax = t('incl_tax');
  const tDetails = t('details');
  const tPayments = t('payment', { count: 10 });
  const tPaymentEditionImpossible = t('no_payment_edition_on_new_bill');

  return (
    <Block className="BillHistoryBlock">
      <div className="flex justify-between">
        <Title level={2}>{t('bills_history', { count: 2 })}</Title>
        <Checkbox
          value={hidePaidBills}
          checked={hidePaidBills}
          onChange={(_ev, checked) => setHideEmittedBills(checked)}
          label={t('hide_paid_bills')}
        />
      </div>
      {paginatedBillsSnapshot.map({
        notStarted: 'running',
        running: () => <LoadingBlock />,
        succeeded: (paginatedBillsGrouppedByDate) => (
          <TableContainer component="div">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <GrowTableCell>
                    {t('project')} ({t('company')})
                  </GrowTableCell>
                  <ShrinkTableCell>{t('amount_price')}</ShrinkTableCell>
                  <ShrinkTableCell>{t('payment')}</ShrinkTableCell>
                  <TableCell>{t('actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedBillsGrouppedByDate['hydra:member'].map(
                  ([date, bills]) => (
                    <React.Fragment key={date.getTime()}>
                      <TableRow>
                        <DateCell colSpan={4}>
                          {DateFormatter.toMonth(date)}
                        </DateCell>
                      </TableRow>
                      {bills.map((bill) => {
                        const paymentUpdateLoading = loadingPaymentBills.has(
                          bill.id
                        );
                        return (
                          <React.Fragment key={bill.id}>
                            <TableRow>
                              <GrowTableCell>
                                <div className="flex gap-4 items-center">
                                  <span>
                                    {bill.project.name}{' '}
                                    {bill.project.billingCompany &&
                                      `(${bill.project.billingCompany.name})`}
                                  </span>
                                  {paymentUpdateLoading && (
                                    <StatusChip
                                      context="info"
                                      label={
                                        <div className="flex gap-2 items-center">
                                          <i className="icon-loader animate-spin-slow" />
                                          <span>{t('update_in_progress')}</span>
                                        </div>
                                      }
                                    />
                                  )}
                                </div>
                              </GrowTableCell>
                              <ShrinkTableCell>
                                {NumberFormatter.toEuro(bill.totalExclTax, {
                                  roundMethod: RoundMethods.Ceil,
                                })}
                                &nbsp;{tExclTax} (
                                {NumberFormatter.toEuro(bill.totalInclTax, {
                                  roundMethod: RoundMethods.Ceil,
                                })}
                                &nbsp;{tInclTax})
                              </ShrinkTableCell>
                              <ShrinkTableCell>
                                <div className="flex gap-3 justify-between items-baseline">
                                  <span>
                                    {NumberFormatter.toEuro(bill.totalPaid, {
                                      roundMethod: RoundMethods.Ceil,
                                    })}{' '}
                                    /{' '}
                                    {NumberFormatter.toEuro(bill.totalInclTax, {
                                      roundMethod: RoundMethods.Ceil,
                                    })}{' '}
                                  </span>
                                  <PaymentStatus bill={bill} />
                                </div>
                              </ShrinkTableCell>
                              <TableCell>
                                <div className="flex">
                                  <Tooltip title={tDetails}>
                                    <IconButton
                                      onClick={() =>
                                        setOpenedBillForDetails(bill)
                                      }
                                    >
                                      {<i className="icon-search" />}
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      bill.status !== BillStatus.New
                                        ? tPayments
                                        : tPaymentEditionImpossible
                                    }
                                  >
                                    <span>
                                      <IconButton
                                        onClick={() =>
                                          setOpenedBillForPayments(bill)
                                        }
                                        disabled={
                                          bill.status === BillStatus.New ||
                                          paymentUpdateLoading
                                        }
                                      >
                                        {<i className="icon-dollar-sign" />}
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
            <TablePagination
              onPageChange={setPage}
              page={page}
              count={paginatedBillsGrouppedByDate['hydra:totalItems']}
            />
          </TableContainer>
        ),
        failed: (e) => <ErrorBlock noShadow>{`${e}`}</ErrorBlock>,
      })}
      <BillingLinesModal
        openedBill={openedBillForDetails}
        onCloseBill={() => setOpenedBillForDetails(null)}
      />
      <PaymentModal
        bill={openedBillForPayments}
        onClose={() => setOpenedBillForPayments(null)}
        onBillUpdateStart={() => {
          const newLoadingPaymentBills = new Set(loadingPaymentBills);
          newLoadingPaymentBills.add(unwrapNull(openedBillForPayments).id);
          setLoadingPaymentBills(newLoadingPaymentBills);
        }}
        onBillUpdatedEnd={(bill) => {
          patch(bill);
          const newLoadingPaymentBills = new Set(loadingPaymentBills);
          newLoadingPaymentBills.delete(unwrapNull(openedBillForPayments).id);
          setLoadingPaymentBills(newLoadingPaymentBills);
        }}
      />
    </Block>
  );
}

import { useEffect, useState } from 'react';

/**
 * @returns An {@link AbortController} that is aborted when the hooks unmount. (So when the component will be unmounted.)
 */
export function useUnmountAbortController(): AbortController {
  const [abortController, setAbortController] = useState<AbortController>(
    new AbortController()
  );

  useEffect(() => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    return () => {
      newAbortController.abort();
    };
  }, []);

  return abortController;
}

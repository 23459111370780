import { Project } from '../models/project';
import { User } from '../models/user';
import { UserProjectRole } from '../models/userProjectRole';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';

const { REACT_APP_ROUTE_USER_PROJECT_ROLES } = process.env;

/**
 * Internal data for {@link GetOptionsHandler}.
 */
export interface GetOptions {
  page?: number;
  'targeted_user[]'?: User['id'][];
  'project[]'?: Project['id'][];
}

export type PostOptions = {
  role?: string;
  userId: string;
  projectId: string;
};

/**
 * Get options for the {@link UserProjectRoleApi}.
 */
export class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      'targeted_user[]': ParamMappingHelper.identity,
      'project[]': ParamMappingHelper.identity,
    };
  }
}

/**
 * API for the {@link UserProjectRole}s.
 */
export class UserProjectRoleApi extends BaseApi<
  UserProjectRole,
  GetOptions,
  UserProjectRole,
  PostOptions
> {
  constructor() {
    super(
      unwrap(
        REACT_APP_ROUTE_USER_PROJECT_ROLES,
        'User project roles route not defined.'
      ),
      GetOptionsHandler,
      {
        allowDelete: true,
      }
    );
  }

  override transformGottenPartialEntity(
    entity: UserProjectRole
  ): UserProjectRole {
    return this.transformGottenEntity(entity);
  }

  override transformGottenEntity(entity: UserProjectRole): UserProjectRole {
    entity.project = BaseApi.getIdFromIri(entity.project);
    entity.user = BaseApi.getIdFromIri(entity.user);

    return entity;
  }
}

import _ from 'lodash';
import { useMemo } from 'react';
import { Colors } from '../../../../utilities/colors';
import { Modal } from '../../Modal/Modal';
import { Title } from '../../Title/Title';
import { ColorElement } from './ColorElement';

export interface ColorPickerModalProps {
  color?: string;
  onSelectedColorChanged: (color: string) => void;
  opened: boolean;
  onClose: () => void;
  title: string;
}

export function ColorPickerModal(props: ColorPickerModalProps) {
  const colors = useMemo(
    () =>
      _.mapValues(Colors.getAllColors({ keepDefault: false }), (variations) =>
        _.orderBy(
          Object.entries(variations),
          ([variationName]) => variationName,
          'desc'
        )
      ),
    []
  );

  return (
    <Modal open={props.opened} onClose={props.onClose}>
      <div className="flex flex-col gap-2">
        <Title level={2}>{props.title}</Title>
        <div className="ColorPickerModal flex flex-col gap-2">
          {Object.entries(colors).map(([colorName, variations]) => (
            <div key={colorName} className="flex gap-2">
              {variations.map(([variationName, color]) => {
                const colorFullName = `${colorName}-${variationName}`;
                return (
                  <ColorElement
                    key={variationName}
                    color={color}
                    selected={props.color === colorFullName}
                    onSelected={() =>
                      props.onSelectedColorChanged(colorFullName)
                    }
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

import { devStringify } from '../../utilities/devMessages';
import { Exception } from '../exception';

export type Details =
  | {
      type: 'user_already_there';
      email: string;
    }
  | {
      type: 'user_not_found';
      email: string;
    }
  | {
      type: 'role_not_allowed';
      givenRole: string;
    };

/**
 * Exception for the associated method in the API.
 */
export class ProjectAddUserException extends Exception {
  public readonly details: Details;

  constructor(details: Details) {
    super(devStringify(details));
    this.details = details;
  }
}

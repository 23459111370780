import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAllEnvironmentsFromProject } from '../../../hooks/useAllEnvironmentsFromProject';
import { useTitle } from '../../../hooks/useTitle';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import { DateFormatter, TimeUnit } from '../../../utilities/dateFormatter';
import { StatusChip } from '../../atoms/Chip/StatusChip';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { TablePagination } from '../../atoms/TablePagination/TablePagination';

export function HistoryEnvironment() {
  const { t } = useTranslation();

  useTitle(t('envs_history'));

  const projectId = unwrap(useProjectsStore((state) => state.getProject()?.id));
  const { paginatedEnvironmentsSnapshot, page, setPage } =
    useAllEnvironmentsFromProject(projectId);

  return (
    <div className="HistoryEnvironment">
      {paginatedEnvironmentsSnapshot.map({
        failed: (error) => (
          <ErrorBlock noShadow title={t('error_env_fetch') + t('colon')}>
            {(error as Error | undefined)?.message}
          </ErrorBlock>
        ),
        notStarted: 'running',
        running: () => <LoadingBlock></LoadingBlock>,
        succeeded: (paginatedEnvironments) => (
          <div className="flex flex-col h-full">
            <TableContainer className="grow">
              <Table stickyHeader size="small" className="max-h-full">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('environment')}</TableCell>
                    <TableCell>{t('creation_date')}</TableCell>
                    <TableCell>{t('deletion_date')}</TableCell>
                    <TableCell>{t('activity_duration')}</TableCell>
                    <TableCell>{t('reference')}</TableCell>
                    <TableCell>{t('status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedEnvironments['hydra:member'].map((env) => (
                    <TableRow key={env.id}>
                      <TableCell>{env.name}</TableCell>
                      <TableCell>
                        {DateFormatter.toDateTime(env.createdAt)}
                      </TableCell>
                      <TableCell>
                        {env.deletedAt
                          ? DateFormatter.toDateTime(env.deletedAt)
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {env.deletedAt
                          ? DateFormatter.toDurationFromDates(
                              env.createdAt,
                              env.deletedAt,
                              t,
                              {
                                useAbbrev: false,
                                parts: TimeUnit.All & ~TimeUnit.Second,
                              }
                            )
                          : '-'}
                      </TableCell>
                      <TableCell>{env.gitReference}</TableCell>
                      <TableCell>
                        <StatusChip
                          context={env.deletedAt ? 'error' : 'success'}
                          label={env.deletedAt ? t('deleted') : t('active')}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={page}
              onPageChange={setPage}
              count={paginatedEnvironments['hydra:totalItems']}
            />
          </div>
        ),
      })}
    </div>
  );
}

import { Block } from '../../atoms/Block/Block';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';
import { Title } from '../../atoms/Title/Title';

import profilePictureLaurent from '../../../assets/images/profilepicture_laurent.png';
import profilePictureWilliam from '../../../assets/images/profilepicture_william.png';

import { useTitle } from '../../../hooks/useTitle';
import { useTranslationFormatter } from '../../../hooks/useTranslationFormatter';
import { SupportPersonBlock } from './dependencies/SupportPersonBlock';
import './Support.css';

export function Support() {
  const { t, tFormatted } = useTranslationFormatter();
  useTitle(t('support'));

  return (
    <div className="Support max-h-full overflow-y-auto">
      <MainPageContainer>
        <div className="Support-layout gap-8">
          <Block className="Support-layout__explanations flex flex-col gap-4">
            <div>
              <Title level={3}>
                {t('support_hours_incident_reports_title')}
              </Title>
              <p>
                {t('support_hours_incident_reports_details')}
                <br></br>
                {t('support_hours_incident_reporting_intro')}
              </p>
              <ul className="list-disc pl-6">
                <li className="list-item whitespace-normal">
                  {t('support_hours_incident_reporting_creating_a_ticket')}
                  <a
                    className="underline"
                    href="https://redmine.codein.fr/projects/hosting-sandaya/issues"
                  >
                    https://redmine.codein.fr/projects/hosting-sandaya/issues
                  </a>
                </li>
                <li>{t('support_hours_incident_reporting_sys_contact')}</li>
                <li>
                  {t('support_hours_incident_reporting_call_service')}
                  <a className="font-semibold" href="tel:+0806110351">
                    0 806 11 03 51
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <Title level={3}>{t('support_automatic_procedure_title')}</Title>
              <p>
                {t('support_automatic_procedure_details')} <br></br>
                {t('support_automatic_procedure_intro')}
              </p>
              <ul className="list-disc pl-6">
                <li>{t('support_automatic_procedure_data_compromise')}</li>
                <li>{t('support_automatic_procedure_disabled_probe')}</li>
                <li>
                  {t('support_automatic_procedure_insufficient_disk_space')}
                </li>
              </ul>
              <p className="text-sm pt-4">
                {t('support_automatic_procedure_incomplete_list')}
                <br></br>
                {t('support_automatic_procedure_alert')}
              </p>
            </div>
            <div>
              <Title level={3}>{t('support_on_call_duty_title')}</Title>
              <p>
                {t('support_on_call_duty_details_1_p')}
                <br></br>
                {t('support_on_call_duty_details_2_p')}
                <br></br>
                <span className="font-semibold">
                  {t('support_on_call_duty_details_3_p_delay')}
                </span>
                {t('support_on_call_duty_details_3_p')}
                <a className="font-semibold" href="tel:+0806110351">
                  0 806 11 03 51
                </a>
                .<br></br>
                {t('support_on_call_duty_details_4_p')}
              </p>
            </div>
            <div>
              <Title level={3}>{t('support_intervention_request_title')}</Title>
              <p>
                {tFormatted(
                  'support_intervention_request_details',
                  { url: 'https://redmine.codein.fr' },
                  {
                    url: {
                      encapsulator: (trans) => (
                        <a
                          key="redmine"
                          href="https://redmine.codein.fr"
                          className="underline"
                        >
                          {trans}
                        </a>
                      ),
                    },
                  }
                )}
              </p>
            </div>
          </Block>
          <div className="flex flex-col gap-4">
            <SupportPersonBlock
              title={t('your_system_contact')}
              fullname="William Brundu"
              email="william.brundu@codein.fr"
              phone="09 72 82 26 03"
              imgSrc={profilePictureWilliam}
            ></SupportPersonBlock>
            <SupportPersonBlock
              title={t('your_commercial_contact')}
              fullname="Laurent Esposito"
              email="laurent.esposito@codein.fr"
              phone="07 82 81 43 21"
              imgSrc={profilePictureLaurent}
            ></SupportPersonBlock>
          </div>
        </div>
      </MainPageContainer>
    </div>
  );
}

import { Block } from '../../../atoms/Block/Block';
import { Title } from '../../../atoms/Title/Title';

export function SupportPersonBlock(props: {
  title: string;
  fullname: string;
  email: string;
  phone: string;
  imgSrc: string;
}) {
  return (
    <Block className="SupportPersonBlock flex items-center gap-4">
      <img
        src={props.imgSrc}
        alt={props.fullname}
        className="rounded-full w-12 h-12 shrink-0"
      />
      <div className="flex flex-col">
        <p className="text-smd text-grey-600">{props.title}</p>
        <Title level={3}>{props.fullname}</Title>
        <a href={`mailto:${props.email}`} className="text-sm text-grey-500">
          {props.email}
        </a>
        <a
          href={`tel:${props.phone.replaceAll(' ', '')}`}
          className="text-sm text-grey-500"
        >
          {props.phone}
        </a>
      </div>
    </Block>
  );
}

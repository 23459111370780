import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DefaultProjectIcon,
  getProjectIcon,
} from '../../../businessComponentUtilities/projectHelper';
import { useProjectsStore } from '../../../stores/projectsStore';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Colors } from '../../../utilities/colors';
import { Image } from '../../atoms/Image/Image';

export type ProjectPickerProps = Record<string, never>;

export function ProjectPicker(props: ProjectPickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    projectsSnapshotsMap,
    selectedProject,
    selectedProjectId,
    setSelectedProjectIndex,
    projectNumberSnapshot,
  ] = useProjectsStore((state) => [
    state.projects,
    state.getProject(),
    state.selectedProjectId,
    state.setSelectedProjectId,
    state.projectNumber,
  ]);

  const projectsSnapshots = Array.from(projectsSnapshotsMap.values());
  const atLeastOneProject =
    projectsSnapshots !== undefined && projectsSnapshots.length > 0;
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const menuItems = Array.from(projectsSnapshotsMap.entries()).map(
    ([projectId, projectSnapshot]) => (
      <MenuItem
        key={projectId}
        className="flex gap-2"
        onClick={() => {
          setSelectedProjectIndex(projectId);
          setAnchorEl(null);
        }}
        selected={projectId === selectedProjectId}
      >
        {projectSnapshot.map({
          notStarted: 'running',
          running: () => (
            <>
              <ListItemIcon>
                <div
                  role="presentation"
                  className="w-10 h-10 rounded-md shadow-sm generic-placeholder"
                ></div>
              </ListItemIcon>
              <span
                role="presentation"
                className="generic-placeholder rounded-full text-trans"
              >
                Loading
              </span>
            </>
          ),
          failed: () => <></>,
          succeeded: (project) => (
            <>
              <ListItemIcon>
                <img
                  src={getProjectIcon(project)}
                  alt="Icône du projet"
                  className="w-10 h-10 rounded-md shadow-sm"
                  onError={(ev) => {
                    console.warn(
                      'Project picture could not be loaded, replacing with the default one.'
                    );
                    ev.currentTarget.src = DefaultProjectIcon;
                  }}
                />
              </ListItemIcon>
              <span>{project.name}</span>
            </>
          ),
        })}
      </MenuItem>
    )
  );

  if (
    projectNumberSnapshot.data !== undefined &&
    projectsSnapshots.length < projectNumberSnapshot.data
  ) {
    menuItems.push(<Divider key="divider" />);
    menuItems.push(
      <Link to="/project-list" key="see_more">
        <MenuItem className="flex gap-2 group">
          <ListItemIcon>
            <div className="w-10 flex justify-center">
              <i className="icon-more-horizontal" />
            </div>
          </ListItemIcon>
          <span className="text-grey-500 group-hover:text-inherit">
            {t('show_more')}
          </span>
        </MenuItem>
      </Link>
    );
  }

  return (
    <article
      className={ClassUtilities.flatten(
        'ProjectPicker',
        ClassUtilities.conditional({
          'animate-pulse': selectedProject === undefined,
        })
      )}
    >
      <div className="w-full h-full flex items-center gap-3">
        {selectedProject ? (
          <Image
            src={getProjectIcon(selectedProject)}
            alt="Icône du projet"
            className="w-10 h-10 rounded-md"
            imgClassName="shadow-md"
            fallbackSrc={DefaultProjectIcon}
            onError={() => {
              console.warn(
                'Project picture could not be loaded, replacing with the default one.'
              );
            }}
          />
        ) : (
          <div className="w-10 h-10 rounded-md shadow-md flex justify-center items-center bg-black/10"></div>
        )}
        {atLeastOneProject ? (
          <div className="flex items-baseline gap-2">
            {selectedProject ? (
              <span className="font-semibold text-blue-800">
                {selectedProject.name}
              </span>
            ) : (
              <span className="h-[1em] w-24 bg-black/10 rounded"></span>
            )}
            <IconButton
              onClick={(event) => setAnchorEl(event.currentTarget)}
              sx={{ backgroundColor: Colors.fromTailwind('grey-200') }}
              size="small"
              disabled={
                projectsSnapshots === undefined || projectsSnapshots.length < 2
              }
            >
              <i className="icon-chevron-down text-sm text-grey-500"></i>
            </IconButton>
          </div>
        ) : (
          <div className="h-10 text-grey-500 flex items-center text-smd italic">
            {t('no_existing_project')}
          </div>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems}
      </Menu>
    </article>
  );
}

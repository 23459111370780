import { ClassUtilities } from '../../../utilities/classUtility';
import { BackButton } from '../../molecules/BackButton/BackButton';
import { StyleBaseProps } from '../../shared/props/style';

export interface MainPageContainerProps extends StyleBaseProps {
  backRoute?: string;
}

export function MainPageContainer(props: MainPageContainerProps) {
  const { backRoute: backButtonRoute } = props;

  return (
    <div
      className={ClassUtilities.flatten(
        'MainPageContainer p-2 md:p-8 bg-grey-100 transition-[padding] shrink overflow-y-auto overflow-x-hidden h-full',
        props.className
      )}
    >
      {backButtonRoute && (
        <div className="absolute flex items-center justify-center -translate-x-8 translate-y-6">
          <BackButton className="absolute" route={backButtonRoute} />
        </div>
      )}
      {props.children}
    </div>
  );
}

import { useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Navigate } from 'react-router-dom';

export interface ExternalNavigateProps {
  /** The address to navigate to, this is an external address. Example: `https://www.google.fr` */
  to: string;
}

/**
 * Similar to {@link Navigate} but for external link.
 * @param props See {@link ExternalNavigateProps}.
 */
export function ExternalNavigate(props: ExternalNavigateProps) {
  useEffect(() => {
    window.location.href = props.to;
  }, [props.to]);

  return <></>;
}

import { CSSProperties, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getActionRouting,
  getSpecification,
} from '../../../../../constants/environmentAction';
import { useEnvironmentRoute } from '../../../../../hooks/useEnvironmentRoute';
import { useProjectRoute } from '../../../../../hooks/useProjectRoute';
import {
  ActionType,
  ActionTypeHandler,
} from '../../../../../models/actionType';
import { ActionButton } from '../../../../molecules/ActionButton/ActionButton';

export interface EnvironmentActionButtonProps
  extends Pick<CSSProperties, 'order'> {
  actionType: ActionType;
}

export function EnvironmentActionButton(props: EnvironmentActionButtonProps) {
  const navigate = useNavigate();
  const routeEnd = getActionRouting(props.actionType.name);
  const specification = getSpecification(props.actionType.name);
  const projectRoute = useProjectRoute();
  const environmentRoute = useEnvironmentRoute();

  const route = useMemo(() => {
    if (routeEnd === undefined) return undefined;
    return projectRoute + environmentRoute + routeEnd;
  }, [environmentRoute, projectRoute, routeEnd]);

  const displayInfo = useMemo(() => {
    const actionTypeHandler = new ActionTypeHandler(props.actionType);
    return {
      color: actionTypeHandler.getColorClass(),
      icon: actionTypeHandler.getIconClass(),
      friendlyName: actionTypeHandler.getFriendlyName(),
    };
  }, [props.actionType]);

  return (
    <>
      {route !== undefined && (
        <div
          className="EnvironmentActionButton w-full max-w-full"
          style={{ order: props.order }}
        >
          <ActionButton
            className="max-w-full w-full"
            color={displayInfo.color}
            icon={displayInfo.icon}
            justifyContent="start"
            tooltipText={displayInfo.friendlyName}
            onClick={route ? () => navigate(route) : undefined}
            hasBadge={specification.requireAttention}
          >
            {displayInfo.friendlyName}
          </ActionButton>
        </div>
      )}
    </>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EnvironmentActionTypeApi } from '../../../api/environmentActionTypeApi';
import { useActionAuthorization } from '../../../hooks/useActionAuthorization';
import { useProjectAndEnvironmentRoute } from '../../../hooks/useProjectAndEnvironmentRoute';
import { ActionTypeName } from '../../../models/actionTypeName';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';
import { EnvironmentPicker } from '../../organisms/EnvironmentPicker/EnvironmentPicker';

export interface ActionPageTemplateProps {
  actionTypeName: ActionTypeName;
  /** Disable the back route button. Defaults to `false`. */
  disableBackRoute?: boolean;
  children: React.ReactNode;
}

export function ActionPageTemplate(props: ActionPageTemplateProps) {
  const [authorizedAction, setAuthorizedAction] = useState(
    new PromiseSnapshot<boolean>()
  );
  const canExecuteAction = useActionAuthorization();
  const abortController = useRef(new AbortController());
  const environmentId = useProjectEnvironmentsStore(
    (state) => state.environments.data?.[state.selectedEnvironmentIndex]?.id
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const workplaceRoute = useProjectAndEnvironmentRoute();

  console.debug({ canExecuteAction, environmentId, authorizedAction });

  useEffect(() => {
    if (environmentId === undefined) return;
    if (canExecuteAction === undefined) return;

    if (!canExecuteAction) {
      setAuthorizedAction(PromiseSnapshot.buildSucceeded(false));
    } else {
      PromiseSnapshot.trackPromiseSetter(async () => {
        const actionTypes = await new EnvironmentActionTypeApi().getAll(
          {
            'environment[]': [environmentId],
          },
          { abortController: abortController.current }
        );

        return (
          actionTypes.findIndex(
            (at) => at.actionType.name === props.actionTypeName
          ) !== -1
        );
        //#endregion
      }, setAuthorizedAction);
    }

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [canExecuteAction, environmentId, props.actionTypeName]);

  useEffect(() => {
    if (authorizedAction.data === false) {
      navigate(workplaceRoute, { replace: true });
    }
  }, [authorizedAction.data, navigate, workplaceRoute]);

  return (
    <div className="ActionPageTemplate h-full flex flex-col">
      <EnvironmentPicker disabled />
      <MainPageContainer
        className="grow flex flex-col gap-4"
        backRoute={props.disableBackRoute === true ? undefined : workplaceRoute}
      >
        {authorizedAction.state !== PromiseState.Succeeded ||
        authorizedAction.data === false ? (
          <LoadingBlock text={t('loading_in_progress')} />
        ) : (
          props.children
        )}
      </MainPageContainer>
    </div>
  );
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router-dom';
import {
  DefaultProjectIcon,
  getProjectIcon,
} from '../../../businessComponentUtilities/projectHelper';
import { useCanEditProjectSettings } from '../../../hooks/useCanEditProjectSettings';
import { useCanUseBilling } from '../../../hooks/useCanUseBilling';
import { useEnvironmentRoute } from '../../../hooks/useEnvironmentRoute';
import { useProjectRoute } from '../../../hooks/useProjectRoute';
import { Project, ProjectPartial } from '../../../models/project';
import { rawProjectRoute } from '../../../Routing';
import { Image } from '../../atoms/Image/Image';
import { MenuButtonBase } from '../../atoms/MenuButtonBase/MenuButtonBase';
import { SubMenu } from '../../atoms/SubMenu/SubMenu';
import { SubMenuButton } from '../../atoms/SubMenuButton/SubMenuButton';
import { SelectMode } from '../MenuButton/MenuButton';

export function CurrentProjectMenu(props: CurrentProjectMenuButtonProps) {
  const route = generatePath(rawProjectRoute, { projectId: props.project.id });
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const projectIconSrc = useMemo(() => {
    return getProjectIcon(props.project);
  }, [props.project]);
  const canEditProjectSettings = useCanEditProjectSettings();

  const projectRoute = useProjectRoute();
  const environmentRoute = useEnvironmentRoute();
  const canUseBilling = useCanUseBilling();

  const routes = useMemo(
    () => ({
      workplace: projectRoute + environmentRoute,
      history: projectRoute + '/history',
      billing: projectRoute + '/billing',
      specifications: projectRoute + '/specifications',
      settings: projectRoute + '/settings',
    }),
    [environmentRoute, projectRoute]
  );

  return (
    <div className="CurrentProjectMenu flex md:flex-col">
      <MenuButtonBase
        route={routes.workplace}
        selected={pathname.startsWith(route)}
        icon={
          <Image
            className="w-full h-full rounded-md"
            imgClassName="shadow-sm"
            src={projectIconSrc}
            fallbackSrc={DefaultProjectIcon}
            alt={t('project_icon')}
          />
        }
        label={props.project.name}
      />
      <SubMenu>
        <SubMenuButton
          icon="icon-table"
          selectMode={SelectMode.RouteStart}
          route={routes.workplace}
          label={t('workplace')}
        />
        <SubMenuButton
          selectMode={SelectMode.RouteStart}
          route={routes.history}
          icon="icon-bar-chart-2"
          label={t('history')}
        />
        {canUseBilling && (
          <SubMenuButton
            selectMode={SelectMode.RouteStart}
            route={routes.billing}
            icon="icon-bill"
            label={t('billing')}
          />
        )}
        <SubMenuButton
          selectMode={SelectMode.RouteExact}
          route={routes.specifications}
          icon="icon-cpu"
          label={t('specifications')}
        />
        {canEditProjectSettings && (
          <SubMenuButton
            selectMode={SelectMode.RouteStart}
            route={routes.settings}
            icon="icon-settings"
            label={t('settings')}
          />
        )}
      </SubMenu>
    </div>
  );
}

export interface CurrentProjectMenuButtonProps {
  project: ProjectPartial | Project;
}

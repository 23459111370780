import { WithTranslation } from 'react-i18next';
import { z } from 'zod';
import { PatchArgs, PostArgs } from '../../../api/companyApi';
import { ZodImageEdit } from '../../atoms/ImagePicker/ImagePicker';

const billingContactRegex = /^[\p{L} -]*$/u;

export function getSchema(t: WithTranslation['t']) {
  return z.object({
    img: ZodImageEdit,
    name: z.string().min(1, 'required_field'),
    legalStatus: z.string().nullable(),
    address: z.string().nullable(),
    zipCode: z.string().max(12, t('zip_code_too_long')).nullable(),
    city: z.string().nullable(),
    country: z.string().nullable(),
    billingContactFullName: z
      .string()
      .regex(billingContactRegex, t('firstname_lastname_regex_bad_match'))
      .nullable(),
  });
}

export type Schema = z.infer<ReturnType<typeof getSchema>>;

export function schemaToPostArgs(data: Schema): PostArgs {
  return {
    ...schemaToPatchArgs(data),
    img: data.img ?? undefined,
  };
}

export function schemaToPatchArgs(data: Schema): PatchArgs {
  return {
    name: data.name,
    address: data.address ?? undefined,
    billingContactFullname: data.billingContactFullName ?? undefined,
    city: data.city ?? undefined,
    country: data.country ?? undefined,
    legalStatus: data.legalStatus ?? undefined,
    zipCode: data.zipCode ?? undefined,
  };
}

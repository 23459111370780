export enum ActionTypeName {
  InitEnv = 'init-env',
  ScaleFronts = 'scale-fronts',
  DeployFrontImage = 'deploy-front-image',
  DeleteEnv = 'delete-env',
  BuildFrontImage = 'build-front-image',
  BackupProd = 'backup-prod',
  DeleteNonLiveFront = 'delete-non-live-front',
  UpdateSources = 'update-sources',
  RetryInitEnv = 'retry-init-env',
  RunEnforcingAll = 'run-enforcing-all',
  RunEnforcingFront = 'run-enforcing-front',
  RunTask = 'run-task',
  ListEnvironments = 'list-environments',
  ListFronts = 'list-fronts',
  ListImages = 'list-images',
  ListSnapshots = 'list-snapshots',
  Maintenance = 'maintenance',
  UpdateData = 'update-data',
  RenameEnvironment = 'rename-environment',
  Deploy = 'deploy',
  Rollback = 'rollback',
  PickFront = 'pick-front',
  ToggleVm = 'toggle-vm',
}

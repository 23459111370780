import { UserProjectRoleType } from '../models/userProjectRole';
import { useCurrentUserStore } from '../stores/currentUserStore';
import { useProjectsStore } from '../stores/projectsStore';
import { unwrap } from '../utilities/assertions';
import { PromiseState } from '../utilities/promiseSnapshot';

/**
 * @returns The user project roles if the user and the project contexts can be retrieved (`undefined` otherwise).
 */
export function useProjectRoles(): UserProjectRoleType[] | undefined {
  const userSnapshot = useCurrentUserStore((state) => state.user);
  const project = useProjectsStore((state) => state.getProject());
  if (userSnapshot.state !== PromiseState.Succeeded || project === undefined)
    return undefined;
  const roles = unwrap(userSnapshot.data)
    .projectRoles.filter((role) => role.project === project.id)
    .map((role) => role.role);
  return roles;
}

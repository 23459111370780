// From: https://www.economie.gouv.fr/entreprises/entreprise-choisir-statut-juridique#
/**
 * List of known company status (only French type for now)
 */
export const knownCompanyLegalStatus = [
  'SAS', // Société par actions simplifiée (SAS)
  'SARL', // Société à responsabilité limitée
  'SA', // Société anonyme
  'SNC', // Société en nom collectif
  'SASU', // Société par actions simplifiée unipersonnelle
  'EI', // Entreprise individuelle
  'EURL', // Entreprise unipersonnelle à responsabilité limitée
];

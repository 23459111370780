import { TFunction } from 'i18next';
import { NumberFormatter } from '../../../../../utilities/numberFormatter';
import { PromiseSnapshot } from '../../../../../utilities/promiseSnapshot';
import { IconParagraph } from '../../../../molecules/IconParagraph/IconParagraph';

/**
 * @param value If the value is undefined, it is supposed to be not available.
 * @param t The translation function.
 * @returns The value wrapped into the operating icon paragraph.
 */
export function getOperatingIconParagraph(
  value: PromiseSnapshot<number | null>,
  t: TFunction<'translation', undefined>
): JSX.Element {
  return (
    <IconParagraph
      squaredIcon={{
        icon: 'icon-sun',
        color: 'orange-300',
      }}
    >
      <div className="flex flex-col">
        <span className="text-grey-700">{t('operating')}</span>
        {getFormattedValue(value, t)}
      </div>
    </IconParagraph>
  );
}

/**
 * @param value If the value is undefined, it is supposed to be not available.
 * @param t The translation function.
 * @returns The value wrapped into the standby icon paragraph.
 */
export function getStandbyIconParagraph(
  value: PromiseSnapshot<number | null>,
  t: TFunction<'translation', undefined>
): JSX.Element {
  return (
    <IconParagraph
      squaredIcon={{
        icon: 'icon-moon',
        color: 'blue-200',
      }}
    >
      <div className="flex flex-col">
        <span className="text-grey-700">{t('standby')}</span>
        {getFormattedValue(value, t)}
      </div>
    </IconParagraph>
  );
}

function getFormattedValue(
  snapshotValue: PromiseSnapshot<number | null>,
  t: TFunction<'translation', undefined>
): JSX.Element {
  return snapshotValue.map({
    succeeded: (value) =>
      value === null ? (
        <span className="text-grey-300 font-semibold">{t('pending')}</span>
      ) : (
        <span className="text-lg font-bold text-grey-800 leading-5">
          {NumberFormatter.toEuro(value)}
        </span>
      ),
    notStarted: 'running',
    running: () => (
      <span
        className="generic-placeholder text-lg font-bold items-start rounded-full text-transparent leading-5 select-none"
        role="presentation"
      >
        00.00€
      </span>
    ),
    failed: () => (
      <span className="text-pink-500 font-semibold">{t('error')}</span>
    ),
  });
}

import React from 'react';
import { Colors } from '../../../utilities/colors';
import './SquaredIcon.css';

export type SquaredIconProps = {
  /**
   * The path to the icon to display.
   */
  icon: string;
  /**
   * A tailwind color to apply
   */
  color: string;
  /**
   * Should the icon be displayed in a small way?
   * Default to `false`.
   */
  small?: boolean;
  /**
   * Should the icon be animated when the user hovers it?
   * Default to `false`.
   */
  hoverAnimation?: boolean;
  /**
   * Additional icon classes
   */
  iconClassName?: string;
};

/**
 * Represents a icon into a square.
 * The icon color will be computed from `props.color`.
 */
export function SquaredIcon(props: SquaredIconProps) {
  function getIconClasses(): string {
    const classes = [props.icon];
    classes.push(
      'flex',
      'justify-center',
      'items-center',
      'transition-transform'
    );
    if (props.iconClassName) {
      classes.push(props.iconClassName);
    }
    if (props.small) {
      classes.push('w-4', 'h-4', 'text-[0.875rem]');
      if (props.hoverAnimation) {
        classes.push('group-hover:scale-110');
      }
    } else {
      classes.push('w-8', 'h-8');
      if (props.hoverAnimation) {
        classes.push('group-hover:scale-125');
      }
    }
    return classes.join(' ');
  }

  function getIconStyle(): React.CSSProperties {
    return {
      color: Colors.fromTailwind(props.color) ?? Colors.debugWrong(),
    };
  }

  return (
    <div
      className={`SquaredIcon group rounded-md p-1 transition-[background-color] duration-500`}
      style={{
        backgroundColor:
          Colors.fromTailwind(props.color) ?? Colors.debugWrong(),
      }}
    >
      <i className={getIconClasses()} style={getIconStyle()}></i>
    </div>
  );
}

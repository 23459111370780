import { useEffect, useRef, useState } from 'react';
import { ApiPlatformList } from '../utilities/baseApi';
import { debounce } from '../utilities/debounce';
import { PromiseSnapshot } from '../utilities/promiseSnapshot';

export interface UseLazyAutocompleteSearchProps<T> {
  /** The function to fetch the elements,
   *
   * @remarks The number of requested items per page must never changed.
   *
   * @example
   * useLazyAutocompleteSearch<Flower>({
   *   fetchFunction: (input, itemsPerPage, abortController) => new FlowerApi().getAllPaginated({
   *     name: input
   *     itemsPerPage
   *   }, {
   *     abortController
   *   })
   *   // ...
   * });
   **/
  fetchFunction: (
    input: string,
    itemsPerPage: number,
    abortController: AbortController
  ) => Promise<ApiPlatformList<T>>;
  /**
   * The function to extract from a fetch result, the total string matching the research.
   * For example if you searched for "cam" in a flower database, the function on matching results to extract values like "Camassia", "Camellia" or "Campanula".
   *
   * @example
   * useLazyAutocompleteSearch<Flower>({
   *   // ...
   *   extractAutocompleteValueFunction: (flower) => flower.name.toLowerCase()
   * });
   */
  extractAutocompleteValueFunction: (singleFetchResult: T) => string | string[];
  /** The user autocomplete input. */
  input: string;
  itemsPerPage: number;
}

/** Performs an efficient autocomplete research, assumed results are paginated. */
export function useLazyAutocompleteSearch<T>(
  props: UseLazyAutocompleteSearchProps<T>
): T[] {
  const [snapshot, setSnapshot] = useState<PromiseSnapshot<ApiPlatformList<T>>>(
    new PromiseSnapshot()
  );
  const abortControllerRef = useRef(new AbortController());
  const fetchFunctionStringified = useRef<string>('');
  const {
    fetchFunction,
    extractAutocompleteValueFunction,
    input,
    itemsPerPage,
  } = props;
  const fetchedInput = useRef<string | undefined>(undefined);

  // Refetch when necessary
  useEffect(() => {
    if (!needsRefetch(snapshot, input, fetchedInput.current, itemsPerPage))
      return;

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    const abortController = abortControllerRef.current;

    fetchedInput.current = input;
    PromiseSnapshot.trackPromiseSetter(
      () =>
        debounce<ApiPlatformList<T>>(
          () =>
            fetchFunction(input, itemsPerPage, abortController).then((res) => {
              fetchedInput.current = input;
              return res;
            }),
          400,
          abortController
        ),
      setSnapshot,
      {
        abortController,
      }
    );
  }, [fetchFunction, input, itemsPerPage, snapshot]);

  // Refetch when the function changes
  useEffect(() => {
    const newFetchFunctionStringified = fetchFunction.toString();
    if (newFetchFunctionStringified !== fetchFunctionStringified.current) {
      // Reset fetched input and snapshot
      fetchedInput.current = undefined;
      setSnapshot(new PromiseSnapshot());

      // Register new function body
      fetchFunctionStringified.current = newFetchFunctionStringified;
    }
  }, [fetchFunction]);

  // Abort when leaving
  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  return (
    snapshot.data?.['hydra:member'].filter((el) =>
      elementMatchesInput(el, input, extractAutocompleteValueFunction)
    ) ?? []
  );
}

function elementMatchesInput<T>(
  el: T,
  input: string,
  extractAutocompleteValueFunction: UseLazyAutocompleteSearchProps<T>['extractAutocompleteValueFunction']
): boolean {
  const extracted = extractAutocompleteValueFunction(el);
  const extractedTerms = Array.isArray(extracted) ? extracted : [extracted];
  return extractedTerms.some((term) => term.startsWith(input));
}

/**
 * The condition that does not need a refetch is when all results for a specific input has already been fetched and the input is just narrowing the condition.
 * @returns `true` if the conditions requires a new refetch.
 */
function needsRefetch<T>(
  snapshot: PromiseSnapshot<ApiPlatformList<T>>,
  input: string,
  fetchedInput: string | undefined,
  itemsPerPage: number
): boolean {
  if (snapshot.isRunning()) return false;
  if (
    fetchedInput === undefined ||
    !input.startsWith(fetchedInput) ||
    !snapshot.isSucceeded()
  )
    return true;

  const data = snapshot.getSucceededData();
  return data['hydra:totalItems'] > itemsPerPage;
}

import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
  Key,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ClassUtilities } from '../../../../utilities/classUtility';
import { Title } from '../../Title/Title';
// Used for documentation of TransferList
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TransferLists } from '../TransferLists';

import { unwrap } from '../../../../utilities/assertions';
import { autoAnimationPlugin } from '../autoAnimationPlugin';

export interface TransferListProps<T> {
  /** The title of the list. Will be displayed above it. */
  title: string;
  /** The data this list contains. */
  data: T[];
  /**
   * How to render a single element of the list.
   */
  renderElement?: (dataElement: T) => ReactNode;
  /**
   * How to extract a unique key from a single element of the list.
   */
  elementToKey: (dataElement: T) => Key;
  /**
   * Callback triggered when an element is sent to another compatible list.
   * @param index The index of the element in this list.
   */
  onElementSent: (index: number) => void;
  /**
   * Callback triggered when an element is received from another compatible list.
   * @param oldIndex The index from the source list (the other one).
   * @param newIndex The insertion index to the target list (this one).
   */
  onElementReceived: (oldIndex: number, newIndex: number) => void;
  /**
   * Callback triggered when an element is reordered in this list.
   * @param oldIndex The index the element was.
   * @param newIndex The index the element is.
   */
  onElementReordered: (oldIndex: number, newIndex: number) => void;
  /**
   * A string identifying the handler of this list. Only lists with the same
   * handler can exchange elements.
   */
  handlerId: string;
  /**
   * Is the list disabled? If `true`, all interactions will be disabled.
   */
  disabled?: boolean;
}

/**
 * The data a drag-and-drop between two {@link TransferList} uses.
 */
interface DragAndDropData {
  /** A {@link string} identifying the list where the data comes from (the source list). */
  listId: string;
  /** The identifier of the handler of this list. A handler manages two list at once. */
  handlerId: string;
  /** A {@link Key} identifying the data that is being dragged. */
  dataKey: Key;
  /** The index of the data being dragged in the source list. */
  dataIndex: number;
  /** The height of the dragged data, for UI purpose. */
  height: number;
}

/**
 * Statically stores the data of a drag-and-drop. This is for convenience.
 */
class DragAndDrop {
  static data?: DragAndDropData;
}

/**
 *
 * @param parent The element assumed to be the parent.
 * @param child The element assumed to be the child of {@link parent}.
 * @returns `true` if {@link parent} is an ancestor of {@link child}, `false` otherwise.
 */
function isDescendant(parent: HTMLElement, child: HTMLElement) {
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }

    // Traverse up to the parent
    node = node.parentNode;
  }

  // Go up until the root but couldn't find the `parent`
  return false;
}

/**
 * A single list that needs to be part of {@link TransferLists} component.
 * @param props See {@link TransferListProps}
 */
export function TransferList<T>(props: TransferListProps<T>) {
  const renderElement = useMemo(
    () => props.renderElement ?? ((el: T) => `${el}`),
    [props.renderElement]
  );
  const id = useId();
  const [draggingKey, setDraggingKey] = useState<Key | undefined>();
  const [draggingOver, setDraggingOver] = useState(false);
  const [insertionIndex, setInsertionIndex] = useState(-1);
  const children = useRef<NodeListOf<Element> | undefined>(undefined);
  const [childHeight, setChildHeight] = useState(0);
  const [container] = useAutoAnimate(autoAnimationPlugin);

  // If elements change and dragging key is not in the child anymore remove the reference.
  useEffect(() => {
    if (draggingKey === undefined) return;

    for (const el of props.data) {
      if (props.elementToKey(el) === draggingKey) {
        return;
      }
    }

    setDraggingKey(undefined);
  }, [draggingKey, props]);

  const generateList: () => JSX.Element[] = useCallback(() => {
    const widgets = props.data.map((element, index) => {
      const key = props.elementToKey(element);
      return (
        <div
          key={key}
          draggable={!props.disabled}
          onDragStart={(ev) => {
            const data: DragAndDropData = {
              listId: id,
              handlerId: props.handlerId,
              dataKey: key,
              dataIndex: index,
              height: ev.currentTarget.getBoundingClientRect().height,
            };

            DragAndDrop.data = data;
            ev.dataTransfer.dropEffect = 'move';
            setDraggingKey(key);
          }}
          onDragEnd={() => {
            // Only triggered is the element is dragged and stay in the container so
            // when reordering only, the useEffect take care of cleaning the draggingKey
            // if the element disappear from the list.
            DragAndDrop.data = undefined;
            setDraggingKey(undefined);
          }}
          className={ClassUtilities.flatten(
            'TransferList__element border border-grey-200 bg-white rounded-md transition-colors cursor-pointer',
            ClassUtilities.conditional({
              'bg-white': draggingKey !== key,
              'bg-orange-200': draggingKey === key,
              'hover:bg-orange-200': !draggingOver,
            })
          )}
          onClick={() => props.onElementSent(index)}
        >
          {renderElement(element)}
        </div>
      );
    });
    if (insertionIndex !== -1) {
      widgets.splice(
        insertionIndex,
        0,
        <div
          key={`${id}-placeholder`}
          className="TransferList__placeholder bg-white/75 rounded-md shadow-inner"
          style={{ height: childHeight, minHeight: childHeight }}
        ></div>
      );
    }
    return widgets;
  }, [
    childHeight,
    draggingKey,
    draggingOver,
    id,
    insertionIndex,
    props,
    renderElement,
  ]);

  const isDragValid = useCallback(
    (data: DragAndDropData | undefined) => {
      return data?.handlerId === props.handlerId;
    },
    [props.handlerId]
  );

  return (
    <div
      className="TransferList flex flex-col items-stretch select-none"
      id={id}
    >
      <Title level={3}>{props.title}</Title>
      <div
        ref={container as Ref<HTMLDivElement>}
        className={ClassUtilities.flatten(
          'h-52 max-h-52 shadow-inner rounded p-2 flex flex-col gap-1 transition-colors self-stretch overflow-y-auto',
          ClassUtilities.conditional({
            'bg-orange-150': draggingOver,
          })
        )}
        onDragEnter={(ev) => {
          if (draggingOver) {
            ev.preventDefault();
            ev.stopPropagation();
          }

          const data = DragAndDrop.data;
          if (isDragValid(data)) {
            children.current = ev.currentTarget.querySelectorAll(
              `.TransferList__element`
            );
            setChildHeight(unwrap(data).height);
            setDraggingOver(true);
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
        onDragOver={(ev) => {
          if (!draggingOver) return;
          ev.preventDefault();

          if (children.current === undefined) return;
          let index = 0;
          for (const child of children.current) {
            const { bottom, height } = child.getBoundingClientRect();
            if (ev.clientY < bottom - height / 2) break;
            ++index;
          }

          // If same list and order changes nothing
          const data = unwrap(DragAndDrop.data);
          if (
            data.listId === id &&
            (index === data.dataIndex + 1 || index === data.dataIndex)
          ) {
            index = -1;
          }

          setInsertionIndex(index);
        }}
        onDragLeave={(ev) => {
          if (
            ev.relatedTarget instanceof HTMLElement &&
            ev.currentTarget !== ev.relatedTarget &&
            !isDescendant(ev.currentTarget, ev.relatedTarget)
          ) {
            ev.preventDefault();
            ev.stopPropagation();
            setDraggingOver(false);
            setInsertionIndex(-1);
          }
        }}
        onDrop={(ev) => {
          const data = DragAndDrop.data;
          if (data === undefined || !isDragValid(data)) {
            return;
          }
          ev.preventDefault();

          if (data.listId === id) {
            if (insertionIndex !== -1) {
              props.onElementReordered(
                data.dataIndex,
                insertionIndex > data.dataIndex
                  ? insertionIndex - 1
                  : insertionIndex
              );
            }
          } else {
            props.onElementReceived(data.dataIndex, insertionIndex);
          }

          setDraggingOver(false);
          setInsertionIndex(-1);
          DragAndDrop.data = undefined;
        }}
      >
        {generateList()}
      </div>
    </div>
  );
}

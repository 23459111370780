import dayjs from 'dayjs';

/**
 * Ready-to-use functions for mapping values to string.
 */
export class ParamMappingHelper {
  static mapNumber(val: number | undefined): string | undefined {
    return val?.toString();
  }

  static mapDate(val: Date | undefined): string | undefined {
    return val?.toISOString();
  }

  static mapOptionalDate(val: Date | null | undefined): string | undefined {
    if (val === null) {
      return '';
    }
    return ParamMappingHelper.mapDate(val);
  }

  /**
   * @param val The date.
   * @returns The date formatted stripping the information that is more precised than the day.
   */
  static mapDay(val: Date | null | undefined): string | undefined {
    if (val === undefined) return undefined;
    if (val === null) return '';
    return dayjs(val).format('YYYY-MM-DD');
  }

  static mapArray<T>(
    val: T[] | undefined,
    elementMapper: (el: T) => string
  ): string[] | undefined {
    return val?.map(elementMapper);
  }

  static identity<T>(val: T): T {
    return val;
  }
}

import { useContext } from 'react';
import { LogContext } from '../LogProvider';

export interface SingleLogWidgetProps {
  line: number;
  log: string;
}

/**
 * @param log The log line to display.
 * @param collapsibleLine Should this be represented as a collapsible line? (First log of a region).
 * @param initialCollapsed Is the region initially collapsed.
 * @returns A widget displaying a log region.
 */
export function SingleLogWidget(props: SingleLogWidgetProps) {
  const { maxLineCharNumber } = useContext(LogContext);

  return (
    <div className="LogWidget relative flex justify-start gap-4">
      <span
        className="shrink-0 font-mono text-white text-right select-none"
        style={{ minWidth: `${maxLineCharNumber + 1}ch` }}
      >
        {props.line}
      </span>
      <pre
        className="relative overflow-x-hidden text-ellipsis pr-2 text-white"
        title={props.log}
      >
        {props.log}
      </pre>
    </div>
  );
}

import { styled, Tab, Tabs } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';

export interface AdminTabPickerProps {
  /** Disables the possibility to switch environment. Default: `false`. */
  disabled?: boolean;
}

enum TabName {
  Projects = 'projects',
  Users = 'users',
  Companies = 'companies',
  Bills = 'bills',
}

export function AdminTabPicker(props: AdminTabPickerProps) {
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedIndex] = useState<number | string>(
    'projects'
  );
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (pathname.includes('/admin/projects')) {
      setSelectedIndex(TabName.Projects);
    } else if (pathname.includes('/admin/users')) {
      setSelectedIndex(TabName.Users);
    } else if (pathname.includes('/admin/companies')) {
      setSelectedIndex(TabName.Companies);
    } else if (pathname.includes('/admin/bills')) {
      setSelectedIndex(TabName.Bills);
    }
  }, [pathname]);

  return (
    <section className="bg-white flex pr-8 justify-start md:justify-between gap-4 pb-2 md:pb-0">
      <div className="flex">
        <Tabs
          value={selectedTabIndex}
          scrollButtons={true}
          allowScrollButtonsMobile
          variant="scrollable"
          sx={{ minWidth: '10px', flexShrink: 1 }}
        >
          <StyledTab
            key={0}
            value={TabName.Projects}
            label={<Link to="/admin/projects">{t('projects')}</Link>}
          />
          <StyledTab
            key={1}
            value={TabName.Users}
            label={<Link to="/admin/users">{t('users')}</Link>}
          />
          <StyledTab
            key={2}
            value={TabName.Companies}
            label={<Link to="/admin/companies">{t('company_other')}</Link>}
          />
          <StyledTab
            key={3}
            value={TabName.Bills}
            label={<Link to="/admin/bills">{t('bill', { count: 2 })}</Link>}
          />
        </Tabs>
      </div>
    </section>
  );
}

export function Link(props: Omit<RouterLinkProps, 'className'>) {
  return (
    <RouterLink
      className="h-full w-full px-4 flex items-center justify-center"
      {...props}
    />
  );
}

const StyledTab = styled(Tab)({
  textTransform: 'none',
  padding: 0,
});

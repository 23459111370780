import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../../api/userApi';
import { useTitle } from '../../../hooks/useTitle';
import { User } from '../../../models/user';
import { ApiPlatformList } from '../../../utilities/baseApi';
import { MockData } from '../../../utilities/mockData';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { Button } from '../../atoms/Button/Button';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { TablePagination } from '../../atoms/TablePagination/TablePagination';
import { TextField } from '../../atoms/TextField/TextField';
import { Title } from '../../atoms/Title/Title';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';

type UserFilters = {
  userIdentifier?: string;
  fullName?: string;
  companyName?: string;
};

export function AdminUsers() {
  const [users, setUsers] = useState<PromiseSnapshot<ApiPlatformList<User>>>(
    new PromiseSnapshot()
  );
  const [page, setPage] = useState<number>(1);
  const count = useMemo(() => users.data?.['hydra:totalItems'] ?? 0, [users]);

  const abortController = useRef(new AbortController());
  const userIdentifierFieldRef = useRef<HTMLInputElement>(null);
  const fullNameFieldRef = useRef<HTMLInputElement>(null);
  const companyNameFieldRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useTitle(t('administration_projects'));

  const fetch = useCallback(function (page = 1, filters: UserFilters = {}) {
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new UserApi().getAllPaginated(
          {
            page,
            id: filters.userIdentifier || undefined,
            'company.name': filters.companyName || undefined,
            full_name: filters.fullName || undefined,
          },
          { abortController: abortController.current }
        ),
      setUsers
    );
  }, []);

  useEffect(() => {
    fetch();

    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [fetch]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    fetch(newPage);
  };

  const handleChangeFilters = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    filters: UserFilters
  ) => {
    setPage(newPage);
    fetch(newPage, filters);
  };

  return (
    <AdminPageTemplate>
      <div>
        <Block>
          <Title level={2}>{t('users_list')}</Title>
          <div className="h-6"></div>
          <form>
            <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4">
              <TextField
                label={t('user_identifier')}
                inputRef={userIdentifierFieldRef}
                placeholder={MockData.uuidV4}
              />
              <TextField label={t('fullname')} inputRef={fullNameFieldRef} />
              <TextField
                label={t('company_one')}
                inputRef={companyNameFieldRef}
              />
              <Button
                variant="contained"
                onClick={() => {
                  handleChangeFilters(null, 1, {
                    userIdentifier: userIdentifierFieldRef.current?.value,
                    fullName: fullNameFieldRef.current?.value,
                    companyName: companyNameFieldRef.current?.value,
                  });
                }}
                startIcon={<i className="icon-search"></i>}
              >
                {t('search')}
              </Button>
            </div>
            <div className="h-3"></div>
          </form>
          <div className="h-6"></div>
          {users.state === PromiseState.Succeeded && (
            <>
              <TableContainer className="hidden lg:block" component={'div'}>
                <Table sx={{ minWidth: 650 }} aria-label="users table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('user_identifier')}</TableCell>
                      <TableCell>{t('fullname')}</TableCell>
                      <TableCell>{t('company_one')}</TableCell>
                      <TableCell>{t('sso_identifier')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users.data &&
                      users?.data['hydra:member'].map((user: User) => {
                        return (
                          <TableRow key={user.id}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{`${user.firstname} ${user.lastname}`}</TableCell>
                            <TableCell>
                              {user.company ? user.company.name : ''}
                            </TableCell>
                            <TableCell>{user.ssoIdentifier}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex flex-col gap-4 px-1 lg:hidden">
                {users &&
                  users.data &&
                  users.data['hydra:member'].map((user: User) => (
                    <DataPaper
                      key={user.id}
                      header={user.id}
                      content={[
                        <DataPaperElement
                          key="fullname"
                          label={t('fullname')}
                          value={`${user.firstname} ${user.lastname}`}
                        />,
                        <DataPaperElement
                          key="company_one"
                          label={t('company_one')}
                          value={user.company ? user.company.name : ''}
                        />,
                        <DataPaperElement
                          key="sso_identifier"
                          label={t('sso_identifier')}
                          value={user.ssoIdentifier}
                        />,
                      ]}
                    ></DataPaper>
                  ))}
              </div>
              <TablePagination
                count={count}
                page={page}
                onPageChange={handleChangePage}
              />
            </>
          )}
          {users.state === PromiseState.Running && (
            <LoadingBlock
              icon={<i className="icon-search animate-search" />}
              text={t('search_in_progress')}
            />
          )}
          {users.state === PromiseState.Failed && (
            <Alert severity="error">
              {t('users_list_could_not_be_loaded')}
            </Alert>
          )}
        </Block>
      </div>
    </AdminPageTemplate>
  );
}

import { FormHelperText } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UniqueId } from '../../../../../../../../../utilities/uniqueIdGenerator';
import { RoundedFilledLightGrayButton } from '../../../../../../../../atoms/Button/variations/RoundedButtonVariations';
import { DiscreteTextLine } from '../../../../../../../../atoms/DiscreteTextLine/DiscreteTextLine';
import {
  IdentifiedPaymentLineInfo,
  isGenerated,
} from '../../types/identifiedPaymentLineInfo';
import { PaymentLineInfo } from '../../types/paymentLineInfo';
import { PaymentLine } from './PaymentLine';

export interface PaymentLinesProps {
  value: IdentifiedPaymentLineInfo[];
  onChange?: (value: PaymentLinesProps['value']) => void;
  error?: boolean;
  nestedErrors?: Partial<Record<keyof PaymentLineInfo, FieldError>>[];
  helperText?: string;
}

export function PaymentLines(props: PaymentLinesProps) {
  const { t } = useTranslation();
  const onChange = (index: number, value: IdentifiedPaymentLineInfo | null) => {
    const editedArray = Array.from(props.value);
    if (value === null) {
      editedArray.splice(index, 1);
    } else {
      editedArray[index] = { ...editedArray[index], ...value };
    }

    props.onChange?.(editedArray);
  };

  const addPayment = () => {
    props.onChange?.(
      props.value.concat([{ generatedId: UniqueId.generate() }])
    );
  };

  return (
    <div className="PaymentLines">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          {props.value.length > 0 ? (
            props.value.map((paymentLine, index) => {
              const error = props.nestedErrors?.[index];

              return (
                <PaymentLine
                  key={
                    isGenerated(paymentLine)
                      ? paymentLine.generatedId
                      : paymentLine.id
                  }
                  value={paymentLine}
                  onChange={(paymentInfo) =>
                    onChange(
                      index,
                      paymentInfo === null
                        ? null
                        : { ...paymentLine, ...paymentInfo }
                    )
                  }
                  amountError={Boolean(error?.amount)}
                  amountHelperText={error?.amount?.message}
                  paidAtError={Boolean(error?.paidAt)}
                  paidAtHelperText={error?.paidAt?.message}
                />
              );
            })
          ) : (
            <DiscreteTextLine>{t('no_payment')}</DiscreteTextLine>
          )}
        </div>
        <RoundedFilledLightGrayButton
          className="self-start"
          startIcon={<i className="icon-plus" />}
          onClick={addPayment}
        >
          {t('add')}
        </RoundedFilledLightGrayButton>
      </div>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </div>
  );
}

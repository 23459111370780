import { ActionHistory } from './actionHistory';
import { Entity } from './entity';
import { EnvironmentStatus } from './environmentStatus';
import { GitReferenceType } from './gitReferenceType';
import { Project } from './project';

export interface Environment extends Entity {
  name: string;
  identifier: string;
  createdAt: Date; // Input example: "2022-04-22T18:25:43.511Z"
  lastDeployDate?: Date; // Input example: "2022-04-22T18:25:43.511Z"
  deletedAt?: Date;
  label: string; // ex: inte-develop
  gitReference: string;
  isMonoVM: boolean;
  isScalable: boolean;
  template: string | null;
  websiteURL: string;
  monitoringURL: string;
  logsURL: string;
  dataUpdate: {
    done: number;
    allowed: number;
  };
  scalable: boolean;
  frontNumber?: number;
  isInMaintenance: boolean;
  liveFront: FrontColor;

  // Linked entities
  projectId?: Project['id'];
  actionHistories: ActionHistory['id'][];
  status: EnvironmentStatus;
}

export type PartialEnvironment = Pick<
  Environment,
  | 'identifier'
  | 'isMonoVM'
  | 'gitReference'
  | 'id'
  | 'name'
  | 'deletedAt'
  | 'createdAt'
  | 'status'
>;

export enum FrontColor {
  Blue = 'blue',
  Green = 'green',
}

export type ScalingPayload = {
  frontNumber: number;
};

export type SourceUpdatePayload = {
  gitReferenceType: GitReferenceType;
  gitReference: string;
};

export type DeployPayload = {
  gitReferenceType: GitReferenceType;
  gitReference: string;
  switchTraffic: boolean;
};

export type RollbackPayload = {
  /** The image id of the target image for the rollback. */
  imageId: string;
  /** Should the maintenance be enabled before proceeding to the rollback? */
  activateMaintenance: boolean;
};

export enum PickFrontVersion {
  Live = 'live',
  NonLive = 'nonLive',
}
export type PickFrontPayload = {
  version: PickFrontVersion;
};

export enum ToggleVirtualMachineType {
  Start = 'start',
  Stop = 'stop',
}

export type ToggleVirtualMachinePayload = {
  toggle: ToggleVirtualMachineType;
};

/**
 * Helper class for the {@link Environment} data interface.
 */
export class EnvironmentHandler {
  constructor(public environment: Environment) {}

  /**
   * @returns `true` if the environment should be accessible to the user, `false` otherwise.
   */
  isAccessible(): boolean {
    return new PartialEnvironmentHandler(this.environment).isAccessible();
  }
}

export class PartialEnvironmentHandler {
  constructor(public partialEnvironment: PartialEnvironment) {}

  /**
   * @returns `true` if the environment should be accessible to the user, `false` otherwise.
   */
  isAccessible(): boolean {
    return this.partialEnvironment.status === EnvironmentStatus.Running;
  }
}

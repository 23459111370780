import { createContext } from 'react';
import { BadLogicException } from '../../../../exceptions/badLogicException';

export type MainPageTemplateHeaderContextType = {
  header: React.ReactNode | undefined;
  setHeader: (value: React.ReactNode | undefined) => void;
  headerAlignBottom: boolean;
  setHeaderAlignBottom: (value: boolean) => void;
};

export const MainPageTemplateHeaderContext =
  createContext<MainPageTemplateHeaderContextType>({
    header: <></>,
    setHeader: () => {
      throw new BadLogicException('setHeader function not overridden.');
    },
    headerAlignBottom: false,
    setHeaderAlignBottom: () => {
      throw new BadLogicException(
        'setHeaderOverrideAlignBottom function not overridden.'
      );
    },
  });

// eslint-disable-next-line @typescript-eslint/no-var-requires
const plugin = require('tailwindcss/plugin');

module.exports = {
  content: ['src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      borderRadius: {
        small: '6px',
        DEFAULT: '10px',
        large: '20px',
      },
      fontFamily: {
        heebo: ['Heebo'],
        raleway: ['Raleway'],
      },
      fontSize: {
        xs: '0.5rem',
        sm: '0.75rem',
        smd: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
        xl: '1.5rem',
      },
      colors: {
        blue: {
          DEFAULT: '#4791FF',
          100: '#EAF3FF',
          200: '#D4E6FF',
          500: '#B4BBEE',
          700: '#4791FF',
          800: '#141B4D',
        },
        orange: {
          DEFAULT: '#FF5C39',
          150: '#FFF4F2',
          200: '#FFE7E2',
          300: '#FFD0C5',
          350: '#FFBFB1',
          400: '#ED6400',
          500: '#FF5C39',
        },
        green: {
          DEFAULT: '#C0D95F',
          100: '#E3EDBF',
          200: '#E4EFBA',
          500: '#C0D95F',
          800: '#A0A944',
        },
        deepgreen: {
          DEFAULT: '#02BC77',
          200: '#D4F4E8',
          500: '#02BC77',
        },
        grey: {
          DEFAULT: '#A3A6B4',
          100: '#F9FAFB',
          200: '#EFF1F4',
          300: '#C1C8D8',
          500: '#A3A6B4',
          600: '#707070',
          700: '#4D4F5C',
          800: '#383838',
        },
        pink: {
          DEFAULT: '#FF2366',
          200: '#FFD6E2',
          300: '#FFBDD1',
          500: '#FF2366',
        },
        yellow: {
          DEFAULT: '#FFD950',
          200: '#FFF9E1',
          500: '#FFD950',
        },
        purple: {
          DEFAULT: '#764CED',
          200: '#F5F0FF',
          300: '#ceb9fb',
          400: '#a583f5',
          500: '#764CED',
        },
        red: {
          400: '#ef5350',
          500: '#d32f2f',
          600: '#c62828',
        },
      },
      animation: {
        'spin-sometimes': 'spinsometimes 4s ease infinite',
        'spin-slow': 'spin 3s linear infinite',
        search: 'search 5s ease-in-out infinite',
        wiggle: 'wiggle 1s ease-in-out infinite',
        'wiggle-once': 'wiggle 0.5s ease-in-out 1',
      },
      keyframes: {
        spinsometimes: {
          '0%': { transform: 'rotate(0deg); ' },
          '50%, 100%': { transform: 'rotate(360deg); ' },
        },
        search: {
          '0%, 75%, 100%': { transform: 'translate(0px, 0px)' },
          '15%': { transform: 'translate(2px, -2px)' },
          '30%': { transform: 'translate(-2px, 2px)' },
          '45%': { transform: 'translate(-2px, -2px)' },
          '60%': { transform: 'translate(2px, 2px)' },
        },
        wiggle: {
          '0%, 100%': { transform: 'translate(0deg)' },
          '33%': { transform: 'translateY(-1.5px)' },
          '66%': { transform: 'translateY(1.5px)' },
        },
      },
      minWidth: ({ theme }) => ({
        ...theme('width'),
      }),
      maxHeight: ({ theme }) => ({
        ...theme('height'),
      }),
      gridTemplateColumns: {
        'aside-xs': '4fr 1fr',
        'aside-sm': '3fr 1fr',
        'aside-md': '2fr 1fr',
        'select-md': '2fr 3fr',
        'select-lg': '3fr 3fr',
        logs: '30px 30px 1fr',
      },
      boxShadow: ({ theme }) => ({
        DEFAULT: '1px 1px 5px black',
        selected_aside: `inset 5px 0px 0px 0px ${theme('colors.orange.500')} `,
      }),
    },
  },
  plugins: [
    plugin(function ({ addComponents }) {
      addComponents({
        '.generic-placeholder': {
          '@apply bg-black/10 animate-pulse text-transparent': {},
        },
        '.stack': {
          display: 'grid',
          '&>*': {
            'grid-row': '1',
            'grid-column': '1',
          },
        },
      });
    }),
  ],
};

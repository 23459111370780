import { createContext } from 'react';

/** The context tied to a Log widget */
type LogContextType = {
  /**
   * The maximum character number a line indicator will take.
   *
   * For example: if there are `12345` lines, it will be `5`.
   */
  maxLineCharNumber: number;
};

export const LogContext = createContext<LogContextType>({
  maxLineCharNumber: 0,
});

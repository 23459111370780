import { IconButton, Tooltip, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../utilities/colors';
import { Button, buttonClasses } from '../../Button/Button';
import { Modal } from '../../Modal/Modal';

import { unwrap } from '../../../../utilities/assertions';
import { newLineToBr } from '../../../../utilities/textFormatter';
import {
  GlobalSnackbar,
  GlobalSnackbarProps,
} from '../../../organisms/GlobalSnackbars/subcomponents/GlobalSnackbar';
import './MailButtonStyle.css';

const { REACT_APP_CODEOPS_EMAIL } = process.env;

interface MailButtonProps {
  error: { error: Error; errorInfo: React.ErrorInfo };
}

export function MailButton(props: MailButtonProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { error, errorInfo } = props.error;
  const [snackbarInfo, setSnackbarInfo] = useState<
    Pick<GlobalSnackbarProps, 'name' | 'info'> | undefined
  >();

  const mailParams: Record<string, string> = {};
  mailParams.subject = t('unexpected_error_mail_subject') ?? 'Error on Codeops';
  mailParams.body = t('unexpected_error_mail_body', {
    replace: {
      page: window.location.href,
      error: `${error}\n${error.stack}\n${errorInfo.componentStack}`,
    },
  });
  const stringifiedMailParams = Object.entries(mailParams)
    .map(([key, value]) => {
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  const copy = async (
    text: string | undefined,
    notificationTermPrefix: string
  ) => {
    try {
      await navigator.clipboard.writeText(unwrap(text));
      setSnackbarInfo({
        name: notificationTermPrefix,
        info: {
          message: t(`${notificationTermPrefix}_succeeded`),
          severity: 'success',
          autoHideDuration: 2000,
        },
      });
    } catch (e) {
      console.error(e);
      setSnackbarInfo({
        name: notificationTermPrefix,
        info: {
          message: t(`${notificationTermPrefix}_failed`),
          severity: 'error',
          autoHideDuration: 2000,
        },
      });
    }
  };

  const emailBody = t('unexpected_error_mail_body', {
    replace: {
      page: window.location.href,
      error: `${error}\n${error.stack}\n${errorInfo.componentStack}`,
    },
  });

  return (
    <div className="flex">
      <Tooltip title={t('error_send_mail_manually')}>
        <LeftButton
          variant="contained"
          startIcon={<i className="icon-mail" />}
          onClick={() => setModalOpen(true)}
        />
      </Tooltip>
      <Tooltip title={t('error_send_mail_automatically')}>
        <a href={`mailto:${REACT_APP_CODEOPS_EMAIL}?${stringifiedMailParams}`}>
          <RightButton
            variant="contained"
            startIcon={<i className="icon-send" />}
          >
            {t('unexpected_error_mail_send') ?? 'Send mail to support'}
          </RightButton>
        </a>
      </Tooltip>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="flex flex-col gap-6 max-h-full overflow-y-auto">
          <div>
            <p className="font-raleway font-semibold">{t('recipient')}</p>
            <div className="MailButton__read-only">
              <p>{REACT_APP_CODEOPS_EMAIL}</p>
              <Tooltip title={t('copy')}>
                <IconButton
                  className="MailButton__copy-button"
                  onClick={() =>
                    copy(REACT_APP_CODEOPS_EMAIL, 'email_recipient_copy')
                  }
                >
                  <i className="icon-copy"></i>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div>
            <p className="font-raleway">
              <span className="font-semibold">{t('email_body')}</span>{' '}
              <span className="font-light text-smd">
                {t('error_email_body_details')}
              </span>
            </p>
            <div className="MailButton__read-only">
              <div className="max-w-prose max-h-96 overflow-x-auto overflow-y-auto">
                <p className="">{newLineToBr(emailBody)}</p>
              </div>
              <Tooltip title={t('copy')}>
                <IconButton
                  className="MailButton__copy-button"
                  onClick={() => copy(emailBody, 'email_body_copy')}
                >
                  <i className="icon-copy"></i>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </Modal>
      <div className="absolute boo w-screen h-screen left-0 top-0 p-4 z-[1400] flex justify-start items-end pointer-events-none">
        {snackbarInfo !== undefined && (
          <GlobalSnackbar
            {...snackbarInfo}
            className="pointer-events-auto"
            in
            onDeleteRequested={() => setSnackbarInfo(undefined)}
          />
        )}
      </div>
    </div>
  );
}

const LeftButton = styled(Button)`
  & {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 1px solid ${Colors.fromTailwind('orange.350')};
    min-width: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .${buttonClasses.content} {
    display: none;
  }

  .${buttonClasses.startIcon} {
    margin: 0;
  }
`;

const RightButton = styled(Button)`
  & {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

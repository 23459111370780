import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Block, BlockProps } from '../Block/Block';

export interface WarningBlockProps extends Pick<BlockProps, 'noShadow'> {
  className?: string;
  children?: React.ReactNode;
  /**
   * The text indicated before the children. Default: `"Avertissement :"`.
   */
  title?: string;
}

export function WarningBlock(props: WarningBlockProps) {
  const { t } = useTranslation();

  return (
    <Block
      className={ClassUtilities.flatten('WarningBlock', props.className)}
      context="warning"
      noShadow={props.noShadow}
    >
      <div className="text-orange flex gap-2 leading-6 text-smd">
        <div className="pt-[0.2em] text-md">
          <i className="icon-alert-triangle"></i>
        </div>
        <p className="text-orange-400">
          <span className="text-orange font-bold underline">
            {props.title ?? t('warning') + t('colon')}
          </span>{' '}
          {props.children}
        </p>
      </div>
    </Block>
  );
}

import { Snackbar } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { useSnackbarStore } from '../../../stores/snackbarStore';
import { GlobalSnackbar } from './subcomponents/GlobalSnackbar';

export function GlobalSnackbars() {
  const snackbarsInfo = useSnackbarStore((state) => state.widget_snackbarsInfo);
  const deleteSnackbarInfo = useSnackbarStore(
    (state) => state.widget_deleteSnackbarInfo
  );

  return (
    <div className="absolute GlobalSnackbar">
      <Snackbar open={Object.keys(snackbarsInfo).length > 0}>
        <div className="flex flex-col ">
          <TransitionGroup className="flex flex-col items-start">
            {Object.entries(snackbarsInfo).map(([name, info]) => (
              <GlobalSnackbar
                key={name}
                name={name}
                info={info}
                onDeleteRequested={() => deleteSnackbarInfo(name)}
              />
            ))}
          </TransitionGroup>
        </div>
      </Snackbar>
    </div>
  );
}

import { PaymentLineInfo } from './paymentLineInfo';

/** A {@link PaymentLineInfo} with an id or a generatedId (mutually exclusive). */
export type IdentifiedPaymentLineInfo = Partial<PaymentLineInfo> &
  (
    | {
        /** An id for identifying it in the front application. This means nothing for back side. Having a {@link generatedId} implies this payment line did not exist in the DB ans has been created by the user. */
        generatedId: string;
      }
    | {
        /** An id coming from the back application. This line is associated to an entity that already exists in the back. */
        id: string;
      }
  );

/**
 * @returns `true` if this {@link IdentifiedPaymentLineInfo} does not exist in the back (thus has a `generatedId`).
 */
export function isGenerated(
  paymentLineInfo: IdentifiedPaymentLineInfo
): paymentLineInfo is Partial<PaymentLineInfo> & { generatedId: string } {
  return 'generatedId' in paymentLineInfo;
}

/**
 * @returns `true` if this {@link IdentifiedPaymentLineInfo} exists in the back (thus has an `id`).
 * @remarks This line may come from back data but may have been modified by the user, so it does not reflect back data. However `id` should be a valid `id` coming from the back.
 */
export function isIdentified(
  paymentLineInfo: IdentifiedPaymentLineInfo
): paymentLineInfo is Partial<PaymentLineInfo> & { id: string } {
  return 'id' in paymentLineInfo;
}

import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { Block } from '../../atoms/Block/Block';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { MainPageContainer } from '../../atoms/MainPageContainer/MainPageContainer';
import { Modal } from '../../atoms/Modal/Modal';
import { FormSingleSelect } from '../../atoms/SingleSelect/FormSingleSelect';
import { FormTextField } from '../../atoms/TextField/FormTextField';
import { Title } from '../../atoms/Title/Title';
import { WarningBlock } from '../../atoms/WarningBlock/WarningBlock';
import { FormGitReferencePicker } from '../../molecules/FormGitReferencePicker/FormGitReferencePicker';
import { gitReferenceSchemaDefaultValues } from '../../molecules/FormGitReferencePicker/schema';
import { DataSource } from './dataSource';
import { templatesOptions } from './mockData';
import { Schema, getSchema, schemaToPostArgs } from './schema';
import { DataDumpPicker } from './subcomponents/DataDumpPicker/DataDumpPicker';

import { useNavigate } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { NetworkRequestException } from '../../../exceptions/networkRequestException';
import { useCanCreateNewEnvironment } from '../../../hooks/useCanCreateNewEnvironment';
import { useCheckAdmin } from '../../../hooks/useCheckAdmin';
import { useInitEnvEstimates } from '../../../hooks/useInitEnvEstimates';
import { useProjectAndEnvironmentRoute } from '../../../hooks/useProjectAndEnvironmentRoute';
import { EnvironmentStatus } from '../../../models/environmentStatus';
import { useSnackbarStore } from '../../../stores/snackbarStore';
import { unwrap } from '../../../utilities/assertions';
import { UniqueId } from '../../../utilities/uniqueIdGenerator';
import { FormCheckbox } from '../../atoms/Checkbox/FormCheckbox';
import { EstimateErrorBlock } from '../../molecules/EstimateErrorBlock/EstimateErrorBlock';
import './NewEnvironment.css';
import { EstimatesBlocks } from './subcomponents/EstimatesBlocks';
import { errorToErrorTerm, waitForEnvironmentToBeReady } from './utilities';

export function NewEnvironment() {
  const navigate = useNavigate();
  const canCreateNewEnvironment = useCanCreateNewEnvironment();
  const isAdmin = useCheckAdmin();

  useEffect(() => {
    if (false === canCreateNewEnvironment) {
      navigate('/', { replace: true });
    }
  }, [canCreateNewEnvironment, navigate]);

  const { t } = useTranslation();
  useTitle(t('create_an_environment'));
  const [loading, setLoading] = useState(false);
  const [errorModalText, setErrorModalText] = useState<string | undefined>(
    undefined
  );
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const projectId = unwrap(
    useProjectsStore((state) => state.selectedProjectId)
  );
  const refreshEnvironments = () =>
    useProjectEnvironmentsStore.getState().fetch(projectId, true);
  const workplaceRoute = useProjectAndEnvironmentRoute();
  const snackbarHandlePromise = useSnackbarStore(
    (state) => state.handlePromise
  );

  const initEnvEstimates = useInitEnvEstimates(projectId);

  const formMethods = useForm<Schema>({
    defaultValues: {
      name: '',
      isMonoVM: 1,
      template: templatesOptions[0].value,
      gitReference: gitReferenceSchemaDefaultValues,
      dataSource: DataSource.Environment,
      environmentSource: '',
      backupIds: undefined,
      isDeletable: true,
    },
    resolver: zodResolver(getSchema(t)),
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
  } = formMethods;

  const onSubmit = async (data: Schema) => {
    setLoading(true);

    await snackbarHandlePromise(
      `new-environment-${UniqueId.generate()}`,
      async () => {
        try {
          let newEnvironment = await new EnvironmentApi().post(
            schemaToPostArgs(
              data,
              { projectId: unwrap(projectId) },
              unwrap(isAdmin)
            )
          );
          unwrap(refreshEnvironments)(); // To see environment in creation
          newEnvironment = await waitForEnvironmentToBeReady(newEnvironment.id);
          setLoading(false);
          if (newEnvironment.status === EnvironmentStatus.Running) {
            setSuccessModalOpen(true);
            unwrap(refreshEnvironments)();
          } else {
            setErrorModalText('env_unknown_error_could_not_create');
          }
        } catch (e) {
          setLoading(false);
          if (e instanceof NetworkRequestException) {
            setErrorModalText(errorToErrorTerm(e) ?? e.toString());
          }
          throw e;
        }
        return null;
      },
      {
        getErrorMessage: (error) => (
          <div className="flex flex-col">
            <div>
              {t('snackbar_operation_failure', {
                actionName: t('env_creation'),
              })}
            </div>
            <div>{t(errorToErrorTerm(error))}</div>
          </div>
        ),
        getLoadingMessage: () => (
          <>
            {t('snackbar_operation_in_progress', {
              actionName: t('env_creation'),
            })}
          </>
        ),
        getSuccessMessage: () => (
          <>
            {t('snackbar_operation_success', {
              actionName: t('env_creation'),
            })}
          </>
        ),
      }
    );
  };

  const isMonoVM = Boolean(watch('isMonoVM'));

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <div className="NewEnvironment h-full flex flex-col">
      <MainPageContainer
        className="grow flex flex-col gap-4"
        backRoute={workplaceRoute}
      >
        <div className="grid grid-cols-1 xl:grid-cols-aside-sm gap-6 items-start">
          <div className="flex flex-col gap-4">
            <Block className="grow shrink flex flex-col gap-8">
              <Title level={2}>{t('create_an_environment')}</Title>
              <div className="grid gap-8">
                <form className="min-w-48 flex flex-col items-stretch gap-8 pb-2">
                  <div className="NewEnvironment__form-group NewEnvironment__form-group--extra-gap">
                    <div className="NewEnvironment__form-group__controls">
                      <FormTextField
                        label={t('env_name')}
                        name="name"
                        control={control}
                      />
                      <div className="h-4"></div>
                      <FormSingleSelect
                        control={control}
                        options={[
                          {
                            label: 'Mono-VM',
                            value: 1,
                          },
                          {
                            label: 'Multi-VM',
                            value: 0,
                          },
                        ]}
                        name="isMonoVM"
                        label={t('architecture')}
                      />

                      <FormSingleSelect
                        control={control}
                        options={templatesOptions}
                        name="template"
                        label={t('template')}
                      />
                    </div>
                  </div>

                  <div className="NewEnvironment__form-group NewEnvironment__form-group--extra-gap">
                    <Title level={3}>{t('select_git_reference')}</Title>
                    <div className="NewEnvironment__form-group__controls">
                      <FormGitReferencePicker formMethods={formMethods} />
                    </div>
                  </div>

                  <div className="NewEnvironment__form-group NewEnvironment__form-group--extra-gap">
                    <Title level={3}>{t('select_data_reference')}</Title>
                    <DataDumpPicker
                      control={control}
                      watch={watch}
                      clearErrors={clearErrors}
                      errors={errors}
                      setValue={setValue}
                    />
                  </div>

                  {isAdmin && (
                    <div className="NewEnvironment__form-group">
                      <div className="flex items-baseline gap-2">
                        <Title level={3}>{t('administrator_fields')}</Title>
                        <i className="icon-chess-king" />
                      </div>
                      <div className="NewEnvironment__form-group__controls">
                        <FormCheckbox
                          control={control}
                          name="isDeletable"
                          label={t('is_deletable')}
                        />
                      </div>
                    </div>
                  )}

                  {hasErrors && (
                    <WarningBlock>{t('warning_error_in_form')}</WarningBlock>
                  )}

                  <RoundedFilledBlueButton
                    color="info"
                    className="self-end"
                    disabled={
                      hasErrors ||
                      projectId === undefined ||
                      isAdmin === undefined ||
                      loading
                    }
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('create')}
                  </RoundedFilledBlueButton>
                </form>
              </div>
            </Block>
          </div>
          <div className="min-w-48 flex flex-col gap-4 shrink grow">
            {initEnvEstimates.map({
              succeeded: (data) => (
                <EstimatesBlocks data={data} isMonoVM={isMonoVM} />
              ),
              running: () => (
                <>
                  <EstimatesBlocks isMonoVM={isMonoVM} />
                </>
              ),
              notStarted: 'running',
              failed: (err) => <EstimateErrorBlock />,
            })}
          </div>
        </div>
      </MainPageContainer>
      <Modal
        doNotUseCardWrapper
        open={errorModalText !== undefined}
        onClose={() => setErrorModalText(undefined)}
      >
        <Alert severity="error" title={t('error_env_creation') ?? undefined}>
          <span>{errorModalText && t(errorModalText)}</span>
        </Alert>
      </Modal>
      <Modal
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        doNotUseCardWrapper
      >
        <Alert severity="success">{t('env_creation_successful')}</Alert>
      </Modal>
    </div>
  );
}

import { useLocation, useParams } from 'react-router-dom';

/**
 * Attempts to retrieve the unparameterized path from a parameterized path.
 *
 * For example `/project/8ab0c085-82e6-4a14-bd4b-d46131024ec3/history/action` would be `/project/:projectId/history/action`.
 * Note that this hook use React router to retrieve parameters.
 */
export function useUnparameterizedPath() {
  const pathname = useLocation().pathname;
  const params = useParams();

  let unparameterizedPath = pathname;
  for (const [param, value] of Object.entries(params)) {
    if (value === undefined) continue;
    unparameterizedPath = unparameterizedPath.replace(value, `:${param}`);
  }

  return unparameterizedPath;
}

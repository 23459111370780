import { ClassUtilities } from '../../../utilities/classUtility';

export interface DataPaperElementProps {
  fullSpan?: boolean;
  label: React.ReactNode;
  value: React.ReactNode;
}

export function DataPaperElement(props: DataPaperElementProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'DataPaperElement flex flex-col',
        ClassUtilities.conditional({ 'col-span-full': !!props.fullSpan })
      )}
    >
      <span className="text-smd font-medium text-grey-800">{props.label}</span>
      <span className="text-smd">{props.value}</span>
    </div>
  );
}

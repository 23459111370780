import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ActionHistoryApi } from '../../../api/actionHistoryApi';
import { useTitle } from '../../../hooks/useTitle';
import { useUsers } from '../../../hooks/useUsers';
import { ActionHistory } from '../../../models/actionHistory';
import { ActionStatus } from '../../../models/actionStatus';
import { ActionType, ActionTypeHandler } from '../../../models/actionType';
import { ActionTypeName } from '../../../models/actionTypeName';
import { Environment } from '../../../models/environment';
import { User } from '../../../models/user';
import { unwrap } from '../../../utilities/assertions';
import { ClassUtilities } from '../../../utilities/classUtility';
import { DateFormatter, TimeUnit } from '../../../utilities/dateFormatter';
import { PromiseSnapshot } from '../../../utilities/promiseSnapshot';
import { StatusChip } from '../../atoms/Chip/StatusChip';
import { DatePicker } from '../../atoms/DatePicker/DatePicker';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { Logs } from '../../atoms/Logs/Logs';
import { LogsLoadingBlock } from '../../atoms/LogsLoadingBlock/LogsLoadingBlock';
import { ActionTypeSelect } from './components/ActionTypeSelect';
import { AuthorSelect } from './components/AuthorSelect';

import { useActionTypes } from '../../../hooks/useActionTypes';
import { useProjectsStore } from '../../../stores/projectsStore';
import { ApiPlatformList } from '../../../utilities/baseApi';
import * as URLSearchParamUtils from '../../../utilities/urlSearchParamUtilities';
import { TablePagination } from '../../atoms/TablePagination/TablePagination';
import { EnvironmentSelect } from './components/EnvironmentSelect';
import { StatusSelect } from './components/StatusSelect';
import './HistoryAction.css';
import { useEnvironments } from './hooks/useEnvironments';
import { BoldTableCell } from './style';
import { actionStatusToChip, formatAuthorName } from './utilities';

export interface MockAction extends ActionHistory {
  logs: string[];
}

export function HistoryAction() {
  const { t } = useTranslation();

  useTitle(t('actions_history'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsLoaded, setSearchParamsLoaded] = useState(false);

  //#region Filters' necessary info fetch
  const projectId = unwrap(useProjectsStore((state) => state.getProject()?.id));

  const environmentsSnapshot = useEnvironments(projectId);

  const usersSnapshot = useUsers();
  const actionTypesSnapshot = useActionTypes();
  //#endregion

  //#region Filter registering
  const [selectedEnvironmentIds, setSelectedEnvironmentIds] = useState<
    Environment['id'][]
  >([]);
  const [selectedStatuses, setSelectedStatuses] = useState<ActionStatus[]>([]);
  const [selectedAuthorIds, setSelectedAuthorsIds] = useState<User['id'][]>([]);
  const [selectedActionTypeNames, setSelectedActionTypeNames] = useState<
    ActionType['name'][]
  >([]);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [page, setPage] = useState(1);

  // Get filters from URL.
  useEffect(() => {
    if (searchParamsLoaded) return;

    setSelectedEnvironmentIds(searchParams.getAll('environment[]'));
    setSelectedStatuses(searchParams.getAll('status[]') as ActionStatus[]);
    setSelectedAuthorsIds(searchParams.getAll('author[]'));
    setSelectedActionTypeNames(
      searchParams.getAll('actionTypeName[]') as ActionTypeName[]
    );

    const fromDateStr = searchParams.get('fromDate');
    const toDateStr = searchParams.get('toDate');
    setFromDate(fromDateStr !== null ? dayjs(fromDateStr) : null);
    setToDate(toDateStr !== null ? dayjs(toDateStr) : null);
    setPage(URLSearchParamUtils.getInt(searchParams, 'page') ?? 1);

    setSearchParamsLoaded(true);
  }, [searchParams, searchParamsLoaded]);

  // Put selected filters into URL
  useEffect(() => {
    setSearchParams({
      'environment[]': selectedEnvironmentIds,
      'status[]': selectedStatuses,
      'author[]': selectedAuthorIds,
      'actionTypeName[]': selectedActionTypeNames,
      fromDate: fromDate ? fromDate.toISOString() : [],
      toDate: toDate ? toDate.toISOString() : [],
      page: page.toString(),
    });
  }, [
    fromDate,
    page,
    selectedActionTypeNames,
    selectedAuthorIds,
    selectedEnvironmentIds,
    selectedStatuses,
    setSearchParams,
    toDate,
  ]);
  //#endregion

  const [paginatedActionHistories, setPaginatedActionHistories] = useState(
    new PromiseSnapshot<ApiPlatformList<ActionHistory>>()
  );
  const actionHistoriesAbortController = useRef(new AbortController());
  const logsAbortController = useRef(new AbortController());

  useEffect(() => {
    const actionTypes = actionTypesSnapshot.data;
    if (actionTypes === undefined) return;
    if (!environmentsSnapshot.isSucceeded()) return;
    const actionTypeNames = searchParams.getAll('actionTypeName[]');
    const selectedActionTypeIds = actionTypes
      .filter((at) => actionTypeNames.includes(at.name))
      .map((at) => at.id);

    const abortController = actionHistoriesAbortController.current;
    setVisibleLogsId(undefined);

    PromiseSnapshot.trackPromiseSetter(
      () => {
        return new ActionHistoryApi().getAllPaginated(
          {
            'environment.project[]': [projectId],
            'environment[]': searchParams.getAll('environment[]'),
            'status[]': searchParams.getAll('status[]') as ActionStatus[],
            'author[]': searchParams.getAll('author[]'),
            'action_type[]': selectedActionTypeIds,
            'started_at[after]': fromDate?.toDate(),
            'started_at[before]': toDate?.toDate(),
            page: page,
          },
          { abortController }
        );
      },
      setPaginatedActionHistories,
      { abortController }
    );

    return () => {
      abortController.abort();
      actionHistoriesAbortController.current = new AbortController();
    };
  }, [
    actionTypesSnapshot.data,
    environmentsSnapshot,
    fromDate,
    page,
    projectId,
    searchParams,
    toDate,
  ]);

  //#region Log handling
  const [visibleLogsId, setVisibleLogsId] = useState<
    ActionHistory['id'] | undefined
  >(undefined);
  const [visibleLogs, setVisibleLogs] = useState<PromiseSnapshot<string[]>>(
    new PromiseSnapshot()
  );

  const actionClicked = (action: ActionHistory) => {
    if (visibleLogsId !== action.id) {
      setVisibleLogsId(action.id);
    } else {
      setVisibleLogsId(undefined);
      setVisibleLogs(new PromiseSnapshot());
    }
  };

  useEffect(() => {
    if (visibleLogsId === undefined) return;

    const abortController = logsAbortController.current;
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ActionHistoryApi().getActionHistoryLogs(visibleLogsId, {
          abortController,
        }),
      setVisibleLogs,
      { abortController }
    );

    return () => {
      abortController.abort();
      logsAbortController.current = new AbortController();
    };
  }, [visibleLogsId]);
  //#endregion

  return (
    <div className="HistoryAction flex flex-col items-stretch gap-16 w-full h-full">
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 pt-4 text-smd">
        <div className="grow flex flex-col gap-2">
          <EnvironmentSelect
            environmentsSnapshot={environmentsSnapshot}
            selectedEnvironmentIds={selectedEnvironmentIds}
            onSelectedEnvironmentIdsChanged={setSelectedEnvironmentIds}
          />
          <ActionTypeSelect
            actionTypesSnapshot={actionTypesSnapshot}
            selectedActionTypeNames={selectedActionTypeNames}
            onSelectedActionTypeNamesChanged={setSelectedActionTypeNames}
          />
        </div>
        <div className="grow flex flex-col gap-2">
          <StatusSelect
            selectedStatuses={selectedStatuses}
            onSelectedStatusesChanged={setSelectedStatuses}
          />
          <AuthorSelect
            usersSnapshot={usersSnapshot}
            selectedAuthorIds={selectedAuthorIds}
            onSelectedAuthorIdsChanged={setSelectedAuthorsIds}
          />
        </div>
        <div className="shrink flex items-baseline gap-2 pl-2 flex-wrap content-start">
          <span>{t('period_from')}</span>
          <DatePicker value={fromDate} onChange={setFromDate} />
          <span>{t('period_to')}</span>
          <DatePicker value={toDate} onChange={setToDate} />
        </div>
      </div>

      <div className="relative grow min-w-0">
        {paginatedActionHistories.data !== undefined &&
        usersSnapshot.data !== undefined &&
        actionTypesSnapshot.data !== undefined &&
        environmentsSnapshot.data !== undefined ? (
          <div
            className={ClassUtilities.flatten(
              'relative transition-[height] flex flex-col',
              ClassUtilities.conditional({
                'h-1/2': visibleLogsId !== undefined,
                'h-full': visibleLogsId === undefined,
              })
            )}
          >
            <TablePagination
              page={page}
              onPageChange={setPage}
              count={paginatedActionHistories.data['hydra:totalItems']}
            />
            <TableContainer component={'div'} className="grow relative">
              <Table
                size="small"
                stickyHeader
                className="min-w-[1064px] absolute max-h-full"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t('action')}</TableCell>
                    <TableCell>{t('environment')}</TableCell>
                    <TableCell>{t('start_date')}</TableCell>
                    <TableCell>{t('end_date')}</TableCell>
                    <TableCell>{t('duration')}</TableCell>
                    <TableCell>{t('status')}</TableCell>
                    <TableCell>{t('author')}</TableCell>
                    <TableCell>{t('reference')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedActionHistories.data['hydra:member'].map(
                    (action) => {
                      const author = unwrap(usersSnapshot.data).find(
                        (user) => user.id === action.author
                      );
                      const formattedAuthorName = formatAuthorName(author);
                      return (
                        <TableRow
                          key={action.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                          onClick={() => actionClicked(action)}
                          selected={action.id === visibleLogsId}
                          hover
                        >
                          <BoldTableCell className="font-semibold">
                            {new ActionTypeHandler(
                              unwrap(
                                unwrap(
                                  actionTypesSnapshot.data,
                                  'No action types data'
                                ).find((at) => at.id === action.actionType),
                                `No action type id matching action.actionType "${action.actionType}"`
                              )
                            ).getDoneFriendlyName()}
                          </BoldTableCell>
                          <TableCell>
                            {action.environment
                              ? unwrap(
                                  unwrap(
                                    environmentsSnapshot.data,
                                    'Environments snapshot data is not defined.'
                                  )?.find(
                                    (env) => env.id === action.environment
                                  ),
                                  `Could not find action.environment "${action.environment}" in environments.`
                                ).name
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {DateFormatter.toDateTime(action.startedAt)}
                          </TableCell>
                          <TableCell>
                            {action.endedAt ? (
                              DateFormatter.toDateTime(action.endedAt)
                            ) : (
                              <StatusChip context="neutral" label="En cours" />
                            )}
                          </TableCell>
                          <TableCell>
                            {DateFormatter.toDurationFromDates(
                              action.startedAt,
                              action.endedAt ?? new Date(),
                              t,
                              {
                                parts:
                                  TimeUnit.Hour |
                                  TimeUnit.Minute |
                                  TimeUnit.Second,
                                maximumParts: 2,
                                countZeroParts: false,
                              }
                            )}
                          </TableCell>
                          <TableCell>
                            {actionStatusToChip(action.status)}
                          </TableCell>
                          <TableCell>{formattedAuthorName}</TableCell>
                          <TableCell>{action.reference}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <LoadingBlock
            icon={<i className="icon-search animate-search" />}
            text={t('search_in_progress')}
          />
        )}
        <div
          className={ClassUtilities.flatten(
            'h-1/2 py-4 flex flex-col',
            ClassUtilities.conditional({
              hidden: visibleLogsId === undefined,
            })
          )}
        >
          <div className="relative grow">
            {visibleLogsId !== undefined && !visibleLogs.isOver() ? (
              <div
                className={
                  'Logs min-h-[2rem] rounded overflow-y-auto absolute h-full w-full min-w-0'
                }
              >
                <LogsLoadingBlock></LogsLoadingBlock>
              </div>
            ) : (
              <Logs
                className="absolute h-full w-full min-w-0"
                logs={visibleLogs?.data ?? []}
              ></Logs>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

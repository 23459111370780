import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EnvironmentApi } from '../../../api/environmentApi';
import { ProjectApi } from '../../../api/projectApi';
import { useTitle } from '../../../hooks/useTitle';
import { PartialEnvironment } from '../../../models/environment';
import { Project } from '../../../models/project';
import { unwrap } from '../../../utilities/assertions';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { Block } from '../../atoms/Block/Block';
import { Breadcrumb } from '../../atoms/Breadcrumb/Breadcrumb';
import { DataPaper } from '../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../atoms/DataPaperElement/DataPaperElement';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';
import { LoadingBlock } from '../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../atoms/Title/Title';
import { SSHPublicKeyBlock } from '../../molecules/SSHPublicKeyBlock/SSHPublicKeyBlock';
import {
  FirstColumnType,
  ProjectUserList,
} from '../../organisms/ProjectUserList/ProjectUserList';
import { AdminPageTemplate } from '../../templates/AdminPageTemplate/AdminPageTemplate';
import './AdminProject.css';
import { ActionBlock } from './subcomponents/ActionBlock';

export function AdminProject() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());

  useTitle(t('administration_projects'));

  const [projectSnapshot, setProjectSnapshot] = useState<
    PromiseSnapshot<Project>
  >(new PromiseSnapshot());
  const [environmentsSnapshot, setEnvironmentsSnapshot] = useState<
    PromiseSnapshot<PartialEnvironment[]>
  >(new PromiseSnapshot());

  // Fetch projects, environments and users
  useEffect(() => {
    // Redirect to projects page if projectId is undefined
    if (projectId === undefined) {
      navigate('/admin/projects', { replace: true });
      return;
    }

    // Fetch project
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new ProjectApi().get(projectId, {
          abortController: abortController.current,
        }),
      setProjectSnapshot
    );

    // Fetch environments
    PromiseSnapshot.trackPromiseSetter(
      () =>
        new EnvironmentApi().getAll(
          { 'project[]': [projectId], 'deleted_at[after]': null },
          { abortController: abortController.current }
        ),
      setEnvironmentsSnapshot
    );

    // Fetch users
  }, [navigate, projectId]);

  // Abort request if page is quitted
  useEffect(() => {
    const thisAbortController = abortController.current;

    return () => {
      thisAbortController.abort();
      abortController.current = new AbortController();
    };
  }, []);

  //#region Rendering
  if (
    projectSnapshot.state === PromiseState.Failed ||
    environmentsSnapshot.state === PromiseState.Failed
  ) {
    return (
      <div className="AdminProject">
        <ErrorBlock>{`${
          projectSnapshot.state === PromiseState.Failed
            ? projectSnapshot.error
            : environmentsSnapshot.state
        }`}</ErrorBlock>
      </div>
    );
  } else if (
    projectSnapshot.state !== PromiseState.Succeeded ||
    environmentsSnapshot.state !== PromiseState.Succeeded
  ) {
    return (
      <div className="AdminProject">
        <LoadingBlock></LoadingBlock>
      </div>
    );
  }

  const updateProject = (newProject: Project) => {
    setProjectSnapshot(PromiseSnapshot.buildSucceeded(newProject));
  };

  const project = unwrap(projectSnapshot.data);
  const environments = unwrap(environmentsSnapshot.data);

  // #region Duplicated components generation function
  const environmentActions = (environment: PartialEnvironment) => (
    <Tooltip title={t('show_edit_environment')}>
      <Link
        to={`environments/${environment.id}`}
        state={{ project: project, environment: environment }}
      >
        <IconButton size="small">
          <i className="icon-edit text-orange"></i>
        </IconButton>
      </Link>
    </Tooltip>
  );
  // #endregion

  return (
    <AdminPageTemplate className="AdminProject">
      <div className="flex flex-col gap-4">
        <Breadcrumb
          homeLink={'/admin/projects'}
          elements={[
            {
              title: project.name,
              subtitle: t('project'),
              link: '',
            },
          ]}
        ></Breadcrumb>
        <ActionBlock project={project} />
        <div className="h-4"></div>
        <Block>
          <Title level={2}>{t('environments_list')}</Title>
          <div className="h-4"></div>
          <TableContainer className="hidden lg:block" component={'div'}>
            <Table sx={{ minWidth: 650 }} aria-label="users table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('environment_identifier')}</TableCell>
                  <TableCell>{t('environment_name')}</TableCell>
                  <TableCell>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {environments.map((env) => {
                  return (
                    <TableRow key={env.identifier}>
                      <TableCell>{env.identifier}</TableCell>
                      <TableCell>{env.name}</TableCell>
                      <TableCell>{environmentActions(env)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="lg:hidden flex flex-col gap-4 pb-4">
            {environments.map((env) => (
              <DataPaper
                key={env.id}
                header={env.id}
                content={[
                  <DataPaperElement
                    key="identifier"
                    label={t('environment_identifier')}
                    value={env.identifier}
                  />,
                  <DataPaperElement
                    key="name"
                    label={t('environment_name')}
                    value={env.name}
                  />,
                  <DataPaperElement
                    key="actions"
                    fullSpan
                    label={t('action')}
                    value={environmentActions(env)}
                  />,
                ]}
              />
            ))}
          </div>
        </Block>
        <div className="h-4"></div>
        <ProjectUserList
          project={project}
          onProjectUpdate={updateProject}
          firstColumnType={FirstColumnType.Id}
        />
        <div className="h-4"></div>
        <SSHPublicKeyBlock />
      </div>
    </AdminPageTemplate>
  );
  //#endregion
}

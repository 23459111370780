import dayjs from 'dayjs';
import { ProjectAddUserException } from '../exceptions/app/projectAddUserException';
import { NetworkRequestException } from '../exceptions/networkRequestException';
import { Estimate, RawEstimate, refineEstimate } from '../models/estimate';
import { GitReference } from '../models/gitReference';
import {
  ContactPatch,
  InitEnvEstimate,
  PatchArgs,
  Project,
  ProjectPartial,
  ProjectPricing,
  ProjectSpecifications,
  RawInitEnvEstimate,
  refineInitEnvEstimate,
} from '../models/project';
import { User } from '../models/user';
import { UserProjectRoleType } from '../models/userProjectRole';
import { unwrap } from '../utilities/assertions';
import { BaseApi } from '../utilities/baseApi';
import {
  AbortOptions,
  AbortOptionsDefault,
  getJsonFromServerRoute,
  postJsonFromServerRoute,
} from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { ParamMappingHelper } from '../utilities/paramMappingHelper';
import {
  ParameterifyFunctionMapper,
  QueryOptionsHandler,
} from '../utilities/queryOptionsHandler';
import { getKeys } from '../utilities/recordUtilities';
import { CompanyApi } from './companyApi';

const {
  REACT_APP_ROUTE_PROJECTS,
  REACT_APP_ROUTE_PROJECTS_BRANCHES,
  REACT_APP_ROUTE_PROJECTS_TAGS,
  REACT_APP_ROUTE_PROJECTS_PRICING,
  REACT_APP_ROUTE_PROJECTS_SNAPSHOTS,
  REACT_APP_ROUTE_PROJECTS_ESTIMATIONS,
  REACT_APP_ROUTE_PROJECTS_INIT_ENV_ESTIMATED_BILL,
  REACT_APP_ROUTE_PROJECTS_SPECIFICATIONS,
  REACT_APP_ROUTE_PROJECTS_CONTACT,
  REACT_APP_ROUTE_PROJECTS_SET_IMAGE,
  REACT_APP_ROUTE_PROJECTS_ADD_USER,
  REACT_APP_ROUTE_PROJECTS_JOB_DISCOVERY,
} = process.env;

export interface GetOptions {
  page?: number;
  itemsPerPage?: number;
  name?: string;
  'identifier[]'?: string[];
  identifier?: string;
  'id[]'?: Project['id'][];
  id?: Project['id'];
}

export class GetOptionsHandler extends QueryOptionsHandler<GetOptions> {
  protected stringMapper(): ParameterifyFunctionMapper<GetOptions> {
    return {
      page: ParamMappingHelper.mapNumber,
      itemsPerPage: ParamMappingHelper.mapNumber,
      name: ParamMappingHelper.identity,
      'identifier[]': ParamMappingHelper.identity,
      identifier: ParamMappingHelper.identity,
      'id[]': ParamMappingHelper.identity,
      id: ParamMappingHelper.identity,
    };
  }
}

export type NewProjectArgs = {
  image?: File | null | undefined;
  name: string;
  gitRepositoryUrl: string;
  billingCompany: string;
  [params: string]: File | string | null | undefined;
};

export class ProjectApi extends BaseApi<
  Project,
  GetOptions,
  ProjectPartial,
  NewProjectArgs,
  PatchArgs
> {
  constructor() {
    super(
      unwrap(REACT_APP_ROUTE_PROJECTS, 'Project route not defined.'),
      GetOptionsHandler,
      { allowDelete: true }
    );
  }

  transformPostArgs(
    postArgs: NewProjectArgs
  ): FormData | Record<string, unknown> {
    const formData = new FormData(undefined);
    formData.append('name', postArgs.name);
    formData.append('gitRepositoryUrl', postArgs.gitRepositoryUrl);
    formData.append('billingCompany', postArgs.billingCompany);

    if (undefined !== postArgs.image && null !== postArgs.image) {
      formData.append('img', postArgs.image);
    }

    return formData;
  }

  /**
   * @param id The project id.
   * @param options See {@link AbortOptions}
   * @returns The existing git references of the project. (This may for example be git branches or git tags.)
   */
  protected async getGitReferences(
    route: string,
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<GitReference[]> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    return (
      (await getJsonFromServerRoute(route, {
        args: { id },
        abortController: filledOptions.abortController,
      })) as { 'hydra:member': GitReference[] }
    )['hydra:member'];
  }

  /**
   * @param id The project id.
   * @param options See {@link AbortOptions}
   * @returns The existing git branches of the project.
   */
  async getBranches(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<GitReference[]> {
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_BRANCHES,
      'Project branches route not defined'
    );
    return this.getGitReferences(route, id, options);
  }

  /**
   * @param id The project id.
   * @param options See {@link AbortOptions}
   * @returns The existing tags of the project.
   */
  async getTags(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<GitReference[]> {
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_TAGS,
      'Project tags route not defined'
    );
    return this.getGitReferences(route, id, options);
  }

  /**
   * Returns the snapshots of a specific project.
   * @param id The id of the {@link Project} to get the snapshots from.
   * @param options The options. See {@link AbortOptions}.
   * @returns The projects snapshots.
   */
  async getSnapshots(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<ProjectSnapshots> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_SNAPSHOTS,
      'Project snapshots route not defined'
    );

    const projectSnapshots = await getJsonFromServerRoute<ProjectSnapshots>(
      route,
      {
        args: { id },
        abortController: filledOptions.abortController,
      }
    );

    // Remap date of snapshot to Date
    for (const [type, info] of Object.entries(projectSnapshots)) {
      projectSnapshots[type] = info.map((snapshot) => {
        snapshot.date = dayjs(snapshot.date).toDate();
        return snapshot;
      });
    }

    return projectSnapshots;
  }

  /**
   * Attaches an image to the project.
   * @param id The id of the {@link Project} to change.
   * @param imgFile The image file or `null` to remove it.
   * @param options See {@link AbortOptions}
   * @returns The project updated information.
   */
  static async patchPicture(
    id: Project['id'],
    imgFile: File | null,
    options?: Partial<AbortOptions>
  ): Promise<User> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_SET_IMAGE,
      'project image route not defined'
    );
    const formPayload = new FormData();
    if (imgFile) {
      formPayload.append('img', imgFile);
      formPayload.append('delete', 'false');
    } else {
      formPayload.append('delete', 'true');
    }

    return await postJsonFromServerRoute(route, {
      method: 'POST',
      abortController: filledOptions.abortController,
      args: {
        id,
      },
      formPayload,
    });
  }

  transformGottenPartialEntity(entity: ProjectPartial): ProjectPartial {
    const transformedEntity = this.transformGottenCommonEntity(entity);
    if (transformedEntity.billingCompany) {
      transformedEntity.billingCompany = BaseApi.getIdFromIri(
        transformedEntity.billingCompany
      );
    }
    return transformedEntity;
  }

  transformGottenEntity(entity: Project): Project {
    return this.transformGottenCommonEntity(entity);
  }

  transformGottenCommonEntity<
    TCommonEntity extends Omit<Project, 'billingCompany'>
  >(entity: TCommonEntity): TCommonEntity {
    entity.userProjectRoles = entity.userProjectRoles.map(
      (userProjectRoleIri) => BaseApi.getIdFromIri(userProjectRoleIri)
    );
    entity.environments = entity.environments.map((envIri) =>
      BaseApi.getIdFromIri(envIri)
    );
    entity.contact =
      entity.contact !== undefined
        ? BaseApi.getIdFromIri(entity.contact)
        : undefined;

    return entity;
  }

  transformPatchArgs(patchArgs: PatchArgs): FormData | Record<string, unknown> {
    return {
      ...patchArgs,
      billingCompany: patchArgs.billingCompany
        ? new CompanyApi().getIriFromId(patchArgs.billingCompany)
        : null,
    };
  }

  /**
   * @param id The id of the {@link Project}.
   * @param options The options. See {@link AbortOptions}.
   * @returns The estimates costs for the current project.
   */
  async getEstimates(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<Estimate[]> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_ESTIMATIONS,
      'Estimations route not defined'
    );

    const rawEstimates = (await getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    })) as RawEstimate[];

    return rawEstimates.map(refineEstimate);
  }

  async getInitEnvEstimates(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<InitEnvEstimate> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_INIT_ENV_ESTIMATED_BILL,
      'Init env estimates route not defined'
    );

    const rawEstimates = (await getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    })) as RawInitEnvEstimate;

    return refineInitEnvEstimate(rawEstimates);
  }

  async getPricings(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<ProjectPricing[]> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_PRICING,
      'Pricing route not defined'
    );

    return getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    });
  }

  async getSpecifications(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<ProjectSpecifications> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_SPECIFICATIONS,
      'Specifications route not defined'
    );

    return getJsonFromServerRoute(route, {
      args: { id },
      abortController: filledOptions.abortController,
    });
  }

  /**
   * Change the contact for the given {@link projectId} to the contact matching {@link contactId}.
   * @param projectId The {@link Project} id.
   * @param contactId The contact id. (from an existing {@link User}).
   * @param options The options. See {@link AbortOptions}.
   * @returns The patched {@link Project}.
   */
  async patchContact(
    projectId: Project['id'],
    contactId: ContactPatch['contactId'],
    options?: Partial<AbortOptions>
  ): Promise<Project> {
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_CONTACT,
      'Contact route not defined'
    );

    const payload: ContactPatch = {
      contactId,
    };

    const patchedProject: Project = await postJsonFromServerRoute(route, {
      args: { id: projectId },
      payload,
      abortController: filledOptions.abortController,
      method: 'PATCH',
    });
    return this.transformGottenEntity(patchedProject);
  }

  /**
   * @returns `true` if the {@link patchArgs} contains information that are different from {@link Project}.
   */
  static isPatchNeeded(project: Project, patchArgs: PatchArgs): boolean {
    const { billingCompany, ...similarProps } = patchArgs;
    for (const prop of getKeys(similarProps)) {
      if (project[prop] !== similarProps[prop]) {
        return true;
      }
    }
    return (project.billingCompany?.id ?? null) !== billingCompany;
  }

  /**
   * Adds a user (identified by its {@link email}) to the project with given {@link id}.
   * @param role The role to attach to the user.
   * @param options See {@link AbortOptions}.
   *
   * @throws {@link ProjectAddUserException} if something goes wrong, but this is a handled case back-side.
   * @throws {@link NetworkRequestException} if the error is not handled.
   */
  async addUser(
    id: Project['id'],
    email: User['email'],
    role: UserProjectRoleType,
    options?: Partial<AbortOptions>
  ): Promise<void> {
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_ADD_USER,
      'Add user route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    try {
      await postJsonFromServerRoute(route, {
        args: { id },
        payload: {
          email,
          role,
        },
        abortController: filledOptions.abortController,
        expectJsonResponse: false,
      });
    } catch (e) {
      if (e instanceof NetworkRequestException) {
        switch (e.status) {
          case 400:
            throw new ProjectAddUserException({
              type: 'user_already_there',
              email,
            });
          case 404:
            switch (e.messageKey) {
              case 'exception.user.not_found':
                throw new ProjectAddUserException({
                  type: 'user_not_found',
                  email,
                });
              case 'exception.role.not_allowed':
                throw new ProjectAddUserException({
                  type: 'role_not_allowed',
                  givenRole: role,
                });
            }
            break;
        }
      }
      throw e;
    }
  }

  /**
   * Try to autodicover jobs for the project with given {@link id}.
   * @param options See {@link AbortOptions}.
   *
   * @throws {@link ProjectAddUserException} if something goes wrong, but this is a handled case back-side.
   * @throws {@link NetworkRequestException} if the error is not handled.
   */
  async autodiscoverJobs(
    id: Project['id'],
    options?: Partial<AbortOptions>
  ): Promise<void> {
    const route = unwrap(
      REACT_APP_ROUTE_PROJECTS_JOB_DISCOVERY,
      'Job discovery route not defined'
    );
    const filledOptions = fillOptions(options, AbortOptionsDefault);

    try {
      await postJsonFromServerRoute(route, {
        args: { id },
        abortController: filledOptions.abortController,
        expectJsonResponse: false,
      });
    } catch (e) {
      if (e instanceof NetworkRequestException) {
        throw e;
      }
    }
  }
}

/**
 * The projects snapshots list.
 */
export interface ProjectSnapshots {
  [snapshotType: string]: ProjectSnapshot[];
}

/**
 * A single project snapshot.
 */
export interface ProjectSnapshot {
  /** Identifier of the snapshot. */
  id: string;
  /** Date of snapshot creation. */
  date: Date;
}

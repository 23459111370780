import { create } from 'zustand';
import { ProjectApi } from '../api/projectApi';
import { Project, ProjectPartial } from '../models/project';
import { unwrap } from '../utilities/assertions';
import { AbortOptions, AbortOptionsDefault } from '../utilities/fetchUtilities';
import { fillOptions } from '../utilities/options';
import { PromiseSnapshot, PromiseState } from '../utilities/promiseSnapshot';

/**
 * Options for {@link ProjectsStoreState.fetchSpecific}.
 */
export interface FetchOptions extends AbortOptions {
  /**
   * If `true` the request is performed no matter what, if false the request is performed only when needed. Default to `false`.
   */
  force: boolean;
}

const fetchOptionsDefault: FetchOptions = {
  force: false,
  ...AbortOptionsDefault,
};

export interface ProjectsStoreState {
  /**
   * The projects mapped by their ids. They will be only presents if fetched once.
   */
  projects: Map<Project['id'], PromiseSnapshot<Project | ProjectPartial>>;
  _projectsAbortController: Map<Project['id'], AbortOptions['abortController']>;
  projectNumber: PromiseSnapshot<number>;
  _projectNumberAbortController?: AbortController;
  selectedProjectId: Project['id'] | undefined;
  /**
   * Returns the current selected environment if there is any.
   */
  getProject: () => Project | ProjectPartial | undefined;
  setSelectedProjectId: (projectId: Project['id']) => void;
  /** Lazily fetch a specific project
   * @param projectId The project id.
   * @param options The options. See {@link FetchOptions}.
   */
  fetchSpecific: (
    projectId: Project['id'],
    options?: Partial<FetchOptions>
  ) => void;
  /**
   * Fetches the project number.
   * @param options See {@link FetchOptions}.
   */
  fetchProjectNumber(options?: Partial<FetchOptions>): void;
  /**
   * Updates the project with given {@link projects}.
   * The given {@link projects} are considered to be the source of truth and will overwrite the stored projects with the same id.
   */
  localUpdateProjects(...projects: (Project | ProjectPartial)[]): void;
}

export const useProjectsStore = create<ProjectsStoreState>(
  (set, get): ProjectsStoreState => {
    return {
      projects: new Map(),
      _projectsAbortController: new Map(),
      projectNumber: new PromiseSnapshot(),
      selectedProjectId: undefined,
      getProject() {
        const selectedProjectId = get().selectedProjectId;
        if (selectedProjectId === undefined) return undefined;
        return get().projects?.get(selectedProjectId)?.data;
      },
      setSelectedProjectId(projectId) {
        set({
          selectedProjectId: projectId,
        });
      },
      async fetchSpecific(projectId, options) {
        const filledOptions = fillOptions(options, fetchOptionsDefault);

        const projects = get().projects;
        if (
          filledOptions.force ||
          !projects.has(projectId) ||
          [PromiseState.Failed, PromiseState.NotStarted].includes(
            unwrap(projects.get(projectId)?.state)
          )
        ) {
          const abortController = filledOptions.abortController;
          const oldAbortController =
            get()._projectsAbortController.get(projectId);
          oldAbortController?.abort();

          PromiseSnapshot.trackPromiseSetter(
            () => new ProjectApi().get(projectId, { abortController }),
            (snapshot) => {
              const projects = get().projects;
              projects.set(projectId, snapshot);
              set({ projects });
            },
            { abortController }
          );
        }
      },
      fetchProjectNumber(options) {
        const filledOptions = fillOptions(options, fetchOptionsDefault);

        const projectNumberSnapshot = get().projectNumber;
        const performRequest =
          filledOptions.force ||
          projectNumberSnapshot.state === PromiseState.Failed ||
          projectNumberSnapshot.state === PromiseState.NotStarted;

        if (!performRequest) return;

        if (
          projectNumberSnapshot.state === PromiseState.Running ||
          projectNumberSnapshot.state === PromiseState.Succeeded
        ) {
          get()._projectNumberAbortController?.abort();
        }

        const abortController = filledOptions.abortController;

        set({
          projectNumber: PromiseSnapshot.buildRunning(),
          _projectNumberAbortController: abortController ?? undefined,
        });

        PromiseSnapshot.trackPromiseSetter(
          async () => {
            const paginatedProjects = await new ProjectApi().getAllPaginated(
              { itemsPerPage: 0 },
              { abortController }
            );
            return paginatedProjects['hydra:totalItems'];
          },
          (projectNumber) => set({ projectNumber }),
          { abortController }
        );
      },
      localUpdateProjects(...projects) {
        const projectsMap = get().projects;
        for (const project of projects) {
          const oldProject = projectsMap.get(project.id) ?? {};
          projectsMap.set(
            project.id,
            PromiseSnapshot.buildSucceeded({ ...oldProject, ...project })
          );
        }
        set({ projects: projectsMap });
      },
    };
  }
);

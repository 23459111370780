import { ForbiddenException } from '../exceptions/forbiddenException';
import { NotImplementedException } from '../exceptions/notImplementedException';
import { Entity } from '../models/entity';
import { Uuid } from '../models/uuid';
import { unwrap, unwrapNull } from './assertions';
import {
  getJsonFromServerRoute,
  postJsonFromServerRoute,
} from './fetchUtilities';
import { fillOptions } from './options';
import { QueryOptionsHandler } from './queryOptionsHandler';

export interface BaseApiGetOption {
  abortController: AbortController | null;
}

export const baseApiGetOptionDefaults: BaseApiGetOption = {
  abortController: null,
};

export type BaseApiPatchOption = BaseApiGetOption;

const baseApiPatchOptionDefaults = baseApiGetOptionDefaults;
export type BaseApiPostOption = BaseApiGetOption;
export const baseApiPostOptionDefaults = baseApiGetOptionDefaults;

export type BaseApiDeleteOption = BaseApiGetOption;
export const baseApiDeleteOptionDefaults = baseApiGetOptionDefaults;

export type ApiPlatformList<E> = {
  'hydra:member': E[];
  'hydra:totalItems': number;
  'hydra:view': Record<
    'hydra:first' | 'hydra:last' | 'hydra:previous' | 'hydra:next',
    string
  >;
};

/**
 * Base class to work with the backend API.
 * @template TEntity The type of the entity.
 * @template TGetOptions The type of the GET options. (See {@link QueryOptionsHandler} for more details.)
 * @template TEntityPartial The type of the entity when retrieved through the {@link getAll} or {@link getAllPaginated} method. (default to {@link TEntity}).
 * @template TPostArgs The type of entity POST arguments. (default to `null` making it not usable).
 * @template TPatchArgs The type of entity PATCH arguments. (default to `null` making it not usable).
 */
export class BaseApi<
  TEntity extends Entity,
  TGetOptions,
  TEntityPartial extends Entity = TEntity,
  TPostArgs extends Record<string, unknown> | null = null,
  TPatchArgs extends Record<string, unknown> | null = null
> {
  private _entityRoute: string;
  private _getOptionsHandler: new (
    options: TGetOptions
  ) => QueryOptionsHandler<TGetOptions>;
  protected _allowDelete: boolean;

  public get entityRoute() {
    return this._entityRoute;
  }

  /**
   * Constructs the base API.
   * @param entityRoute The route where the entities can be fetched. (example: `server_adress:port/api/myEntities`).
   * @param getOptionsHandler The {@link QueryOptionsHandler} for this api GET result processing.
   * @param options The options
   *   * `allowDelete`: Does this API have the rights to delete an entity. (Default: `false`)
   */
  constructor(
    entityRoute: string,
    getOptionsHandler: new (
      options: TGetOptions
    ) => QueryOptionsHandler<TGetOptions>,
    options?: { allowDelete?: boolean }
  ) {
    this._entityRoute = entityRoute;
    this._getOptionsHandler = getOptionsHandler;
    this._allowDelete = options?.allowDelete ?? false;

    this.transformGottenEntity = this.transformGottenEntity.bind(this);
    this.transformGottenPartialEntity =
      this.transformGottenPartialEntity.bind(this);
    this.transformPatchArgs = this.transformPatchArgs.bind(this);
    this.transformPostArgs = this.transformPostArgs.bind(this);
    this.get = this.get.bind(this);
    this.getAll = this.getAll.bind(this);
    this.getAllPaginated = this.getAllPaginated.bind(this);
    this.post = this.post.bind(this);
    this.delete = this.delete.bind(this);
    this.getSeveralByIds = this.getSeveralByIds.bind(this);
    this.patch = this.patch.bind(this);
    this.getIriFromId = this.getIriFromId.bind(this);
  }

  /**
   * Gets all entities filtered by the given {@link options}.
   * Please consider the performance issue this request could make as this disables the `pagination` to have all elements.
   * The client of the API should be able to set `pagination` in order for this request to worK properly (otherwise the content may remain paginated).
   * @param options The options to provide to the request.
   * @returns All the fetched entities.
   */
  async getAll(
    apiOptions?: TGetOptions,
    options?: Partial<BaseApiGetOption>
  ): Promise<TEntityPartial[]> {
    const filledOptions = fillOptions(options, baseApiGetOptionDefaults);

    let url = this._entityRoute;

    if (apiOptions !== undefined) {
      const queryParams = new this._getOptionsHandler(
        apiOptions
      ).toQueryParams();
      if (queryParams) {
        queryParams.set('pagination', 'false');
        const stringifiedParams = queryParams.toString();
        if (stringifiedParams.length > 0) {
          url += '?' + stringifiedParams;
        }
      }
    }
    const res = await (getJsonFromServerRoute(url, {
      abortController: filledOptions.abortController,
    }) as Promise<{
      'hydra:member': TEntityPartial[];
    }>);
    return res['hydra:member'].map(this.transformGottenPartialEntity);
  }

  /**
   * Gets all entities paginated and filtered by the given {@link options}.
   * @param options The options to provide to the request.
   * @returns All the fetched entities.
   */
  async getAllPaginated(
    apiOptions?: TGetOptions,
    options?: Partial<BaseApiGetOption>
  ): Promise<ApiPlatformList<TEntityPartial>> {
    const filledOptions = fillOptions(options, baseApiGetOptionDefaults);

    let url = this._entityRoute;

    if (apiOptions !== undefined) {
      const queryParams = new this._getOptionsHandler(
        apiOptions
      ).toQueryParams();
      if (queryParams) {
        const stringifiedParams = queryParams.toString();
        if (stringifiedParams.length > 0) {
          url += '?' + stringifiedParams;
        }
      }
    }
    const res = await (getJsonFromServerRoute(url, {
      abortController: filledOptions.abortController,
    }) as Promise<ApiPlatformList<TEntityPartial>>);
    res['hydra:member'] = res['hydra:member'].map(
      this.transformGottenPartialEntity
    );
    return res;
  }

  /**
   * Gets a specific entity using its {@link id} to identify it.
   * @param id The id of the entity to fetch.
   * @param options The options of the request. See {@link BaseApiGetOption}.
   * @returns The entity matching this id.
   */
  async get(
    id: TEntity['id'],
    options?: Partial<BaseApiGetOption>
  ): Promise<TEntity> {
    const filledOptions = fillOptions(options, baseApiGetOptionDefaults);
    const entity = await (getJsonFromServerRoute(this._entityRoute + '/{id}', {
      args: { id },
      abortController: filledOptions.abortController,
    }) as Promise<TEntity>);
    return this.transformGottenEntity(entity);
  }

  async post(
    args: TPostArgs,
    options?: Partial<BaseApiPostOption>
  ): Promise<TEntity> {
    if (args === null) {
      throw new NotImplementedException(
        `${this.constructor['name']}.post is not implemented`
      );
    }

    const transformedArgs = this.transformPostArgs(args);
    const payload =
      transformedArgs instanceof FormData ? undefined : transformedArgs;
    const formPayload =
      transformedArgs instanceof FormData ? transformedArgs : undefined;

    const filledOptions = fillOptions(options, baseApiPostOptionDefaults);
    return await (postJsonFromServerRoute(this._entityRoute, {
      expectJsonResponse: true,
      payload,
      formPayload,
      abortController: filledOptions.abortController,
    }) as Promise<TEntity>);
  }

  /**
   * Deletes a specific entity using its {@link id} to identify it.
   * @param id The id of the entity to delete.
   */
  async delete(
    id: Entity['id'],
    options?: Partial<BaseApiDeleteOption>
  ): Promise<void> {
    if (!this._allowDelete) {
      throw new ForbiddenException(
        `DELETE operation is not allowed for the ${this.constructor['name']}.`
      );
    }

    const filledOptions = fillOptions(options, baseApiDeleteOptionDefaults);
    await postJsonFromServerRoute(this._entityRoute + '/{id}', {
      args: { id },
      abortController: filledOptions.abortController,
      method: 'DELETE',
      expectJsonResponse: false,
    });
  }

  /**
   * Gets a specific set of entities using their {@link ids} to identify them.
   * @param ids The ids of the entity to fetch.
   * @returns The entities matching those ids.
   */
  async getSeveralByIds(
    ids: Set<TEntity['id']>,
    options?: Partial<BaseApiGetOption>
  ): Promise<Record<Uuid, TEntity>> {
    const filledOptions = fillOptions(options, baseApiGetOptionDefaults);

    const promises: Promise<void>[] = [];
    const entities: Record<Uuid, TEntity> = {};

    for (const id of ids) {
      promises.push(
        this.get(id, { abortController: filledOptions.abortController }).then(
          (entity) => {
            entities[id] = entity;
          }
        )
      );
    }

    await Promise.all(promises);

    return entities;
  }

  /**
   * Patches the entity with the data given by {@link args}.
   * @param id The id of the entity to patch.
   * @param args The payload to send with the PATCH method.
   * @param options The options of the request. See {@link BaseApiPatchOption}.
   */
  async patch(
    id: TEntity['id'],
    args: TPatchArgs,
    options?: BaseApiPatchOption
  ): Promise<TEntity> {
    if (args === null) {
      throw new NotImplementedException(
        `${this.constructor['name']}.patch is not implemented`
      );
    }

    const transformedArgs = this.transformPatchArgs(args);
    const payload =
      transformedArgs instanceof FormData ? undefined : transformedArgs;
    const formPayload =
      transformedArgs instanceof FormData ? transformedArgs : undefined;

    const filledOptions = fillOptions(options, baseApiPatchOptionDefaults);
    return this.transformGottenEntity.bind(this)(
      (await postJsonFromServerRoute(this._entityRoute + '/{id}', {
        args: { id },
        method: 'PATCH',
        abortController: filledOptions.abortController,
        payload,
        formPayload,
      })) as TEntity
    );
  }

  /**
   * Transforms the partial entity received.
   * @param entity The received partial entity.
   * @returns The partial entity processed for convenience purpose.
   */
  transformGottenPartialEntity(entity: TEntityPartial): TEntityPartial {
    return entity;
  }

  /**
   * Transforms the entity received.
   *
   * @description Tip: This may be convenient to reuse the behaviour of {@link transformGottenPartialEntity} in this method.
   *
   * @param entity The received entity.
   * @returns The entity processed for convenience purpose.
   */
  transformGottenEntity(entity: TEntity): TEntity {
    return entity;
  }

  /**
   * Transforms {@link postArgs} to the format expected by the API.
   * If not overrided, keeps the data as-is.
   */
  transformPostArgs(postArgs: TPostArgs): Record<string, unknown> | FormData {
    return unwrapNull(postArgs);
  }

  /**
   * Transforms {@link patchArgs} to the format expected by the API.
   * If not overrided, keeps the data as-is.
   */
  transformPatchArgs(
    patchArgs: TPatchArgs
  ): Record<string, unknown> | FormData {
    return unwrapNull(patchArgs);
  }

  /**
   * From an {@link iri}, returns the id.
   * @param iri An IRI (looking like `/api/entity_name/uuid`).
   * @returns The id (`uuid` part of IRI).
   */
  static getIdFromIri(iri: string): string {
    return unwrap(iri.split('/').at(-1));
  }

  /**
   * From an {@link id}, returns an IRI for this type of entity ({@link TEntity}).
   * @param id The id of the entity.
   * @returns The iri of this entity.
   */
  getIriFromId(id: TEntity['id']): string {
    return [this._entityRoute, id].join('/');
  }
}

import {
  TablePagination as MUITablePagination,
  TablePaginationProps as MUITablePaginationProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface TablePaginationProps
  extends Omit<
    MUITablePaginationProps,
    | 'component'
    | 'rowsPerPage'
    | 'rowsPerPageOptions'
    | 'backIconButtonProps'
    | 'nextIconButtonProps'
    | 'labelDisplayedRows'
    | 'page'
    | 'onPageChange'
  > {
  /**
   * The rows in a single page. Default to `12`.
   */
  rowsPerPage?: MUITablePaginationProps['rowsPerPage'];
  /**
   * The one-based index of the current page.
   */
  page: MUITablePaginationProps['page'];
  /**
   * Callback fired when the page is changed.
   *
   * @param {number} page The page selected.
   */
  onPageChange: (page: number) => void;
}

export function TablePagination(props: TablePaginationProps) {
  const { t } = useTranslation();
  const {
    rowsPerPage: optionalRowsPerPage,
    onPageChange,
    page,
    ...unhandledProps
  } = props;

  const rowsPerPage = optionalRowsPerPage ?? 12;

  const previousPageLabel = t('go_to_previous_page');
  const nextPageLabel = t('go_to_next_page');

  return (
    <MUITablePagination
      component="div"
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
      backIconButtonProps={{
        title: previousPageLabel,
        'aria-label': previousPageLabel,
      }}
      nextIconButtonProps={{
        title: nextPageLabel,
        'aria-label': nextPageLabel,
      }}
      labelDisplayedRows={function defaultLabelDisplayedRows({
        from,
        to,
        count,
      }) {
        const pageCount =
          count !== -1 ? count : t('table_pagination_more_than', { to });
        return t('table_pagination', {
          from,
          to,
          pageCount,
        });
      }}
      page={page - 1}
      onPageChange={(_ev, page) => onPageChange(page + 1)}
      {...unhandledProps}
    />
  );
}

import { ClassUtilities } from '../../../utilities/classUtility';

export interface BlockTabProps {
  children: React.ReactNode;
  isCurrentTab?: boolean;
  className?: string;
  onClick?: () => void;
}

export function BlockTab(props: BlockTabProps) {
  return (
    <div
      className={ClassUtilities.flatten(
        'Block relative shadow-md rounded-t-md min-w-1 bg-white transition-[padding] py-1 px-3 md:py-3 md:px-6 font-bold flex items-center',
        ClassUtilities.conditional({
          'transition-opacity opacity-50 hover:opacity-100':
            !props.isCurrentTab,
          'z-[1] text-blue-800': !!props.isCurrentTab,
        })
      )}
      style={{
        minHeight: '2rem',
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useUnparameterizedPath } from '../../../hooks/useUnparameterizedPath';
import { Project, ProjectPartial } from '../../../models/project';
import { useLoadingScreenStore } from '../../../stores/loadingScreenStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import {
  PromiseSnapshot,
  PromiseState,
} from '../../../utilities/promiseSnapshot';
import { InformationBlock } from '../../atoms/InformationBlock/InformationBlock';
import { process } from './utilities';

/**
 * Guards that try to ensure a project is selected through the URL. Handles the following tasks:
 * * Uses the project given in the URL to set stores data.
 * * Sets the URL with a default project if at least a project is accessible for the user.
 * * Set the URL project to the store project if a change is made externally in the store.
 */
export function RequireProject() {
  const { t } = useTranslation();
  const { projectId, ...otherParams } = useParams();
  const rawPath = useUnparameterizedPath();
  const redirect = rawPath;
  const abortControllerRef = useRef(new AbortController());

  const navigate = useNavigate();

  const [projectSnapshot, setProjectSnapshot] = useState<
    PromiseSnapshot<Project | ProjectPartial | null>
  >(new PromiseSnapshot());

  // Get selected project
  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () => process(projectId, { abortController }),
      setProjectSnapshot,
      { abortController }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [projectId]);

  // Retrigger function if the project disappears in the store or project id changes.
  useEffect(() => {
    if (!projectSnapshot.isSucceeded()) return;

    const unsubscribe = useProjectsStore.subscribe((state) => {
      // If the project has been removed
      if (
        state.getProject() === undefined ||
        state.getProject()?.id !== projectId
      ) {
        PromiseSnapshot.trackPromiseSetter(
          () => process(state.getProject()?.id),
          setProjectSnapshot
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [projectId, projectSnapshot]);

  // Handle loading screen
  useEffect(() => {
    if (
      [PromiseState.NotStarted, PromiseState.Running].includes(
        projectSnapshot.state
      )
    ) {
      const loadingProjectLabel = t('loading_project');
      useLoadingScreenStore.setState({
        opened: true,
        title: loadingProjectLabel,
      });
    } else {
      useLoadingScreenStore.setState({ opened: false });
    }
  }, [projectSnapshot.state, t]);

  // Handle redirection if necessary
  useEffect(() => {
    if (!projectSnapshot.isSucceeded()) return;
    const project = projectSnapshot.getSucceededData();

    if (project === null) return;

    if (projectId !== project.id) {
      navigate(
        generatePath(redirect, {
          projectId: project.id,
          ...otherParams,
        }),
        { replace: false }
      );
    }
  }, [navigate, otherParams, projectId, projectSnapshot, redirect]);

  // Close loading when the component unmount
  useEffect(() => {
    return () => {
      useLoadingScreenStore.setState({
        opened: false,
      });
    };
  }, []);

  // Triggers state related function, when it changes.
  if (!projectSnapshot.isSucceeded()) return <></>;

  const project = projectSnapshot.getSucceededData();

  if (project === null)
    return <InformationBlock>{t('no_existing_project')}</InformationBlock>;

  return <Outlet />;
}

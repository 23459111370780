import { unwrap } from '../../../../utilities/assertions';
import { Colors } from '../../../../utilities/colors';

//#region Filled styles
export const blueFilledStyle = {
  '&': {
    color: Colors.fromTailwind('blue-800'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('blue-500'),
  },
};

export const lightGrayFilledStyle = {
  '&': {
    color: Colors.fromTailwind('grey-500'),
    backgroundColor: Colors.fromTailwind('grey-100'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('grey-200'),
  },
};

export const grayFilledStyle = {
  '&': {
    color: Colors.fromTailwind('grey-800'),
    backgroundColor: Colors.fromTailwind('grey-300'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('grey-500'),
  },
};

export const lightOrangeFilledStyle = {
  '&': {
    color: Colors.fromTailwind('orange'),
    backgroundColor: Colors.withAlpha(
      unwrap(Colors.fromTailwind('orange')),
      0.1
    ),
  },
  '&:hover': {
    backgroundColor: Colors.withAlpha(
      unwrap(Colors.fromTailwind('orange')),
      0.2
    ),
  },
};

export const greenFilledButton = {
  '&': {
    color: Colors.fromTailwind('grey-800'),
    backgroundColor: Colors.fromTailwind('green-200'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('green-500'),
  },
};

export const redFilledButton = {
  '&': {
    color: 'rgba(255, 255, 255, 0.8)',
    backgroundColor: Colors.fromTailwind('red-400'),
  },
  '&:hover': {
    backgroundColor: Colors.fromTailwind('red-600'),
  },
};
//#endregion Filled styles

/**
 * A style for discrete buttons. The button and its content is more tightly packed and the text is lighter.
 */
export const tightStyle = {
  '&': {
    padding: `0px 0.75rem`,
    minWidth: '0px',
    fontWeight: 'normal',
    fontSize: '0.875rem',
  },
};

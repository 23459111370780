import {
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCanCreateNewEnvironment } from '../../../hooks/useCanCreateNewEnvironment';
import { useProjectAndEnvironmentRoute } from '../../../hooks/useProjectAndEnvironmentRoute';
import { PartialEnvironment } from '../../../models/environment';
import { EnvironmentStatus } from '../../../models/environmentStatus';
import { useProjectEnvironmentsStore } from '../../../stores/projectEnvironmentsStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { unwrap } from '../../../utilities/assertions';
import { Colors } from '../../../utilities/colors';
import { RoundedFilledBlueButton } from '../../atoms/Button/variations/RoundedButtonVariations';
import { blueFilledStyle } from '../../atoms/Button/variations/sharedBase';

export interface EnvironmentPickerProps {
  /** Disables the possibility to switch environment. Default: `false`. */
  disabled?: boolean;
}

export function EnvironmentPicker(props: EnvironmentPickerProps) {
  const { t } = useTranslation();

  const canCreateNewEnvironment = useCanCreateNewEnvironment();
  const newEnvironmentRoute =
    useProjectAndEnvironmentRoute() + '/new-environment';

  const disabled = props.disabled ?? false;

  const [currentProjectId, noProject] = useProjectsStore((state) => [
    state.selectedProjectId,
    state.projectNumber.getSucceededData() === 0,
  ]);
  const [
    environmentsSnapshot,
    selectedEnvironmentIndex,
    setSelectedEnvironmentIndex,
    fetchEnvironments,
  ] = useProjectEnvironmentsStore((state) => [
    state.environments,
    state.selectedEnvironmentIndex,
    state.setSelectedEnvironmentIndex,
    state.fetch,
  ]);

  useEffect(() => {
    if (currentProjectId) {
      fetchEnvironments(currentProjectId);
    }
  }, [currentProjectId, fetchEnvironments]);

  const selectedEnvironment =
    environmentsSnapshot.data?.[selectedEnvironmentIndex];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function setSelectedEnvironment(env: PartialEnvironment) {
    setSelectedEnvironmentIndex(
      unwrap(environmentsSnapshot.data).findIndex((e) => e.id === env.id)
    );
  }

  return (
    <section className="EnvironmentPicker bg-white flex pr-8 justify-start md:justify-between gap-4 pb-2 md:pb-0">
      {environmentsSnapshot.data !== undefined ? (
        <>
          {selectedEnvironment ? (
            <>
              <div className="hidden lg:flex">
                <Tabs
                  value={selectedEnvironmentIndex}
                  onChange={(_ev, newValue) => {
                    setSelectedEnvironment(
                      unwrap(environmentsSnapshot.data)[newValue]
                    );
                  }}
                  scrollButtons={true}
                  allowScrollButtonsMobile
                  variant="scrollable"
                  sx={{ minWidth: '10px', flexShrink: 1 }}
                >
                  {environmentsSnapshot.data.map((environment, index) => (
                    <StyledTab
                      key={environment.id}
                      value={index}
                      disabled={
                        environment.status === EnvironmentStatus.Creating ||
                        disabled
                      }
                      label={
                        <span className="flex gap-2 EnvironmentPicker__tab-content">
                          <span>{environment.name}</span>
                          <div className="flex items-center">
                            {environment.status ===
                              EnvironmentStatus.Creating && (
                              <i className="icon-loader animate-spin-slow"></i>
                            )}
                            {environment.status ===
                              EnvironmentStatus.Failed && (
                              <Tooltip title={t('this_env_creation_failed')}>
                                <i className="icon-x-square" />
                              </Tooltip>
                            )}
                            {environment.status ===
                              EnvironmentStatus.Running && (
                              <>
                                {!environment.isMonoVM && (
                                  <Tooltip title={t('this_env_is_scalable')}>
                                    <i className="icon-multi-server"></i>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </div>
                        </span>
                      }
                    />
                  ))}
                </Tabs>
              </div>
              <div className="flex lg:hidden pl-8">
                <div className="flex gap-2 items-center">
                  <span className="text-[0.875rem] flex gap-2">
                    {selectedEnvironment.name}
                    {selectedEnvironment.status ===
                      EnvironmentStatus.Creating && (
                      <div className="flex items-center">
                        <i className="icon-loader animate-spin-slow"></i>
                      </div>
                    )}
                  </span>
                  <IconButton
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    sx={{ backgroundColor: Colors.fromTailwind('grey-200') }}
                    size="small"
                    disabled={environmentsSnapshot.data.length < 2}
                  >
                    <i className="icon-chevron-down text-sm text-grey-500"></i>
                  </IconButton>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {environmentsSnapshot.data.map((environment) => (
                    <MenuItem
                      key={environment.id}
                      disabled={
                        environment.status === EnvironmentStatus.Creating ||
                        disabled
                      }
                      onClick={
                        environment.status !== EnvironmentStatus.Creating
                          ? () => {
                              setSelectedEnvironment(environment);
                              setAnchorEl(null);
                            }
                          : undefined
                      }
                    >
                      {environment.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </>
          ) : (
            <div className="text-grey-500 flex items-center text-smd italic pl-10 h-12">
              {t('no_existing_env_for_project')}
            </div>
          )}
          {canCreateNewEnvironment && (
            <div className="md:pb-2">
              <div className="hidden md:block">
                <Link to={newEnvironmentRoute}>
                  <RoundedFilledBlueButton
                    sx={{}}
                    startIcon={<i className="icon-plus"></i>}
                  >
                    {t('add_environment')}
                  </RoundedFilledBlueButton>
                </Link>
              </div>
              <div className="block md:hidden">
                <Link to={newEnvironmentRoute}>
                  <BlueIconButton
                    title={t('add_environment') ?? undefined}
                    size="small"
                  >
                    <i className="icon-plus text-sm"></i>
                  </BlueIconButton>
                </Link>
              </div>
            </div>
          )}
        </>
      ) : noProject ? (
        <div className="h-12"></div> // No project, thus nothing to display
      ) : (
        <div className="text-grey-500 flex gap-2 items-center pl-10 h-12">
          <i className="icon-loader animate-spin-slow"></i>
          <span>{t('loading')}</span>
        </div>
      )}
    </section>
  );
}

const StyledTab = styled(Tab)({
  textTransform: 'none',
});

const BlueIconButton = styled(IconButton)(blueFilledStyle);

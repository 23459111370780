import { User } from '../../../models/user';
import {
  UserProjectRoleHandler,
  UserProjectRoleType,
} from '../../../models/userProjectRole';

/**
 * @param user The user.
 * @param projectRole The most relevant role the user has in the project if the context provides a project.
 * @returns A term ready to be translated that describes very briefly the user role.
 */
export function getRoleTerm(
  user: User,
  projectRole: UserProjectRoleType | undefined | null
): string {
  if (user.admin) return 'role_admin';
  if (projectRole === undefined || projectRole === null) return 'role_user';
  return UserProjectRoleHandler.getTermForRole(projectRole);
}

import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';
import { Block, BlockProps } from '../Block/Block';

export interface ErrorBlockProps extends Pick<BlockProps, 'noShadow'> {
  className?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  /**
   * The text indicated before the children. Default: `"Avertissement :"`.
   */
  title?: string;
}

export function ErrorBlock(props: ErrorBlockProps) {
  const { t } = useTranslation();

  return (
    <Block
      className={ClassUtilities.flatten(
        'ErrorBlock flex flex-col md:flex-row justify-between',
        props.className
      )}
      context="error"
      noShadow={props.noShadow}
    >
      <div className="text-pink-500 flex items-center gap-2 leading-6 text-smd">
        <div className="pb-[0.2em] text-md">
          <i className="icon-alert-octagon"></i>
        </div>
        <p className="text-pink-500">
          <span className="text-pink-500 font-bold underline">
            {props.title ?? t('error') + t('colon')}
          </span>
          <span> </span>
          {props.children}
        </p>
      </div>
      {props.actions && (
        <div className="flex justify-end gap-2">{props.actions}</div>
      )}
    </Block>
  );
}

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CompanyApi } from '../../../../../api/companyApi';
import DefaultCompanyPicture from '../../../../../assets/images/default-company-picture.png';
import { Company, CompanyHandler } from '../../../../../models/company';
import { ApiPlatformList } from '../../../../../utilities/baseApi';
import { getServerUrl } from '../../../../../utilities/fetchUtilities';
import { MockData } from '../../../../../utilities/mockData';
import { PromiseSnapshot } from '../../../../../utilities/promiseSnapshot';
import { Alert } from '../../../../atoms/Alert/Alert';
import { Block } from '../../../../atoms/Block/Block';
import { Button } from '../../../../atoms/Button/Button';
import { DataPaper } from '../../../../atoms/DataPaper/DataPaper';
import { DataPaperElement } from '../../../../atoms/DataPaperElement/DataPaperElement';
import { Image } from '../../../../atoms/Image/Image';
import { LoadingBlock } from '../../../../atoms/LoadingBlock/LoadingBlock';
import { TablePagination } from '../../../../atoms/TablePagination/TablePagination';
import { FormTextField } from '../../../../atoms/TextField/FormTextField';
import { Title } from '../../../../atoms/Title/Title';
import { Schema } from './schema';

export function CompanyList() {
  const { t } = useTranslation();
  const [companiesSnapshot, setCompaniesSnapshot] = useState(
    new PromiseSnapshot<ApiPlatformList<Company>>()
  );
  const abortControllerRef = useRef(new AbortController());
  const [parameters, setParameters] = useState<Partial<Schema>>({});

  const { control, handleSubmit, setValue, watch } = useForm<Schema>({
    defaultValues: {
      id: '',
      name: '',
      page: 1,
    },
  });

  const page = watch('page');

  const changePage = (newPage: number) => {
    setValue('page', newPage);
  };

  // Fetch if necessary
  useEffect(() => {
    const abortController = abortControllerRef.current;

    PromiseSnapshot.trackPromiseSetter(
      () =>
        new CompanyApi().getAllPaginated(parameters, {
          abortController,
        }),
      setCompaniesSnapshot,
      {
        abortController,
      }
    );

    return () => {
      abortController.abort();
      abortControllerRef.current = new AbortController();
    };
  }, [parameters]);

  const onSubmit = (data: Schema) => {
    setParameters({
      id: data.id === '' ? undefined : data.id,
      name: data.name === '' ? undefined : data.name,
      page: data.page,
    });
  };

  const onTextFieldKeyDown: KeyboardEventHandler = (ev) => {
    if (ev.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Block>
      <div className="flex flex-col gap-6">
        <Title level={2}>{t('companies_list')}</Title>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 items-stretch">
          <FormTextField
            control={control}
            name="id"
            label={t('company_identifier')}
            placeholder={MockData.uuidV4}
            onKeyDown={onTextFieldKeyDown}
          />
          <FormTextField
            control={control}
            name="name"
            label={t('name')}
            onKeyDown={onTextFieldKeyDown}
          />
          <Button
            className="col-span-1 md:col-span-2 lg:col-span-1"
            variant="contained"
            startIcon={<i className="icon-search" />}
            onClick={handleSubmit(onSubmit)}
          >
            {t('search')}
          </Button>
        </div>
        <div>
          {companiesSnapshot.map({
            notStarted: 'running',
            running: () => (
              <LoadingBlock
                icon={<i className="icon-search animate-search" />}
                text={t('search_in_progress')}
              />
            ),
            failed: () => (
              <Alert severity="error">
                {t('companies_list_could_not_be_loaded')}
              </Alert>
            ),
            succeeded: ({
              'hydra:member': companies,
              'hydra:totalItems': totalItems,
            }) => (
              <>
                <TableContainer className="hidden lg:block">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('image')}</TableCell>
                        <TableCell>{t('company_identifier')}</TableCell>
                        <TableCell>{t('name')}</TableCell>
                        <TableCell>{t('billing_contact')}</TableCell>
                        <TableCell>{t('address')}</TableCell>
                        <TableCell>{t('actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companies.map((company) => (
                        <TableRow key={company.id}>
                          <TableCell>
                            <Image
                              src={
                                company.imgUri
                                  ? getServerUrl() + company.imgUri
                                  : DefaultCompanyPicture
                              }
                              alt={t('company_image')}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </TableCell>
                          <TableCell>{company.id}</TableCell>
                          <TableCell>{company.name}</TableCell>
                          <TableCell>
                            {company.billingContactFullname}
                          </TableCell>
                          <TableCell>
                            {new CompanyHandler(company).getFullAddress()}
                          </TableCell>
                          <TableCell>
                            <Tooltip title={t('show_edit_company')}>
                              <Link
                                to={`${company.id}/edit`}
                                state={{ company }}
                              >
                                <IconButton size="small">
                                  <i className="icon-edit text-orange" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="flex flex-col gap-4 lg:hidden">
                  {companies.map((company) => (
                    <DataPaper
                      key={company.id}
                      header={company.id}
                      content={[
                        <DataPaperElement
                          key="name"
                          label={t('name')}
                          value={company.name}
                        />,
                        <DataPaperElement
                          key="billing_contact"
                          label={t('billing_contact')}
                          value={company.billingContactFullname}
                        />,
                        <DataPaperElement
                          key="address"
                          label={t('address')}
                          fullSpan
                          value={new CompanyHandler(company).getFullAddress()}
                        />,
                      ]}
                    />
                  ))}
                </div>
                <TablePagination
                  count={totalItems}
                  page={page}
                  onPageChange={changePage}
                />
              </>
            ),
          })}
        </div>
      </div>
    </Block>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentActionTypeApi } from '../../../../../api/environmentActionTypeApi';
import { PartialEnvironment } from '../../../../../models/environment';
import { EnvironmentActionType } from '../../../../../models/environmentActionType';
import { ClassUtilities } from '../../../../../utilities/classUtility';
import { PromiseSnapshot } from '../../../../../utilities/promiseSnapshot';
import { Block } from '../../../../atoms/Block/Block';
import { ErrorBlock } from '../../../../atoms/ErrorBlock/ErrorBlock';
import { Title } from '../../../../atoms/Title/Title';
import { EnvironmentActionButton } from '../EnvironmentActionButton/EnvironmentActionButton';
import './ActionBlock.css';

export interface ActionBlockProps {
  environment: PartialEnvironment;
  className?: string;
}

export function ActionBlock(props: ActionBlockProps) {
  const { t } = useTranslation();
  //#region Fetch the action types
  const [envActionTypesSnapshot, setEnvActionTypesSnapshot] = useState<
    PromiseSnapshot<EnvironmentActionType[]>
  >(new PromiseSnapshot());
  const abortController = useRef(new AbortController());

  useEffect(() => {
    abortController.current.abort();
    abortController.current = new AbortController();

    PromiseSnapshot.trackPromiseSetter(
      async () => {
        return await new EnvironmentActionTypeApi().getAll(
          { 'environment[]': [props.environment.id] },
          { abortController: abortController.current }
        );
      },
      setEnvActionTypesSnapshot,
      { abortController: abortController.current }
    );
  }, [props.environment.id]);

  //#endregion

  return (
    <Block
      className={ClassUtilities.flatten(
        'ActionBlock flex flex-col gap-6 justify-self-start lg:justify-self-auto',
        props.className
      )}
    >
      <Title level={2}>Actions</Title>
      <div className="flex flex-col-reverse items-start sm:flex-row sm:items-center gap-2 justify-between">
        {envActionTypesSnapshot.map({
          notStarted: 'running',
          running: () => (
            <div className="action-buttons">
              <div className="w-full h-6 generic-placeholder rounded-md"></div>
              <div className="w-full h-6 generic-placeholder rounded-md"></div>
            </div>
          ),
          succeeded: (envActionTypes) => {
            if (envActionTypes.length === 0) {
              return (
                <div className="text-grey-500 text-smd italic">
                  {t('no_available_action_for_this_env')}
                </div>
              );
            } else {
              return (
                <div className="action-buttons">
                  {envActionTypes?.map((envActionType) => (
                    <EnvironmentActionButton
                      key={envActionType.id}
                      actionType={envActionType.actionType}
                      order={envActionType.position}
                    />
                  ))}
                </div>
              );
            }
          },
          failed: (error) => (
            <div className="grow self-stretch">
              <ErrorBlock noShadow>{`${error}`}</ErrorBlock>
            </div>
          ),
        })}
      </div>
    </Block>
  );
}

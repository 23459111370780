import { AbortedException } from '../exceptions/abortedException';
import { delayAbortable } from './delay';

/**
 * Executes {@link func} after {@link delay} unless the {@link abortController} signals an abortion.
 * @throws {@link AbortedException} if the {@link abortController} signals an abortion.
 * @returns The result of the function (if executed).
 */
export async function debounce<T>(
  func: (() => Promise<T>) | (() => T),
  delay: number,
  abortController: AbortController
): Promise<T> {
  const aborted = !(await delayAbortable(delay, abortController));
  if (aborted) {
    throw new AbortedException();
  } else {
    return func();
  }
}

import React from 'react';
import {
  SquaredIcon,
  SquaredIconProps,
} from '../../atoms/SquaredIcon/SquaredIcon';
import './IconParagraph.css';

/**
 * Presentation component to display some text next to an icon.
 */
export function IconParagraph(props: IconParagraphProps) {
  return (
    <div className="IconParagraph flex gap-2 items-center icon p-2 group">
      <SquaredIcon {...props.squaredIcon} />
      <div className="font-light text-sm">{props.children}</div>
    </div>
  );
}

export type IconParagraphProps = {
  /**
   * The text to display.
   */
  children: React.ReactNode;
  squaredIcon: SquaredIconProps;
};

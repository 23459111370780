import { ClassUtilities } from '../../../utilities/classUtility';
import { Block, BlockProps } from '../Block/Block';
import { InformationText } from '../InformationText/InformationText';

export interface InformationBlockProps extends Pick<BlockProps, 'noShadow'> {
  className?: string;
  children?: React.ReactNode;
}

export function InformationBlock(props: InformationBlockProps) {
  const { children, className, ...blockProps } = props;

  return (
    <Block
      className={ClassUtilities.flatten('InformationBlock', props.className)}
      {...blockProps}
    >
      <div className="p-2">
        <InformationText>{props.children}</InformationText>
      </div>
    </Block>
  );
}

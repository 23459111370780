import { Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import { useHorizontalOverflowDetector } from '../../../hooks/useHorizontalOverflowDetector';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button as MUIButton } from '../../atoms/Button/Button';
import { SquaredIcon } from '../../atoms/SquaredIcon/SquaredIcon';
import { DotBadge } from '../DotBadge/DotBadge';

/**
 * Derived from {@link MUIButton}. Will display a small squared icon
 * next to the button and use the props color for the icon and the button.
 */
export function ActionLine(props: ActionLineProps) {
  const justifyContent = props.justifyContent ?? 'center';

  // Overflow detection
  const textAreaRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLElement>(null);
  const overflow = useHorizontalOverflowDetector({
    element: textAreaRef,
    parent: containerRef,
  });

  const icon = (
    <SquaredIcon color={props.color} icon={props.icon} small={true} />
  );

  return (
    <Tooltip title={overflow ? props.tooltipText ?? '' : ''}>
      <span
        className={`flex items-center gap-2 grow justify-${justifyContent} max-w-full`}
        ref={containerRef}
      >
        {props.hasBadge === true ? <DotBadge>{icon}</DotBadge> : icon}
        <span
          className="text-ellipsis overflow-hidden grow text-left shrink min-w-0"
          ref={textAreaRef}
        >
          {props.children}
        </span>
      </span>
    </Tooltip>
  );
}

export type ActionLineProps = {
  color: string;
  icon: string;
  children?: React.ReactNode;
  justifyContent?: 'start' | 'center' | 'end';
  /**
   * The text to display in a tooltip if the content of the button is overflowing.
   */
  tooltipText?: string;
  /**
   * Adds a badge next to icon button. Default: `false`.
   */
  hasBadge?: boolean;
};

import { useTranslation } from 'react-i18next';
import { ClassUtilities } from '../../../utilities/classUtility';

export interface InformationTextProps {
  className?: string;
  children: React.ReactNode;
}

export function InformationText(props: InformationTextProps) {
  const { t } = useTranslation();

  return (
    <div className={ClassUtilities.flatten('InformationText', props.className)}>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <i className="icon-info"></i>
          <span>{t('informations')}</span>
        </div>
        <p className="text-smd font-light text-justify leading-6">
          {props.children}
        </p>
      </div>
    </div>
  );
}

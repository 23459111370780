import DefaultProjectIcon from '../assets/images/default_project_icon.jpg';
import { Project, ProjectPartial } from '../models/project';
import { getServerUrl } from '../utilities/fetchUtilities';
export { default as DefaultProjectIcon } from '../assets/images/default_project_icon.jpg';

/**
 * Returns the relevant image for the img src attribute of the {@link project}.
 * @param project The project.
 * @returns The image URI of the project if it is defined. The default project icon otherwise.
 */
export function getProjectIcon(project: ProjectPartial | Project | undefined) {
  return project !== undefined &&
    project.imgUri !== undefined &&
    project.imgUri !== null &&
    project.imgUri.length > 0
    ? getServerUrl() + project.imgUri
    : DefaultProjectIcon;
}

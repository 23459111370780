import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionTypeApi } from '../../../../api/actionTypeApi';
import { EnvironmentActionTypeApi } from '../../../../api/environmentActionTypeApi';
import { getActionRouting } from '../../../../constants/environmentAction';
import { ActionType } from '../../../../models/actionType';
import { entityEquality } from '../../../../models/entity';
import { Environment } from '../../../../models/environment';
import { EnvironmentActionType } from '../../../../models/environmentActionType';
import { useSnackbarStore } from '../../../../stores/snackbarStore';
import { all, contain, equals, removeFirst } from '../../../../utilities/array';
import { unwrap } from '../../../../utilities/assertions';
import {
  PromiseSnapshot,
  PromiseSnapshotData,
  PromiseState,
} from '../../../../utilities/promiseSnapshot';
import { ActionLine } from '../../../atoms/ActionLine/ActionLine';
import { Block } from '../../../atoms/Block/Block';
import { Button } from '../../../atoms/Button/Button';
import { LoadingBlock } from '../../../atoms/LoadingBlock/LoadingBlock';
import { Title } from '../../../atoms/Title/Title';
import { TransferListsHandler } from '../../../atoms/TransferLists/TransferListsHandler';
import { ActionTypeLists } from '../utilities';

/**
 * Props for {@link EnvironmentActionTypeManager}
 */
export interface EnvironmentActionTypeManagerProps {
  /** The {@link Environment} which {@link ActionType}s should be managed. */
  environment: Environment;
}

/**
 * A component to manage an environment linked {@link ActionType}s.
 * @param props See {@link EnvironmentActionTypeManagerProps}
 */
export function EnvironmentActionTypeManager(
  props: EnvironmentActionTypeManagerProps
) {
  const abortController = useRef(new AbortController());
  const { t } = useTranslation();
  const snackbarPromiseHandle = useSnackbarStore(
    (state) => state.handlePromise
  );

  /** All {@link ActionType} available. */
  const [actionTypesSnapshot, setActionTypesSnapshot] = useState<
    PromiseSnapshot<ActionType[]>
  >(new PromiseSnapshot());
  /** All {@link EnvironmentActionType} associated to this environment. */
  const [envActionTypesSnapshot, setEnvActionTypesSnapshot] = useState<
    PromiseSnapshot<EnvironmentActionType[]>
  >(new PromiseSnapshot());

  const [actionsSnapshot, setActionsSnapshot] = useState<
    PromiseSnapshot<ActionTypeLists>
  >(new PromiseSnapshot());

  const [submissionSnapshot, setSubmissionSnapshot] = useState<
    PromiseSnapshot<PromiseSnapshotData>
  >(new PromiseSnapshot());

  const [dbActions, setDbActions] = useState<ActionTypeLists | undefined>(
    undefined
  );
  const edited = useMemo(() => {
    if (dbActions === undefined || actionsSnapshot.data === undefined)
      return false;

    return (
      !equals(
        dbActions.enabled,
        actionsSnapshot.data.enabled,
        entityEquality
      ) ||
      !equals(dbActions.disabled, actionsSnapshot.data.disabled, entityEquality)
    );
  }, [actionsSnapshot.data, dbActions]);

  const setDisabledActions = useCallback(
    (disabledActions: ActionType[]) => {
      setActionsSnapshot(
        PromiseSnapshot.buildSucceeded({
          disabled: disabledActions,
          enabled: unwrap(actionsSnapshot.data).enabled,
        })
      );
    },
    [actionsSnapshot.data]
  );

  const setEnabledActions = useCallback(
    (enabledActions: ActionType[]) => {
      setActionsSnapshot(
        PromiseSnapshot.buildSucceeded({
          disabled: unwrap(actionsSnapshot.data).disabled,
          enabled: enabledActions,
        })
      );
    },
    [actionsSnapshot.data]
  );

  const fetchActionTypes = useCallback(
    () =>
      PromiseSnapshot.trackPromiseSetter(
        () =>
          new ActionTypeApi().getAll(
            {},
            { abortController: abortController.current }
          ),
        setActionTypesSnapshot
      ),
    []
  );

  const fetchEnvActionTypes = useCallback(
    (environmentId: Environment['id']) =>
      PromiseSnapshot.trackPromiseSetter(
        async () =>
          _.sortBy(
            await new EnvironmentActionTypeApi().getAll({
              'environment[]': [unwrap(environmentId)],
            }),
            'position'
          ),
        setEnvActionTypesSnapshot
      ),
    []
  );

  /** {@link actionTypesSnapshot} handling */
  useEffect(() => {
    fetchActionTypes();
  }, [fetchActionTypes]);

  /** {@link envActionTypesSnapshot} handling */
  useEffect(() => {
    fetchEnvActionTypes(props.environment.id);
  }, [props.environment.id, fetchEnvActionTypes]);

  // Sort fetched actions
  useEffect(() => {
    const snapshotsDependencies = [actionTypesSnapshot, envActionTypesSnapshot];

    // Check everything is ready, otherwise abort
    if (
      contain(
        snapshotsDependencies,
        (snapshot) => snapshot.state !== PromiseState.Succeeded
      )
    )
      return;

    // Get base data to work with
    const actionTypes = actionTypesSnapshot.getSucceededData();
    const envActionTypes = envActionTypesSnapshot.getSucceededData();

    const disabled = actionTypes;
    const enabled: ActionType[] = [];

    for (const envActionType of envActionTypes) {
      removeFirst(disabled, envActionType.actionType, entityEquality);
      enabled.push(envActionType.actionType);
    }

    setDbActions({ enabled: [...enabled], disabled: [...disabled] });
    setActionsSnapshot(PromiseSnapshot.buildSucceeded({ enabled, disabled }));
  }, [actionTypesSnapshot, envActionTypesSnapshot]);

  // Abort request if page is quitted
  useEffect(() => {
    const thisAbortController = abortController.current;

    return () => {
      thisAbortController.abort();
      abortController.current = new AbortController();
    };
  }, []);

  // Edit button callback
  const edit = useCallback(async () => {
    const actions = actionsSnapshot.getSucceededData();
    const envActions = envActionTypesSnapshot.getSucceededData();
    const originalActions = unwrap(dbActions);

    const promises: Promise<unknown>[] = [];

    // Delete disabled action
    for (const disabledAction of actions.disabled) {
      if (
        contain(originalActions.enabled, (action) =>
          entityEquality(action, disabledAction)
        )
      ) {
        const envActionToDelete = unwrap(
          envActions.find(
            (envAction) => envAction.actionType.id === disabledAction.id
          )
        );
        // DELETE
        promises.push(
          new EnvironmentActionTypeApi().delete(envActionToDelete.id)
        );
      }
    }

    let index = 0;
    for (const enabledAction of actions.enabled) {
      const matchingEnvActionType = envActions.find(
        (envAction) => envAction.actionType.id === enabledAction.id
      );

      const position = index;

      if (matchingEnvActionType !== undefined) {
        // PATCH
        if (position !== matchingEnvActionType.position) {
          promises.push(
            new EnvironmentActionTypeApi().patch(matchingEnvActionType.id, {
              position,
            })
          );
        }
      } else {
        // POST
        promises.push(
          new EnvironmentActionTypeApi().post({
            actionTypeId: enabledAction.id,
            environmentId: props.environment.id,
            position,
          })
        );
      }

      ++index;
    }

    PromiseSnapshot.trackPromiseSetter(
      () =>
        snackbarPromiseHandle<null>(
          'admin_environment_submission',
          async () => {
            setDbActions(undefined); // Invalidate database actions
            await Promise.all(promises);
            return null;
          },
          {
            getErrorMessage: (error) =>
              t('env_action_types_update_error', { error }),
            getLoadingMessage: () => t('env_action_types_update_in_progress'),
            getSuccessMessage: () => t('env_action_types_update_succeeded'),
          },
          {
            abortController: abortController.current,
            autoHideSuccessDuration: 3000,
          }
        ),
      setSubmissionSnapshot
    ).finally(() => fetchEnvActionTypes(props.environment.id));
  }, [
    actionsSnapshot,
    envActionTypesSnapshot,
    dbActions,
    props.environment.id,
    snackbarPromiseHandle,
    t,
    fetchEnvActionTypes,
  ]);

  const fetchEndedSuccessfully = useMemo(
    () =>
      all(
        [actionTypesSnapshot, envActionTypesSnapshot],
        (el) => el.state === PromiseState.Succeeded
      ),
    [actionTypesSnapshot, envActionTypesSnapshot]
  );

  return (
    <Block className="flex flex-col gap-4">
      <Title level={2}>{t('env_action_types')}</Title>
      {actionsSnapshot.state === PromiseState.Succeeded &&
      actionsSnapshot.data !== undefined ? (
        <TransferListsHandler
          disabled={
            !fetchEndedSuccessfully ||
            submissionSnapshot.state === PromiseState.Running
          }
          leftList={{
            title: t('disabled_actions'),
            data: actionsSnapshot.data.disabled,
          }}
          rightList={{
            title: t('enabled_actions'),
            data: actionsSnapshot.data.enabled,
          }}
          onLeftListDataChanged={setDisabledActions}
          onRightListDataChanged={setEnabledActions}
          elementToKey={(el) => el.id}
          renderElement={(el) => (
            <div className="p-2 pr-4 flex space-between align-baseline">
              <ActionLine
                color={t(`action_colors.${el.name}`)}
                icon={`icon-${t(`action_icons.${el.name}`)}`}
              >
                {t(`action_labels.${el.name}`)}
              </ActionLine>
              {getActionRouting(el.name) === undefined && (
                <div className="flex items-center">
                  <Tooltip title={t('admin_action_type_not_implemented')}>
                    <i className="icon-alert-triangle text-orange" />
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        />
      ) : (
        <LoadingBlock text={t('env_action_types_fetch_in_progress')} />
      )}
      <Button
        className="self-end"
        variant="contained"
        disabled={
          !fetchEndedSuccessfully ||
          !edited ||
          submissionSnapshot.state === PromiseState.Running
        }
        loading={submissionSnapshot.state === PromiseState.Running}
        onClick={edit}
      >
        {t('edit')}
      </Button>
    </Block>
  );
}
